import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import React, { useEffect, useState } from 'react'
import { FaFacebook } from 'react-icons/fa'
import { connect, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { loginFacebook, loginGoogle, loginUser } from '../../actions'
import * as routes from '../../constants/routes'
import ScrollToTop from '../layout/ScrollToTop'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: theme.spacing(1),
        minHeight: '30vh',
    },
    loginButton: {
        marginTop: theme.spacing(1),
    },
    signupButton: {},
    logo: {
        maxWidth: 300,
        margin: 20,
        marginTop: 0,
        marginBottom: 60,
    },
    resetButton: {
        marginTop: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
    },
    passwordInput: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    emailInput: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    istoriaLogo: {
        marginBottom: theme.spacing(1),
    },
    signupMessage: {
        color: '#666',
    },
    signup: {
        color: '#512da8',
    },
}))

function Login(props) {
    const classes = useStyles()
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const [showError, setShowError] = useState(false)
    const loginError = useSelector((state) => state.auth.loginError)
    const error = useSelector((state) => state.auth.error)
    const { dispatch } = props

    const { isAuthenticated } = props
    const FacebookButton = withStyles({
        label: {
            color: '#1877F2',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#1877F2',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#F6fafe',
                borderColor: '#0062cc',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#0062cc',
                borderColor: '#005cbf',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
    })(Button)

    const GoogleButton = withStyles({
        label: {
            color: '#444',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#444',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#fafafa',
                borderColor: '#444',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#fafafa',
                borderColor: '#444',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.1rem #fafafa',
            },
        },
    })(Button)
    const _loginUser = () => {
        console.log('logging in ' + loginEmail)
        dispatch(loginUser(loginEmail, loginPassword))
    }

    useEffect(() => {
        if (loginError) {
            setShowError(true)
        }
    }, [loginError])

    if (isAuthenticated) {
        return <Redirect to="/" />
    } else
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                classes={{
                    root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                }}
            >
                <ScrollToTop />
                <Snackbar
                    open={showError}
                    autoHideDuration={6000}
                    onClose={() => {
                        setShowError(false)
                    }}
                >
                    <Alert
                        onClose={() => {
                            setShowError(false)
                        }}
                        severity="error"
                    >
                        Error logging in: {loginError && error.message}
                    </Alert>
                </Snackbar>
                <Typography
                    style={{
                        fontSize: 28,
                        color: '#512da8',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.istoriaLogo}
                >
                    istoria
                </Typography>
                <FacebookButton
                    variant="outlined"
                    color="#1877F2"
                    onClick={() => {
                        console.log('signing in with facebook...')
                        dispatch(loginFacebook())
                    }}
                    startIcon={<FaFacebook />}
                    className={classes.resetButton}
                >
                    Login with Facebook
                </FacebookButton>
                <GoogleButton
                    variant="outlined"
                    component={Link}
                    startIcon={
                        <img
                            alt="Google logo"
                            height="20"
                            width="20"
                            src="https://storage.googleapis.com/istoria-images/assets/g-logo.png"
                        />
                    }
                    onClick={() => {
                        console.log('signing in with google...')
                        dispatch(loginGoogle())
                    }}
                    className={classes.resetButton}
                >
                    Sign in with Google
                </GoogleButton>
                <Divider className={classes.divider} />

                <TextField
                    onChange={(e) => {
                        setLoginEmail(e.target.value)
                    }}
                    id="loginUser"
                    variant="outlined"
                    className={classes.emailInput}
                    label="Email"
                />
                <TextField
                    onChange={(e) => {
                        setLoginPassword(e.target.value)
                    }}
                    id="loginPassword"
                    label="Password"
                    className={classes.passwordInput}
                    variant="outlined"
                    type="password"
                />
                <Button
                    variant="outlined"
                    color="primary"
                    classes={{ root: classes.loginButton }}
                    onClick={_loginUser}
                >
                    Login
                </Button>
                <Divider className={classes.divider} />
                <Typography variant="body2" className={classes.signupMessage}>
                    Don't have an account yet?&nbsp;
                    <Link
                        className={classes.signup}
                        to={{
                            pathname: routes.SIGNUP,
                        }}
                    >
                        Create an Account
                    </Link>
                </Typography>

                {/* or
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.signupButton }}
          component={Link}
          to={{
            pathname: routes.SIGNUP
          }}
        >
          Create Account
        </Button> */}
            </Grid>
        )
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
    }
}
export default connect(mapStateToProps)(Login)
