import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextField,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteUserProject,
    editProject,
    editSection,
} from '../../../actions/write'
import FeaturedPicker from '../FeaturedPicker'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.sm,
        marginTop: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    preview: {
        marginTop: 20,
    },
    previewMessage: {
        marginTop: 20,
        textAlign: 'center',
        color: '#666',
    },
    aboutInput: {
        width: '100%',
        backgroundColor: 'white',
    },
    aboutDescription: {
        width: '100%',
        backgroundColor: 'white',
    },
    aboutWarning: {
        color: '#666',
        marginTop: 20,
    },
    aboutGenre: {
        width: '100%',
        marginBottom: theme.spacing(2),
        backgroundColor: 'white',
    },
    genreInput: {
        border: 'none',
    },
    genreHolder: {
        margin: 0,
    },
    genresInput: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: theme.spacing(1),
    },

    tagsInput: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: theme.spacing(1),
    },
    chip: { margin: 0 },
}))

function DetailsTab() {
    const classes = useStyles()
    const [confirm, setConfirm] = React.useState(false)
    const [confirmDelete, setConfirmWriting] = React.useState(false)

    const dispatch = useDispatch()

    const current = useSelector((state) => state.ui.sidebarPosition)
    const project = useSelector((state) => state.write.project)
    const isOverview = current === -1

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const currentSection = project.sections.filter(
        (s) => s.position === current
    )[0]

    const [sectionTitle, setSectionTitle] = useState('')
    const [sectionDescription, setSectionDescription] = useState('')

    const [genres, setGenres] = useState([])
    const [tags, setTags] = useState([])

    const handleClose = () => {
        setConfirm(false)
    }
    const handleCloseDelete = () => {
        setConfirmWriting(false)
    }
    const genreList = [
        'Action',
        'Adventure',
        'Comedy',
        'Confessional',
        'Drama',
        'Family',
        'Fantasy',
        'Non-Fiction',
        'Fiction',
        'Historical Fiction',
        'History',
        'Horror',
        'Memoire',
        'Mystery',
        'Paranormal',
        'Perspectives',
        'Poetry',
        'Romance',
        'Science Fiction',
        'Spiritual',
        'Thriller',
    ]
    useEffect(() => {
        console.log(project.sections)
        if (currentSection) {
            console.log(currentSection)
            if (currentSection.description)
                setSectionDescription(currentSection.description)
            setSectionTitle(currentSection.title)
        }
    }, [project.sections, currentSection])

    useEffect(() => {
        // dispatch(getWriting(writing.id));
        console.log(process.env)
    }, [])

    useEffect(() => {
        if (isOverview) {
            setTitle(project.title)
            if (project.description) setDescription(project.description)
            console.log(project.tags)
            if (project.genres) setGenres(project.genres)
            if (project.tags) setTags(project.tags)
        } else {
        }
    }, [project, isOverview])

    const updateProject = () => {
        console.log('update project')
        dispatch(editProject(project.id, { title, description, genres, tags }))
    }
    const updateSection = () => {
        console.log('update section')
        console.log(sectionTitle)
        console.log(sectionDescription)
        dispatch(
            editSection(project.id, currentSection.sectionId, {
                sectionTitle,
                sectionDescription,
            })
        )
    }

    return (
        <Grid
            className={classes.mainBox}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Dialog
                open={confirm}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the project{' '}
                        <b>{project.title}</b>, including all writing? This
                        action can't be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(deleteUserProject(project.id))
                            handleClose()
                        }}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete <b>{project.title}</b>?
                        This action can't be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDelete}
                        color="primary"
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(deleteUserProject(project.id))
                            handleClose()
                        }}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete <b>{project.title}</b>?
                        This action can't be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            dispatch(deleteUserProject(project.id))
                            handleClose()
                        }}
                        color="primary"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={handleCloseDelete}
                        color="primary"
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <TextField
                className={classes.aboutInput}
                variant="outlined"
                label={isOverview ? 'Project Title' : 'Writing Title'}
                onChange={(e) => {
                    if (isOverview) setTitle(e.target.value)
                    else setSectionTitle(e.target.value)
                }}
                onBlur={isOverview ? updateProject : updateSection}
                value={isOverview ? title : sectionTitle}
            />
            <br />
            <TextField
                multiline
                className={classes.aboutDescription}
                variant="outlined"
                label={
                    isOverview ? 'Project Description' : 'Writing Description'
                }
                value={isOverview ? description : sectionDescription}
                onBlur={isOverview ? updateProject : updateSection}
                onChange={(e) => {
                    if (isOverview) setDescription(e.target.value)
                    else setSectionDescription(e.target.value)
                }}
            />

            <br />
            {isOverview && (
                <Autocomplete
                    multiple
                    className={classes.genresInput}
                    defaultValue={[]}
                    value={genres}
                    options={genreList}
                    onBlur={updateProject}
                    onChange={(e, value) => {
                        console.log(value)
                        setGenres(value)
                        console.log(genres)
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.tagsInput}
                            variant="outlined"
                            label="Genres"
                        />
                    )}
                />
            )}
            {isOverview && (
                <Autocomplete
                    freeSolo
                    multiple
                    options={[]}
                    className={classes.tagsInput}
                    value={tags}
                    onBlur={updateProject}
                    onChange={(e, value) => {
                        setTags(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.tagsInput}
                            variant="outlined"
                            label="Tags"
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                />
            )}

            {/* {isOverview && <Autocomplete
        multiple
        id="tags-filled"
        className={classes.tagsInput}
        onBlur={updateProject}
        onChange={(e, value) => {
          setTags(value);
        }}
        value={tags}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.tagsInput}
            variant="outlined"
            label="Tags"
          />
        )}
      />} */}

            {isOverview && <FeaturedPicker projectId={project.id} />}
            {isOverview && (
                <Button
                    variant="outlined"
                    startIcon={<FaExclamationTriangle />}
                    classes={{ root: classes.aboutDelete }}
                    onClick={() => {
                        setConfirm(true)
                    }}
                >
                    Delete Project
                </Button>
            )}

            {!isOverview && (
                <Button
                    variant="contained"
                    startIcon={<FaExclamationTriangle />}
                    classes={{ root: classes.aboutDelete }}
                    onClick={() => {
                        setConfirmWriting(true)
                    }}
                >
                    Delete Writing
                </Button>
            )}
        </Grid>
    )
}

export default DetailsTab
