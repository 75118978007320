import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Button,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Hidden
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import FeaturedLanding from './FeaturedLanding'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
  mainBox: {
    minHeight: '52vh',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundImage: 'url("https://storage.googleapis.com/istoria-images/assets/landing-banner-compressed-2.jpg")',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    backgroundSize: 'cover'
  },
  createAccountButton: {
    marginTop: '20px',
  },
  mainHeadingBlock: {
    textTransform: 'lowercase',
    backgroundColor: 'white',
    display: 'inline',
    lineHeight: 1.3,
    fontSize: '3rem',
    fontWeight: 'bold',
  },
  subHeadingBlock: {
    backgroundColor: 'white',
    display: 'inline',
    fontSize: '1.75rem',
    lineHeight: 1.4,
  },
  headingCopy: {
    maxWidth: 400,
  },
  subheadingCopy: {
    maxWidth: 400,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headingBox: {
    maxWidth: 600,
    minWidth: 300,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  subHeading: {

    textTransform: 'lowercase',
    display: 'inline',
    lineHeight: 1.5,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
  },
  subHeadingSmall: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
  },
  comingSoon: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontStyle: 'italic',
    fontFamily: 'Quicksand, sans-serif'
  },
  lightButton: {
    backgroundColor: 'white',
    borderRadius: 20,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:hover': {
      color: '#512da8',
      backgroundColor: 'white',
      border: '1px solid #512da8',
    },
  },

  darkButton: {
    borderRadius: 20,
    backgroundColor: '#512da8',
    color: 'white',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:hover': {
      color: '#512da8',
    },
  },
  divider: {
    borderTop: '1px solid #ccc',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  dividerHidden: {
    marginTop: theme.spacing(4),
  },
  dividerHiddenSmall: {
  },
  bodyCopy: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: theme.breakpoints.values.lg,
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontFamily: 'Quicksand, sans-serif'
  },
  bodyCopyLeft: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.lg,
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontFamily: 'Quicksand, sans-serif'
  },
  bodyCopyRight: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.lg,
    textAlign: 'right',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontFamily: 'Quicksand, sans-serif'
  },
  featuredBox: {
    border: '1px solid white',
    maxWidth: 600,
    minWidth: 300,
    textAlign: 'center',
    minHeight: 300,
    borderRadius: 20,
    backgroundColor: 'white',
    opacity: 0.6,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  featuredHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  leftGrid: {
    maxWidth: 500,
    minWidth: 400,
  },

  rightGrid: {
    maxWidth: '40%',
    minWidth: 400,
    textAlign: 'center'
  },
  gridImage: {
    maxWidth: 380,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  sideGrid: {
    maxWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(2),
  }
}))
function WelcomeLanding({ show, setShow, message }) {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.mainBox}
      >
        <Grid
          xs={6}
          item
          className={classes.headingBox}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box className={classes.headingCopy}>
            <Typography
              variant="h4"
              className={classes.mainHeadingBlock}
            >
              istoria is for everyone who loves stories
            </Typography>
          </Box>
          <Box className={classes.subheadingCopy}>
            <Typography
              variant="h6"
              className={classes.subHeadingBlock}
            >
              It's time for a truly decentralized world of
              writing. Share your story or discover your next
              literary love.
            </Typography>
            <Box></Box>
          </Box>
          <Button
            className={classes.lightButton}
            variant="outlined"
            component={Link}
            to={{
              pathname: '/signup',
            }}
          >
            Turn the Page
          </Button>
        </Grid>

      <FeaturedLanding />
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.sideGrid}
      >
        <Hidden mdUp>
          <Typography className={classes.subHeadingSmall} variant="h4">
            it takes a village to raise a story
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography className={classes.subHeading} variant="h4">
            it takes a village to raise a story
          </Typography>
        </Hidden>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >

          <Hidden mdUp>

            <Box
              className={classes.rightGrid}>
              <img className={classes.gridImage} src="https://storage.googleapis.com/istoria-images/assets/landing-circles.png" />
            </Box>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.leftGrid}
            >
              <Typography className={classes.bodyCopy} variant="h6">
                The best novels are born within community. On Istoria, this takes the form of Circles - literary groups focused on common genres, geographies, and goals.
              </Typography>

              <Button
                className={classes.darkButton}
                variant="outlined"
                onClick={() => {
                  window.location =
                    'https://docs.google.com/forms/d/e/1FAIpQLSdEITf88924DAaiIihptntkqmUuIwmwE9CFDuXNxrPg5STD_A/viewform?usp=sf_link'
                }}
              >
                Join A Circle
              </Button>
              <Typography className={classes.comingSoon} variant="h6">
                Circles coming soon
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.leftGrid}
            >
              <Typography className={classes.bodyCopyLeft} variant="h6">
                The best novels are born within community. On Istoria, this takes the form of Circles - literary groups focused on common genres, geographies, and goals.

              </Typography>
              <Button
                className={classes.darkButton}
                variant="outlined"
                onClick={() => {
                  window.location =
                    'https://docs.google.com/forms/d/e/1FAIpQLSdEITf88924DAaiIihptntkqmUuIwmwE9CFDuXNxrPg5STD_A/viewform?usp=sf_link'
                }}
              >
                Join A Circle
              </Button>
              <Typography className={classes.comingSoon} variant="h6">
                Circles coming soon
              </Typography>
            </Grid>
            <Box
              className={classes.rightGrid}>
              <img className={classes.gridImage} src="https://storage.googleapis.com/istoria-images/assets/landing-circles.png" />
            </Box>
          </Hidden>

        </Grid>
        <Hidden smDown>
          <Divider className={classes.dividerHidden} />
        </Hidden>
        <Hidden mdUp>
          <Typography className={classes.subHeadingSmall} variant="h4">
            Read, connect, and write for free on Istoria
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography className={classes.subHeading} variant="h4">
            Read, connect, and write for free on Istoria
          </Typography>
        </Hidden>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.leftGrid}
          >
            <Hidden mdUp>
              <Typography className={classes.bodyCopy} variant="h6">
                Istoria brings the best of digital life to a new world of storytelling. Still social, still free, but nuanced, inspiring, and authentic. Browse by the hundreds of stories written by our community, or sign up to add your voice.
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Typography className={classes.bodyCopyLeft} variant="h6">
                Istoria brings the best of digital life to a new world of storytelling. Still social, still free, but nuanced, inspiring, and authentic. Browse by the hundreds of stories written by our community, or sign up to add your voice.
              </Typography>
            </Hidden>
            <Button
              className={classes.darkButton}
              variant="outlined"
              component={Link}
              to={{
                pathname: routes.FEED,
                state: { tab: 'explore' },
              }}
            >
              Explore Istoria
            </Button>
          </Grid>
          <Box
            className={classes.rightGrid}>
            <img className={classes.gridImage} src="https://storage.googleapis.com/istoria-images/assets/landing-explore.png" />

          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WelcomeLanding
