import { Box, Button, Chip, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { format, formatDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
    FaClock,
    FaCalendar,
    FaEye,
    FaHeart,
    FaPen,
    FaRegHeart,
    FaUserCheck,
    FaUserPlus,
    FaChevronLeft,
    FaExclamationTriangle,
    FaVolumeUp,
    FaRunning,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getAvatarUrls, likePublished, unlikePublished } from '../../actions'
import { followProfile, unFollowProfile } from '../../actions/profile'
import { flagPublished } from '../../actions/admin'
import { getFeaturedUrls } from '../../actions/write'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'
import RawHTML from '../etc/RawHTML'
import ProfileAvatar from '../profile/ProfileAvatar'
import SharePublished from '../publish/SharePublished'
import PublishedComments from './PublishedComments'
import Confirm from '../layout/Confirm'
import FeaturedPicker from '../project/FeaturedPicker'
import SignUpModal from '../profile/SignUpModal'

const LikeChip = withStyles({
    root: {
        color: '#bd1e13',
        borderColor: '#bd1e13',
        paddingLeft: 2,
        backgroundColor: 'white',
    },
})(Chip)
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },

    mainBox: {
        maxWidth: theme.breakpoints.values.md,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    content: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    avatar: {
        marginRight: 8,
        height: 30,
        width: 30,
        display: 'inline-block',
        position: 'relative',
        bottom: 2,
    },
    avatarSkeleton: {
        position: 'relative',
        marginRight: 8,
        display: 'inline-block',
        height: 30,
        width: 30,
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    authorName: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
        bottom: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#512da8',
    },
    authorNameDefault: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
        bottom: 6,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#512da8',
    },
    authorText: {},
    authorBox: {},

    progressBox: {
        width: '100%',
        textAlign: 'center',
    },
    spinner: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    sectionHeading: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    follow: {
        marginLeft: theme.spacing(2),
        position: 'relative',
        bottom: 4,
    },
    byline: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    detailLine: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    whenLine: {},
    words: {
        color: '#666',
    },

    mediaLarge: {
        width: '100%',
    },

    scroll: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    publishedOn: {
        color: '#666',
    },
    calendar: {
        position: 'relative',
        top: 2,
        color: '#666',
    },
    readTime: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: '#666',
    },
    body: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    comments: {
        width: '100%',
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    sections: {
        backgroundColor: 'blue',
    },
    headingBox: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    sectionBody: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    nothing: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        textAlign: 'center',
        fontSize: '18pt',
        fontFamily: 'Gowun Batang',
    },
    likeCount: {
        // marginBottom: theme.spacing(1),
        border: '1px solid #bd1e13',
    },
    SharePublished: {
        position: 'relative',
        top: 0,
        marginTop: theme.spacing(2),
    },
    tags: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),

        paddingLeft: theme.spacing(1),
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
    },
    privateMessage: {
        color: '#666',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    adminHeader: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },
    pickerBox: {
        marginTop: theme.spacing(2),
    },
    adminBox: {},
    flagButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

function formatReadTime(published) {
    if (published) {
        const reducer = (accumulator, currentValue) =>
            accumulator + currentValue?.bodyText.split(' ').length

        let wordCount = published.publishedSections.reduce(reducer, null)
        let rt = Math.ceil(wordCount / 275)

        return formatDuration({
            minutes: rt,
        })
            .replace('minute', 'min')
            .replace('mins', 'min')
    }
}

function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return format(d, 'LLLL do, yyyy')
}
function compare(a, b) {
    let comparison = 0
    if (a.position > b.position) {
        comparison = 1
    } else if (a.position < b.position) {
        comparison = -1
    }
    return comparison
}
function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

function PublishedContent({ viaCommunity }) {
    const classes = useStyles()

    const dispatch = useDispatch()
    const history = useHistory()

    const loggedInProfile = useSelector((state) => state.profile.profile)
    const publishedReady = useSelector(
        (state) => state.write.publishedGettingSuccess
    )
    const published = useSelector((state) => state.write.published)
    const publishedGettingFailure = useSelector(
        (state) => state.write.publishedGettingFailure
    )
    const [toUrl, setToUrl] = useState('')

    const profile = useSelector((state) => state.profile.profile)

    const project = useSelector((state) => state.write.project)
    const [featuredUrl, setFeaturedUrl] = useState('')

    const [ready, setReady] = useState(false)
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [avatarLoaded, setAvatarLoaded] = useState(null)
    const [wordCount, setWordCount] = useState('')
    const [likedCount, setLikedCount] = useState(0)
    const [firstLikedLoad, setFirstLikedLoad] = useState(true)
    const [readTime, setReadTime] = useState(-1)

    const [liked, setLiked] = useState(false)
    const [likedHover, setLikedHover] = useState(false)

    const [following, setFollowing] = useState(false)
    const [confirmFlag, setConfirmFlag] = useState(false)

    const [displayStatus, setDisplayStatus] = useState('none')
    const [titleText, setTitleText] = useState('')

    const renderSections = (sections, isSection) => {
        return sections.sort(compare).map((section) => (
            <>
                {!section.default && !isSection && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.headingBox}
                    >
                        <Typography
                            className={classes.sectionHeading}
                            variant="h5"
                        >
                            {section.title}
                        </Typography>
                    </Grid>
                )}
                {section.bodyText.length > 2 && (
                    <div className={classes.sectionBody}>
                        <div className="quill-html">
                            <RawHTML>{section.body}</RawHTML>
                        </div>
                    </div>
                )}

                {section.bodyText.length <= 1 && (
                    <Typography className={classes.nothing}>
                        This section has been left blank.
                    </Typography>
                )}
            </>
        ))
    }

    useEffect(() => {
        if (displayStatus === 'block') {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [displayStatus])

    useEffect(() => {
        if (published?.profile) {
            if (loggedInProfile?.following_profile_ids) {
                if (
                    loggedInProfile.following_profile_ids.includes(
                        published.profile.id
                    )
                ) {
                    setFollowing(true)
                }
            }
        }
    }, [loggedInProfile, published, dispatch])

    useEffect(() => {
        if (!published) {
            return
        }
        if (published.title) {
            setToUrl(
                routes.PUBLISHED.replace(
                    ':pid',
                    slugUrl(published.title, published.shortUrl)
                )
            )
        }
        if (firstLikedLoad) {
            if (published.liked_uids?.length > 0) {
                setLikedCount(published.liked_uids.length)
                setFirstLikedLoad(false)
                console.log('refreshed likes')
            }
        }
        if (published.liked_uids?.includes(profile.uid)) {
            setLiked(true)
        }
    }, [profile, published])

    useEffect(() => {
        if (published.featuredId) {
            getFeaturedUrls(published.featuredId).then((urls) => {
                console.log(urls)
                setFeaturedUrl(urls.featuredLarge)
                setReady(true)
            })
        } else {
            setReady(true)
        }
    }, [published])
    useEffect(() => {
        console.log(published)
        setAvatarUrl(null)
        if (published?.id) {
            const reducer = (accumulator, currentValue) =>
                accumulator + currentValue?.bodyText.split(' ').length
            let wordCount = published.publishedSections.reduce(reducer, null)

            let rt = Math.ceil(wordCount / 275)

            setWordCount(nFormatter(wordCount))
            setReadTime(
                formatDuration({
                    minutes: rt,
                }).replace('minute', 'min')
            )

            if (published.profile.avatarId) {
                getAvatarUrls(published.profile.avatarId).then((urls) => {
                    setAvatarLoaded(true)
                    if (urls) setAvatarUrl(urls.avatarSmall)
                })
            } else {
                setAvatarUrl(null)
                setAvatarLoaded(true)
            }
        }
    }, [published])

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.content}
            >
                {confirmFlag && (
                    <Confirm
                        confirm={confirmFlag}
                        setConfirm={setConfirmFlag}
                        message={
                            'Are you sure you want to flag this post for removal?'
                        }
                        action={'Flag'}
                        callback={() => {
                            flagPublished(published.id)
                            setTimeout(() => {
                                window.location.reload()
                            }, 500)
                        }}
                    />
                )}
                {viaCommunity && (
                    <Box>
                        <Button
                            component={Link}
                            startIcon={<FaChevronLeft />}
                            to={{
                                pathname: routes.COMMUNITY_VIEW.replace(
                                    ':id',
                                    published.community_id
                                ),
                            }}
                            className={classes.backContainer}
                        >
                            Back to {published.community_name}
                        </Button>
                    </Box>
                )}
                {!viaCommunity && (
                    <Box>
                        <Button
                            onClick={() => {
                                history.goBack()
                            }}
                            startIcon={<FaChevronLeft />}
                            className={classes.backContainer}
                        >
                            Back
                        </Button>
                    </Box>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.byline}
                >
                    <Box className={classes.authorBox}>
                        {avatarLoaded && (
                            <Box className={classes.avatar}>
                                <ProfileAvatar
                                    size="small"
                                    load
                                    avatarId={published.profile.avatarId}
                                />
                            </Box>
                        )}
                        {!avatarLoaded && (
                            <Skeleton
                                variant="circle"
                                className={classes.avatarSkeleton}
                                width={30}
                                height={30}
                            />
                        )}
                        <Typography
                            variant="h6"
                            className={
                                avatarUrl
                                    ? classes.authorName
                                    : classes.authorNameDefault
                            }
                            component={Link}
                            to={{
                                pathname: routes.PROFILE_PUBLIC.replace(
                                    ':id',
                                    published.profile.id
                                ),
                            }}
                        >
                            {published.profile.displayName}
                        </Typography>
                    </Box>
                    {published.profile.id === loggedInProfile.id && (
                        <Button
                            startIcon={<FaPen />}
                            variant="outlined"
                            component={Link}
                            to={{
                                pathname: routes.VIEW.replace(
                                    ':id',
                                    published.projectId
                                ),
                            }}
                        >
                            Edit
                        </Button>
                    )}
                    {!loggedInProfile.id && (
                        <Box>
                            <SignUpModal
                                displayStatus={displayStatus}
                                setDisplayStatus={setDisplayStatus}
                                titleText={titleText}
                            />
                            <Button
                                startIcon={<FaUserPlus />}
                                className={classes.follow}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setDisplayStatus('block')
                                    setTitleText(
                                        <Typography>
                                            <strong>
                                                Create an account to follow
                                                writers and be <br /> notified
                                                of future stories.
                                            </strong>
                                        </Typography>
                                    )
                                }}
                                variant="outlined"
                            >
                                Follow
                            </Button>
                        </Box>
                    )}
                    {loggedInProfile.id &&
                        published.profile.id !== loggedInProfile.id && (
                            <>
                                {!following && (
                                    <Button
                                        startIcon={<FaUserPlus />}
                                        className={classes.follow}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            dispatch(
                                                followProfile(published.profile)
                                            )
                                            setFollowing(true)
                                        }}
                                        variant="outlined"
                                    >
                                        Follow
                                    </Button>
                                )}
                                {following && (
                                    <Button
                                        startIcon={<FaUserCheck />}
                                        className={classes.follow}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            dispatch(
                                                unFollowProfile(
                                                    published.profile
                                                )
                                            )
                                            setFollowing(false)
                                        }}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Following
                                    </Button>
                                )}
                            </>
                        )}
                </Grid>
                <Box>
                    {viaCommunity && (
                        <Typography
                            className={classes.privateMessage}
                            variant="body2"
                        >
                            This is a private story, visible only to members of{' '}
                            <b>{published.community_name}</b>
                        </Typography>
                    )}
                </Box>
                <Typography variant="h3" className={classes.title}>
                    {published.title}
                </Typography>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.detailLine}
                >
                    <Box>
                        <Typography
                            variant="body"
                            className={classes.publishedOn}
                        >
                            <FaCalendar className={classes.calendar} />{' '}
                            {formatDate(published.published_on)}
                        </Typography>
                        <Typography variant="body" className={classes.readTime}>
                            <FaClock className={classes.clock} />{' '}
                            {formatReadTime(published)} read
                        </Typography>

                        {published.viewCount && (
                            <Typography
                                variant="body"
                                className={classes.words}
                            >
                                <FaEye className={classes.clock} />{' '}
                                {published.viewCount} view
                                {published.viewCount > 1 && <>s</>}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Box className={classes.tags}>
                    {published.genres &&
                        published.genres
                            .slice(0, 1)
                            .map((genre) => (
                                <Chip
                                    size="small"
                                    className={classes.chipItem}
                                    variant="outlined"
                                    label={genre}
                                />
                            ))}
                    {published.tags &&
                        published.tags
                            .slice(0, 1)
                            .map((tag) => (
                                <Chip
                                    size="small"
                                    className={classes.chipItem}
                                    label={tag}
                                />
                            ))}
                </Box>

                {featuredUrl && (
                    <img
                        alt="Large featured"
                        className={classes.mediaLarge}
                        src={featuredUrl}
                    />
                )}
                {renderSections(
                    published.publishedSections,
                    published.sectionId
                )}

                <Divider className={classes.divider} />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid>
                        {!project.owned && (
                            <LikeChip
                                label={
                                    likedHover
                                        ? liked
                                            ? 'Unlike'
                                            : 'Like'
                                        : likedCount + ' Likes'
                                }
                                className={classes.likeCount}
                                icon={
                                    liked ? (
                                        <FaHeart color="#bd1e13" />
                                    ) : (
                                        <FaRegHeart color="#bd1e13" />
                                    )
                                }
                                onMouseOver={() => {
                                    setLikedHover(true)
                                }}
                                onMouseOut={() => {
                                    setLikedHover(false)
                                }}
                                onClick={() => {
                                    if (profile.id) {
                                        if (!liked) {
                                            console.log(
                                                'liking published writing ' +
                                                    published.publishedId
                                            )
                                            dispatch(
                                                likePublished(
                                                    published.publishedId
                                                )
                                            )
                                            setLikedCount(likedCount + 1)
                                            setLiked(true)
                                        } else {
                                            console.log(
                                                'unliking published writing ' +
                                                    published.publishedId
                                            )
                                            dispatch(
                                                unlikePublished(
                                                    published.publishedId
                                                )
                                            )
                                            setLikedCount(likedCount - 1)
                                            setLiked(false)
                                            console.log('hi')
                                        }
                                    } else {
                                        setTitleText(
                                            <Typography>
                                                <strong>
                                                    Create an account to{' '}
                                                    <FaHeart color="#bd1e13" />{' '}
                                                    this story.
                                                </strong>
                                            </Typography>
                                        )
                                        setDisplayStatus('block')
                                    }
                                }}
                            />
                        )}
                        {project.owned && (
                            <LikeChip
                                label={likedCount + ' Likes'}
                                className={classes.likeCount}
                                icon={<FaHeart color="#bd1e13" />}
                            />
                        )}

                        <Box className={classes.SharePublished}>
                            <SharePublished
                                shortUrl={published.shortUrl}
                                published={published}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {profile.isAdmin && (
                <Grid
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.adminBox}
                >
                    <Divider className={classes.divider} />
                    <Grid
                        container
                        className={classes.adminHeader}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Slab',
                            }}
                        >
                            admin
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        className={classes.adminHeader}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant="outlined"
                            startIcon={<FaExclamationTriangle />}
                            onClick={() => {
                                setConfirmFlag(true)
                            }}
                            className={classes.flagButton}
                        >
                            Flag for Removal
                        </Button>
                    </Grid>
                    <Box className={classes.pickerBox}>
                        <FeaturedPicker publishedId={published.id} />
                    </Box>
                </Grid>
            )}
            <Box className={classes.comments}>
                <PublishedComments
                    projectId={published.projectId}
                    sectionId={published.sectionId}
                    communityId={viaCommunity ? published.community_id : null}
                />
            </Box>
        </>
    )
}

export default PublishedContent
