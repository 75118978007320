import { myFirebase, db } from '../firebase/firebase'

export const REVIEW_PICKER_SET = 'REVIEW_PICKER_SET'

export const FEEDBACK_ASK_PUT_REQUEST = 'FEEDBACK_ASK_PUT_REQUEST'
export const FEEDBACK_ASK_PUT_SUCCESS = 'FEEDBACK_ASK_PUT_SUCCESS'
export const FEEDBACK_ASK_PUT_FAILURE = 'FEEDBACK_ASK_PUT_FAILURE'

export const FEEDBACK_ASK_LIST_REQUEST = 'FEEDBACK_ASK_LIST_REQUEST'
export const FEEDBACK_ASK_LIST_SUCCESS = 'FEEDBACK_ASK_LIST_SUCCESS'
export const FEEDBACK_ASK_LIST_FAILURE = 'FEEDBACK_ASK_LIST_FAILURE'

export const FEEDBACK_TAB_REQUEST = 'FEEDBACK_TAB_REQUEST'
export const FEEDBACK_TAB_LIST = 'FEEDBACK_TAB_LIST'

export const REV_REQUEST_PUT_REQUEST = 'REV_REQUEST_PUT_REQUEST'
export const REV_REQUEST_PUT_SUCCESS = 'REV_REQUEST_PUT_SUCCESS'
export const REV_REQUEST_PUT_FAILURE = 'REV_REQUEST_PUT_FAILURE'
export const REV_REQUEST_PUT_COMPLETE = 'REV_REQUEST_PUT_COMPLETE'

export const REVIEW_GET_REQUEST = 'REV_GET_REQUEST'
export const REVIEW_GET_SUCCESS = 'REVIEW_GET_SUCCESS'
export const REVIEW_GET_FAILURE = 'REVIEW_GET_FAILURE'

export const FEEDBACK_PUT = 'FEEDBACK_PUT'
export const FEEDBACK_PUT_SUCCESS = 'FEEDBACK_PUT_SUCCESS'
export const FEEDBACK_PUT_FAILURE = 'FEEDBACK_PUT_FAILURE'

export const FEEDBACK_LIST = 'FEEDBACK_LIST'
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS'
export const FEEDBACK_LIST_FAILURE = 'FEEDBACK_LIST_FAILURE'

const feedbackTabRequest = () => {
    return {
        type: FEEDBACK_TAB_REQUEST,
    }
}
const feedbackTabList = () => {
    return {
        type: FEEDBACK_TAB_LIST,
    }
}

const requestListFeedbackAsk = () => {
    return {
        type: FEEDBACK_ASK_LIST_REQUEST,
    }
}

const receiveListFeedbackAsk = (feedbackAskList) => {
    return {
        type: FEEDBACK_ASK_LIST_SUCCESS,
        feedbackAskList,
    }
}

const failListFeedbackAsk = () => {
    return {
        type: FEEDBACK_ASK_LIST_REQUEST,
    }
}
const requestPutFeedback = () => {
    return {
        type: FEEDBACK_ASK_PUT_REQUEST,
    }
}
const receivePutFeedback = () => {
    return {
        type: FEEDBACK_ASK_PUT_SUCCESS,
    }
}
const failPutFeedback = () => {
    return {
        type: FEEDBACK_ASK_PUT_FAILURE,
    }
}

const listFeedback = () => {
    return {
        type: FEEDBACK_LIST,
    }
}

const listFeedbackSuccess = (feedbackList) => {
    console.log(feedbackList)
    return {
        type: FEEDBACK_LIST_SUCCESS,
        feedbackList,
    }
}

const listFeedbackFailure = () => {
    return {
        type: FEEDBACK_LIST_FAILURE,
    }
}

export const getFeedback = (projectId) => (dispatch) => {
    dispatch(listFeedback())
    let feedbackRef = db.collection('feedback')
    let query = feedbackRef.where('projectId', '==', projectId).onSnapshot(
        (snapshot) => {
            if (snapshot.empty) {
                dispatch(listFeedbackSuccess([]))
            }

            let response = []
            snapshot.forEach((doc) => {
                response.push({ ...doc.data(), id: doc.id })
            })
            dispatch(listFeedbackSuccess(response))
        },
        () => {
            dispatch(listFeedbackFailure())
        }
    )
}

export const listFeedbackRequest = () => (dispatch) => {
    dispatch(requestListFeedbackAsk())
    let reviewRef = db.collection('reviewrequests')
    let query = reviewRef
        .where('recipientEmail', '==', myFirebase.auth().currentUser.email)
        .onSnapshot(
            (snapshot) => {
                console.log(snapshot)
                if (snapshot.empty) {
                    dispatch(receiveListFeedbackAsk([]))
                }

                let response = []
                snapshot.forEach((doc) => {
                    response.push({ ...doc.data(), id: doc.id })
                })
                dispatch(receiveListFeedbackAsk(response))
            },
            () => {
                dispatch(failListFeedbackAsk())
            }
        )
}

const requestReview = () => {
    return {
        type: REVIEW_GET_REQUEST,
    }
}
const receiveReview = (review) => {
    return {
        type: REVIEW_GET_SUCCESS,
        review,
    }
}
const failReview = () => {
    return {
        type: REVIEW_GET_FAILURE,
    }
}
const requestRevRequest = () => {
    return {
        type: REV_REQUEST_PUT_REQUEST,
    }
}
const receiveFeedbackRequest = (request) => {
    return {
        type: REV_REQUEST_PUT_SUCCESS,
        request,
    }
}
const failRevRequest = () => {
    return {
        type: REV_REQUEST_PUT_FAILURE,
    }
}

const completeRevRequest = () => {
    return {
        type: REV_REQUEST_PUT_COMPLETE,
    }
}

export const getFeedbackRequest = (reviewId) => (dispatch) => {
    let reviewRef = db.collection('reviewrequests')
    reviewRef.doc(reviewId).onSnapshot(function (doc) {
        var source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
        dispatch(
            receiveFeedbackRequest({
                ...doc.data(),
                id: doc.id,
                source: source,
            })
        )
    })
}

export const putFeedbackComplete = () => (dispatch) => {
    dispatch(completeRevRequest())
}

export const putFeedbackRequest = (email, message, projectId) => (dispatch) => {
    console.log('got here')
    dispatch(requestRevRequest())
    myFirebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
            let addDoc = db
                .collection('tempreviewrequests')
                .add({
                    recipientEmail: email,
                    message: message,
                    projectId: projectId,
                    token: idToken,
                })
                .then((ref) => {
                    console.log(ref)
                    dispatch(receiveFeedbackRequest({ id: ref.id }))
                })
                .catch((error) => {
                    dispatch(failRevRequest())
                })
        })
        .catch(function (error) {
            dispatch(failRevRequest())
        })
}

const putFeedbackSuccess = (feedback) => {
    return {
        type: FEEDBACK_PUT_SUCCESS,
        feedback,
    }
}

const putFeedback = () => {
    return {
        type: FEEDBACK_PUT,
    }
}
const putFeedbackFailure = () => {
    return {
        type: FEEDBACK_PUT_FAILURE,
    }
}

export const saveFeedback =
    (requestId, projectId, bodyNew, bodyOld, comments) => (dispatch) => {
        console.log('put feedback')
        dispatch(putFeedback())
        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                let addDoc = db
                    .collection('feedback')
                    .add({
                        requestId: requestId,
                        comments: comments,
                        bodyNew: bodyNew,
                        bodyOld: bodyOld,
                        from: myFirebase.auth().currentUser.email,
                        createdOn: new Date().toISOString(),
                        projectId: projectId,
                        token: idToken,
                    })
                    .then((ref) => {
                        console.log(ref)
                        dispatch(putFeedbackSuccess({ id: ref.id }))
                    })
                    .catch((error) => {
                        console.log(error)
                        dispatch(putFeedbackFailure())
                    })
            })
            .catch(function (error) {
                console.log(error)
                dispatch(putFeedbackFailure())
            })
    }

const setReviewPicker = (email, message) => {
    return {
        type: REVIEW_PICKER_SET,
        reviewPicker: {
            email,
            message,
        },
    }
}
