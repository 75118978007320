import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaFacebookF, FaLink, FaTumblr, FaTwitter } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    shareProfile: {
        maxWidth: 80,
    },
    shareFacebook: {
        position: 'relative',
        left: -2,
    },
    shareTumblr: {
        marginTop: 0,
    },
    shareIcon: {
        margin: 0,
        padding: 0,
    },
    linkIcon: {
        margin: 0,
        padding: 0,
        cursor: 'pointer',
    },
}))

const ShareProfile = ({ publicProfileId, publicProfile }) => {
    const wh = window.innerHeight
    const ww = window.innerWidth
    const h = Math.min(600, 1 * wh)
    const w = Math.min(800, 1 * ww)

    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=https://istoria.io/pr/${publicProfileId}&amp;src=sdkpreparse&quote=${publicProfile.displayName} on istoria.io`
    const twitterUrl = `http://twitter.com/share?text=${publicProfile.displayName} on istoria.io&url=https://istoria.io/pr/${publicProfileId}`
    const tumblrUrl = `http://tumblr.com/widgets/share/tool?canonicalUrl=https://istoria.io/pr/${publicProfileId}&title=${publicProfile.displayName} on istoria.io&caption=${publicProfile.displayName} on istoria.io`

    const onClickFB = (e) => {
        window &&
            window.open(
                fbUrl,
                '',
                [
                    'height=' + h,
                    'left=' + (ww - w) / 2,
                    'top=' + (wh - h) / 2,
                    'width=' + w,
                    'status=1',
                    'toolbar=0',
                ].join(',')
            )

        e.preventDefault()
        e.stopPropagation()
    }
    const onClickTwitter = (e) => {
        window &&
            window.open(
                twitterUrl,
                '',
                [
                    'height=' + h,
                    'left=' + (ww - w) / 2,
                    'top=' + (wh - h) / 2,
                    'width=' + w,
                    'status=1',
                    'toolbar=0',
                ].join(',')
            )

        e.preventDefault()
        e.stopPropagation()
    }

    const onClickTumblr = (e) => {
        window &&
            window.open(
                tumblrUrl,
                '',
                [
                    'height=' + h,
                    'left=' + (ww - w) / 2,
                    'top=' + (wh - h) / 2,
                    'width=' + w,
                    'status=1',
                    'toolbar=0',
                ].join(',')
            )

        e.preventDefault()
        e.stopPropagation()
    }

    const classes = useStyles()
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Box className={classes.shareFacebook}>
                <div
                    className="social-share-button"
                    data-href={`https://istoria.io/pr/${publicProfileId}`}
                    data-layout="button_count"
                    data-size="small"
                    data-caption={`${publicProfile.displayName} on istoria.io`}
                    data-text={`${publicProfile.displayName} on istoria.io`}
                >
                    <a
                        style={{ color: '#666' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={fbUrl}
                        className="fb-xfbml-parse-ignore"
                        onClick={onClickFB}
                    >
                        <FaFacebookF
                            className={classes.shareIcon}
                            size=".8em"
                        />
                    </a>
                </div>
            </Box>
            <Box className={classes.shareProfile}>
                <div
                    className="social-share-button"
                    data-href={`https://istoria.io/pr/${publicProfileId}`}
                    data-layout="button_count"
                    data-size="small"
                    data-caption={`${publicProfile.displayName} on istoria.io`}
                    data-text={`${publicProfile.displayName} on istoria.io`}
                >
                    <a
                        style={{ color: '#666' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={twitterUrl}
                        className="fb-xfbml-parse-ignore"
                        onClick={onClickTwitter}
                    >
                        <FaTwitter className={classes.shareIcon} size=".8em" />
                    </a>
                </div>
            </Box>
            <Box className={classes.shareTumblr}>
                <div
                    className="tumblr-button"
                    data-href={`https://istoria.io/pr/${publicProfileId}`}
                    data-layout="button_count"
                    data-size="small"
                    data-caption={`${publicProfile.displayName} on istoria.io`}
                    data-text={`${publicProfile.displayName} on istoria.io`}
                >
                    <a
                        style={{ color: '#666' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={tumblrUrl}
                        className="fb-xfbml-parse-ignore"
                        onClick={onClickTumblr}
                    >
                        <FaTumblr className={classes.shareIcon} size=".8em" />
                    </a>
                </div>
            </Box>
            <Box className={classes.shareTumblr}>
                <div
                    className="tumblr-button"
                    data-href={`https://istoria.io/pr/${publicProfileId}`}
                    data-layout="button_count"
                    data-size="small"
                    data-caption={`${publicProfile.displayName} on istoria.io`}
                    data-text={`${publicProfile.displayName} on istoria.io`}
                >
                    <CopyToClipboard
                        text={`https://istoria.io/pr/${publicProfileId}`}
                        onCopy={() => console.log('copied to clipboard')}
                    >
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            <FaLink
                                color="#666"
                                className={classes.linkIcon}
                                size=".8em"
                            />
                        </a>
                    </CopyToClipboard>
                </div>
            </Box>
        </Grid>
    )
}

export default ShareProfile
