import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProjects } from '../../actions'
import * as routes from '../../constants/routes'
import ProjectCard from '../project/ProjectCard'

function compare(a, b) {
    if (!a.updated_on) {
        return 1
    } else if (!b.updated_on) {
        return -1
    }
    return a.updated_on - b.updated_on
}
function ListProjects(props) {
    const dispatch = useDispatch()
    const [projectItemList, setProjectItemList] = useState([])
    const projectsList = useSelector((state) => state.write.projectsList)
    const listProjectAvailable = useSelector(
        (state) => state.write.listProjectAvailable
    )

    const classes = useStyles()

    useEffect(() => {
        dispatch(listProjects())
    }, [dispatch])

    useEffect(() => {
        setProjectItemList(projectsList)
    }, [projectsList])

    const renderedItems = projectItemList
        .sort(compare)
        .slice(0, 12)
        .map((project) => (
            <ProjectCard
                title={project.title}
                newlyCreated={props.newlyCreated}
                description={project.description?.slice(0, 80)}
                projectId={project.id}
                featuredId={project.featuredId}
                hideAuthor
                url={routes.VIEW.replace(':id', project.id)}
                project={project}
                key={project.id}
            />
        ))

    return (
        <Grid
            className={classes.feedList}
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={24}
            id="main-list-projects"
        >
            {listProjectAvailable ? (
                projectItemList.length > 0 ? (
                    <>{renderedItems}</>
                ) : (
                    <Typography className={classes.noMessage} variant="body2">
                        {/* You don't have any saved projects yet. */}
                    </Typography>
                )
            ) : (
                <CircularProgress className={classes.feedProgress} />
            )}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    writingDescription: {},
    noMessage: {
        color: '#666',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    feedProgress: {
        margin: theme.spacing(2),
    },
    feedList: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gridColumnGap: theme.spacing(2),
    },
    cardTitle: {
        fontSize: 24,
    },
    feedBox: {
        marginTop: 20,
    },
}))

export default ListProjects
