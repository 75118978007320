import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Box,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaCheck, FaTimesCircle, FaUsers } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    getPublicCommunityFeaturedUrls,
    leaveCommunity,
    requestJoin,
    unrequestJoinCommunity,
    communityBackFrom,
} from '../../actions'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 200,
        width: '100%',
    },
    root: {
        minWidth: 300,
        maxWidth: 430,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    name: {
        textAlign: 'center',
    },
    description: {
        backgroundColor: 'white',
        position: 'absolute',
        bottom: 20,
        left: 20,
        color: 'black',
        padding: 10,
        opacity: 0.7,
        borderRadius: 3,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: 200,
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 150,
        height: 180,
    },
    iconBox: {
        textAlign: 'center',
    },
    coverIcon: {
        marginTop: 65,
    },
    contentGrid: {
        height: '100%',
    },
    actionArea: {
        display: 'flex',
    },
}))

function CommunityCard({
    name,
    description,
    comId,
    featuredId,
    memberCount,
    community,
    backFrom,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [following, setFollowing] = useState('no')
    const [hovering, setHovering] = useState(false)
    const [hasFeatured, setHasFeatured] = useState(false)
    const [mediaUrl, setMediaUrl] = useState(
        require('../../static/grey-background.jpg')
    )
    const profile = useSelector((state) => state.profile.profile)

    useEffect(() => {
        if (community.hasFeatured != undefined) {
            console.log(community)
            if (community.hasFeatured) {
                setMediaUrl(community.featuredUrls.featuredLarge)
                setHasFeatured(true)
                console.log(comId + ' has featured')
            }
        } else {
            console.log(community.hasFeatured)
            console.log(community)
            if (featuredId) {
                getPublicCommunityFeaturedUrls(featuredId).then((urls) => {
                    console.log(urls)
                    setMediaUrl(urls.featuredLarge)
                    setHasFeatured(true)
                    console.log(comId + ' has featured')
                })
            } else {
                setHasFeatured(false)
                setMediaUrl(require('../../static/grey-background.jpg'))
                console.log(comId + ' no featured')
            }
        }
    }, [
        featuredId,
        comId,
        getPublicCommunityFeaturedUrls,
        following,
        hasFeatured,
    ])

    useEffect(() => {
        console.log(profile.requested_community_ids)
        if (profile?.requested_community_ids?.includes(comId))
            setFollowing('requested')

        if (community?.member_profile_ids?.includes(profile.id))
            setFollowing('joined')
    }, [profile, community.member_profile_ids, comId])

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={Link}
                to={{
                    pathname: routes.COMMUNITY_VIEW.replace(':id', comId),
                }}
                className={classes.actionArea}
                onClick={() => {
                    console.log(backFrom)
                    if (backFrom == 'connect') {
                        dispatch(
                            communityBackFrom(routes.HOME, { tab: 'connect' })
                        )
                    } else if (backFrom == 'all') {
                        dispatch(communityBackFrom(routes.COMMUNITY_ALL, {}))
                    }
                }}
            >
                <CardMedia className={classes.cover} image={mediaUrl}>
                    {' '}
                    {!hasFeatured && (
                        <Box className={classes.iconBox}>
                            <FaUsers
                                size="3em"
                                color="white"
                                className={classes.coverIcon}
                            />
                        </Box>
                    )}
                </CardMedia>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.contentGrid}
                        >
                            <Typography className={classes.name} variant="h5">
                                {name}
                            </Typography>
                            {community.member_profile_ids && (
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    {community.member_profile_ids.length} member
                                    {community.member_profile_ids.length !==
                                        1 && 's'}
                                </Typography>
                            )}

                            {following === 'no' && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        dispatch(requestJoin(comId))
                                        setFollowing('requested')
                                    }}
                                    variant="outlined"
                                >
                                    Request to Join
                                </Button>
                            )}

                            {following === 'requested' && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        dispatch(unrequestJoinCommunity(comId))
                                        setFollowing('no')
                                    }}
                                    variant="outlined"
                                >
                                    Cancel Request
                                </Button>
                            )}
                            {following === 'joined' && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (hovering) {
                                            dispatch(leaveCommunity(comId))
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        setHovering(true)
                                    }}
                                    onMouseLeave={() => {
                                        setHovering(false)
                                    }}
                                    variant="outlined"
                                    startIcon={
                                        hovering ? (
                                            <FaTimesCircle />
                                        ) : (
                                            <FaCheck />
                                        )
                                    }
                                    color={hovering ? '' : 'primary'}
                                >
                                    {hovering ? 'Leave' : 'Joined'}
                                </Button>
                            )}
                        </Grid>
                    </CardContent>
                    <div className={classes.controls}></div>
                </div>
            </CardActionArea>
        </Card>
    )
}

export default CommunityCard
