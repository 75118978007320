import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FaClock, FaEye, FaHeart, FaQuoteLeft, FaStar } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { getAvatarUrls } from '../../actions/profile'
import { getFeaturedUrls } from '../../actions/write'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'
import SharePublished from './SharePublished'

function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 360,
        width: '100%',
    },
    root: {
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.md,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(1),
        borderRadius: 12,
        position: 'relative',
    },
    rootFill: {
        marginBottom: theme.spacing(2),
        width: '100%',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(1),
        borderRadius: 12,
        position: 'relative',
    },
    banner: {
        position: 'relative',
    },
    header: {
        color: '#666',
        textAlign: 'center',
    },
    headerGutter: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#666',
        textAlign: 'center',
    },
    title: {
        fontSize: '1.5rem',
        color: '#333',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    detailBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
    },
    date: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#512da8',
    },
    tagsBox: {
        marginLeft: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    actionsBox: {
        marginLeft: 4,
    },
    cardActions: {
        marginTop: 0,
        marginRight: theme.spacing(1),
    },
    author: {
        textAlign: 'center',
    },
    avatarLine: {
        textAlign: 'center',
    },
    avatar: {
        display: 'inline-block',
        position: 'relative',
        border: '1px solid #512da8',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    avatarSkeleton: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    excerptBody: {
        padding: theme.spacing(2),
        textAlign: 'left',
        fontSize: '14pt',
    },
    authorName: {
        display: 'block',
        textDecoration: 'none',
        color: '#512da8',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    likes: {
        color: '#a1453f',
        marginRight: theme.spacing(1),
    },
    readTime: {
        color: '#666',
        marginRight: theme.spacing(1),
    },
    wordCount: {
        color: '#666',
    },

    heart: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    quotes: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    by: {
        fontWeight: 'normal',
        color: 'black',
    },
    info: {
        marginBottom: theme.spacing(1) - 2,
    },
    featuredMedia: {
        width: '100%',
        maxHeight: 300,
        objectFit: 'cover',
    },
    shareLine: {
        textAlign: 'center',
        height: 45,
    },
    shareLineGutter: {
        paddingTop: theme.spacing(1),
        textAlign: 'center',
        height: 52,
    },
    featuredChipLine: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: 0,
        right: 0,
    },
    featuredChipLineGutter: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: -2,
        right: 0,
    },
    featuredLine: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    shareBox: {
        paddingTop: 5,
    },
}))

function shortenString(str) {
    if (str.length <= 180) return str
    else return str.slice(0, 180) + '...'
}

const FeaturedChip = withStyles({
    root: {
        color: '#e68a00',
        borderColor: 'white',
        backgroundColor: 'white',
    },
})(Chip)
function PublishedCard({ published, hideAuthor, pinned, fill }) {
    const classes = useStyles()
    const [featuredUrl, setFeaturedUrl] = useState('')

    let toUrl

    if (published.community_id) {
        toUrl = routes.COMMUNITY_PUBLISHED_VIEW.replace(
            ':cid',
            published.community_id
        ).replace(':pid', slugUrl(published.title, published.shortUrl))
    } else {
        toUrl = routes.PUBLISHED.replace(
            ':pid',
            slugUrl(published.title, published.shortUrl)
        )
    }

    const [avatarUrl, setAvatarUrl] = useState('')
    const [avatarLoaded, setAvatarLoaded] = useState(false)
    const [wordCount, setWordCount] = useState('')
    const [likedCount, setLikedCount] = useState('')
    const [readTime, setReadTime] = useState('')

    useEffect(() => {
        console.log(published, 'this is publishedCard')

        if (published.featuredId) {
            getFeaturedUrls(published.featuredId).then((urls) => {
                // console.log(urls.featuredLarge)
                setFeaturedUrl(urls.featuredLarge)
            })
        }
        if (published) {
            const reducer = (accumulator, currentValue) =>
                accumulator + currentValue?.bodyText.split(' ').length
            let wordCount = published.publishedSections.reduce(reducer, null)

            let rt = Math.ceil(wordCount / 275)

            // console.log(published.title)
            // console.log(published.liked_count)
            if (published.liked_uids) {
                if (published.liked_uids.length > 0)
                    setLikedCount(published.liked_uids.length)
            } else {
                if (published?.liked_count > 0)
                    setLikedCount(published.liked_count)
            }
            setWordCount(nFormatter(wordCount))
            setReadTime(
                formatDuration({
                    minutes: rt,
                })
                    .replace('minute', 'min')
                    .replace('mins', 'min')
            )
        }
    }, [published])
    useEffect(() => {
        // console.log('dispatch')

        if (published.profile.avatarId) {
            getAvatarUrls(published.profile.avatarId).then((urls) => {
                setAvatarUrl(urls.avatarSmall)
                setAvatarLoaded(true)
            })
        } else {
            setAvatarLoaded(true)
        }
    }, [published.profile.avatarId])

    return (
        <Card className={fill ? classes.rootFill : classes.root}>
            <CardActionArea
                component={Link}
                to={{
                    pathname: toUrl,
                }}
            >
                {featuredUrl && (
                    <img
                        alt="featured"
                        src={featuredUrl}
                        className={classes.featuredMedia}
                    />
                )}

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.featuredLine}
                >
                    {pinned && (
                        <FeaturedChip
                            label="Featured"
                            className={classes.featuredChip}
                            icon={<FaStar color="#e68a00" />}
                            variant="outlined"
                        />
                    )}
                    {!pinned && <>&nbsp;</>}
                    <Box className={classes.shareBox}>
                        <SharePublished
                            shortUrl={published.shortUrl}
                            published={published}
                        />
                    </Box>
                </Grid>

                <Typography
                    variant="body2"
                    className={classes.authorName}
                    gutterBottom
                    component={Link}
                    to={{
                        pathname: routes.PROFILE_PUBLIC.replace(
                            ':id',
                            published.profile.id
                        ),
                    }}
                >
                    {avatarLoaded && (
                        <Avatar className={classes.avatar} src={avatarUrl} />
                    )}

                    {!avatarLoaded && (
                        <Skeleton
                            variant="circle"
                            className={classes.avatarSkeleton}
                            width={30}
                            height={30}
                        />
                    )}
                    {published.profile.displayName}
                </Typography>

                <Box className={classes.headerGutter}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                        component="h2"
                    >
                        {published.title}
                    </Typography>
                    {published.fromTitle && (
                        <Typography variant="body2">
                            from <b>{published.fromTitle}</b>
                        </Typography>
                    )}
                </Box>

                <Grid
                    container
                    className={classes.info}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {likedCount && (
                        <Typography
                            variant="body2"
                            className={classes.likes}
                            gutterBottom
                        >
                            <FaHeart
                                className={classes.heart}
                                color="#bf281d"
                            />
                            {likedCount}
                        </Typography>
                    )}
                    {readTime && (
                        <Typography
                            variant="body2"
                            className={classes.readTime}
                            gutterBottom
                        >
                            <FaClock className={classes.clock} color="#666" />
                            {readTime} read
                        </Typography>
                    )}
                    {/* {wordCount && (
                        <Typography
                            variant="body2"
                            className={classes.wordCount}
                            gutterBottom
                        >
                            <FaQuoteLeft
                                className={classes.quotes}
                                color="#666"
                            />
                            {wordCount} word{wordCount > 1 && <>s</>}
                        </Typography>
                    )} */}
                    {published.viewCount && (
                        <Typography
                            variant="body2"
                            className={classes.wordCount}
                            gutterBottom
                        >
                            <FaEye className={classes.quotes} color="#666" />
                            {published.viewCount}&nbsp;view
                            {published.viewCount > 1 && <>s</>}
                        </Typography>
                    )}
                </Grid>
                <Box className={classes.tags}>
                    {published.genres &&
                        published.genres
                            .slice(0, 1)
                            .map((genre) => (
                                <Chip
                                    size="small"
                                    className={classes.chipItem}
                                    variant="outlined"
                                    label={genre}
                                    key={genre.id}
                                />
                            ))}
                    {published.tags &&
                        published.tags
                            .slice(0, 1)
                            .map((tag) => (
                                <Chip
                                    size="small"
                                    className={classes.chipItem}
                                    label={tag}
                                    key={tag.id}
                                />
                            ))}
                </Box>
                {published.excerpt.length > 0 && (
                    <Typography
                        variant="body2"
                        component="p"
                        className={classes.excerptBody}
                    >
                        {shortenString(published.excerpt)}
                    </Typography>
                )}
            </CardActionArea>
        </Card>
    )
}

export default PublishedCard
