import { myFirebase, db, images, functions } from '../firebase/firebase'
import uuid from 'uuid'
import DiscussionCard from '../components/communities/DiscussionCard'

export const COMMUNITY_PUT = 'COMMUNITY_PUT'
export const COMMUNITY_PUT_SUCCESS = 'COMMUNITY_PUT_SUCCESS'
export const COMMUNITY_PUT_FAILURE = 'COMMUNITY_PUT_FAILURE'
export const COMMUNITY_PUT_COMPLETE = 'COMMUNITY_PUT_COMPLETE'

export const COMMUNITY_UPDATE = 'COMMUNITY_UPDATE'
export const COMMUNITY_UPDATE_SUCCESS = 'COMMUNITY_UPDATE_SUCCESS'
export const COMMUNITY_UPDATE_FAILURE = 'COMMUNITY_UPDATE_FAILURE'
export const COMMUNITY_UPDATE_COMPLETE = 'COMMUNITY_UPDATE_COMPLETE'

export const COMMUNITY_GET = 'COMMUNITY_GET'
export const COMMUNITY_GET_SUCCESS = 'COMMUNITY_GET_SUCCESS'
export const COMMUNITY_GET_FAILURE = 'COMMUNITY_GET_FAILURE'

export const DISCUSSION_GET_SUCCESS = 'DISCUSSION_GET_SUCCESS'
export const DISCUSSION_COMMENTS_LIST_SUCCESS =
    'DISCUSSION_COMMENTS_LIST_SUCCESS'

export const COMMUNITY_LIST = 'COMMUNITY_LIST'
export const COMMUNITY_LIST_SUCCESS = 'COMMUNITY_LIST_SUCCESS'
export const COMMUNITY_LIST_FAILURE = 'COMMUNITY_LIST_FAILURE'
export const COMMUNITY_LIST_APPEND = 'COMMUNITY_LIST_FAILURE'
export const COMMUNITY_LIST_CLEAR = 'COMMUNITY_LIST_CLEAR'

export const PROFILE_LIST_COMMUNITIES = 'PROFILE_LIST_COMMUNITIES'
export const PROFILE_LIST_COMMUNITIES_APPEND =
    'PROFILE_LIST_COMMUNITIES_SUCCESS'
export const PROFILE_LIST_COMMUNITIES_CLEAR = 'PROFILE_LIST_COMMUNITIES_CLEAR'
export const PROFILE_LIST_COMMUNITIES_FAILURE =
    'PROFILE_LIST_COMMUNITIES_FAILURE'

export const COMMUNITY_LIST_FEED = 'COMMUNITY_LIST_FEED'
export const COMMUNITY_LIST_FEED_SUCCESS = 'COMMUNITY_LIST_FEED_SUCCESS'
export const COMMUNITY_LIST_FEED_FAILURE = 'COMMUNITY_LIST_FEED_FAILURE'
export const COMMUNITY_LIST_FEED_APPEND = 'COMMUNITY_LIST_FEED_APPEND'

export const COMMUNITY_LIST_MEMBERS = 'COMMUNITY_LIST_MEMBERS'
export const COMMUNITY_LIST_MEMBERS_APPEND = 'COMMUNITY_LIST_MEMBERS_APPEND'
export const COMMUNITY_LIST_MEMBERS_CLEAR = 'COMMUNITY_LIST_MEMBERS_CLEAR'
export const COMMUNITY_LIST_MEMBERS_FAILURE = 'COMMUNITY_LIST_MEMBERS_FAILURE'

export const COMMUNITY_DELETE = 'COMMUNITY_DELETE'
export const COMMUNITY_DELETE_SUCCESS = 'COMMUNITY_DELETE_SUCCESS'
export const COMMUNITY_DELETE_FAILURE = 'COMMUNITY_DELETE_FAILURE'
export const COMMUNITY_DELETE_CLEAR = 'COMMUNITY_DELETE_FAILURE'

export const COMMUNITY_REQUEST_JOIN = 'COMMUNITY_REQUEST_JOIN'
export const COMMUNITY_REQUEST_JOIN_SUCCESS = 'COMMUNITY_REQUEST_JOIN_SUCCESS'
export const COMMUNITY_REQUEST_JOIN_FAILURE = 'COMMUNITY_REQUEST_JOIN_FAILURE'

export const COMMUNITY_BACK_FROM = 'COMMUNITY_BACK_FROM'

let unsubscribe

const deleteCommunityRequest = () => {
    return {
        type: COMMUNITY_DELETE,
    }
}
const deleteCommunitySuccess = () => {
    return {
        type: COMMUNITY_DELETE_SUCCESS,
    }
}
const deleteCommunityFailure = () => {
    return {
        type: COMMUNITY_DELETE_FAILURE,
    }
}
export const deleteCommunityClear = () => {
    return {
        type: COMMUNITY_DELETE_CLEAR,
    }
}

export const communityBack = (backFrom, backState) => {
    return {
        type: COMMUNITY_BACK_FROM,
        backFrom,
        backState,
    }
}

export const communityBackFrom = (backUrl, backState) => (dispatch) => {
    dispatch(communityBack(backUrl, backState))
}

export const deleteCommunity = (profileId, communityId) => (dispatch) => {
    dispatch(deleteCommunityRequest())
    var deleteCommunityFunc = functions.httpsCallable('deleteCommunity')

    deleteCommunityFunc({
        profileId: profileId,
        communityId: communityId,
    })
        .then(() => {
            console.log('delete success')
            dispatch(deleteCommunitySuccess())
        })
        .catch((error) => {
            dispatch(deleteCommunityFailure())
        })
}

const putCommunity = () => {
    return {
        type: COMMUNITY_PUT,
    }
}
const putCommunitySuccess = (community) => {
    return {
        type: COMMUNITY_PUT_SUCCESS,
        community,
    }
}
const putCommunityFailure = () => {
    return {
        type: COMMUNITY_PUT_FAILURE,
    }
}

export const putCommunityComplete = () => {
    console.log('community put complete')
    return {
        type: COMMUNITY_PUT_COMPLETE,
    }
}

export const requestJoin = (communityId) => (dispatch) => {
    let profilesRef = db.collection('profiles')

    console.log('requesting to join community ' + communityId)

    var user = myFirebase.auth().currentUser

    if (user) {
        profilesRef
            .where('uid', '==', user.uid)
            .get()
            .then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let profiles = []
                        if (profileData.requested_community_ids)
                            profiles = profileData.requested_community_ids
                        console.log('requesting to join ' + communityId)
                        profiles.push(communityId)
                        profilesRef
                            .doc(doc.id)
                            .update({ requested_community_ids: profiles })
                        var requestJoinFunc = functions.httpsCallable(
                            'requestJoinCommunity'
                        )

                        requestJoinFunc({
                            profileId: doc.id,
                            communityId: communityId,
                        }).then((response) => {
                            dispatch(requestJoinCommunitySuccess())
                        })
                    })
                },
                () => {
                    requestJoinCommunityFailure()
                }
            )
    }
}

export const leaveCommunity = (communityId) => (dispatch) => {
    let profilesRef = db.collection('profiles')

    console.log('leaving community ' + communityId)

    var user = myFirebase.auth().currentUser

    if (user) {
        profilesRef
            .where('uid', '==', user.uid)
            .get()
            .then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        console.log('leaving com ' + communityId)
                        var leaveCommunityFunc =
                            functions.httpsCallable('leaveCommunity')

                        leaveCommunityFunc({
                            profileId: doc.id,
                            communityId: communityId,
                        }).then((response) => {
                            console.log('left community')
                        })
                    })
                },
                () => {
                    console.log('error leaving community')
                }
            )
    }
}

export const addCommunityAdmin = (profileId, communityId) => (dispatch) => {
    db.collection('community')
        .doc(communityId)
        .get()
        .then((doc) => {
            if (!doc.data().admin_profile_ids.includes(profileId)) {
                db.collection('community')
                    .doc(communityId)
                    .update({
                        admin_profile_ids: doc
                            .data()
                            .admin_profile_ids.concat([profileId]),
                    })
                    .then(() => {
                        console.log('added admin')
                    })
            }
        })
}
export const removeCommunityAdmin = (profileId, communityId) => (dispatch) => {
    db.collection('community')
        .doc(communityId)
        .get()
        .then((doc) => {
            if (doc.data().admin_profile_ids.includes(profileId)) {
                db.collection('community')
                    .doc(communityId)
                    .update({
                        admin_profile_ids: doc
                            .data()
                            .admin_profile_ids.filter((p) => p != profileId),
                    })
                    .then(() => {
                        console.log('removed admin')
                        dispatch(listMembers(communityId))
                    })
            }
        })
}
const requestJoinCommunity = () => {
    return {
        type: COMMUNITY_REQUEST_JOIN,
    }
}
const requestJoinCommunityFailure = () => {
    return {
        type: COMMUNITY_REQUEST_JOIN_FAILURE,
    }
}

export const requestJoinCommunitySuccess = () => {
    console.log('community join request success')
    return {
        type: COMMUNITY_REQUEST_JOIN_SUCCESS,
    }
}

export const listProfileCommunities = (profileId) => (dispatch) => {
    db.collection('profiles')
        .doc(profileId)
        .get()
        .then((profDoc) => {
            dispatch(profileListCommunitiesClear())
            console.log('got profile ' + profileId)
            let member_community_ids = profDoc.data().member_community_ids
                ? profDoc.data().member_community_ids
                : []
            console.log(member_community_ids)
            member_community_ids.forEach((comId) => {
                console.log('got community ' + comId)

                db.collection('community')
                    .doc(comId)
                    .get()
                    .then((comDoc) => {
                        let comDocData = comDoc.data()

                        dispatch(
                            profileListCommunitiesAppend({
                                name: comDocData.name,
                                description: comDocData.description,
                                member_count: comDocData.member_count,
                                id: comDoc.id,
                                visiblity: comDocData.visibility,
                                member_profile_ids: comDocData.memberProfileIds,
                                requested_profile_ids:
                                    comDocData.requested_profile_ids,
                                featuredId: comDocData.featuredId,
                            })
                        )
                    })
            })
        })
}

const profileListCommunities = () => {
    return {
        type: PROFILE_LIST_COMMUNITIES,
    }
}
const profileListCommunitiesClear = () => {
    return {
        type: PROFILE_LIST_COMMUNITIES_CLEAR,
    }
}
const profileListCommunitiesAppend = (community) => {
    return {
        type: PROFILE_LIST_COMMUNITIES_APPEND,
        community,
    }
}
const profileListCommunitiesFailure = () => {
    return {
        type: PROFILE_LIST_COMMUNITIES_FAILURE,
    }
}

const listCommunityMembers = () => {
    return {
        type: COMMUNITY_LIST_MEMBERS,
    }
}
const listCommunityMembersClear = () => {
    return {
        type: COMMUNITY_LIST_MEMBERS_CLEAR,
    }
}
const listCommunityMembersAppend = (member) => {
    return {
        type: COMMUNITY_LIST_MEMBERS_APPEND,
        member,
    }
}
const listCommunityMembersFailure = () => {
    return {
        type: COMMUNITY_LIST_MEMBERS_FAILURE,
    }
}

export const listMembers = (communinityId) => (dispatch) => {
    dispatch(listCommunityMembers())
    dispatch(listCommunityMembersClear())
    db.collection('community')
        .doc(communinityId)
        .get()
        .then((comDoc) => {
            console.log('got community ' + comDoc.id)
            let member_profile_ids = comDoc.data().member_profile_ids
            member_profile_ids.forEach((profileId) => {
                db.collection('profiles')
                    .doc(profileId)
                    .get()
                    .then((profDoc) => {
                        dispatch(
                            listCommunityMembersAppend({
                                ...profDoc.data(),
                                id: profDoc.id,
                            })
                        )
                    })
            })
        })
}

const listCommunityRequest = () => {
    return {
        type: COMMUNITY_LIST,
    }
}
const listCommunitySuccess = (communityList) => {
    return {
        type: COMMUNITY_LIST_SUCCESS,
        communityList,
    }
}
const listCommunityClear = () => {
    return {
        type: COMMUNITY_LIST_CLEAR,
    }
}
const listCommunityAppend = (community) => {
    return {
        type: COMMUNITY_LIST_APPEND,
        community,
    }
}

const listCommunityFailure = () => {
    return {
        type: COMMUNITY_LIST_FAILURE,
    }
}

const listCommunityFeedRequest = () => {
    return {
        type: COMMUNITY_LIST_FEED,
    }
}
const listCommunityFeedSuccess = (communityFeed) => {
    // console.log(communityFeed)
    return {
        type: COMMUNITY_LIST_FEED_SUCCESS,
        communityFeed,
    }
}
const listCommunityFeedAppend = (feedItem) => {
    return {
        type: COMMUNITY_LIST_FEED_APPEND,
        feedItem,
    }
}
const listCommunityFeedFailure = () => {
    return {
        type: COMMUNITY_LIST_FEED_FAILURE,
    }
}

export const listCommunityFeed = (communityId) => (dispatch) => {
    dispatch(listCommunityFeedRequest())
    let publishedRef = db.collection('published')
    let discussionRef = db
        .collection('community')
        .doc(communityId)
        .collection('discussion')
    let uid = myFirebase.auth().currentUser.uid

    if (myFirebase.auth().currentUser) {
        publishedRef
            .where('community_id', '==', communityId)

            .get()
            .then(
                (snapshot) => {
                    if (snapshot.empty) {
                        dispatch(listCommunityFeedSuccess([]))
                    }

                    let response = []

                    discussionRef.onSnapshot((discSnap) => {
                        // console.log('got discussion snapshot')
                        discSnap.forEach((discDoc) => {
                            // console.log(discDoc.data())
                            response.push({
                                ...discDoc.data(),
                                postType: 'discussion',
                                created_on: discDoc.data().created_on,
                                id: discDoc.id,
                            })
                            dispatch(
                                listCommunityFeedAppend({
                                    ...discDoc.data(),
                                    postType: 'discussion',
                                    created_on: discDoc.data().created_on,
                                    id: discDoc.id,
                                })
                            )
                        })
                    })
                    snapshot.forEach((doc) => {
                        response.push({
                            ...doc.data(),
                            postType: 'published',
                            created_on: doc.data().published_on,
                            id: doc.id,
                        })
                        dispatch(
                            listCommunityFeedAppend({
                                ...doc.data(),
                                postType: 'published',
                                created_on: doc.data().published_on,
                                id: doc.id,
                            })
                        )
                    })

                    // dispatch(listCommunityFeedSuccess(response))
                },
                () => {
                    dispatch(listCommunityFeedFailure())
                }
            )
    } else {
        setTimeout(() => {
            listCommunities()
        }, 500)
    }
}
export const listUserCommunities = (profileId) => (dispatch) => {
    dispatch(listCommunityClear())
    dispatch(listCommunityRequest())
    let profileRef = db.collection('profiles')
    let communityRef = db.collection('community')

    profileRef
        .doc(profileId)
        .get()
        .then((doc) => {
            let communities = doc.data()?.member_community_ids
            let comList = []

            // communities.forEach((comId) => {
            comList.forEach((comId) => {
                // console.log(comId)
                communityRef
                    .doc(comId)
                    .get()
                    .then((comDoc) => {
                        comList.push({ ...comDoc.data(), id: comDoc.id })
                        // console.log(comList.length)
                        if (comDoc.data().featuredId) {
                            getPublicCommunityFeaturedUrls(
                                comDoc.data().featuredId
                            ).then((urls) => {
                                dispatch(
                                    listCommunityAppend({
                                        ...comDoc.data(),
                                        id: comDoc.id,
                                        joined: true,
                                        hasFeatured: true,
                                        featuredUrls: urls,
                                    })
                                )
                            })
                        } else {
                            dispatch(
                                listCommunityAppend({
                                    ...comDoc.data(),
                                    id: comDoc.id,
                                    joined: true,
                                    hasFeatured: false,
                                    featuredUrls: [],
                                })
                            )
                        }
                    })
            })
            if (comList.length >= 4) {
                // console.log('long community list')
                dispatch(listCommunitySuccess(comList))
            } else {
                communityRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((comDoc) => {
                        if (comList.length < 5) {
                            if (
                                comList.filter((c) => c.id == comDoc.id)
                                    .length == 0
                            ) {
                                comList.push({
                                    ...comDoc.data(),
                                    id: comDoc.id,
                                })
                                if (comDoc.data().featuredId) {
                                    getPublicCommunityFeaturedUrls(
                                        comDoc.data().featuredId
                                    ).then((urls) => {
                                        dispatch(
                                            listCommunityAppend({
                                                ...comDoc.data(),
                                                id: comDoc.id,
                                                joined: false,
                                                hasFeatured: true,
                                                featuredUrls: urls,
                                            })
                                        )
                                    })
                                } else {
                                    dispatch(
                                        listCommunityAppend({
                                            ...comDoc.data(),
                                            id: comDoc.id,
                                            joined: false,
                                            hasFeatured: false,
                                            featuredUrls: [],
                                        })
                                    )
                                }
                            }
                        }
                    })
                })
            }
        })
}
export const listCommunities = (profileId) => (dispatch) => {
    dispatch(listCommunityRequest())

    let projectsRef = db.collection('community')

    projectsRef.onSnapshot(
        (snapshot) => {
            if (snapshot.empty) {
                dispatch(listCommunitySuccess([]))
            }

            let response = []
            snapshot.forEach((doc) => {
                response.push({ ...doc.data(), id: doc.id })
            })
            // console.log(response)
            dispatch(listCommunitySuccess(response))
        },
        () => {
            dispatch(listCommunityFailure())
        }
    )
}

export const pinDiscussion = (discussionId, communityId) => {
    // console.log('pinning discussion ' + discussionId)

    let communityRef = db.collection('community')
    let discussionRef = communityRef.doc(communityId).collection('discussion')
    discussionRef
        .where('pinned', '==', true)
        .where('__name__', '!=', discussionId)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                discussionRef
                    .doc(doc.id)
                    .update({
                        pinned: false,
                    })
                    .then(() => {
                        // console.log('unpinned existing')
                    })
            })
            discussionRef
                .doc(discussionId)
                .update({
                    pinned: true,
                })
                .then(() => {
                    // console.log('pinned discussion')
                })
        })
}
export const unpinDiscussion = (discussionId, communityId) => {
    // console.log('unpinning discussion ' + discussionId)

    let communityRef = db.collection('community')
    let discussionRef = communityRef
        .doc(communityId)
        .collection('discussion')
        .doc(discussionId)

    discussionRef
        .update({
            pinned: false,
        })
        .then(() => {
            // console.log('pinned discussion')
        })
}

export const deleteDiscussion = (discussionId, communityId) => {
    // console.log('deleting discussion ' + discussionId)
    unsubscribe()
    let communityRef = db.collection('community')
    let discussionRef = communityRef
        .doc(communityId)
        .collection('discussion')
        .doc(discussionId)

    discussionRef.delete().then(() => {
        // console.log('deleted discussion')
    })
}
export const createDiscussion = (communityId, options) => (dispatch) => {
    // console.log('creating discussion in ' + communityId)

    let communityRef = db.collection('community')
    let discussionRef = communityRef.doc(communityId).collection('discussion')

    discussionRef
        .add({
            title: options.title,
            bodyText: options.bodyText,
            profileId: options.profileId,
            created_on: new Date().toISOString(),
            communityId: communityId,
            pinned: false,
        })
        .then(() => {
            // console.log('created discussion')
        })
}
export const updateCommunity = (communityId, options) => (dispatch) => {
    // console.log('updating community ' + communityId)

    let communityRef = db.collection('community').doc(communityId)

    communityRef
        .update({
            ...(options.name && { name: options.name }),
            ...(options.description && { description: options.description }),
            ...(options.tags && { tags: options.tags }),
        })
        .then(() => {
            // console.log('update community')
        })
}

export const createCommunity =
    (name, description, visibility, tags, profileId) => (dispatch) => {
        dispatch(putCommunity())
        let profilesRef = db.collection('profiles')

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                // console.log('got id')

                db.collection('community')
                    .add({
                        name,
                        description,
                        visibility,
                        tags,
                        member_profile_ids: [profileId],
                        admin_profile_ids: [profileId],
                        member_count: 1,
                        creator_profile_id: profileId,
                        requested_profile_ids: [],
                    })
                    .then((ref) => {
                        // console.log(ref)
                        // console.log('created community')
                        profilesRef
                            .doc(profileId)
                            .get()
                            .then((profDoc) => {
                                let community_ids = []
                                db.collection('url_association').add({
                                    type: 'community',
                                    communityId: ref.id,
                                    shortUrl: ref.id.slice(0, 7),
                                })

                                if (profDoc.data().member_community_ids) {
                                    community_ids =
                                        profDoc.data().member_community_ids
                                }
                                community_ids.push(ref.id)
                                profilesRef.doc(profileId).update({
                                    member_community_ids: community_ids,
                                })
                                dispatch(putCommunitySuccess({ id: ref.id }))
                                // console.log('updated creator profile')
                            })
                    })
                    .catch((error) => {
                        // console.log(error)

                        dispatch(putCommunityFailure())
                    })
            })
            .catch(function (error) {
                // console.log(error)
                dispatch(putCommunityFailure())
            })
    }

const getCommunityRequest = () => {
    return {
        type: COMMUNITY_GET,
    }
}
const getCommunitySuccess = (community) => {
    return {
        type: COMMUNITY_GET_SUCCESS,
        community,
    }
}
const getCommunityFailure = () => {
    return {
        type: COMMUNITY_GET_FAILURE,
    }
}

const getDiscussionSuccess = (discussion) => {
    return {
        type: DISCUSSION_GET_SUCCESS,
        discussion,
    }
}

const listDiscussionCommentsSuccess = (comments) => {
    return {
        type: DISCUSSION_COMMENTS_LIST_SUCCESS,
        comments,
    }
}
export const unrequestJoinCommunity = (communityId) => (dispatch) => {
    let profilesRef = db.collection('profiles')

    var user = myFirebase.auth().currentUser

    if (user) {
        profilesRef
            .where('uid', '==', user.uid)
            .get()
            .then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let ids = []
                        // console.log(profileData.requested_community_ids)
                        if (profileData.requested_community_ids)
                            ids = profileData.requested_community_ids

                        var filtered = ids.filter(function (value, index, arr) {
                            return value !== communityId
                        })

                        // console.log(filtered)
                        // console.log('unrequesting to join ' + communityId)

                        profilesRef
                            .doc(doc.id)
                            .update({ requested_community_ids: filtered })

                        var unrequestJoinFunc = functions.httpsCallable(
                            'unrequestJoinCommunity'
                        )

                        unrequestJoinFunc({
                            profileId: doc.id,
                            communityId: communityId,
                        })
                            .then((response) => {
                                // console.log('unrequesting...')
                            })
                            .catch((error) => {
                                // console.log(error)
                            })
                    })
                },
                () => {}
            )
    }
}

export const getCommunity = (comId) => (dispatch) => {
    dispatch(getCommunityRequest())
    let communityRef = db.collection('community')
    communityRef.doc(comId).onSnapshot(function (doc) {
        var source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
        dispatch(
            getCommunitySuccess({ ...doc.data(), id: comId, source: source })
        )
    })
}

export const getDiscussion = (discId, comId) => (dispatch) => {
    dispatch(getCommunityRequest())
    let discRef = db.collection('community').doc(comId).collection('discussion')

    unsubscribe = discRef.doc(discId).onSnapshot(function (discDoc) {
        db.collection('profiles')
            .doc(discDoc.data().profileId)
            .get()
            .then((profDoc) => {
                dispatch(
                    getDiscussionSuccess({
                        ...discDoc.data(),
                        profile: profDoc.data(),
                        id: discId,
                    })
                )
            })
    })
}

export const listDiscussionComments =
    (discussionId, communityId) => (dispatch) => {
        let commentsRef = db
            .collection('community')
            .doc(communityId)
            .collection('discussion')
            .doc(discussionId)
            .collection('comments')

        commentsRef.onSnapshot((snapshot) => {
            let comments = []
            snapshot.forEach((comment) => {
                // console.log(communityId)
                // console.log(comment.data())
                comments.push({ id: comment.id, ...comment.data() })
            })
            // console.log(comments)
            dispatch(listDiscussionCommentsSuccess(comments))
        })
    }

export const deleteDiscussionComment =
    (commentId, discussionId, communityId) => (dispatch) => {
        // console.log('deleting comment ' + commentId)

        let commentRef = db
            .collection('community')
            .doc(communityId)
            .collection('discussion')
            .doc(discussionId)
            .collection('comments')
            .doc(commentId)

        commentRef
            .delete()
            .then(() => {
                // console.log('comment deleted')
            })
            .catch((error) => {
                // console.log(error)
            })
    }

export const saveDiscussionComment =
    (discussionId, communityId, options) => (dispatch) => {
        // console.log('saving comment')
        let commentsRef = db
            .collection('community')
            .doc(communityId)
            .collection('discussion')
            .doc(discussionId)
            .collection('comments')

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                commentsRef
                    .add({
                        profile: options.profile,
                        uid: options.profile.uid,
                        token: idToken,
                        text: options.text,
                        communityId: communityId,
                        commented_on: new Date().toISOString(),
                    })
                    .then(() => {
                        // console.log('comment saved')
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            })
    }

export const saveCommunityFeaturedImage = (communityId, file) => (dispatch) => {
    const featuredId = uuid.v1()
    var imagesRef = images.ref()
    var avatarRef = imagesRef.child('featured/' + featuredId)
    var metadata = {
        contentType: file.type,
    }
    // console.log(communityId)
    avatarRef.put(file, metadata).then(function (snapshot) {
        // console.log('upload community featured image ' + featuredId)

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                let comRef = db.collection('community').doc(communityId)
                // console.log('updated community featured')
                comRef.update({ featuredId: featuredId })
            })
            .catch(function (error) {})
    })
}

export const getPublicCommunityFeaturedUrls = async (featuredId) => {
    // console.log(featuredId)
    const urlBase = 'featured/resized/' + featuredId

    var imagesRef = images.ref()

    const featuredSmall = await imagesRef
        .child(urlBase + '_120x120')
        .getDownloadURL()
    const featuredMedium = await imagesRef
        .child(urlBase + '_300x300')
        .getDownloadURL()
    const featuredLarge = await imagesRef
        .child(urlBase + '_600x600')
        .getDownloadURL()

    // console.log(featuredLarge)
    return { featuredSmall, featuredMedium, featuredLarge }
}
