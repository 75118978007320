import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { clearNotifications } from '../../actions'
import NotificationsFeed from '../notifications/NotificationsFeed'

const useStyles = makeStyles({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainSpinner: {
        marginTop: 20,
    },
})
function Notifications(props) {
    const classes = useStyles()
    const { dispatch } = props

    useEffect(() => {
        dispatch(clearNotifications())
    }, [dispatch])

    return (
        <Container className={classes.mainLanding}>
            <NotificationsFeed />
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
    }
}
export default connect(mapStateToProps)(Notifications)
