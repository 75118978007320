import {
    FEEDBACK_ASK_PUT_REQUEST,
    FEEDBACK_ASK_PUT_SUCCESS,
    FEEDBACK_ASK_PUT_FAILURE,
    FEEDBACK_ASK_LIST_REQUEST,
    FEEDBACK_ASK_LIST_SUCCESS,
    FEEDBACK_ASK_LIST_FAILURE,
    FEEDBACK_TAB_REQUEST,
    FEEDBACK_TAB_LIST,
    REV_REQUEST_PUT_REQUEST,
    REV_REQUEST_PUT_SUCCESS,
    REV_REQUEST_PUT_FAILURE,
    REV_REQUEST_PUT_COMPLETE,
    REVIEW_GET_REQUEST,
    REVIEW_GET_FAILURE,
    REVIEW_GET_SUCCESS,
    FEEDBACK_LIST,
    FEEDBACK_LIST_FAILURE,
    FEEDBACK_LIST_SUCCESS,
} from '../actions/'

export default (
    state = {
        isProfileRequesting: true,
        profileRequested: false,
        profileRequestError: false,
        reviewPicker: {
            email: '',
            message: '',
        },

        isFeedbackAskListRequesting: false,
        feedbackAskListRequested: true,
        feedbackAskListError: false,
        feedbackAskList: [],
        feedbackTabRequest: false,
        feedbackTabList: true,
        feedbackList: [],
        feedbackListing: false,
        feedbackListSuccess: false,
        feedbackListFailure: false,
    },
    action
) => {
    switch (action.type) {
        case FEEDBACK_LIST:
            return {
                ...state,
                feedbackList: [],
                feedbackListing: true,
                feedbackListSuccess: false,
                feedbackListFailure: false,
            }

        case FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                feedbackList: action.feedbackList,
                feedbackListing: false,
                feedbackListSuccess: true,
                feedbackListFailure: false,
            }
        case FEEDBACK_LIST_FAILURE:
            return {
                ...state,
                feedbackList: [],
                feedbackListing: false,
                feedbackListSuccess: false,
                feedbackListFailure: true,
            }
        case REVIEW_GET_REQUEST:
            return {
                ...state,
                isReviewGetting: true,
                reviewAvailable: false,
                reviewError: false,
            }
        case REVIEW_GET_FAILURE:
            return {
                ...state,
                isReviewGetting: false,
                reviewAvailable: false,
                reviewError: true,
            }
        case REVIEW_GET_SUCCESS:
            return {
                ...state,
                isReviewGetting: true,
                reviewAvailable: false,
                reviewError: false,
                review: action.review,
            }
        case REV_REQUEST_PUT_REQUEST:
            return {
                ...state,
                isRevRequestSubmitting: true,
                revReqSubmitted: false,
                revReqError: false,
            }
        case REV_REQUEST_PUT_SUCCESS:
            return {
                ...state,
                isRevRequestSubmitting: false,
                revReqSubmitted: true,
                revReqError: false,
                revReq: action.revReq,
                request: action.request,
            }
        case REV_REQUEST_PUT_FAILURE:
            return {
                ...state,
                isRevRequestSubmitting: false,
                revReqSubmitted: false,
                revReqError: true,
            }
        case REV_REQUEST_PUT_COMPLETE:
            return {
                ...state,
                isRevRequestSubmitting: false,
                revReqSubmitted: false,
                revReqError: false,
            }
        case FEEDBACK_TAB_REQUEST:
            return {
                ...state,
                feedbackTabRequest: true,
                feedbackTabList: false,
            }
        case FEEDBACK_TAB_LIST:
            return {
                ...state,
                feedbackTabRequest: false,
                feedbackTabList: true,
            }
        case FEEDBACK_ASK_LIST_REQUEST:
            return {
                ...state,
                isFeedbackAskListRequesting: true,
                feedbackAskListRequested: false,
                feedbackAskListError: false,
            }
        case FEEDBACK_ASK_LIST_SUCCESS:
            return {
                ...state,
                isFeedbackAskListRequesting: false,
                feedbackAskListRequested: true,
                feedbackAskListError: false,
                feedbackAskList: action.feedbackAskList,
            }
        case FEEDBACK_ASK_LIST_FAILURE:
            return {
                ...state,
                isFeedbackAskListRequesting: false,
                feedbackAskListRequested: false,
                feedbackAskListError: true,
            }
        case FEEDBACK_ASK_PUT_REQUEST:
            return {
                ...state,
                isProfileRequesting: true,
                profileRequested: false,
                profileRequestError: false,
            }
        case FEEDBACK_ASK_PUT_SUCCESS:
            return {
                ...state,
                isProfileRequesting: false,
                profileRequested: true,
                profileRequestError: false,
                profile: action.profile,
            }
        case FEEDBACK_ASK_PUT_FAILURE:
            return {
                ...state,
                isProfileRequesting: false,
                profileRequested: false,
                profileRequestError: true,
            }
        default:
            return state
    }
}
