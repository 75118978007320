import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Hidden,
    Tab,
    Tabs,
    TextField,
    Typography,
    Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import CommunityFeaturedPicker from '../CommunityFeaturedPicker'
import { FaExclamationTriangle } from 'react-icons/fa'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { genreList } from '../../../constants/constants'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCommunity, updateCommunity } from '../../../actions'
import Confirm from '../../layout/Confirm'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mediaLarge: {
        objectFit: 'cover',
        width: '100%',
        height: 320,
    },
    banner: {
        position: 'relative',
        maxWidth: theme.breakpoints.values.lg,
        marginTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
    },
    headingLargeBox: {
        color: 'white',
        position: 'absolute',
        top: '35%',
        left: 40,
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headingLarge: {
        color: 'white',
        fontSize: '2.5em',
    },
    headingSmall: {
        color: 'white',
        position: 'absolute',
        top: '20%',
        left: 30,
        fontSize: '2.5em',
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    back: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        marginLeft: theme.spacing(0),
    },
    backContainer: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
    },
    blurb: {
        position: 'absolute',
        bottom: 20,
        left: 30,
        fontSize: '1.25em',
        color: 'white',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: 'rgba(77, 77, 77, 0.8)',
    },
    smallList: {
        marginTop: theme.spacing(1),
        maxWidth: theme.breakpoints.values.md,
    },
    mainBox: {
        marginTop: theme.spacing(1),
        maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    communityActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    communityDescription: {
        paddingLeft: theme.spacing(1),
        color: '#666',
    },
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    admin: {
        marginTop: theme.spacing(1),
    },
    progressBox: {
        textAlign: 'center',
        margin: theme.spacing(2),
    },
    messageBox: {
        padding: theme.spacing(2),
    },
    textBox: {
        padding: theme.spacing(1),
    },
    memberCount: {
        color: '#666',
        textAlign: 'center',
        width: 180,
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    messageText: {
        color: '#666',
        textAlign: 'center',
    },
    joinButton: {
        marginTop: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    chipItem: {
        marginRight: theme.spacing(1),
    },
    startDiscussion: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    discussionTitle: {
        backgroundColor: 'white',
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    discussionBody: {
        backgroundColor: 'white',
        width: '100%',
    },
    discussionAdd: {
        maxWidth: 600,
        padding: theme.spacing(2),
    },
    discussionHeading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    discussionMessage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },

    emphasis: {
        color: '#512da8',
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    postToCircle: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    postCancel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formGrid: {
        maxWidth: theme.breakpoints.values.sm,
    },
    tagsInput: {
        width: '100%',
        backgroundColor: 'white',
    },
    storyMessage: {
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        color: '#666',
    },
    nameInput: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: theme.spacing(2),
    },
    descriptionInput: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: theme.spacing(1),
    },
}))

function AdminTab() {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [tags, setTags] = useState([])
    const dispatch = useDispatch()

    const profile = useSelector((state) => state.profile.profile)
    const community = useSelector((state) => state.community.community)

    const [loggedInProfileIsCircleAdmin, setLoggedInProfileIsCircleAdmin] =
        useState(false)

    useEffect(() => {
        if (community) {
            setName(community.name)
            setDescription(community.description)
            console.log(community.tags)
            setTags(community.tags)
        }
    }, [])

    useEffect(() => {
        if (community?.admin_profile_ids?.includes(profile.id)) {
            setLoggedInProfileIsCircleAdmin(true)
        }
    }, [community, profile])
    return (
        <Grid
            className={classes.mainBox}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            <TextField
                className={classes.nameInput}
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                    dispatch(
                        updateCommunity(community.id, { name: e.target.value })
                    )
                    console.log(name)
                }}
            />
            <TextField
                className={classes.descriptionInput}
                variant="outlined"
                multiline
                value={description}
                label="Description"
                onChange={(e) => {
                    setDescription(e.target.value)
                    dispatch(
                        updateCommunity(community.id, {
                            description: e.target.value,
                        })
                    )
                    console.log(description)
                }}
            />
            <br />
            <Typography variant="body" className={classes.storyMessage}>
                Add tags to help people discover your Istoria Circle. Tags can
                be things like genres, commitment level, or subject matter.
            </Typography>
            <Autocomplete
                freeSolo
                multiple
                options={[
                    'Everyone ❤️',
                    'Casual Writers',
                    'Serious Hobbyists',
                    'Aspiring Professionals',
                    'Established/Published Writers',
                ].concat(genreList)}
                className={classes.tagsInput}
                value={tags}
                onChange={(e, value) => {
                    setTags(value)
                    console.log(value)
                    console.log(community.tags)
                    dispatch(updateCommunity(community.id, { tags: value }))
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.tagsInput}
                        variant="outlined"
                        label="Tags"
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
            />
            <br />
            <CommunityFeaturedPicker communityId={community.id} />

            {loggedInProfileIsCircleAdmin && (
                <>
                    <Divider className={classes.divider} />
                    <Button
                        startIcon={<FaExclamationTriangle />}
                        // onClick={() => {
                        //     console.log('deleting community')
                        //     dispatch(deleteCommunity(profile.id, community.id))
                        // }}
                        variant="outlined"
                        href="mailto:support@istoria.io"
                    >
                        Request Deletion
                    </Button>
                </>
            )}
        </Grid>
    )
}

export default AdminTab
