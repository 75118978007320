import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaEdit, FaFacebook } from 'react-icons/fa'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
    loginFacebook,
    loginGoogle,
    saveProject,
    saveProjectComplete,
} from '../../actions'
import { projectGetComplete } from '../../actions/write'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    projectEditor: {
        marginTop: 20,
        minHeight: 200,
        height: 200,
        backgroundColor: 'white',
    },
    editingTitle: {
        marginTop: 20,
        width: '100%',

        backgroundColor: 'white',
    },
    editingAction: {
        marginLeft: 5,
        marginRight: 5,
    },
    loginMessage: {
        textAlign: 'center',
        fontSize: '14pt',
        marginBottom: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    messageLink: {
        color: '#512da8',
    },
    createAccountButton: {
        marginTop: theme.spacing(1),
    },
    loginButton: {
        marginTop: theme.spacing(1),
    },
    projectMessage: {
        fontWeight: 'normal',
    },
    signupMessage: {
        color: '#666',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    startProject: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    googleButton: {
        marginTop: theme.spacing(1),
    },
}))

const Join = ({ showLogo, showDivider }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const FacebookButton = withStyles({
        label: {
            color: '#1877F2',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#1877F2',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#F6fafe',
                borderColor: '#0062cc',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#0062cc',
                borderColor: '#005cbf',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
    })(Button)
    const GoogleButton = withStyles({
        label: {
            color: '#444',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#444',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#fafafa',
                borderColor: '#444',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#fafafa',
                borderColor: '#444',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.1rem #fafafa',
            },
        },
    })(Button)

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            {showLogo && (
                <Typography
                    style={{
                        fontSize: 28,
                        color: '#512da8',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.istoriaLogo}
                >
                    istoria
                </Typography>
            )}

            {(showDivider || showLogo) && (
                <Divider className={classes.divider} />
            )}

            <Typography variant="body2" className={classes.signupMessage}>
                Don't have an account yet?
            </Typography>
            <FacebookButton
                variant="outlined"
                color="#1877F2"
                onClick={() => {
                    console.log('signing in with facebook...')
                    dispatch(loginFacebook())
                }}
                startIcon={<FaFacebook />}
                className={classes.resetButton}
            >
                Continue with Facebook
            </FacebookButton>
            <GoogleButton
                variant="outlined"
                component={Link}
                startIcon={
                    <img
                        height="20"
                        width="20"
                        alt="Google logo"
                        src="https://storage.googleapis.com/istoria-images/assets/g-logo.png"
                    />
                }
                onClick={() => {
                    console.log('signing in with google...')
                    dispatch(loginGoogle())
                }}
                className={classes.googleButton}
            >
                Sign up with Google
            </GoogleButton>

            <Button
                variant="outlined"
                component={Link}
                to={{
                    pathname: routes.SIGNUP,
                }}
                className={classes.createAccountButton}
            >
                Create Account
            </Button>

            <Divider className={classes.divider} />
            <Button
                variant="outlined"
                classes={{ root: classes.loginButton }}
                component={Link}
                to={{
                    pathname: routes.LOGIN,
                }}
            >
                Login
            </Button>
        </Grid>
    )
}

export default Join
