import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Hidden,
    Tab,
    Tabs,
    TextField,
    Typography,
    Chip,
} from '@material-ui/core'
import MemberCard from '../MemberCard'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    admin: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    memberCount: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))
function MembersTab() {
    const members = useSelector((state) => state.community.communityMembers)
    const classes = useStyles()

    const renderedMembers = (members) => {
        return members.map((member) => <MemberCard profile={member} />)
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.admin}
        >
            <Typography className={classes.memberCount}>
                {members.length} member{members.length !== 1 && 's'}
            </Typography>
            {renderedMembers(members)}
        </Grid>
    )
}

export default MembersTab
