import { Button, Container, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as routes from '../../constants/routes'
import Comment from './Comment'

import { listDiscussionComments } from '../../actions/community'
import { listProjectComments } from '../../actions/write'
const useStyles = makeStyles((theme) => ({
    commentBox: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        color: '#666',
    },
    icon: {
        position: 'relative',
        top: 2,
    },
    commentInput: {
        width: '100%',
    },
    commentSubmit: {
        marginTop: theme.spacing(2),
    },
    commentMessage: {
        marginBottom: theme.spacing(1),
    },

    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
    },
    authorContainer: {
        marginBottom: theme.spacing(2),
    },
    loginMessageBox: {
        borderTop: '1px solid #ccc',
        paddingTop: theme.spacing(2),
        width: '100%',
        textAlign: 'center',
        display: 'block',
    },
    loginMessage: {
        textAlign: 'center',
        fontSize: '14pt',
        marginBottom: theme.spacing(1),
    },
    messageLink: {
        color: '#512da8',
    },

    signupMessage: {
        color: '#666',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    loginOrSignup: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mainBox: {
        marginTop: theme.spacing(2),
    },
}))

function ListComments({ projectId, sectionId, communityId, discussionId }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const profile = useSelector((state) => state.profile.profile)
    const publishedComments = useSelector((state) => state.write.comments)
    const discussionComments = useSelector(
        (state) => state.community.discussionComments
    )

    let comments = discussionId ? discussionComments : publishedComments

    useEffect(() => {
        if (discussionId) {
            dispatch(listDiscussionComments(discussionId, communityId))
        } else {
            if (communityId) {
                dispatch(listProjectComments(projectId, sectionId, communityId))
            } else {
                dispatch(listProjectComments(projectId, sectionId, null))
            }
        }
    }, [])

    useEffect(() => {
        console.log(comments)
    }, [comments])

    return (
        <Grid
            container
            className={classes.mainBox}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
        >
            {comments && (
                <>
                    {profile.id &&
                        comments.map((comment) => (
                            <Comment
                                projectId={projectId}
                                sectionId={sectionId}
                                communityId={communityId}
                                discussionId={discussionId}
                                comment={comment}
                            />
                        ))}

                    {!profile.id &&
                        comments
                            .slice(0, 1)
                            .map((comment) => (
                                <Comment
                                    projectId={projectId}
                                    sectionId={sectionId}
                                    communityId={communityId}
                                    discussionId={discussionId}
                                    comment={comment}
                                />
                            ))}
                </>
            )}
        </Grid>
    )
}

export default ListComments
