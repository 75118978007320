import { db } from '../firebase/firebase'

export const WRITE_TAB = 'WRITE_TAB'
export const WRITE_SIDEBAR_POSITION = 'WRITE_SIDEBAR_POSITION'
export const WRITE_SIDEBAR_NOTE = 'WRITE_SIDEBAR_NOTE'

export const HOME_TOUR_START = 'HOME_TOUR_START'
export const HOME_TOUR_END = 'HOME_TOUR_END'

export const PROJECT_TOUR_START = 'PROJECT_TOUR_START'
export const PROJECT_TOUR_END = 'PROJECT_TOUR_END'

export const TOUR_MENU_OPEN = 'TOUR_MENU_OPEN'
export const TOUR_MENU_CLOSE = 'TOUR_MENU_CLOSE'

export const FEATURED_NEXT = 'FEATURED_NEXT'
export const FEATURED_PREVIOUS = 'FEATURED_PREVIOUS'
export const FEATURED_LOAD = 'FEATURED_LOAD'
export const FEATURED_LOAD_SUCCESS = 'FEATURED_LOAD_SUCCESS'

export const NEWSLETTER = 'NEWSLETTER'



export const featuredNext = () => {
  return {
    type: FEATURED_NEXT,
  }
}

export const featuredPrevious = () => {
  return {
    type: FEATURED_PREVIOUS,
  }
}
export const featuredLoading = (featuredList) => {
  return {
    type: FEATURED_LOAD,
    featuredList
  }
}
export const featuredLoadSuccess = (featuredList) => {
  console.log(featuredList)
  return {
    type: FEATURED_LOAD_SUCCESS,
    featuredList
  }
}

export const featuredLoad = () => (dispatch) => {
  dispatch(featuredLoading())
  console.log('loading featured pinned')
  const query = db.collection('published').where('isPin', '==', true)
  query.onSnapshot(
    async (docSnapshot) => {
      const arr = []
      docSnapshot.docs.map((doc) =>
        arr.push({ id: doc.id, ...doc.data() })
      )
      console.log('got featured pinned list')
      dispatch(featuredLoadSuccess(arr))
    },
    (err) => {
      console.log(`Encountered error: ${err}`)
    }
  )
}


export const openTourMenu = () => {
  return {
    type: TOUR_MENU_OPEN,
  }
}

export const closeTourMenu = () => {
  return {
    type: TOUR_MENU_CLOSE,
  }
}

export const startHomeTour = () => {
  return {
    type: HOME_TOUR_START,
  }
}

export const endHomeTour = () => {
  return {
    type: HOME_TOUR_END,
  }
}

export const startProjectTour = () => {
  return {
    type: PROJECT_TOUR_START,
  }
}

export const endProjectTour = () => {
  return {
    type: PROJECT_TOUR_END,
  }
}

export const setWriteTab = (tab) => {
  return {
    type: WRITE_TAB,
    tab,
  }
}

export const setSidebarPosition = (position) => {
  return {
    type: WRITE_SIDEBAR_POSITION,
    position,
  }
}

export const setSidebarNote = (note) => (dispatch) => {
  dispatch(setWriteTab('edit'))
  dispatch(setSideNote(note))
}

export const setSideNote = (note) => {
  return {
    type: WRITE_SIDEBAR_NOTE,
    note,
  }
}

export const promptNewsletter = () => {
  return {
    type: NEWSLETTER,
  }
}
