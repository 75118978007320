import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatRelative } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import ProfileAvatar from '../profile/ProfileAvatar'

const useStyles = makeStyles((theme) => ({
    box: {
        maxWidth: 280,
        textAlign: 'left',
    },
    boxInline: {
        maxWidth: 240,
        textAlign: 'left',
        display: 'inline-block',
    },
    authorName: {
        fontWeight: 'bold',
        fontSize: 16,
        marginLeft: theme.spacing(1),
    },
    authorLink: {
        color: '#512da8',
        textDecoration: 'none',
    },
    readTime: {
        marginLeft: theme.spacing(1),
        color: '#666',
    },
    commentedOn: {
        color: '#666',
        marginLeft: 10,
    },
}))
function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return formatRelative(d, new Date())
}

function AuthorBox({ size, uid, displayName, readTime, profile, at }) {
    const classes = useStyles()

    if (profile) {
        return (
            <Link className={classes.authorLink} to={'/pr/' + profile.id}>
                <Grid
                    className={classes.box}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <ProfileAvatar
                        size={size}
                        load
                        avatarId={profile.avatarId}
                    />

                    <Box className={classes.infoBox}>
                        <span className={classes.authorName}>
                            {profile.displayName
                                ? profile.displayName.length > 0
                                    ? profile.displayName
                                    : profile.email
                                : profile.email}
                        </span>
                        {at && (
                            <span className={classes.commentedOn}>
                                {formatDate(at)}
                            </span>
                        )}
                        {/* <span className={classes.readTime}>{readTime}</span> */}
                    </Box>
                </Grid>
            </Link>
        )
    } else {
        return (
            <Grid
                className={classes.box}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <ProfileAvatar size={size} />
                <Box className={classes.infoBox}>
                    <span className={classes.authorName}>{displayName}</span>{' '}
                    <br />
                    {/* <span className={classes.readTime}>{readTime}</span> */}
                </Box>
            </Grid>
        )
    }
}

export default AuthorBox
