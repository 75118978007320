import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Helmet } from 'react-helmet'
import {
    FaBookOpen,
    FaHeart,
    FaInstagram,
    FaUserCheck,
    FaUserFriends,
    FaChevronLeft,
    FaUserPlus,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { saveUserAvatar } from '../../actions'
import {
    followProfile,
    getAvatarUrls,
    getPublicProfile,
    getAllPublished,
    getAllProfile,
    getUserProfile,
    unFollowProfile,
} from '../../actions/profile'
import { listPublishedWriting } from '../../actions/write'
import * as routes from '../../constants/routes'
import ScrollToTop from '../layout/ScrollToTop'
import PublishedCard from '../publish/PublishedCard'
import ShareProfile from './ShareProfile'
import NotFound from './NotFound'
import ProfileModal from './ProfileModal'
import SignUpModal from './SignUpModal'

const useStyles = makeStyles((theme) => ({
    activityList: {
        fontSize: 20,
        textAlign: 'left',
    },
    avatar: {
        border: '1px solid #512da8',
        height: 240,
        width: 240,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    avatarSkeleton: {
        height: 240,
        width: 240,
        marginBottom: theme.spacing(2),
    },
    backActions: {
        position: 'absolute',
        left: '9.5%',
        width: 100,
        textAlign: 'left',
    },
    bannerActions: {
        position: 'relative',
        textAlign: 'center',
        height: 32,
        textDecoration: 'none',
        maxWidth: theme.breakpoints.values.lg,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    configItem: {
        marginBottom: 20,
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '896px',
    },

    dividerShort: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '60px',
    },
    edit: {
        fontSize: 19,
        marginBottom: 5,
        width: '173.56px',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    follow: {
        marginBottom: theme.spacing(3),
        fontSize: 20,
    },

    insta: {
        marginBottom: theme.spacing(2),
        fontSize: 20,
        paddingLeft: '2px',
    },
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainBox: {
        position: 'relative',
        maxWidth: '100vw',
    },
    metrics: {},
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    profileActivity: {
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
    },
    profileActivityTitle: {
        color: '#666',
        fontWeight: 'bold',
        fontSize: 20,
    },
    profileBio: {
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontSize: 20,
        maxWidth: theme.breakpoints.values.sm,
        textAlign: 'left',
    },
    profileInfoIcon: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(-0.5),
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
    profileHeartCount: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(10),
        marginRight: theme.spacing(3),
        fontSize: 20,
    },
    profileInput: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
    profileJoined: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: '#999',
        fontSize: 20,
    },
    profileLoading: {
        marginTop: 70,
        textAlign: 'center',
    },
    profileName: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    info: {
        textAlign: 'left',
    },
    profileNameBio: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '25%',
            paddingRight: '25%',
        },
    },
    profilePronouns: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
    profileSummary: {
        display: 'flex',
        justifyContent: 'space-evenly',
        maxWidth: theme.breakpoints.values.md,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    publishedBox: {
        maxWidth: theme.breakpoints.values.md,
        paddingTop: theme.spacing(1),
    },
    publishedHeading: {
        color: '#666',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    quotes: {
        position: 'relative',
        top: 2,
        color: '#666',
        marginRight: 2,
    },
    quotesRight: {
        top: 2,
        color: '#666',
        marginRight: 1,
    },
    rightActions: {
        width: 100,
        textAlign: 'right',
    },
    shareBox: {
        position: 'absolute',
        right: '45%',
        marginTop: theme.spacing(1),
        textAlign: 'center',
        width: 100,
        display: 'inline-block',
        [theme.breakpoints.down('md')]: {
            right: '5%',
        },
    },
    user: {
        position: 'relative',
        top: 2,
    },
}))

function compare(a, b) {
    let comparison = 0
    if (a.published_on < b.published_on) {
        comparison = 1
    } else if (a.published_on > b.published_on) {
        comparison = -1
    }
    return comparison
}

function PublicProfile({ user, isAuthenticated }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [following, setFollowing] = useState(false)
    const [selfProfile, setSelfProfile] = useState(false)
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [avatarLoaded, setAvatarLoaded] = useState(false)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [width, setWidth] = useState(window.innerWidth)
    const [displayStatus, setDisplayStatus] = useState('none')
    const [titleText, setTitleText] = useState('')
    const [displayStatusPM, setDisplayStatusPM] = useState('none')
    const [titleTextPM, setTitleTextPM] = useState('')
    const [authors, setAuthors] = useState(false)
    const publicProfile = useSelector((state) => state.profile.publicProfile)

    const signUpText = (
        <Typography>
            <strong>
                Create an account to follow writers and be <br />
                notified of future stories.
            </strong>
        </Typography>
    )

    let { id } = useParams()

    const profileGetError = useSelector(
        (state) => state.profile.profileGetError
    )
    const profileGetting = useSelector((state) => state.profile.profileGetting)
    const profile = useSelector((state) => state.profile.profile)
    const formattedDate = publicProfile.date
        ? 'Joined ' + dayjs(publicProfile.date).format('dddd, MMMM D YYYY')
        : ''
    let splitProfileName
    if (publicProfile?.displayName?.indexOf(' ') <= 0) {
        splitProfileName =
            publicProfile?.displayName?.slice(0, 15) +
            '- ' +
            publicProfile?.displayName?.slice(
                15,
                publicProfile?.displayName?.length
            )
    } else {
        splitProfileName = publicProfile.displayName
    }
    // +
    // '- ' +
    // publicProfile?.displayName?.match(/.[1,10]/g)[1]

    const publishedList = useSelector((state) => {
        // if (state.write.publishedList.length > 0) {
        //     console.log('here')
        //     console.log(state.write)
        // }
        return state.write.publishedList
    })
    let likeCount = 0
    if (publishedList) {
        console.log('#########################ccc####################')
        console.log(publishedList)
        publishedList.forEach((published) => {
            if (published.liked_uids) {
                if (published.liked_uids.length > 0)
                    likeCount += published.liked_uids.length
            } else {
                if (published?.liked_count > 0)
                    likeCount += published.liked_count
            }
        })
    }
    const publishedListingSuccess = useSelector(
        (state) => state.write.publishedListingSuccess
    )

    const onDrop = useCallback(
        (acceptedFiles) => {
            dispatch(saveUserAvatar(acceptedFiles[0]))
        },
        [dispatch]
    )

    useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/webp',
    })
    useEffect(() => {
        setTimeout(function () {
            setLoading(false)
        }, 1500)

        if (publicProfile.avatarId) {
            getAvatarUrls(publicProfile.avatarId).then((urls) => {
                if (urls.avatarMedium) {
                    setAvatarUrl(urls.avatarMedium)
                } else {
                    setAvatarUrl(null)
                }
                setAvatarLoaded(true)
            })
            setAvatarLoaded(true)
            setLoading(false)
        } else {
            setAvatarLoaded(true)
            setLoading(false)
        }
    }, [publicProfile.avatarId])

    useEffect(() => {
        if (!profile.id) {
            console.log('user logged out')
        }
        if (profile.id && profile.id === publicProfile.id) {
            setSelfProfile(true)
        }
        if (profile.following_profile_ids) {
            if (profile.following_profile_ids.includes(publicProfile.id)) {
                setFollowing(true)
            }
        }
        if (publicProfile.uid) dispatch(listPublishedWriting(publicProfile.uid))
    }, [publicProfile, profile, id, dispatch])

    useEffect(() => {
        setLoading(true)
        dispatch(getPublicProfile(id))
        dispatch(getUserProfile(true))
        console.log(
            '======================================bbb=============================================='
        )
    }, [id, dispatch])

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth))
        dispatch(getAllProfile())
        dispatch(getAllPublished())
    })

    useEffect(() => {
        if (displayStatus === 'block' || displayStatusPM === 'block') {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [displayStatus, displayStatusPM])

    console.log(displayStatus, displayStatusPM)

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox,
            }}
        >
            <Box>
                <ProfileModal
                    displayStatusPM={displayStatusPM}
                    setDisplayStatusPM={setDisplayStatusPM}
                    titleTextPM={titleTextPM}
                    publishedList={publishedList}
                    publicProfile={publicProfile}
                    authors={authors}
                />
            </Box>{' '}
            {publicProfile?.displayName && (
                <Helmet>
                    <title>{publicProfile.displayName} - istoria</title>
                </Helmet>
            )}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.bannerActions}
            >
                <Box className={classes.backActions}>
                    <Button
                        className={classes.back}
                        onClick={() => {
                            history.goBack()
                        }}
                        startIcon={<FaChevronLeft />}
                    >
                        Back
                    </Button>
                </Box>
                {profileGetError && <NotFound />}

                <Box className={classes.shareBox}>
                    {publicProfile.id && (
                        <ShareProfile
                            publicProfileId={publicProfile.id}
                            publicProfile={publicProfile}
                        />
                    )}
                </Box>
            </Grid>
            <ScrollToTop />
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                className={classes.profileSummary}
            >
                {(!publishedListingSuccess || profileGetting || loading) &&
                    !profileGetError && (
                        <Container className={classes.profileLoading}>
                            <CircularProgress />
                        </Container>
                    )}
                {publishedListingSuccess && !profileGetting && !loading && (
                    <>
                        <Grid item columns={{ xs: 4, sm: 8, md: 12 }}>
                            {publicProfile.avatarId && (
                                <Avatar
                                    className={classes.avatar}
                                    src={avatarUrl}
                                    variant="circular"
                                />
                            )}

                            {!publicProfile.avatarId && !avatarLoaded && (
                                <Skeleton
                                    variant="circular"
                                    className={classes.avatarSkeleton}
                                    width={180}
                                    height={180}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={4}
                            className={classes.profileNameBio}
                        >
                            <Typography
                                variant="h4"
                                className={classes.profileName}
                            >
                                {publicProfile.displayName ? (
                                    <strong>{splitProfileName}</strong>
                                ) : (
                                    <strong>{publicProfile.email}</strong>
                                )}
                                &nbsp;
                                {publicProfile.pronouns ? (
                                    <strong>({publicProfile.pronouns})</strong>
                                ) : (
                                    ''
                                )}
                            </Typography>
                            {publicProfile.bio?.length > 1 && (
                                <Box
                                    className={classes.profileBio}
                                    elevation={1}
                                >
                                    <Typography variant="h6">
                                        {publicProfile.bio}{' '}
                                    </Typography>
                                </Box>
                            )}
                            <Box className={classes.profileActivity}>
                                <Typography
                                    className={classes.profileActivityTitle}
                                >
                                    Activity
                                </Typography>
                                <Typography className={classes.activityList}>
                                    <a
                                        href='#"'
                                        color="inherit"
                                        onClick={() => (
                                            console.log(
                                                publicProfile?.likedPublishes
                                            ),
                                            setTitleTextPM('likedText'),
                                            setDisplayStatusPM('block'),
                                            setAuthors(false)
                                        )}
                                    >
                                        {publicProfile?.likedPublishes?.length}{' '}
                                        stories liked
                                    </a>
                                </Typography>{' '}
                                {publicProfile.following_profile_ids &&
                                publicProfile.following_profile_ids.length >
                                    0 ? (
                                    <Typography
                                        className={classes.activityList}
                                    >
                                        <a
                                            href='#"'
                                            color="inherit"
                                            onClick={() => (
                                                console.log(
                                                    publicProfile.following_profile_ids
                                                ),
                                                setTitleTextPM('followedText'),
                                                setDisplayStatusPM('block'),
                                                setAuthors(true)
                                            )}
                                        >
                                            {
                                                publicProfile
                                                    .following_profile_ids
                                                    .length
                                            }{' '}
                                            writers followed
                                        </a>
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={classes.activityList}
                                    >
                                        0 writers followed
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            className={classes.info}
                            // direction="column"
                            // justifyContent="left"
                            // alignItems="left"
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={4}
                                className={classes.info}
                            >
                                {selfProfile && (
                                    <Button
                                        className={classes.edit}
                                        component={Link}
                                        variant="outlined"
                                        to={{
                                            pathname: routes.PROFILE,
                                        }}
                                    >
                                        Edit Profile
                                    </Button>
                                )}
                                {!profile.id && (
                                    <Grid>
                                        <Box>
                                            <SignUpModal
                                                displayStatus={displayStatus}
                                                setDisplayStatus={
                                                    setDisplayStatus
                                                }
                                                titleText={signUpText}
                                            />
                                        </Box>
                                        <Button
                                            startIcon={<FaUserPlus />}
                                            className={classes.follow}
                                            variant="outlined"
                                            onClick={() => {
                                                setDisplayStatus('block')
                                            }}
                                        >
                                            Follow
                                        </Button>
                                    </Grid>
                                )}
                                {profile.id && !following && !selfProfile && (
                                    <Button
                                        startIcon={<FaUserPlus />}
                                        className={classes.follow}
                                        variant="outlined"
                                        onClick={() => {
                                            setFollowing(true)
                                            dispatch(
                                                followProfile(publicProfile)
                                            )
                                        }}
                                    >
                                        Follow
                                    </Button>
                                )}
                                {profile.id && following && !selfProfile && (
                                    <Button
                                        startIcon={<FaUserCheck />}
                                        className={classes.follow}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            setFollowing(false)
                                            dispatch(
                                                unFollowProfile(publicProfile)
                                            )
                                        }}
                                    >
                                        Following
                                    </Button>
                                )}
                            </Grid>
                            <Grid item className={classes.metrics}>
                                <FaUserFriends
                                    className={classes.profileInfoIcon}
                                />
                                {!publicProfile.followers && (
                                    <Typography
                                        variant="body"
                                        className={classes.profileHeartCount}
                                        gutterBottom
                                    >
                                        0
                                    </Typography>
                                )}
                                {publicProfile.followers &&
                                    publicProfile.followers.length > 0 && (
                                        <Typography
                                            variant="body"
                                            className={
                                                classes.profileHeartCount
                                            }
                                            gutterBottom
                                        >
                                            {publicProfile.followers.length}
                                        </Typography>
                                    )}
                                <FaHeart
                                    className={classes.profileInfoIcon}
                                    color="#bf281d"
                                />{' '}
                                <Typography
                                    variant="body"
                                    className={classes.profileHeartCount}
                                    gutterBottom
                                >
                                    {likeCount}
                                </Typography>
                                <FaBookOpen
                                    className={classes.profileInfoIcon}
                                />{' '}
                                <Typography
                                    variant="body"
                                    className={classes.profileHeartCount}
                                    gutterBottom
                                >
                                    {publishedList.length}
                                </Typography>
                            </Grid>
                            {publicProfile.insta && (
                                <Grid item>
                                    <Button
                                        color="primary"
                                        className={classes.insta}
                                        startIcon={<FaInstagram />}
                                        onClick={() => {
                                            window.location.href = `https://www.instagram.com/${publicProfile.insta}`
                                        }}
                                    >
                                        Instagram
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
            {publishedListingSuccess && !profileGetting && !loading && (
                <>
                    <Container className={classes.publishedBox}>
                        <Divider className={classes.dividerS} />

                        <Typography
                            className={classes.publishedHeading}
                            variant="h6"
                        >
                            {publicProfile.displayName
                                ? publicProfile.displayName
                                : publicProfile.email}
                            {publicProfile.displayName.slice(-1) === 's'
                                ? "' Stories"
                                : "'s Stories"}
                        </Typography>
                        {publishedListingSuccess && (
                            <Box>
                                {publishedList.length === 0 && (
                                    <Container
                                        className={classes.nothingMessage}
                                    >
                                        <Typography variant="body2">
                                            There's nothing here yet.
                                        </Typography>
                                    </Container>
                                )}
                                {publishedList
                                    .sort(compare)
                                    .map((published) => (
                                        <PublishedCard
                                            published={published}
                                            key={published.id}
                                        ></PublishedCard>
                                    ))}
                            </Box>
                        )}
                    </Container>
                </>
            )}
        </Grid>
    )
}
export default PublicProfile
