import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Chip,
    CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import {
    FaArrowCircleLeft,
    FaArrowCircleRight,
    FaChevronLeft,
    FaCheckCircle,
} from 'react-icons/fa'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { createCommunity } from '../../actions'
import { putCommunityComplete } from '../../actions/community'
import * as routes from '../../constants/routes'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useHistory } from 'react-router-dom'
import { genreList } from '../../constants/constants'
import CommunityFeaturedPicker from './CommunityFeaturedPicker'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.md,
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    preview: {
        marginTop: 20,
    },
    previewMessage: {
        marginTop: 20,
        textAlign: 'center',
        color: '#666',
    },
    aboutInput: {
        width: '100%',
        backgroundColor: 'white',
    },
    aboutDescription: {
        width: '100%',
        backgroundColor: 'white',
    },
    aboutWarning: {
        color: '#666',
        marginTop: 20,
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    steps: {
        marginBottom: theme.spacing(3),
        fontWeight: 'bold',
    },
    submit: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },

    istoriaInline: {
        display: 'inline-block',
    },
    emphasis: {
        color: '#512da8',
    },
    visibilityInput: {
        width: '100%',
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        backgroundColor: 'white',
    },
    formGrid: {
        maxWidth: theme.breakpoints.values.sm,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    tagsInput: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: theme.spacing(2),
    },
    storyMessage: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        color: '#666',
    },
}))

function NewCommunity({ cancel }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [submitted, setSubmitted] = useState('')
    const [visibility, setVisibility] = useState('request')
    const [tags, setTags] = useState([])
    const [doRedirect, setDoRedirect] = useState(false)

    const [page, setPage] = useState(0)

    const profile = useSelector((state) => state.profile.profile)
    const communityPutSuccess = useSelector(
        (state) => state.community.communityPutSuccess
    )
    const community = useSelector((state) => state.community.community)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    if (doRedirect) {
        dispatch(putCommunityComplete())
        return (
            <Redirect to={routes.COMMUNITY_VIEW.replace(':id', community.id)} />
        )
    } else
        return (
            <Grid
                className={classes.mainBox}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classes.bannerActions}
                >
                    <Box className={classes.backActions}>
                        <Button
                            className={classes.back}
                            startIcon={<FaChevronLeft />}
                            onClick={() => {
                                history.goBack()
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    className={classes.formGrid}
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography variant="h6" className={classes.heading}>
                        Create your own{' '}
                        <Typography
                            style={{
                                fontSize: '1.25rem',
                                color: '#512da8',
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Slab',
                            }}
                            className={classes.istoriaInline}
                        >
                            istoria circle
                        </Typography>
                    </Typography>
                    <Typography variant="h6" className={classes.steps}>
                        Step {page + 1} / 4
                    </Typography>
                    {page == 3 && (
                        <>
                            <Typography
                                variant="body"
                                className={classes.storyMessage}
                            >
                                A featured image brings a little color to your{' '}
                                <b className={classes.emphasis}>Circle</b> and
                                helps set the mood. If you're looking for
                                options try{' '}
                                <a
                                    class="alink"
                                    href="https://search.creativecommons.org"
                                >
                                    Creative Commons
                                </a>{' '}
                                for publicly available images.
                            </Typography>

                            {!communityPutSuccess && (
                                <CircularProgress
                                    className={classes.viewProgress}
                                />
                            )}
                            {communityPutSuccess && !submitted && (
                                <CommunityFeaturedPicker
                                    communityId={community.id}
                                    setSubmitted={setSubmitted}
                                />
                            )}
                            {communityPutSuccess && submitted && (
                                <>
                                    <FaCheckCircle size="4em" color="green" />
                                    <Typography variant="h6">
                                        Featured image uploaded!
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                    {page == 2 && (
                        <>
                            <Typography
                                variant="body"
                                className={classes.storyMessage}
                            >
                                Add tags to help people discover your{' '}
                                <b className={classes.emphasis}>Circle</b>. Tags
                                can be things like genres, commitment level, or
                                subject matter.
                            </Typography>
                            <Autocomplete
                                freeSolo
                                multiple
                                options={[
                                    'Everyone ❤️',
                                    'Casual Writers',
                                    'Serious Hobbyists',
                                    'Aspiring Professionals',
                                    'Established/Published Writers',
                                ].concat(genreList)}
                                className={classes.tagsInput}
                                value={tags}
                                onChange={(e, value) => {
                                    setTags(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.tagsInput}
                                        variant="outlined"
                                        label="Tags"
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                        </>
                    )}
                    {page == 1 && (
                        <>
                            <Typography
                                variant="body"
                                className={classes.storyMessage}
                            >
                                Now that you have a purpose, give your&nbsp;
                                <b className={classes.emphasis}>Circle</b>
                                &nbsp;a simple name.
                            </Typography>
                            <TextField
                                className={classes.aboutInput}
                                label="What do you want to call your Circle?"
                                variant="outlined"
                                onChange={(e) => {
                                    setName(e.target.value)
                                    console.log(name)
                                }}
                            />
                        </>
                    )}
                    {page == 0 && (
                        <>
                            <Typography
                                variant="body"
                                className={classes.storyMessage}
                            >
                                <b className={classes.emphasis}>
                                    Istoria Circles
                                </b>{' '}
                                allow you to share stories privately with a
                                select group of friends. We hope you use{' '}
                                <b className={classes.emphasis}>Circles</b> for
                                uplifting purposes like celebrating writing
                                progress, providing honest feedback, sharing
                                prompts and idea, or supporting one another's
                                writing on social media.
                            </Typography>

                            <br />

                            <TextField
                                className={classes.aboutDescription}
                                variant="outlined"
                                multiline
                                rows={5}
                                label="What is the purpose of your Circle?"
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                    console.log(description)
                                }}
                            />
                        </>
                    )}

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.stepper}
                    >
                        {page > 0 && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={!(page > 0)}
                                classes={{ root: classes.submit }}
                                onClick={() => {
                                    setPage(page - 1)
                                }}
                                startIcon={<FaArrowCircleLeft />}
                            >
                                Back
                            </Button>
                        )}
                        {page == 0 && <>&nbsp;</>}
                        {page == 0 && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={!(description.length > 0)}
                                classes={{ root: classes.submit }}
                                onClick={() => {
                                    setPage(page + 1)
                                }}
                                endIcon={<FaArrowCircleRight />}
                            >
                                Next Step
                            </Button>
                        )}
                        {page == 1 && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={!(name.length > 0)}
                                classes={{ root: classes.submit }}
                                onClick={() => {
                                    setPage(page + 1)
                                }}
                                endIcon={<FaArrowCircleRight />}
                            >
                                Next Step
                            </Button>
                        )}
                        {page == 2 && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={!(description.length > 0)}
                                classes={{ root: classes.submit }}
                                onClick={() => {
                                    setPage(page + 1)
                                    console.log('creating community ' + name)
                                    dispatch(
                                        createCommunity(
                                            name,
                                            description,
                                            visibility,
                                            tags,
                                            profile.id
                                        )
                                    )
                                }}
                                endIcon={<FaArrowCircleRight />}
                            >
                                Next Step
                            </Button>
                        )}
                        {page == 3 && (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        !(
                                            name.length > 0 &&
                                            description.length > 0
                                        )
                                    }
                                    classes={{ root: classes.submit }}
                                    onClick={() => {
                                        setDoRedirect(true)
                                    }}
                                    endIcon={<FaArrowCircleRight />}
                                >
                                    Finish
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
}

function mapStateToProps(state) {
    return {
        communityList: state.community.communityList,
        community: state.community.community,
    }
}
export default connect(mapStateToProps)(NewCommunity)
