import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import SignUpForModals from '../profile/SignUpForModals.js'
import { FaTimes } from 'react-icons/fa'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    modalStyle: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '15vw',
        minHeight: '45vh',
        margin: 'auto',
        padding: '40px',
        backgroundColor: 'white',
        zIndex: 100,
        borderRadius: '12px',
        opacity: 1,
        border: '1px solid #666',
    },
    modalTitle: {
        textAlign: 'center',
    },
    grayBackground: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        opacity: 0.9,
        zIndex: 90,
    },
    modalClose: {
        position: 'absolute',
        minWidth: '2px',
        minHeight: '2px',
        top: 5,
        right: 5,
    },
}))

const SignUpModal = ({ titleText, displayStatus, setDisplayStatus }) => {
    const classes = useStyles()

    return (
        <>
            <Box
                className={classes.grayBackground}
                display={displayStatus}
            ></Box>
            <Box className={classes.modalStyle} display={displayStatus}>
                <Box>
                    <Button
                        className={classes.modalClose}
                        onClick={() => setDisplayStatus('none')}
                    >
                        <FaTimes />
                    </Button>
                </Box>
                <Typography
                    className={classes.modalTitle}
                    variant="h6"
                    component="h2"
                >
                    {titleText}
                </Typography>
                <SignUpForModals />
            </Box>
        </>
    )
}

export default SignUpModal
