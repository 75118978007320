import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({
    component: Component,
    isAuthenticated,
    isVerifying,
    isAdminRoute,
    isAdmin,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) =>
            isVerifying ? (
                <div />
            ) : isAuthenticated ? (
                isAdminRoute ? (
                    isAdmin ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    )
                ) : (
                    <Component {...props} />
                )
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)
export default ProtectedRoute
