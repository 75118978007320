import {
    Button,
    Container,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getFeedbackRequest, saveFeedback } from '../../actions'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mainBox: {
        padding: 10,
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headingBox: {
        textAlign: 'center',
    },
    formBox: {
        display: 'flex',
        width: '100%',
    },
    reviewActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    reviewActionsButton: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: theme.spacing(1),
    },
    reviewComment: {
        width: '100%',
        backgroundColor: 'white',

        marginTop: theme.spacing(1),
    },
    writingBody: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
    },

    editorBox: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
        marginTop: 20,
    },
    actionsBox: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
        marginTop: 20,
    },
    reviewTitle: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    reviewAuthor: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },

    writingEditor: {
        backgroundColor: 'white',
        marginTop: theme.spacing(2),
    },
}))

function EditReview(props) {
    let { id } = useParams()

    const [body, setBody] = useState('')
    const [comments, setComments] = useState('')
    const { dispatch } = props
    const request = useSelector((state) => state.review.request)
    const project = useSelector((state) => state.write.project)
    const classes = useStyles()

    useEffect(() => {
        dispatch(getFeedbackRequest(id))
    }, [dispatch, id])

    useEffect(() => {
        if (request) {
        }
    }, [request])

    useEffect(() => {
        if (request) {
            setBody(project.body)
        }
    }, [project, request])
    return (
        <Container>
            <Typography className={classes.reviewTitle} variant="h5">
                Reviewing <b>{request?.writingTitle}</b>
            </Typography>
            <Typography className={classes.reviewAuthor} variant="body2">
                <span color="#666">for</span> <b>{request?.recipientEmail}</b>
            </Typography>
            <ReactQuill
                className={classes.writingEditor}
                value={body}
                onChange={(value) => {
                    setBody(value)
                }}
            />
            <TextField
                className={classes.reviewComment}
                label="Comments"
                variant="outlined"
                value={comments}
                onChange={(e) => {
                    setComments(e.target.value)
                }}
                multiline
            ></TextField>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.reviewActions}
            >
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.reviewActionsButton}
                    onClick={(e) => {
                        console.log('save feedback')
                        dispatch(
                            saveFeedback(
                                request.id,
                                request.projectId,
                                body,
                                request.writingBody,
                                comments
                            )
                        )
                    }}
                >
                    Save Feedback
                </Button>
            </Grid>
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        project: state.write.project,
    }
}
export default connect(mapStateToProps)(EditReview)
