import { Box, Grid } from '@material-ui/core'
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarNote, setSidebarPosition } from '../../../actions'
import CorkBoard from '../CorkBoard'
import SaveStatus from '../SaveStatus'
import WriteEditor from '../WriteEditor'

function WriteTab() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const classes = useStyles()

    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const current = useSelector((state) => state.ui.sidebarPosition)
    const project = useSelector((state) => state.write.project)

    const isDefault = false

    useEffect(() => {
        if (current === -1 && !noteSelected && isDefault) {
            dispatch(setSidebarPosition(0))
            dispatch(setSidebarNote(false))
        }
    }, [isDefault, project, noteSelected, current, dispatch])
    return (
        <Box className={classes.mainBox} maxWidth={theme.breakpoints.values.lg}>
            <Grid
                className={classes.aboutStatusBox}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                &nbsp;
                {current !== -1 && (
                    <SaveStatus className={classes.aboutStatus} />
                )}
            </Grid>

            {current !== -1 && <WriteEditor />}
            {current === -1 && <CorkBoard isDefault={isDefault} />}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    feedProgress: {
        marginTop: 20,
    },
    feedList: {
        marginTop: 20,
    },
    feedCard: {
        marginBottom: 10,
    },
    cardTitle: {
        fontSize: 24,
    },
    viewAction: {
        marginRight: 10,
    },
    viewTitle: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    viewBody: {
        marginTop: 10,
    },
    viewActionRow: {
        marginTop: 10,
    },
    mainBox: {
        marginTop: 10,
        padding: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    aboutStatus: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    aboutStatusBox: {
        marginBottom: theme.spacing(2),
        marginTop: -30,
    },
    writeQuill: {
        backgroundColor: 'white',
    },
    writeToggle: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

export default withTheme(WriteTab)
