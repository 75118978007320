import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Button,
    Divider,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    MobileStepper,
    IconButton,
} from '@material-ui/core'
import useInterval from '../../constants/useInterval'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import { getPinPublishedStory, listPublishedFeatured } from '../../actions'
import { featuredLoad, featuredPrevious, featuredNext } from '../../actions/ui'

import { useDispatch, useSelector } from 'react-redux'
import LandingCard from './LandingCard'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    lightButton: {
        backgroundColor: 'white',
        borderRadius: 20,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        '&:hover': {
            color: '#512da8',
            backgroundColor: 'white',
            border: '1px solid #512da8',
        },
    },

    darkButton: {
        borderRadius: 20,
        backgroundColor: '#512da8',
        color: 'white',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        '&:hover': {
            color: '#512da8',
        },
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    bodyCopy: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.lg,
        textAlign: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    featuredBox: {
        border: '1px solid white',
        position: 'relative',
        maxWidth: 500,
        minWidth: 400,
        textAlign: 'center',
        minHeight: 400,
        borderRadius: 20,
        backgroundColor: '#efefef',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    featuredHeading: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    stepperBox: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
    },
    stepper: {
        justifyContent: 'center',
        backgroundColor: '#efefef',
    },
    leftArrow: {
        position: 'absolute',
        left: 0,
        top: '50%',
        marginTop: '-24px',
    },
    rightArrow: {
        position: 'absolute',
        right: 0,
        top: '50%',
        marginTop: '-24px',
    },
}))
function FeaturedLanding({ show, setShow, message }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const pinnedList = useSelector((state) => state.ui.featuredList)
    const featuredCurrent = useSelector((state) => state.ui.featuredCurrent)
    const [isAuto, setIsAuto] = useState(true)
    useEffect(() => {
        console.log('pinned update')
        console.log(pinnedList)
    }, [pinnedList])

    useInterval(() => {
        if (isAuto) {
            dispatch(featuredNext())
            console.log(featuredCurrent)
        }
    }, 4000)

    useEffect(() => {
        console.log('loading published')
        dispatch(featuredLoad())
    }, [dispatch])
    return (
        <Grid
            xs={6}
            item
            className={classes.featuredBox}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography className={classes.featuredHeading} variant="h4">
                Featured Stories
            </Typography>

            {pinnedList.map((pinnedItem, index) => (
                <LandingCard
                    visible={index == featuredCurrent}
                    published={pinnedItem}
                />
            ))}

            <IconButton
                className={classes.leftArrow}
                aria-label="left"
                disabled={featuredCurrent <= 0}
                onClick={() => {
                    dispatch(featuredPrevious())
                    setIsAuto(false)
                }}
            >
                <FaChevronLeft />
            </IconButton>
            <IconButton
                className={classes.rightArrow}
                aria-label="right"
                disabled={featuredCurrent >= pinnedList.length - 1}
                onClick={() => {
                    dispatch(featuredNext())
                    setIsAuto(false)
                }}
            >
                <FaChevronRight />
            </IconButton>
            <MobileStepper
                variant="dots"
                steps={pinnedList.length}
                position="static"
                activeStep={featuredCurrent}
                sx={{ backgroundColor: '#666' }}
                className={classes.stepper}
            />
        </Grid>
    )
}

export default FeaturedLanding
