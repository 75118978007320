import { IconButton, TextField } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import {
    FaCheck,
    FaEllipsisV,
    FaRegFileAlt,
    FaRegStickyNote,
    FaTimes,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarNote, setSidebarPosition } from '../../actions'
import {
    addSection,
    copyNote,
    copySection,
    deleteNote,
    deleteSection,
    moveDownNote,
    moveDownSection,
    moveUpNote,
    moveUpSection,
    renameNote,
    renameSection,
} from '../../actions/write'

const useStyles = makeStyles((theme) => ({
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    selected: {
        color: '#512da8',
        fontWeight: 'bold',
    },
    sectionHeading: {
        paddingLeft: 3,
    },
    sidebarItemActions: {
        color: '#666',
    },
    sidebarIcon: {
        position: 'relative',
        top: 2,
        marginRight: theme.spacing(2),
        color: '#A9A9A9',
    },
}))

function SidebarItem({ section, note, first }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [hovering, setHovering] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [renaming, setRenaming] = useState(false)
    const [name, setName] = useState(section.title)

    const project = useSelector((state) => state.write.project)
    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const current = useSelector((state) => state.ui.sidebarPosition)
    const sections = useSelector((state) => state.write.sections)

    const tourMenuOpen = useSelector((state) => state.ui.tourMenuOpen)

    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (tourMenuOpen) {
            setAnchorEl(document.getElementById('project-sidebar-first'))
        }
    }, [tourMenuOpen])
    if (section) {
        if (renaming) {
            return (
                <ListItem>
                    <TextField
                        value={name}
                        inputRef={(input) => input && input.focus()}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                console.log('saving')
                                console.log(name)
                                if (name !== '')
                                    dispatch(
                                        renameSection(
                                            project.id,
                                            section.sectionId,
                                            name
                                        )
                                    )
                                setRenaming(false)
                                handleClose()
                                e.preventDefault()
                            }
                        }}
                    />
                    <IconButton
                        className={classes.saveButton}
                        aria-label="save"
                        onClick={() => {
                            console.log('saving')
                            console.log(name)
                            if (name !== '')
                                dispatch(
                                    renameSection(
                                        project.id,
                                        section.sectionId,
                                        name
                                    )
                                )
                            setRenaming(false)
                            handleClose()
                        }}
                    >
                        <FaCheck size=".75em" />
                    </IconButton>
                    <IconButton
                        className={classes.cancelButton}
                        aria-label="cancel"
                        onClick={() => {
                            console.log('cancel')
                            setRenaming(false)
                            handleClose()
                        }}
                    >
                        <FaTimes size=".75em" />
                    </IconButton>
                </ListItem>
            )
        } else {
            return (
                <>
                    <ListItem
                        button
                        selected={section.position === current && !noteSelected}
                        onClick={() => {
                            dispatch(setSidebarPosition(section.position))
                            dispatch(setSidebarNote(false))
                        }}
                        // onMouseEnter={() => { setHovering(true) }}
                        // onMouseLeave={() => { setHovering(false) }}
                        key={section.id}
                    >
                        <ListItemText>
                            <FaRegFileAlt className={classes.sidebarIcon} />
                            {section.title}
                        </ListItemText>
                        {hovering && (
                            <ListItemSecondaryAction
                                id={first ? 'project-sidebar-first' : ''}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onMouseEnter={() => {
                                    setHovering(true)
                                }}
                                onClick={(e) => {
                                    console.log(e.currentTarget)
                                    setAnchorEl(e.currentTarget)
                                }}
                                className={classes.sidebarItemActions}
                            >
                                {' '}
                                <FaEllipsisV />{' '}
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            id="project-sidebar-menu"
                            onClick={() => {
                                setRenaming(true)
                                handleClose()
                            }}
                        >
                            Rename
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(
                                    copySection(project.id, section.sectionId)
                                )
                                handleClose()
                            }}
                        >
                            Copy
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                dispatch(setSidebarPosition(-1))
                                dispatch(
                                    deleteSection(project.id, section.sectionId)
                                )

                                if (sections.length === 1) {
                                    dispatch(
                                        deleteSection(
                                            project.id,
                                            section.sectionId
                                        )
                                    )
                                    console.log('creating new default section')
                                    dispatch(
                                        addSection(
                                            'Untitled writing',
                                            0,
                                            '',
                                            '',
                                            project.id,
                                            true
                                        )
                                    )
                                } else {
                                    dispatch(
                                        deleteSection(
                                            project.id,
                                            section.sectionId
                                        )
                                    )
                                }
                            }}
                        >
                            Delete
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(moveUpSection(project.id, section))
                                handleClose()
                            }}
                        >
                            Move Up
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(moveDownSection(project.id, section))
                                handleClose()
                            }}
                        >
                            Move Down
                        </MenuItem>
                    </Menu>
                </>
            )
        }
    } else {
        if (renaming) {
            return (
                <ListItem>
                    <TextField
                        value={name}
                        inputRef={(input) => input && input.focus()}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                console.log('saving')
                                console.log(name)
                                if (name !== '')
                                    dispatch(
                                        renameNote(
                                            project.id,
                                            note.noteId,
                                            name
                                        )
                                    )
                                setRenaming(false)
                                handleClose()
                                e.preventDefault()
                            }
                        }}
                    />
                    <IconButton
                        className={classes.saveButton}
                        aria-label="save"
                        onClick={() => {
                            console.log('saving')
                            console.log(name)
                            if (name !== '')
                                dispatch(
                                    renameNote(project.id, note.noteId, name)
                                )
                            setRenaming(false)
                            handleClose()
                        }}
                    >
                        <FaCheck size=".75em" />
                    </IconButton>
                    <IconButton
                        className={classes.cancelButton}
                        aria-label="cancel"
                        onClick={() => {
                            console.log('cancel')
                            setRenaming(false)
                            handleClose()
                        }}
                    >
                        <FaTimes size=".75em" />
                    </IconButton>
                </ListItem>
            )
        } else {
            return (
                <>
                    <ListItem
                        button
                        selected={note.position === current && noteSelected}
                        onClick={() => {
                            dispatch(setSidebarPosition(note.position))
                            dispatch(setSidebarNote(true))
                        }}
                        // onMouseEnter={() => { setHovering(true) }}
                        // onMouseLeave={() => { setHovering(false) }}
                        key={note.id}
                    >
                        {/* <ListItemIcon><FaRegStickyNote /></ListItemIcon> */}
                        <ListItemText>
                            <FaRegStickyNote className={classes.sidebarIcon} />{' '}
                            {note.title}
                        </ListItemText>
                        {hovering && (
                            <ListItemSecondaryAction
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onMouseEnter={() => {
                                    setHovering(true)
                                }}
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}
                                className={classes.sidebarItemActions}
                            >
                                {' '}
                                <FaEllipsisV />{' '}
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                setRenaming(true)
                                handleClose()
                            }}
                        >
                            Rename
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(copyNote(project.id, note.noteId))
                                handleClose()
                            }}
                        >
                            Copy
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                handleClose()
                                dispatch(setSidebarPosition(-1))
                                dispatch(deleteNote(project.id, note.noteId))
                            }}
                        >
                            Delete
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(moveUpNote(project.id, note))
                                handleClose()
                            }}
                        >
                            Move Up
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(moveDownNote(project.id, note))
                                handleClose()
                            }}
                        >
                            Move Down
                        </MenuItem>
                    </Menu>
                </>
            )
        }
    }
}

export default SidebarItem
