import { Badge, Hidden, Button, Tooltip, Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import { FaBell, FaRegQuestionCircle, FaTools } from 'react-icons/fa'
import Joyride from 'react-joyride'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import uuid from 'uuid'
import {
    clearPublished,
    getUserProfile,
    startHomeTour,
    startProjectTour,
} from '../../actions'
import { getAvatarUrls, incrementLoginCounter } from '../../actions/profile'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        position: 'relative',
        color: '#333',
        textAlign: 'center',
        fontSize: '2rem',
        display: 'inline-block',
        fontFamily: 'Allison',
        marginLeft: 10,
        top: -2,
    },
    barNotifications: {
        marginRight: 10,
    },
    mainBar: {
        background: '#fafafa',
        border: 'none',
        boxShadow: 'none',
        borderBottom: '1px solid #dbd9d9',
    },
    mainBarInside: {
        maxWidth: theme.breakpoints.values.lg,
    },
    mainbarHelp: {
        marginRight: theme.spacing(1),
    },
    bannerText: {
        backgroundColor: 'blue',
    },
    mainbarAdmin: {
        marginRight: theme.spacing(1),
    },
    mainLogo: {
        display: 'inline-block',
    },
    mainBeta: {
        display: 'inline-block',
    },
    avatar: {
        height: 30,
        width: 30,
        border: '2px solid #512da8',
    },
    avatarSkeleton: {
        height: 30,
        width: 30,
    },
    joyRideText: {
        fontSize: '22px',
    },
    left: {},
    middle: {},
    right: {
        textAlign: 'right',
        minWidth: 200,
    },
    lightButton: {
        backgroundColor: 'white',
        borderRadius: 20,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}))

function MainBar(props) {
    const classes = useStyles()
    const { dispatch, isAdmin } = props
    const [notificationCount, setNotificationCount] = useState(2)

    const [avatarUrl, setAvatarUrl] = useState(null)
    const [avatarLoaded, setAvatarLoaded] = useState(false)
    const profile = useSelector((state) => state.profile.profile)

    const loginCounter = useSelector((state) => state.profile.loginCounter)
    const showNewsletter = loginCounter === 3

    //Richard
    const steps = [
        {
            content: (
                <>
                    <h3 className={classes.joyRideText}>
                        Hey! Click below to subscribe to our monthly memo for
                        writing prompts, new feature alerts, and a list of our
                        favorite stories!
                    </h3>
                    <Button
                        onClick={() =>
                            (window.location.href =
                                'https://mailchi.mp/6fadb3a9aeef/9up2qfw1xx')
                        }
                    >
                        Click Here
                    </Button>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
    ]

    useEffect(() => {
        dispatch(getUserProfile())
    }, [dispatch])

    useEffect(() => {
        if (profile.avatarId) {
            getAvatarUrls(profile.avatarId).then((urls) => {
                if (urls.avatarMedium) {
                    setAvatarUrl(urls.avatarMedium)
                }
                setAvatarLoaded(true)
            })
        } else {
            setAvatarLoaded(true)
        }
    }, [profile])
    useEffect(() => {
        setNotificationCount(profile.notification_count)
    }, [profile.notification_count])

    return (
        <AppBar className={classes.mainBar} position="static">
            <Container className={classes.mainBarInside}>
                <Toolbar>
                    {showNewsletter && (
                        <Joyride
                            steps={steps}
                            continuous={true}
                            run={showNewsletter}
                            showProgress={true}
                            locale={{
                                back: 'Back',
                                close: 'Close',
                                last: 'End',
                                next: 'Next',
                                skip: 'Skip',
                            }}
                            showSkipButton
                            styles={{
                                options: {
                                    backgroundColor: 'white',
                                    primaryColor: '#512da8',
                                    textColor: '#000',
                                    beaconSize: 36,
                                    overlayColor: 'rgba(0, 0, 0, .8)',
                                    spotlightShadow:
                                        '0 0 15px rgba(0, 0, 0, 0.5)',
                                },
                            }}
                        />
                    )}

                    <Grid item xs={9} className={classes.left}>
                        <>&nbsp;</>

                        <Link
                            style={{
                                textDecoration: 'none',
                                color: 'primary',
                                marginTop: -6,
                            }}
                            onClick={() => dispatch(incrementLoginCounter())}
                            to={{
                                key: uuid(),
                                state: { tab: 'read' },
                                pathname: routes.HOME,
                            }}
                        >
                            <Typography
                                className={classes.mainLogo}
                                style={{
                                    fontSize: 28,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                color="primary"
                            >
                                is_
                            </Typography>
                            {/* <Hidden smDown>
                <Typography className={classes.title}>
                  a creative community for emerging writers
                </Typography>
              </Hidden> */}
                        </Link>
                    </Grid>

                    <Grid item xs={3} className={classes.right}>
                        {props.location.pathname.startsWith('/write/view') && (
                            <Tooltip
                                title="Start tutorial"
                                enterDelay={500}
                                aria-label="add"
                            >
                                <Link
                                    onClick={() => {
                                        dispatch(startProjectTour())
                                    }}
                                    id="mainbar-help"
                                    className={classes.mainbarHelp}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Badge badgeContent={0}>
                                        <FaRegQuestionCircle
                                            color="#A9A9A9"
                                            size="1.5em"
                                        />
                                    </Badge>
                                </Link>
                            </Tooltip>
                        )}
                        {isAdmin && (
                            <Tooltip
                                title="Admin panel"
                                enterDelay={500}
                                aria-label="add"
                            >
                                <Link
                                    id="mainbar-admin"
                                    className={classes.mainbarAdmin}
                                    style={{ textDecoration: 'none' }}
                                    to={{
                                        key: uuid(),
                                        state: { id: profile.id },
                                        pathname: routes.ADMIN,
                                    }}
                                >
                                    <Badge badgeContent={0}>
                                        <FaTools color="#A9A9A9" size="1.5em" />
                                    </Badge>
                                </Link>
                            </Tooltip>
                        )}
                        {profile?.id ? (
                            <>
                                {(props.location.pathname === '/' ||
                                    props.location.pathname === '/tour') && (
                                    <Tooltip
                                        title="Start tutorial"
                                        enterDelay={500}
                                        aria-label="add"
                                    >
                                        <Link
                                            onClick={() => {
                                                dispatch(startHomeTour())
                                            }}
                                            id="mainbar-help"
                                            className={classes.mainbarHelp}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Badge badgeContent={0}>
                                                <FaRegQuestionCircle
                                                    color="#A9A9A9"
                                                    size="1.5em"
                                                />
                                            </Badge>
                                        </Link>
                                    </Tooltip>
                                )}
                                {true && (
                                    <Link
                                        className={classes.barNotifications}
                                        style={{ textDecoration: 'none' }}
                                        to="/notifications"
                                    >
                                        <Tooltip
                                            title="View your latest notifications"
                                            enterDelay={500}
                                            aria-label="add"
                                        >
                                            <Badge
                                                badgeContent={notificationCount}
                                                color="primary"
                                            >
                                                <FaBell
                                                    color="#A9A9A9"
                                                    size="1.5em"
                                                />
                                            </Badge>
                                        </Tooltip>
                                    </Link>
                                )}
                                <Tooltip
                                    title="View or edit your user profile"
                                    enterDelay={500}
                                    aria-label="add"
                                >
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {
                                            dispatch(clearPublished())
                                        }}
                                        to={{
                                            key: uuid(),
                                            state: { id: profile.id },
                                            pathname:
                                                routes.PROFILE_PUBLIC.replace(
                                                    ':id',
                                                    profile.id
                                                ),
                                        }}
                                    >
                                        <Badge badgeContent={0} color="primary">
                                            {avatarLoaded && (
                                                <Avatar
                                                    alt={profile.displayName}
                                                    className={classes.avatar}
                                                    src={avatarUrl}
                                                />
                                            )}
                                            {!avatarLoaded && (
                                                <Skeleton
                                                    variant="circle"
                                                    className={
                                                        classes.avatarSkeleton
                                                    }
                                                    width={30}
                                                    height={30}
                                                />
                                            )}
                                        </Badge>
                                    </Link>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                {props.location.pathname === '/' && (
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            component={Link}
                                            className={classes.lightButton}
                                            to={{
                                                pathname: routes.SIGNUP,
                                            }}
                                        >
                                            Join the Community!
                                        </Button>
                                        {/* <Button
                      color="primary"
                      variant="outlined"
                      href="mailto:contact@istoria.io"
                    >
                      Contact
                  </Button> */}
                                    </>
                                )}
                                {props.location.pathname !== '/' && (
                                    <>
                                        {props.location.pathname !==
                                            '/signup' && (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                component={Link}
                                                className={classes.lightButton}
                                                to={{
                                                    pathname: routes.SIGNUP,
                                                }}
                                            >
                                                Join the Community!
                                            </Button>
                                        )}
                                        {/* <Button
                      color="primary"
                      variant="outlined"
                      href="mailto:contact@istoria.io"
                    >
                      Contact
                  </Button> */}
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

function mapStateToProps(state) {
    return {
        isAdmin: state.auth.isAdmin,
        profile: state.profile.profile,
    }
}

export default connect(mapStateToProps)(withRouter(MainBar))
