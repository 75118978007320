import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    Chip,
    IconButton,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatDuration, formatRelative } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { unpinPublishedStory } from '../../actions/admin'
import { getAvatarUrls } from '../../actions/profile'
import { clearPublished } from '../../actions/write'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'

function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 360,
        width: '100%',
    },
    root: {
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.md,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',

        borderRadius: 12,
    },
    banner: {
        position: 'relative',
    },
    header: {
        color: '#666',
        textAlign: 'center',
    },
    headerGutter: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        color: '#666',
        textAlign: 'center',
    },
    title: {
        color: '#333',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    detailBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
        opacity: 0.7,
    },
    date: {
        fontSize: 12,
        fontStyle: 'italic',
    },
    tagsBox: {
        marginLeft: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    actionsBox: {
        marginLeft: 4,
    },
    cardActions: {
        marginTop: 0,
        marginRight: theme.spacing(1),
    },
    author: {
        textAlign: 'center',
    },
    avatarLine: {
        textAlign: 'center',
    },
    avatar: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 22,
        width: 22,
        top: 7,
        left: 5,
    },
    avatarSkeleton: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 22,
        width: 22,
        top: 5,
        left: 5,
    },
    excerptBody: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        textAlign: 'left',
        fontSize: '14pt',
    },
    authorName: {
        color: 'black',
        display: 'block',
        textDecoration: 'none',
        paddingBottom: theme.spacing(2) + 4,
        fontStyle: 'italic',
    },
    likes: {
        color: '#a1453f',
        opacity: 0.5,
        marginRight: theme.spacing(1),
    },
    readTime: {
        color: '#666',
        opacity: 0.7,
        marginRight: theme.spacing(1),
    },
    wordCount: {
        color: '#666',
        opacity: 0.7,
    },

    heart: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    quotes: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    by: {
        fontWeight: 'normal',
        color: 'black',
    },
    info: {
        marginBottom: theme.spacing(1) - 2,
    },
    closeButton: {
        height: 30,
        width: 30,
    },
}))

function shortenString(str) {
    if (str.length <= 180) return str
    else return str.slice(0, 180) + '...'
}

function PinnedItem({ published, hideAuthor }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const toUrl = routes.PUBLISHED.replace(
        ':pid',
        slugUrl(published.title, published.shortUrl)
    )

    const [mediaUrl, setMediaUrl] = useState(null)
    const [avatarUrl, setAvatarUrl] = useState('')
    const [wordCount, setWordCount] = useState('')
    const [likedCount, setLikedCount] = useState('')
    const [readTime, setReadTime] = useState(-1)

    useEffect(() => {
        console.log(published)
        if (published) {
            const reducer = (accumulator, currentValue) =>
                accumulator + currentValue?.bodyText.split(' ').length
            let wordCount = published.publishedSections.reduce(reducer, null)

            let rt = Math.ceil(wordCount / 275)

            console.log(published.title)
            console.log(published.liked_count)
            if (published.liked_uids) {
                if (published.liked_uids.length > 0)
                    setLikedCount(published.liked_uids.length)
            } else {
                if (published?.liked_count > 0)
                    setLikedCount(published.liked_count)
            }
            setWordCount(nFormatter(wordCount))
            setReadTime(
                formatDuration({
                    minutes: rt,
                })
                    .replace('minute', 'min')
                    .replace('mins', 'min')
            )
        }
    }, [published])
    useEffect(() => {
        console.log('dispatch')

        getAvatarUrls(published.profile.avatarId).then((urls) => {
            setAvatarUrl(urls.avatarSmall)
        })
    })

    const onRemovePinStory = async (published) => {
        const { shortUrl } = published
        dispatch(unpinPublishedStory(shortUrl))
    }

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={Link}
                to={{
                    pathname: toUrl,
                }}
            >
                <div
                    style={{
                        right: 12,
                        top: 12,
                        zIndex: 99,
                        position: 'absolute',
                    }}
                >
                    <IconButton
                        aria-label="add to favorites"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                            onRemovePinStory(published)
                        }}
                    >
                        <FaTimes fontSize="small" color="#000" />
                    </IconButton>
                </div>
                {mediaUrl && (
                    <Box
                        className={classes.banner}
                        onClick={() => {
                            dispatch(clearPublished())
                        }}
                    >
                        <img className={classes.media} src={mediaUrl} />
                    </Box>
                )}
                {/* {published?.title && (
          <div className="share-writing">
            <SharePublished shortUrl={toUrl} published={published} />
          </div>
        )} */}

                {mediaUrl && (
                    <Box className={classes.header}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            component="h2"
                        >
                            {published.title}
                        </Typography>
                        {published.fromTitle && (
                            <Typography variant="body2">
                                from <b>{published.fromTitle}</b>
                            </Typography>
                        )}
                    </Box>
                )}

                {!mediaUrl && (
                    <Box className={classes.headerGutter}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            component="h2"
                        >
                            {published.title}
                        </Typography>
                        {published.fromTitle && (
                            <Typography variant="body2">
                                from <b>{published.fromTitle}</b>
                            </Typography>
                        )}
                    </Box>
                )}

                <Typography
                    variant="body2"
                    className={classes.authorName}
                    gutterBottom
                    component={Link}
                    to={{
                        pathname: routes.PROFILE_PUBLIC.replace(
                            ':id',
                            published.profile.id
                        ),
                    }}
                >
                    <span className={classes.by}>by</span>
                    {avatarUrl && (
                        <Avatar className={classes.avatar} src={avatarUrl} />
                    )}

                    {!avatarUrl && (
                        <Skeleton
                            variant="circle"
                            className={classes.avatarSkeleton}
                            width={22}
                            height={22}
                        />
                    )}
                    {published.profile.displayName}
                </Typography>

                <Typography
                    variant="body2"
                    component="p"
                    className={classes.excerptBody}
                >
                    {shortenString(published.excerpt)}
                </Typography>
                <Box className="avatarLine"></Box>

                {/* <Box className={classes.tags}>
          {published.genres &&
            published.genres
              .slice(0, 1)
              .map((genre) => (
                <Chip
                  size="small"
                  className={classes.chipItem}
                  variant="outlined"
                  label={genre}
                />
              ))}
          {published.tags &&
            published.tags
              .slice(0, 1)
              .map((tag) => (
                <Chip
                  size="small"
                  className={classes.chipItem}
                  label={tag}
                />
              ))}
        </Box> */}
                {/* <Grid
          container
          className={classes.info}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {likedCount && (
            <Typography
              variant="body2"
              className={classes.likes}
              gutterBottom
            >
              <FaHeart
                className={classes.heart}
                color="#bf281d"
              />
              {likedCount}
            </Typography>
          )}
          {readTime && (
            <Typography
              variant="body2"
              className={classes.readTime}
              gutterBottom
            >
              <FaClock className={classes.clock} color="#666" />
              {readTime} read
            </Typography>
          )}
          {wordCount && (
            <Typography
              variant="body2"
              className={classes.wordCount}
              gutterBottom
            >
              <FaQuoteLeft
                className={classes.quotes}
                color="#666"
              />
              {wordCount} word{wordCount > 1 && <>s</>}
            </Typography>
          )}
        </Grid> */}
            </CardActionArea>
        </Card>
    )
}

export default PinnedItem
