import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FaCloudUploadAlt, FaKey, FaSignOutAlt } from 'react-icons/fa'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    getUserProfile,
    putAvatarClear,
    resetPasswordClear,
    resetUserPassword,
    saveUserAvatar,
    setDisplayNameClear,
    setUserDisplayBio,
    setUserDisplayName,
    setUserInsta,
    setUserPronouns,
    setUserReceiveEmails,
} from '../../actions/profile'
import * as routes from '../../constants/routes'
import AvatarToast from '../layout/AvatarToast'
import PasswordToast from '../layout/PasswordToast'
import ScrollToTop from '../layout/ScrollToTop'
import ProfileAvatar from './ProfileAvatar'

const debounce = (func, wait) => {
    let timeout

    // This is the function that is returned and will be executed many times
    // We spread (...args) to capture any number of parameters we want to pass
    return function executedFunction(...args) {
        // The callback function to be executed after
        // the debounce time has elapsed
        const later = () => {
            // null timeout to indicate the debounce ended
            timeout = null

            // Execute the callback
            func(...args)
        }
        // This will reset the waiting every function execution.
        // This is the step that prevents the function from
        // being executed because it will never reach the
        // inside of the previous setTimeout
        clearTimeout(timeout)

        // Restart the debounce waiting period.
        // setTimeout returns a truthy value (it differs in web vs Node)
        timeout = setTimeout(later, wait)
    }
}

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    profileLogout: {
        marginTop: theme.spacing(1),
    },
    profileReset: {
        marginTop: theme.spacing(1),
    },
    configItem: {
        marginBottom: theme.spacing(1),
    },
    uploadAvatarButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    profileInput: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'white',
    },
    profileBio: {
        marginBottom: 20,
        backgroundColor: 'white',
        // minWidth: 230
    },
    input: {},
    emailMessage: {
        maxWidth: 250,
        textAlign: 'center',
        color: '#666',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    profileLoading: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
    },
}))

function Profile({ user, isAuthenticated }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const profileDisplayName = useSelector(
        (state) => state.profile.profile.displayName
    )
    const profileDisplayReceive = useSelector(
        (state) => state.profile.profile.receiveEmails
    )
    const profileDisplayBio = useSelector((state) => state.profile.profile.bio)
    const profileDisplayPronouns = useSelector(
        (state) => state.profile.profile.pronouns
    )
    //Richard//
    const profileDisplayInsta = useSelector(
        (state) => state.profile.profile.insta
    )
    const avatarPutComplete = useSelector(
        (state) => state.profile.avatarPutComplete
    )
    const profile = useSelector((state) => state.profile.profile)

    const passwordResetComplete = useSelector(
        (state) => state.profile.passwordResetComplete
    )
    const onDrop = useCallback(
        (acceptedFiles) => {
            console.log(acceptedFiles)
            dispatch(saveUserAvatar(acceptedFiles[0]))
        },
        [dispatch]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/webp',
    })

    const [profileBio, setProfileBio] = useState('')
    const [profileDisplay, setProfileDisplay] = useState('')
    const [profilePronouns, setProfilePronouns] = useState('')
    //Richard//
    const [profileInsta, setProfileInsta] = useState('')
    const [profileReceiveEmails, setProfileReceiveEmails] = useState(true)

    useEffect(() => {
        if (profileDisplayName) {
            setProfileDisplay(profileDisplayName)
        }
        if (profileDisplayBio) {
            setProfileBio(profileDisplayBio)
        }
        if (profileDisplayPronouns) {
            setProfilePronouns(profileDisplayPronouns)
        }
        //Richard//
        if (profileDisplayInsta) {
            setProfileInsta(profileDisplayInsta)
        }
        if (profileDisplayReceive !== undefined) {
            setProfileReceiveEmails(profileDisplayReceive)
        }
        console.log(profile)
    }, [
        profileDisplayName,
        profileDisplayBio,
        profileDisplayReceive,
        profile,
        profileDisplayInsta,
        profileDisplayPronouns,
    ])

    useEffect(() => {
        dispatch(getUserProfile())
        dispatch(setDisplayNameClear())
    }, [dispatch])
    return (
        <>
            <ScrollToTop />
            {!profile.id && (
                <Container className={classes.profileLoading}>
                    <CircularProgress />
                </Container>
            )}
            <form className={classes.root} noValidate autoComplete="off">
                {profile.id && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        classes={{
                            root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                        }}
                    >
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <Box className={classes.configItem}>
                                    <ProfileAvatar size="large" />
                                </Box>
                            ) : (
                                <Box className={classes.configItem}>
                                    <ProfileAvatar size="large" />
                                </Box>
                            )}
                        </div>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                                color="primary"
                                variant="outlined"
                                className={classes.uploadAvatarButton}
                                startIcon={<FaCloudUploadAlt />}
                            >
                                Upload avatar
                            </Button>
                        </div>
                        <Divider className={classes.divider} />

                        <TextField
                            value={profileDisplay}
                            onChange={(e) => {
                                setProfileDisplay(e.target.value)
                                debounce(function () {
                                    dispatch(setUserDisplayName(profileDisplay))
                                }, 250)
                            }}
                            onBlur={() => {
                                console.log('display blur')
                                dispatch(setUserDisplayName(profileDisplay))
                            }}
                            id="profileDisplay"
                            label="Display name"
                            className={classes.profileInput}
                            variant="outlined"
                        />
                        <TextField
                            onChange={(e) => {}}
                            disabled
                            value={user.email}
                            id="profileEmail"
                            label="Email"
                            variant="outlined"
                            className={classes.profileInput}
                        />
                        <Typography
                            className={classes.emailMessage}
                            variant="body2"
                        >
                            Receive email notifications from authors you are
                            following
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={profileReceiveEmails}
                                    onChange={() => {
                                        dispatch(
                                            setUserReceiveEmails(
                                                !profileReceiveEmails
                                            )
                                        )
                                        setProfileReceiveEmails(
                                            !profileReceiveEmails
                                        )
                                    }}
                                    name="receiveEmails"
                                    color="primary"
                                />
                            }
                        />
                        <Divider className={classes.divider} />

                        <TextField
                            value={profileBio}
                            onChange={(e) => {
                                setProfileBio(e.target.value)
                                debounce(function () {
                                    dispatch(setUserDisplayBio(profileBio))
                                }, 250)
                            }}
                            onBlur={() => {
                                console.log('bio blur')
                                dispatch(setUserDisplayBio(profileBio))
                            }}
                            id="profileBio"
                            label="Bio"
                            multiline
                            rows={4}
                            className={classes.profileBio}
                            InputProps={{
                                className: classes.input,
                            }}
                            variant="outlined"
                        />

                        <TextField
                            value={profilePronouns}
                            onChange={(e) => {
                                setProfilePronouns(e.target.value)
                                debounce(function () {
                                    dispatch(setUserPronouns(profilePronouns))
                                }, 250)
                            }}
                            onBlur={() => {
                                console.log('display blur')
                                dispatch(setUserPronouns(profilePronouns))
                            }}
                            id="profilePronouns"
                            label="Pronouns"
                            className={classes.profileInput}
                            variant="outlined"
                        />
                        {/* Richard */}
                        <TextField
                            value={profileInsta}
                            s
                            onChange={(e) => {
                                setProfileInsta(
                                    e.target.value.replace(
                                        /[^0-9a-zA-Z._]/g,
                                        ''
                                    )
                                )
                                debounce(function () {
                                    dispatch(setUserInsta(profileInsta))
                                }, 250)
                            }}
                            onBlur={() => {
                                console.log('display blur')
                                dispatch(setUserInsta(profileInsta))
                            }}
                            id="profileInsta"
                            label="Instagram Username"
                            className={classes.profileInput}
                            variant="outlined"
                        />

                        <Divider className={classes.divider} />

                        <Button
                            variant="outlined"
                            onClick={() => {
                                console.log('change password')
                                dispatch(resetUserPassword())
                            }}
                            className={classes.profileReset}
                            startIcon={<FaKey />}
                        >
                            Change Password
                        </Button>
                        <Divider className={classes.divider} />

                        <Button
                            variant="outlined"
                            component={Link}
                            to={{
                                pathname: routes.LOGOUT,
                            }}
                            startIcon={<FaSignOutAlt />}
                            className={classes.profileLogout}
                        >
                            Log out
                        </Button>

                        {passwordResetComplete && (
                            <PasswordToast clear={resetPasswordClear} />
                        )}
                        {avatarPutComplete && (
                            <AvatarToast clear={putAvatarClear} />
                        )}
                    </Grid>
                )}
            </form>
        </>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
        passwordResetComplete: state.profile.passwordResetComplete,
        profile: state.profile.profile,
    }
}
export default connect(mapStateToProps)(Profile)
