import React, { useState, useEffect } from 'react'
import { Box, Grid, Item, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RawHTML from '../etc/RawHTML'
import { FaTimesCircle } from 'react-icons/fa'
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles((theme) => ({
    banner: {
        width: '100%',
        backgroundColor: '#512da8',
        color: 'white',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: '1rem',
    },
    left: {},
    middle: {},
    right: {
        textAlign: 'right',
    },
}))
function Banner({ text }) {
    const classes = useStyles()
    const [show, setShow] = useState(true)

    return (
        <Collapse in={show}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.banner}
            >
                <Grid item xs={2} className={classes.left}>
                    <>&nbsp;</>
                </Grid>
                <Grid item xs className={classes.middle}>
                    <div class="bannerText">
                        <RawHTML class="bannerText">{text}</RawHTML>
                    </div>
                </Grid>
                <Grid item xs={2} className={classes.right}>
                    <IconButton
                        aria-label="close-banner"
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        <FaTimesCircle size=".75em" color="white" />
                    </IconButton>
                </Grid>
            </Grid>
        </Collapse>
    )
}

export default Banner
