import {
    COMMUNITY_DELETE,
    COMMUNITY_DELETE_SUCCESS,
    COMMUNITY_DELETE_FAILURE,
    COMMUNITY_DELETE_CLEAR,
    COMMUNITY_PUT,
    COMMUNITY_PUT_SUCCESS,
    COMMUNITY_PUT_FAILURE,
    COMMUNITY_PUT_COMPLETE,
    COMMUNITY_GET,
    COMMUNITY_GET_SUCCESS,
    COMMUNITY_GET_FAILURE,
    DISCUSSION_GET_SUCCESS,
    DISCUSSION_COMMENTS_LIST_SUCCESS,
    COMMUNITY_LIST,
    COMMUNITY_LIST_SUCCESS,
    COMMUNITY_LIST_FAILURE,
    COMMUNITY_LIST_APPEND,
    COMMUNITY_LIST_CLEAR,
    COMMUNITY_LIST_FEED,
    COMMUNITY_LIST_FEED_SUCCESS,
    COMMUNITY_LIST_FEED_APPEND,
    COMMUNITY_LIST_FEED_FAILURE,
    PROFILE_LIST_COMMUNITIES,
    PROFILE_LIST_COMMUNITIES_APPEND,
    PROFILE_LIST_COMMUNITIES_CLEAR,
    PROFILE_LIST_COMMUNITIES_FAILURE,
    COMMUNITY_LIST_MEMBERS,
    COMMUNITY_LIST_MEMBERS_APPEND,
    COMMUNITY_LIST_MEMBERS_CLEAR,
    COMMUNITY_LIST_MEMBERS_FAILURE,
    COMMUNITY_BACK_FROM,
} from '../actions/'

export default (
    state = {
        community: {},
        communityDeleting: false,
        communityDeleteSuccess: false,
        communityDeleteFailure: false,
        communityPutting: false,
        communityPutSuccess: false,
        communityPutFailure: false,
        communityFeedListing: false,
        communityFeedListSuccess: false,
        communityFeedListFailure: false,
        profileCommunitiesListing: false,
        profileCommunitiesListSuccess: false,
        profileCommunitiesListFailure: false,
        communityMembersListing: false,
        communityMembersListSuccess: false,
        communityMembersListFailure: false,
        communityList: [],
        communityFeed: [],
        profileCommunities: [],
        communityMembers: [],
        discussionComments: [],
        communityBackFrom: '',
        communityBackState: '',
    },
    action
) => {
    switch (action.type) {
        case COMMUNITY_DELETE:
            return {
                ...state,
                communityDeleting: true,
                communityDeleteSuccess: false,
                communityDeleteFailure: false,
            }

        case COMMUNITY_DELETE_SUCCESS:
            return {
                ...state,
                communityDeleting: false,
                communityDeleteSuccess: true,
                communityDeleteFailure: false,
            }
        case COMMUNITY_DELETE_FAILURE:
            return {
                ...state,
                communityDeleting: false,
                communityDeleteSuccess: false,
                communityDeleteFailure: true,
            }

        case COMMUNITY_DELETE_CLEAR:
            return {
                ...state,
                communityDeleting: false,
                communityDeleteSuccess: false,
                communityDeleteFailure: false,
            }

        case COMMUNITY_PUT:
            return {
                ...state,
                communityPutting: true,
                communityPutSuccess: false,
                communityPutFailure: false,
            }

        case COMMUNITY_PUT_SUCCESS:
            return {
                ...state,
                communityPutting: false,
                communityPutSuccess: true,
                communityPutFailure: false,
                community: action.community,
            }

        case COMMUNITY_PUT_FAILURE:
            return {
                ...state,
                communityPutting: false,
                communityPutSuccess: false,
                communityPutFailure: true,
            }

        case COMMUNITY_PUT_COMPLETE:
            return {
                ...state,
                communityPutting: false,
                communityPutSuccess: false,
                communityPutFailure: false,
            }

        case COMMUNITY_GET:
            return {
                ...state,
                communityGetting: true,
                communityGetSuccess: false,
                communityGetFailure: false,
            }

        case COMMUNITY_GET_SUCCESS:
            return {
                ...state,
                communityGetting: false,
                communityGetSuccess: true,
                communityGetFailure: false,
                community: action.community,
            }

        case DISCUSSION_GET_SUCCESS:
            return {
                ...state,
                discussion: action.discussion,
            }

        case DISCUSSION_COMMENTS_LIST_SUCCESS:
            return {
                ...state,
                discussionComments: action.comments,
            }

        case COMMUNITY_GET_FAILURE:
            return {
                ...state,
                communityGetting: false,
                communityGetSuccess: false,
                communityGetFailure: true,
            }

        case COMMUNITY_LIST:
            return {
                ...state,
                communityListing: true,
                communityListSuccess: false,
                communityListFailure: false,
            }

        case COMMUNITY_LIST_SUCCESS:
            return {
                ...state,
                communityListing: false,
                communityListSuccess: true,
                communityListFailure: false,
                communityList: action.communityList,
            }

        case COMMUNITY_LIST_APPEND:
            console.log(state.communityList)
            return {
                ...state,
                communityListing: false,
                communityListSuccess: true,
                communityListFailure: false,
                communityList: state.communityList.concat([action.community]),
            }
        case COMMUNITY_LIST_CLEAR:
            console.log(state.communityList)
            return {
                ...state,
                communityListing: false,
                communityListSuccess: false,
                communityListFailure: false,
                communityList: [],
            }
        case COMMUNITY_LIST_FAILURE:
            return {
                ...state,
                communityListing: false,
                communityListSuccess: false,
                communityListFailure: true,
            }

        case COMMUNITY_LIST_FEED:
            return {
                ...state,
                communityFeedListing: true,
                communityFeedListSuccess: false,
                communityFeedListFailure: false,
            }

        case COMMUNITY_LIST_FEED_SUCCESS:
            return {
                ...state,
                communityFeedListing: false,
                communityFeedListSuccess: true,
                communityFeedListFailure: false,
                communityFeed: action.communityFeed,
            }
        case COMMUNITY_LIST_FEED_APPEND:
            console.log(state.communityFeed)
            let newFeed = state.communityFeed
            const exists = Boolean(
                state.communityFeed.find((x) => x.id === action.feedItem.id)
            )
            if (!exists) {
                newFeed = newFeed.concat([action.feedItem])
            }

            return {
                ...state,
                communityFeedListing: false,
                communityFeedListSuccess: true,
                communityFeedListFailure: false,
                communityFeed: newFeed,
            }
        case COMMUNITY_LIST_FEED_FAILURE:
            return {
                ...state,
                communityFeedListing: false,
                communityFeedListSuccess: false,
                communityFeedListFailure: true,
            }
        case PROFILE_LIST_COMMUNITIES:
            return {
                ...state,
                profileCommunitiesListing: true,
                profileCommunitiesListSuccess: false,
                profileCommunitiesListFailure: false,
            }

        case PROFILE_LIST_COMMUNITIES_APPEND:
            return {
                ...state,
                profileCommunitiesListing: false,
                profileCommunitiesListSuccess: true,
                profileCommunitiesListFailure: false,
                profileCommunities: state.profileCommunities.concat([
                    action.community,
                ]),
            }

        case PROFILE_LIST_COMMUNITIES_FAILURE:
            return {
                ...state,
                profileCommunitiesListing: false,
                profileCommunitiesListSuccess: false,
                profileCommunitiesListFailure: true,
            }
        case PROFILE_LIST_COMMUNITIES_CLEAR:
            return {
                ...state,
                profileCommunitiesListing: false,
                profileCommunitiesListSuccess: false,
                profileCommunitiesListFailure: true,
                profileCommunities: [],
            }

        case COMMUNITY_LIST_MEMBERS:
            return {
                ...state,
                communityMembersListing: true,
                communityMembersListSuccess: false,
                communityMembersListFailure: false,
            }

        case COMMUNITY_LIST_MEMBERS_APPEND:
            return {
                ...state,
                communityMembersListing: false,
                communityMembersListSuccess: true,
                communityMembersListFailure: false,
                communityMembers: state.communityMembers.concat([
                    action.member,
                ]),
            }

        case COMMUNITY_LIST_MEMBERS_CLEAR:
            return {
                ...state,
                communityMembersListing: false,
                communityMembersListSuccess: false,
                communityMembersListFailure: false,
                communityMembers: [],
            }
        case COMMUNITY_LIST_MEMBERS_FAILURE:
            return {
                ...state,
                communityMembersListing: false,
                communityMembersListSuccess: false,
                communityMembersListFailure: true,
                communityMembers: [],
            }
        case COMMUNITY_BACK_FROM:
            return {
                ...state,
                communityBackFrom: action.backFrom,
                communityBackState: action.backState,
            }
        default:
            return state
    }
}
