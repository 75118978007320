import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RawHTML from '../etc/RawHTML'

import {
  FaCheck,
  FaChevronLeft,
  FaCog,
  FaStream,
  FaTimesCircle,
  FaUsers,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getCommunity, leaveCommunity } from '../../actions'
import {
  addCommunityAdmin,
  getPublicCommunityFeaturedUrls,
  listCommunityFeed,
  listMembers,
} from '../../actions/community'
import Confirm from '../layout/Confirm'
import Message from '../layout/Message'
import AdminTab from './tabs/AdminTab'
import CommunityBanner from './CommunityBanner'
import FeedTab from './tabs/FeedTab'
import MembersTab from './tabs/MembersTab'
import NotMember from './NotMember'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  mediaLarge: {
    objectFit: 'cover',
    width: '100%',
    height: 320,
  },
  banner: {
    position: 'relative',
    maxWidth: theme.breakpoints.values.xl,
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  headingLargeBox: {
    color: 'white',
    position: 'absolute',
    top: '35%',
    left: 40,
    backgroundColor: 'blue',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headingLarge: {
    color: 'white',
    fontSize: '2.5em',
  },
  headingSmall: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: 30,
    fontSize: '2.5em',
    // backgroundColor: 'rgba(77, 77, 77, 0.8)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  back: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
  },
  backContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    maxWidth: theme.breakpoints.values.lg,
  },
  blurb: {
    position: 'absolute',
    bottom: 20,
    left: 30,
    fontSize: '1.25em',
    color: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'rgba(77, 77, 77, 0.8)',
  },
  smallList: {
    marginTop: theme.spacing(1),
    maxWidth: theme.breakpoints.values.lg,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mainBox: {
    maxWidth: theme.breakpoints.values.xl,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  communityActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    maxWidth: theme.breakpoints.values.md,
  },
  communityDescription: {
    paddingLeft: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14pt'
  },
  nothingMessage: {
    textAlign: 'center',
    color: '#666',
  },
  admin: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  progressBox: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  messageBox: {
    padding: theme.spacing(2),
  },
  textBox: {
    padding: theme.spacing(1),
  },
  memberCount: {
    color: '#666',
    textAlign: 'center',
    width: 180,
  },

  divider: {
    borderTop: '1px solid #ccc',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '60px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  messageText: {
    color: '#666',
    textAlign: 'center',
  },
  joinButton: {
    marginTop: theme.spacing(2),
  },
  tags: {
    marginBottom: theme.spacing(1),
  },

  emphasis: {
    color: '#512da8',
  },
}))

function ViewCommunity({ joined }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  let { id } = useParams()

  const [ready, setReady] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [currentTab, setCurrentTab] = useState('writing')
  const history = useHistory()

  const [following, setFollowing] = useState('no')
  const [isMember, setIsMember] = useState(false)
  const [loading, setLoading] = useState(true)
  const [adding, setAdding] = useState(false)
  const [discussionTitle, setDiscussionTitle] = useState('')
  const [discussionBody, setDiscussionBody] = useState('')
  const [confirmLeave, setConfirmLeave] = useState(false)
  const [cannotLeave, setCannotLeave] = useState(false)
  const [isCreator, setIsCreator] = useState(false)
  const [isCircleAdmin, setIsCircleAdmin] = useState(false)

  const profile = useSelector((state) => state.profile.profile)
  const community = useSelector((state) => state.community.community)
  const communityFeed = useSelector((state) => state.community.communityFeed)
  const communityBackFrom = useSelector(
    (state) => state.community.communityBackFrom
  )
  const communityBackState = useSelector(
    (state) => state.community.communityBackState
  )

  const [mediaUrl, setMediaUrl] = useState(
    require('../../static/grey-background.jpg')
  )

  const communityGetSuccess = useSelector(
    (state) => state.community.communityGetSuccess
  )
  const communityFeedListSuccess = useSelector(
    (state) => state.community.communityFeedListSuccess
  )

  useEffect(() => {
    setLoading(true)
    dispatch(getCommunity(id))
    dispatch(listCommunityFeed(id))
    dispatch(listMembers(id))
  }, [dispatch, id])

  useEffect(() => {
    console.log(profile.requested_community_ids)
    if (community?.admin_profile_ids?.includes(profile.id)) {
      setIsCircleAdmin(true)
    }
    if (communityGetSuccess && profile?.id) {
      if (community.member_profile_ids?.includes(profile.id)) {
        setIsMember(true)
        console.log(community.member_profile_ids)
      } else if (
        profile?.requested_community_ids?.includes(community.id)
      ) {
        setFollowing('requested')
      } else {
        setIsMember(false)
      }
      if (community?.creator_profile_id === profile.id) {
        setIsCreator(true)
      }
    }
  }, [profile, community, communityGetSuccess, id])

  useEffect(() => {
    console.log(community)
  }, [community])
  useEffect(() => {
    if (communityGetSuccess) {
      setTimeout(() => {
        setLoading(false)
      }, 200)
      console.log(community)
      if (community.featuredId) {
        getPublicCommunityFeaturedUrls(community.featuredId).then(
          (urls) => {
            console.log(urls)
            setMediaUrl(urls.featuredLarge)
            setReady(true)
          }
        )
      } else {
        setReady(true)
      }
    }
    console.log(communityGetSuccess)
    console.log(communityFeed)
  }, [
    communityGetSuccess,
    communityFeedListSuccess,
    communityFeed,
    community,
  ])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Grid
      className={classes.mainBox}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        className={classes.backContainer}
        justifyContent="space-between"
        alignItems="center"
      >
        <Message
          show={cannotLeave}
          setShow={setCannotLeave}
          message={
            'You are not able leave this Circle because you are the original creator. However, you can request to delete the Circle.'
          }
        />
        <Confirm
          confirm={confirmLeave}
          setConfirm={setConfirmLeave}
          message={
            'Are you sure you want to leave this Circle? If you change your mind you will have to request to join the Circle again.'
          }
          action={'Leave'}
          callback={() => {
            console.log('leaving')
            dispatch(leaveCommunity(community.id))
          }}
        />
        {!communityBackFrom && (
          <Button
            className={classes.back}
            startIcon={<FaChevronLeft />}
            component={Link}
            onClick={() => {
              history.goBack()
            }}
          >
            Back
          </Button>
        )}
        {communityBackFrom && (
          <Button
            className={classes.back}
            startIcon={<FaChevronLeft />}
            component={Link}
            to={{
              pathname: communityBackFrom,
              state: communityBackState,
            }}
          >
            Back
          </Button>
        )}

        {isMember && (
          <Button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (hovering) {
                if (isCreator) {
                  setCannotLeave(true)
                } else {
                  setConfirmLeave(true)
                }
              }
            }}
            onMouseEnter={() => {
              setHovering(true)
            }}
            onMouseLeave={() => {
              setHovering(false)
            }}
            variant="outlined"
            startIcon={hovering ? <FaTimesCircle /> : <FaCheck />}
            color={hovering ? '' : 'primary'}
          >
            {hovering ? 'Leave' : 'Joined'}
          </Button>
        )}
      </Grid>
      {loading && (
        <Container className={classes.progressBox}>
          <CircularProgress className={classes.viewProgress} />
        </Container>
      )}
      {!isMember && !loading && <NotMember />}
      {isMember && !loading && (
        <>
          <CommunityBanner
            community={community}
            mediaUrl={mediaUrl}
          />

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.communityActions}
          >
            <Typography className={classes.communityDescription}>
              <RawHTML>{community.description}</RawHTML>
            </Typography>

            <Box></Box>
          </Grid>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => {
              setCurrentTab(newValue)
            }}
          >
            <Tab icon={<FaStream />} value="writing" />
            <Tab icon={<FaUsers />} value="members" />
            {isCircleAdmin && (
              <Tab icon={<FaCog />} value="admin" />
            )}
          </Tabs>

          {currentTab === 'writing' && <FeedTab />}
          {currentTab === 'members' && <MembersTab />}
          {currentTab === 'admin' && <AdminTab />}
        </>
      )}
    </Grid>
  )
}

export default ViewCommunity
