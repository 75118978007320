import { combineReducers } from 'redux'
import auth from './auth'
import write from './write'
import profile from './profile'
import review from './review'
import community from './community'
import ui from './ui'
import admin from './admin'

export default combineReducers({
    auth,
    write,
    profile,
    review,
    community,
    ui,
    admin,
})
