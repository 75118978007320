import { CircularProgress, Container, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import MainFeed from './MainFeed'
import WelcomeLanding from '../layout/WelcomeLanding'
import { Link, Redirect } from 'react-router-dom'
import * as routes from '../../constants/routes'

const useStyles = makeStyles({
  main: {
    backgroundColor: '#fff',
  },
  mainLanding: {
    textAlign: 'center',
  },
  mainNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  mainSpinner: {
    marginTop: 20,
  },
  logo: {
    maxWidth: 200,
    margin: 20,
    marginTop: 30,
    marginBottom: 20,
  },
  landingBox: { maxWidth: 500 },
  landingMessage: { marginBottom: 20 },
  messageRest: {
    color: '#666',
  },
  iframe: {
    width: '100%',
    height: 600,
    border: 'none',
    outline: 'none',
    marginBottom: 20,
  },
})
function Home({ dotour, doRedirect = true }) {
  const classes = useStyles()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isVerifying = useSelector((state) => state.auth.isVerifying)

  return (
    <Box className={classes.mainLanding}>
      {isVerifying && (
        <CircularProgress className={classes.mainSpinner} />
      )}
      {!isVerifying && isAuthenticated && <MainFeed dotour={dotour} />}
      {!isVerifying && !isAuthenticated && doRedirect && <Redirect to={routes.WELCOME_LANDING} />}
    </Box>
  )
}

export default Home
