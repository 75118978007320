import React from 'react'
import Helmet from 'react-helmet'

const TitleComponent = ({ mode }) => {
    if (mode === 'development')
        return (
            <Helmet>
                <title>istoria (dev)</title>
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/istoria-favicon-dev.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/istoria-favicon-dev.png"
                />
            </Helmet>
        )
    else return ''
}

export { TitleComponent }
