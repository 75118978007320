import { GET_PINNED_REQUEST } from '../actions/'

export default (
    state = {
        pinnedList: [],
    },
    action
) => {
    // console.log(action.type)
    switch (action.type) {
        case GET_PINNED_REQUEST:
            return {
                ...state,
                pinnedList: action.pinnedList,
            }

        default:
            return state
    }
}
