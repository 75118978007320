import {
    myFirebase,
    db,
    increment,
    images,
    functions,
} from '../firebase/firebase'
import uuid from 'uuid'
import PublicProfile from '../components/profile/PublicProfile'

export const PROFILE_GET = 'PROFILE_GET'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'

export const PROFILE_CLEAR = 'PROFILE_CLEAR'

export const PUBLIC_PROFILE_GET_SUCCESS = 'PUBLIC_PROFILE_GET_SUCCESS'

export const PUBLISHED_GET_SUCCESS = 'PUBLISHED_GET_SUCCESS'

export const PROFILE_ALL_GET_SUCCESS = 'PROFILE_ALL_GET_SUCCESS'

export const NOTIF_GET = 'NOTIF_GET'
export const NOTIF_GET_FAILURE = 'NOTIF_GET_FAILURE'
export const NOTIF_GET_SUCCESS = 'NOTIF_GET_SUCCESS'

export const AVATAR_PUT = 'AVATAR_PUT_REQUEST'
export const AVATAR_PUT_FAILURE = 'AVATAR_PUT_FAILURE'
export const AVATAR_PUT_SUCCESS = 'AVATAR_PUT_SUCCESS'
export const AVATAR_PUT_CLEAR = 'AVATAR_PUT_CLEAR'

export const AVATAR_GET_SUCCESS = 'AVATAR_GET_SUCCESS'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR'

export const DISPLAY_NAME_SET = 'DISPLAY_NAME_SET'
export const DISPLAY_NAME_SET_SUCCESS = 'DISPLAY_NAME_SET_SUCCESS'
export const DISPLAY_NAME_SET_FAILURE = 'DISPLAY_NAME_SET_FAILURE'
export const DISPLAY_NAME_SET_CLEAR = 'DISPLAY_NAME_SET_CLEAR'

export const DID_HOME_TOUR = 'DID_HOME_TOUR'
export const DID_PROJECT_TOUR = 'DID_PROJECT_TOUR'
export const DID_PROFILE_TOUR = 'DID_PROFILE_TOUR'

export const LOGIN_COUNTER_SET = 'LOGIN_COUNTER_SET'

export const LIST_RECOMMENDS = 'LIST_RECOMMENDS'
export const LIST_RECOMMENDS_SUCCESS = 'LIST_RECOMMENDS_SUCCESS'

export const ANALYTICS_GET_SUCCESS = 'ANALYTICS_GET_SUCCESS'

export const ADMIN_CONFIG_SET = 'ADMIN_CONFIG_SET'
export const ADMIN_CONFIG_GET_SUCCESS = 'ADMIN_CONFIG_GET_SUCCESS'

export const SEARCH_PROFILE_GET_SUCCESS = 'SEARCH_PROFILE_GET_SUCCESS'
export const SEARCH_PROJECT_GET_SUCCESS = 'SEARCH_PROJECT_GET_SUCCESS'
export const SEARCH_PUBLISHED_GET_SUCCESS = 'SEARCH_PUBLISHED_GET_SUCCESS'

export const SEARCH_PROFILE_GET = 'SEARCH_PROFILE_GET'
export const SEARCH_PROJECT_GET = 'SEARCH_PROJECT_GET'
export const SEARCH_PUBLISHED_GET = 'SEARCH_PUBLISHED_GET'

const analyticsGetSuccess = (analyticsData) => {
    return {
        type: ANALYTICS_GET_SUCCESS,
        analyticsData,
    }
}
const adminGetSuccess = (configData) => {
    return {
        type: ADMIN_CONFIG_GET_SUCCESS,
        configData,
    }
}

const searchProfileGet = () => {
    return {
        type: SEARCH_PROFILE_GET,
    }
}
const searchProfileGetSuccess = (searchResults) => {
    return {
        type: SEARCH_PROFILE_GET_SUCCESS,
        searchResults,
    }
}
const searchProjectGet = () => {
    return {
        type: SEARCH_PROJECT_GET,
    }
}
const searchProjectGetSuccess = (searchResults) => {
    return {
        type: SEARCH_PROJECT_GET_SUCCESS,
        searchResults,
    }
}

const searchPublishedGet = () => {
    return {
        type: SEARCH_PUBLISHED_GET,
    }
}
const searchPublishedGetSuccess = (searchResults) => {
    return {
        type: SEARCH_PUBLISHED_GET_SUCCESS,
        searchResults,
    }
}

export const listRecommendedProfiles = () => (dispatch) => {
    var listRecommendsCall = functions.httpsCallable('listRecommends')
    dispatch(listRecommends())
    listRecommendsCall().then((response) => {
        var recommends = response.data.recommends
        // console.log(recommends)
        dispatch(listRecommendsSuccess(recommends))
    })
}

const listRecommends = () => {
    return {
        type: LIST_RECOMMENDS,
    }
}
const listRecommendsSuccess = (recommends) => {
    return {
        type: LIST_RECOMMENDS_SUCCESS,
        recommends,
    }
}

export const clearProfile = () => {
    return {
        type: PROFILE_CLEAR,
    }
}

export const didProjectTour = () => {
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        const docRef = db.collection('profiles')
                        docRef.doc(doc.id).update({ didProjectTour: true })
                    })
                })
        }
    })
    return {
        type: DID_PROJECT_TOUR,
    }
}

export const didProfileTour = () => {
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        const docRef = db.collection('profiles')
                        docRef.doc(doc.id).update({ didProfileTour: true })
                    })
                })
        }
    })
    return {
        type: DID_PROJECT_TOUR,
    }
}

export const didHomeTour = () => {
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        const docRef = db.collection('profiles')
                        docRef.doc(doc.id).update({ didHomeTour: true })
                    })
                })
        }
    })
    return {
        type: DID_HOME_TOUR,
    }
}

export const setDisplayName = () => {
    return {
        type: DISPLAY_NAME_SET,
    }
}
export const setDisplayNameClear = () => {
    return {
        type: DISPLAY_NAME_SET_CLEAR,
    }
}
const setDisplayNameFailure = () => {
    return {
        type: DISPLAY_NAME_SET_FAILURE,
    }
}
const setDisplayNameSuccess = () => {
    return {
        type: DISPLAY_NAME_SET_SUCCESS,
    }
}

export const setUserDisplayName = (displayName) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef
                                .doc(doc.id)
                                .update({ displayName: displayName })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

export const setUserDisplayBio = (bio) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef.doc(doc.id).update({ bio: bio })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

export const setUserPronouns = (pronouns) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef.doc(doc.id).update({ pronouns: pronouns })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

//Richard//
export const setUserInsta = (insta) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef.doc(doc.id).update({ insta: insta })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

// //Richard//
export const setUserDate = (date) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef.doc(doc.id).update({ date: date })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

export const setLoginCounter = (counter) => {
    return {
        type: LOGIN_COUNTER_SET,
        counter,
    }
}

export const incrementLoginCounter = () => (dispatch) => {
    // console.log('entered increment function')
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        const docRef = db.collection('profiles')
                        docRef.doc(doc.id).onSnapshot((doc) =>
                            // console.log('changing stuff', doc.get('counter'));
                            dispatch(setLoginCounter(doc.get('counter')))
                        )

                        docRef.doc(doc.id).update({ counter: increment })

                        // console.log('logging increment')
                        // console.log(increment)
                    })
                })
        }
    })
}

export const setUserReceiveEmails = (receiveEmails) => (dispatch) => {
    dispatch(setDisplayName())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef
                                .doc(doc.id)
                                .update({ receiveEmails: receiveEmails })
                            dispatch(setDisplayNameSuccess())
                        })
                    },
                    () => {
                        dispatch(setDisplayNameFailure())
                    }
                )
        }
    })
}

export const setUserDidWelcome = (receiveEmails) => (dispatch) => {
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const docRef = db.collection('profiles')
                            docRef.doc(doc.id).update({ didWelcome: true })
                        })
                    },
                    () => {}
                )
        }
    })
}
const resetPassword = () => {
    return {
        type: PASSWORD_RESET,
    }
}

export const resetPasswordClear = () => {
    // console.log('cleared password action')
    return {
        type: PASSWORD_RESET_CLEAR,
    }
}
const resetPasswordSuccess = () => {
    return {
        type: PASSWORD_RESET_SUCCESS,
    }
}
const resetPasswordFailure = () => {
    return {
        type: PASSWORD_RESET_FAILURE,
    }
}

export const resetUserPassword = () => (dispatch) => {
    var user = myFirebase.auth().currentUser

    myFirebase
        .auth()
        .sendPasswordResetEmail(user.email)
        .then(function () {
            dispatch(resetPasswordSuccess())
        })
        .catch(function (error) {
            return dispatch(resetPasswordFailure())
        })
}
const putAvatar = () => {
    return {
        type: AVATAR_PUT,
    }
}

export const putAvatarClear = () => {
    return {
        type: AVATAR_PUT_CLEAR,
    }
}
const putAvatarSuccess = () => {
    return {
        type: AVATAR_PUT_SUCCESS,
    }
}
const putAvatarError = () => {
    return {
        type: AVATAR_PUT_FAILURE,
    }
}
const receiveAvatar = (avatars) => {
    return { type: AVATAR_GET_SUCCESS, avatars }
}

export const saveUserAvatar = (file) => (dispatch) => {
    dispatch(putAvatar())
    const avatarId = uuid.v1()
    var imagesRef = images.ref()
    var avatarRef = imagesRef.child('avatars/' + avatarId)
    var metadata = {
        contentType: file.type,
    }

    avatarRef.put(file, metadata).then(function (snapshot) {
        // console.log('upload avatar ' + avatarId)

        myFirebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                db.collection('profiles')
                    .where('uid', '==', myFirebase.auth().currentUser.uid)
                    .onSnapshot(
                        (snapshot) => {
                            snapshot.forEach((doc) => {
                                let profileRef = db
                                    .collection('profiles')
                                    .doc(doc.id)
                                console.log('updated profile')
                                profileRef.update({ avatarId: avatarId })
                                dispatch(putAvatarSuccess())
                            })
                        },
                        () => {
                            dispatch(putAvatarError())
                        }
                    )
            })
            .catch(function (error) {
                dispatch(putAvatarError())
            })
    })
}

const getProfile = () => {
    return {
        type: PROFILE_GET,
    }
}

const profileError = () => {
    return {
        type: PROFILE_GET_FAILURE,
    }
}

const receiveProfile = (profile, notifications) => {
    return {
        type: PROFILE_GET_SUCCESS,
        profile,
        notifications,
    }
}

const receivePublicProfile = (publicProfile) => {
    return {
        type: PUBLIC_PROFILE_GET_SUCCESS,
        publicProfile,
    }
}

const receivePublished = (published) => {
    return {
        type: PUBLISHED_GET_SUCCESS,
        published,
    }
}

const profileAllPublished = (allProfile) => {
    return {
        type: PROFILE_ALL_GET_SUCCESS,
        allProfile,
    }
}

const receiveAllPublished = (item) => {
    return {
        type: 'POPULATE_PUBLISHED_ALL_DATA',
        item,
    }
}

const getNotif = () => {
    return {
        type: NOTIF_GET,
    }
}
const receiveNotif = (notifications) => {
    return {
        type: NOTIF_GET_SUCCESS,
        notifications,
    }
}

const notifError = () => {
    return {
        type: NOTIF_GET_FAILURE,
    }
}

export const getAvatarUrls = async (avatarId) => {
    var imagesRef = images.ref()

    const avatarSmall = await imagesRef
        .child('avatars/resized/' + avatarId + '_120x120')
        .getDownloadURL()
    const avatarMedium = await imagesRef
        .child('avatars/resized/' + avatarId + '_300x300')
        .getDownloadURL()
    const avatarLarge = await imagesRef
        .child('avatars/resized/' + avatarId + '_600x600')
        .getDownloadURL()

    return { avatarSmall, avatarMedium, avatarLarge }
}

export const getUserAvatar = () => (dispatch) => {
    let profilesRef = db.collection('profiles')

    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .onSnapshot((snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        getAvatarUrls(profileData.avatarId).then((urls) => {
                            dispatch(receiveAvatar(urls))
                        })
                    })
                })
        }
    })
}
export const getUserProfile = () => (dispatch) => {
    dispatch(getProfile())
    let profilesRef = db.collection('profiles')

    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        // console.log('FIREBASE METADATA')
        // console.log(firebaseUser.metadata)
        if (firebaseUser) {
            profilesRef.where('uid', '==', firebaseUser.uid).onSnapshot(
                (snapshot) => {
                    if (snapshot.empty) {
                        receiveProfile(null)
                        console.log('no profile, user is logged out')
                    } else {
                        snapshot.forEach((doc) => {
                            const profileData = doc.data()
                            dispatch(
                                receiveProfile({
                                    id: doc.id,
                                    date: firebaseUser.metadata.creationTime,
                                    ...profileData,
                                })
                            )
                        })
                    }
                },
                () => {
                    dispatch(profileError())
                }
            )
        } else {
            receiveProfile(null)
            console.log('no profile, user is logged out')
        }
    })
}
let objPublishedData: any = {}
export const getPublicProfile = (profileId) => (dispatch) => {
    dispatch(getProfile())
    let profilesRef = db.collection('profiles').doc(profileId)
    console.log('getting public profile')

    profilesRef
        .get()
        .then((doc) => {
            if (doc.exists) {
                const profileData = doc.data()
                console.log(profileData)

                dispatch(
                    receivePublicProfile({
                        id: doc.id,
                        ...profileData,
                    })
                )
            } else {
                console.log('Profile ' + profileId + ' not found')
                dispatch(profileError())
            }
            dispatch(receiveAllPublished({}))
        })
        .catch((error) => {
            console.log('Error getting profile: ', error)
        })
}

export const getAllPublished = () => (dispatch) => {
    let publishedRef = db.collection('published')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        // console.log('FIREBASE METADATA')
        // console.log(firebaseUser.metadata)
        if (firebaseUser) {
            publishedRef.get().then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const publishedData = doc.data()
                        //console.log('###################ddd################')
                        //console.log(publishedData)
                        let item = {
                            id: doc.id,
                            publishedData,
                        }

                        dispatch(receivePublished(item))
                    })
                    dispatch(receiveAllPublished({}))
                },
                () => {
                    dispatch(profileError())
                }
            )
        } else {
            receivePublished(null)
            console.log('firebaseUser user is null in Published data: ')
        }
    })
}

export const getAllProfile = () => (dispatch) => {
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        // console.log('FIREBASE METADATA')
        // console.log(firebaseUser.metadata)
        if (firebaseUser) {
            profilesRef.get().then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let item = {
                            id: doc.id,
                            profileData,
                        }

                        dispatch(profileAllPublished(item))
                    })
                    dispatch(profileAllPublished({}))
                },
                () => {
                    dispatch(profileError())
                }
            )
        } else {
            profileAllPublished(null)
            console.log('firebaseUser user is null in All Profile data: ')
        }
    })
}

export const unFollowProfile = (profile) => (dispatch) => {
    let profilesRef = db.collection('profiles')

    var user = myFirebase.auth().currentUser

    if (user) {
        profilesRef
            .where('uid', '==', user.uid)
            .get()
            .then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let profiles = []
                        console.log(profileData.following_profile_ids)
                        if (profileData.following_profile_ids)
                            profiles = profileData.following_profile_ids

                        var filtered = profiles.filter(function (
                            value,
                            index,
                            arr
                        ) {
                            return value != profile.id
                        })

                        filtered = [...new Set(filtered)]
                        console.log('unfollowing ', profile.id)

                        profilesRef
                            .doc(doc.id)
                            .update({ following_profile_ids: filtered })

                        profilesRef
                            .doc(profile.id)
                            .get()
                            .then((doc) => {
                                const data = doc.data()
                                profilesRef.doc(profile.id).update({
                                    followers: data.followers.filter(
                                        (follower) => follower !== user.uid
                                    ),
                                })
                            })
                    })
                },
                () => {
                    dispatch(profileError())
                }
            )
    }
}

export const followProfile = (profile) => (dispatch) => {
    let profilesRef = db.collection('profiles')
    console.log('following now')

    var user = myFirebase.auth().currentUser

    if (user) {
        profilesRef
            .where('uid', '==', user.uid)
            .get()
            .then(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let profiles = []
                        if (profileData.following_profile_ids)
                            profiles = profileData.following_profile_ids
                        console.log('following ' + profile.id)
                        profiles.push(profile.id)

                        var createNotification =
                            functions.httpsCallable('createNotification')
                        createNotification({
                            recipientUid: profile.uid,
                            message: `<b>${profileData.displayName}</b> is now following you.`,
                            category: 'follow',
                            payload: {
                                sender_profile: profileData,
                                recipient_profile: profile,
                                sender_uid: profileData.uid,
                                recipient_uid: profile.uid,
                                sender_profile_id: doc.id,
                            },
                        }).then((response) => {
                            console.log('generated following notification')
                        })

                        profilesRef
                            .doc(doc.id)
                            .update({ following_profile_ids: profiles })
                        profilesRef
                            .doc(profile.id)
                            .get()
                            .then((doc) => {
                                const data = doc.data()
                                profilesRef.doc(profile.id).update({
                                    followers: [
                                        ...new Set([
                                            ...(data.followers || []),
                                            user.uid,
                                        ]),
                                    ],
                                })
                            })
                        return
                    })
                },
                () => {
                    dispatch(profileError())
                }
            )
    }
}

export const clearNotifications = () => (dispatch) => {
    let profilesRef = db.collection('profiles')

    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const adminDocument = profilesRef.doc(doc.id)
                            const notRef =
                                adminDocument.collection('notifications')

                            notRef.onSnapshot((notSnapshot) => {
                                notSnapshot.forEach((notDoc) => {
                                    const notDocument = notRef.doc(notDoc.id)
                                    notDocument.update({ read: true })
                                })
                            })

                            adminDocument.update({ notification_count: 0 })
                        })
                    },
                    () => {
                        dispatch(profileError())
                    }
                )
        }
    })
}

export const hideNotification = (notificationId) => (dispatch) => {
    let profilesRef = db.collection('profiles')
    console.log('hiding notification ' + notificationId)
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const adminDocument = profilesRef.doc(doc.id)
                            const notRef =
                                adminDocument.collection('notifications')

                            notRef
                                .doc(notificationId)
                                .get()
                                .then((notDoc) => {
                                    const notDocument = notRef.doc(notDoc.id)
                                    notDocument.update({ hidden: true })

                                    dispatch(listNotifications())
                                })
                        })
                    },
                    () => {
                        dispatch(profileError())
                    }
                )
        }
    })
}

export const listNotifications = () => (dispatch) => {
    dispatch(getNotif())
    let profilesRef = db.collection('profiles')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            profilesRef
                .where('uid', '==', firebaseUser.uid)
                .get()
                .then(
                    (snapshot) => {
                        snapshot.forEach((doc) => {
                            const adminDocument = profilesRef.doc(doc.id)
                            const notRef =
                                adminDocument.collection('notifications')

                            const notList = []
                            notRef.onSnapshot((notSnapshot) => {
                                notSnapshot.forEach((notDoc) => {
                                    notList.push({
                                        ...notDoc.data(),
                                        id: notDoc.id,
                                    })
                                })
                                dispatch(receiveNotif(notList))
                            })
                        })
                    },
                    () => {
                        dispatch(notifError())
                    }
                )
        }
    })
}

export const updateSearchIndex = () => (dispatch) => {
    console.log('updating algolia search index....')

    var updateSearchIndex = functions.httpsCallable('updateSearchIndex')

    updateSearchIndex().then((response) => {
        console.log('update complete')
        console.log(response.data)
    })
}

export const getSearchResults = (searchText, category) => (dispatch) => {
    if (category === 'published') {
        dispatch(searchPublishedGet())
    } else if (category === 'profiles') {
        console.log('got here')
        dispatch(searchProfileGet())
    } else if (category === 'community') {
        console.log('got here')
        dispatch(searchProfileGet())
    }

    var getSearchResults = functions.httpsCallable('getSearchResults')
    console.log(searchText)
    getSearchResults({ searchText, category }).then((response) => {
        console.log(response.data)
        if (category === 'profiles') {
            dispatch(searchProfileGetSuccess(response.data))
        } else if (category === 'published') {
            dispatch(searchPublishedGetSuccess(response.data))
        } else if (category === 'community') {
            dispatch(searchProfileGetSuccess(response.data))
        }
    })
}

export const respondRequest =
    (profileId, requestId, accepted, category) => (dispatch) => {
        console.log('getting istoria analytics from firebase')
        console.log('responding to request ' + requestId)
        var respondRequestFunc = functions.httpsCallable('respondRequest')

        respondRequestFunc({ profileId, requestId, accepted, category }).then(
            (response) => {
                console.log('responded to request')
            }
        )
    }

export const getAdminAnalytics = () => (dispatch) => {
    console.log('getting istoria analytics from firebase')

    var getAnalytics = functions.httpsCallable('getAnalytics')

    getAnalytics().then((response) => {
        dispatch(analyticsGetSuccess(response.data))
    })
}

export const getAdminConfig = () => (dispatch) => {
    let configRef = db.collection('config').doc('admin')
    console.log('getting admin config from firebase')

    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            configRef
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        dispatch(adminGetSuccess(doc.data()))
                    } else {
                        console.log('Config not found')
                    }
                })
                .catch((error) => {
                    console.log('Error getting config:', error)
                })
        }
    })
}

export const setAdminConfig = (configData) => (dispatch) => {
    console.log('setting admin config...')
    myFirebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            db.collection('config')
                .doc('admin')
                .update({
                    ...(typeof configData.show_header !== 'undefined' && {
                        show_header: configData.show_header,
                    }),
                    ...(typeof configData.header_text !== 'undefined' && {
                        header_text: configData.header_text,
                    }),
                })
        }
    })
}
