import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { FaComments } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { createDiscussion, listCommunityFeed } from '../../../actions'
import PublishedCard from '../../publish/PublishedCard'
import DiscussionCard from '../DiscussionCard'

function compare(a, b) {
    let comparison = 0
    if (a.created_on < b.created_on) {
        comparison = 1
    } else if (a.created_on > b.created_on) {
        comparison = -1
    }
    return comparison
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mediaLarge: {
        objectFit: 'cover',
        width: '100%',
        height: 320,
    },
    banner: {
        position: 'relative',
        maxWidth: theme.breakpoints.values.lg,
        marginTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
    },
    headingLargeBox: {
        color: 'white',
        position: 'absolute',
        top: '35%',
        left: 40,
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headingLarge: {
        color: 'white',
        fontSize: '2.5em',
    },
    headingSmall: {
        color: 'white',
        position: 'absolute',
        top: '20%',
        left: 30,
        fontSize: '2.5em',
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    back: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        marginLeft: theme.spacing(0),
    },
    backContainer: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
    },
    blurb: {
        position: 'absolute',
        bottom: 20,
        left: 30,
        fontSize: '1.25em',
        color: 'white',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: 'rgba(77, 77, 77, 0.8)',
    },
    smallList: {
        marginTop: theme.spacing(1),
        maxWidth: theme.breakpoints.values.md,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.lg,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    communityActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    communityDescription: {
        paddingLeft: theme.spacing(1),
        color: '#666',
    },
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    admin: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    progressBox: {
        textAlign: 'center',
        margin: theme.spacing(2),
    },
    messageBox: {
        padding: theme.spacing(2),
    },
    textBox: {
        padding: theme.spacing(1),
    },
    memberCount: {
        color: '#666',
        textAlign: 'center',
        width: 180,
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    messageText: {
        color: '#666',
        textAlign: 'center',
    },
    joinButton: {
        marginTop: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    chipItem: {
        marginRight: theme.spacing(1),
    },
    startDiscussion: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    discussionTitle: {
        backgroundColor: 'white',
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    discussionBody: {
        backgroundColor: 'white',
        width: '100%',
    },
    discussionAdd: {
        maxWidth: 600,
        padding: theme.spacing(2),
    },
    discussionHeading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    discussionMessage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },

    emphasis: {
        color: '#512da8',
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    postToCircle: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    postCancel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}))
function FeedTab() {
    const classes = useStyles()
    const dispatch = useDispatch()
    let { id } = useParams()

    const [ready, setReady] = useState(false)
    const [hovering, setHovering] = useState(false)
    const [currentTab, setCurrentTab] = useState('writing')
    const history = useHistory()

    const [following, setFollowing] = useState('no')
    const [isMember, setIsMember] = useState(false)
    const [loading, setLoading] = useState(true)
    const [adding, setAdding] = useState(false)
    const [discussionTitle, setDiscussionTitle] = useState('')
    const [discussionBody, setDiscussionBody] = useState('')
    const [confirmLeave, setConfirmLeave] = useState(false)
    const profile = useSelector((state) => state.profile.profile)
    const community = useSelector((state) => state.community.community)
    const communityFeed = useSelector((state) => state.community.communityFeed)
    const communityFeedListSuccess = useSelector(
        (state) => state.community.communityFeedListSuccess
    )

    const renderedItems = (feedList) => {
        return feedList.sort(compare).map((published) => {
            if (published.postType === 'published') {
                return <PublishedCard published={published} fill />
            } else if (
                published.postType == 'discussion' &&
                !published.pinned
            ) {
                return (
                    <DiscussionCard
                        discussion={published}
                        community={community}
                    />
                )
            }
        })
    }
    const renderedPinned = (feedList) => {
        return feedList.sort(compare).map((item) => {
            if (item.postType == 'discussion') {
                if (item.pinned) {
                    return (
                        <DiscussionCard
                            pinned
                            discussion={item}
                            community={community}
                        />
                    )
                }
            }
        })
    }
    useEffect(() => {
        if (community) {
            // console.log('updating feed')
            // dispatch(listCommunityFeed(community.id))
        }
    }, [community])
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.smallList}
        >
            {!adding && (
                <>
                    <Button
                        className={classes.startDiscussion}
                        startIcon={<FaComments />}
                        variant="outlined"
                        onClick={() => {
                            setAdding(true)
                        }}
                    >
                        Start Discussion
                    </Button>

                    {communityFeed.length > 0 && renderedPinned(communityFeed)}
                    {communityFeed.length > 0 && renderedItems(communityFeed)}
                    {communityFeedListSuccess && communityFeed.length === 0 && (
                        <Container className={classes.nothingMessage}>
                            <Typography>There's nothing here yet.</Typography>
                        </Container>
                    )}
                </>
            )}

            {adding && (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.discussionAdd}
                >
                    <Typography
                        variant="h6"
                        className={classes.discussionHeading}
                    >
                        Start a Discussion
                    </Typography>
                    <Typography
                        variant="body"
                        className={classes.discussionMessage}
                    >
                        Discussions allow you and other members of{' '}
                        <b>{community.name}</b> to share writing topics,
                        in-person or virtual events, tips and anything else of
                        interest to your{' '}
                        <b className={classes.emphasis}>Istoria Circle</b>.
                    </Typography>
                    <TextField
                        className={classes.discussionTitle}
                        variant="outlined"
                        label="What is the title? (optional)"
                        onChange={(e) => {
                            setDiscussionTitle(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.discussionBody}
                        variant="outlined"
                        multiline
                        rows={10}
                        label="What do you want to talk about?"
                        onChange={(e) => {
                            setDiscussionBody(e.target.value)
                        }}
                    />
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.stepper}
                    >
                        <Button
                            variant="outlined"
                            className={classes.postCancel}
                            onClick={() => {
                                setAdding(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            className={classes.postToCircle}
                            onClick={() => {
                                dispatch(
                                    createDiscussion(community.id, {
                                        title: discussionTitle,
                                        bodyText: discussionBody,
                                        profileId: profile.id,
                                    })
                                )
                                setAdding(false)
                            }}
                        >
                            Post to Circle
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default FeedTab
