const genreList = [
    'Action',
    'Adventure',
    'Comedy',
    'Confessional',
    'Drama',
    'Family',
    'Fantasy',
    'Non-Fiction',
    'Fiction',
    'Historical Fiction',
    'History',
    'Horror',
    'Memoire',
    'Mystery',
    'Paranormal',
    'Perspectives',
    'Poetry',
    'Romance',
    'Science Fiction',
    'Spiritual',
    'Thriller',
]

export { genreList }
