import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import React, { useEffect, useState } from 'react'
import { FaFacebook } from 'react-icons/fa'
import { connect, useSelector } from 'react-redux'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { loginFacebook, loginGoogle, signUpEmailPassword } from '../../actions'
import * as routes from '../../constants/routes'
import ScrollToTop from '../layout/ScrollToTop'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: '20px',
        minHeight: '20vh',
    },
    createAccountButton: {
        marginTop: 20,
    },
    loginButton: {
        marginTop: 10,
    },

    logo: {
        maxWidth: 300,
        margin: 20,
        marginTop: 30,
        marginBottom: 30,
    },
    resetButton: {
        marginTop: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
    },
    signupHeading: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: 'center',
    },
    message: {
        color: '#666',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        textAlign: 'center',

        width: 260,
    },

    passwordInput: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    emailInput: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    loginMessage: {
        color: '#666',
    },
    login: {
        color: '#512da8',
    },
}))
function useQuery() {
    console.log(useLocation())
    return new URLSearchParams(useLocation().search)
}

function SignUpForModals(props) {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState(false)
    const signUpError = useSelector((state) => state.auth.signUpError)
    const error = useSelector((state) => state.auth.error)
    let query = useQuery()
    const { dispatch } = props

    const { isAuthenticated } = props

    const FacebookButton = withStyles({
        label: {
            color: '#1877F2',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#1877F2',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#F6fafe',
                borderColor: '#0062cc',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#0062cc',
                borderColor: '#005cbf',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
    })(Button)
    const GoogleButton = withStyles({
        label: {
            color: '#444',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#444',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#fafafa',
                borderColor: '#444',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#fafafa',
                borderColor: '#444',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.1rem #fafafa',
            },
        },
    })(Button)
    const _signUpForModals = () => {
        console.log('creating account ' + email)
        dispatch(signUpEmailPassword(email, password))
    }
    useEffect(() => {
        if (signUpError) {
            setShowError(true)
        }
    }, [signUpError])
    useEffect(() => {
        if (query.get('mailchimpEmail')) setEmail(query.get('mailchimpEmail'))
    }, [query])

    if (isAuthenticated) {
        return <Redirect to="/" />
    } else
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                classes={{
                    root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                }}
            >
                <ScrollToTop />
                <Snackbar
                    open={showError}
                    autoHideDuration={6000}
                    onClose={() => {
                        setShowError(false)
                    }}
                >
                    <Alert
                        onClose={() => {
                            setShowError(false)
                        }}
                        severity="error"
                    >
                        Error creating account: {signUpError && error.message}
                    </Alert>
                </Snackbar>
                <FacebookButton
                    variant="outlined"
                    color="#1877F2"
                    onClick={() => {
                        console.log('signing in with facebook...')
                        dispatch(loginFacebook())
                    }}
                    startIcon={<FaFacebook />}
                    className={classes.resetButton}
                >
                    Continue with Facebook
                </FacebookButton>

                <GoogleButton
                    variant="outlined"
                    component={Link}
                    startIcon={
                        <img
                            alt="Google logo"
                            height="20"
                            width="20"
                            src="https://storage.googleapis.com/istoria-images/assets/g-logo.png"
                        />
                    }
                    onClick={() => {
                        console.log('signing in with google...')
                        dispatch(loginGoogle())
                    }}
                    className={classes.resetButton}
                >
                    Sign up with Google
                </GoogleButton>
                <Divider className={classes.divider} />
                <TextField
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    variant="outlined"
                    id="loginUser"
                    required
                    className={classes.emailInput}
                    label="Email"
                />

                <TextField
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                    id="loginPassword"
                    label="Password"
                    variant="outlined"
                    required
                    className={classes.passwordInput}
                    type="password"
                />
                {/* <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.createAccountButton }}
          onClick={_signUpForModals}
        >
          Create Account
        </Button> */}
                <Typography className={classes.message} variant="body2">
                    By creating an account you are agreeing to the{' '}
                    <Link to="/terms">Terms of Use</Link>.
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    classes={{ root: classes.loginButton }}
                    onClick={_signUpForModals}
                >
                    Create Account
                </Button>

                <Divider className={classes.divider} />
                <Typography variant="body2" className={classes.loginMessage}>
                    Already have an account?&nbsp;
                    <Link
                        className={classes.login}
                        to={{
                            pathname: routes.LOGIN,
                        }}
                    >
                        Login
                    </Link>
                </Typography>
            </Grid>
        )
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
    }
}
export default connect(mapStateToProps)(SignUpForModals)
