import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Hidden,
  Tab,
  Tabs,
  TextField,
  Typography,
  Chip,
} from '@material-ui/core'
import { getPublicCommunityFeaturedUrls } from '../../actions/community'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  mediaLarge: {
    objectFit: 'cover',
    width: '100%',
    height: 320,
  },
  banner: {
    position: 'relative',
    maxWidth: theme.breakpoints.values.xl,
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  headingLargeBox: {
    color: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    // backgroundColor: 'rgba(77, 77, 77, 0.8)',
    height: 320,
  },
  headingLargeSubBox: {
    maxWidth: theme.breakpoints.values.lg,
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

  },
  headingLarge: {
    color: 'white',
    fontSize: '2.5em',
  },
  headingSmall: {
    color: 'white',
    position: 'absolute',
    top: '20%',
    left: 30,
    fontSize: '2.5em',
    // backgroundColor: 'rgba(77, 77, 77, 0.8)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  back: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
  },
  backContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  blurb: {
    position: 'absolute',
    bottom: 20,
    left: 30,
    fontSize: '1.25em',
    color: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'rgba(77, 77, 77, 0.8)',
  },
  smallList: {
    marginTop: theme.spacing(1),
    maxWidth: theme.breakpoints.values.md,
  },
  mainBox: {
    maxWidth: theme.breakpoints.values.lg,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  communityActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  communityDescription: {
    paddingLeft: theme.spacing(1),
    color: '#666',
  },
  nothingMessage: {
    textAlign: 'center',
    color: '#666',
  },
  admin: {
    marginTop: theme.spacing(1),
  },
  progressBox: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  messageBox: {
    padding: theme.spacing(2),
  },
  textBox: {
    padding: theme.spacing(1),
  },
  memberCount: {
    color: '#666',
    textAlign: 'center',
    width: 180,
  },

  divider: {
    borderTop: '1px solid #ccc',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '60px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  messageText: {
    color: '#666',
    textAlign: 'center',
  },
  joinButton: {
    marginTop: theme.spacing(2),
  },
  tags: {
    marginBottom: theme.spacing(1),
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: 160,
    overflow: 'hidden',
  },
  chipItem: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  startDiscussion: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  discussionTitle: {
    backgroundColor: 'white',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  discussionBody: {
    backgroundColor: 'white',
    width: '100%',
  },
  discussionAdd: {
    maxWidth: 600,
    padding: theme.spacing(2),
  },
  discussionHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  discussionMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },

  emphasis: {
    color: '#512da8',
  },
  stepper: {
    marginTop: theme.spacing(2),
  },
  postToCircle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  postCancel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

function CommunityBanner({ community }) {
  const classes = useStyles()

  const [mediaUrl, setMediaUrl] = useState(
    require('../../static/grey-background.jpg')
  )

  useEffect(() => {
    if (community.featuredId) {
      getPublicCommunityFeaturedUrls(community.featuredId).then(
        (urls) => {
          console.log(urls)
          setMediaUrl(urls.featuredLarge)
        }
      )
    } else {
    }
  }, [community])
  return (
    <Container className={classes.banner}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.headingLargeBox}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.headingLargeSubBox}
        >

          <Typography className={classes.headingLarge}>
            {community.name}
          </Typography>
          {community.tags && (
            <Box className={classes.tags}>
              {community.tags.map((tag) => (
                <Chip
                  size="small"
                  className={classes.chipItem}
                  label={tag}
                />
              ))}
            </Box>
          )}

          {community.member_profile_ids && (
            <Typography className={classes.headingMembers}>
              {community.member_profile_ids.length} member
              {community.member_profile_ids.length !== 1 && 's'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <img
        alt="Story circle featured"
        className={classes.mediaLarge}
        src={mediaUrl}
      />
    </Container>
  )
}

export default CommunityBanner
