import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatRelative } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FaComment, FaHeart, FaPen, FaUserPlus, FaUsers } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    hideNotification,
    listNotifications,
    respondRequest,
} from '../../actions'
import RawHTML from '../etc/RawHTML'

function compare(a, b) {
    let comparison = 0
    if (a.created_on > b.created_on) {
        comparison = -1
    } else if (a.created_on < b.created_on) {
        comparison = 1
    }
    return comparison
}

function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return formatRelative(d, new Date())
}
const renderNot = (not, index, classes, dispatch) => {
    if (not.category === 'comment')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardActionArea
                    component={Link}
                    to={{
                        pathname: 'p/' + not.payload.short_url,
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FaComment
                                color="#512da8"
                                className={classes.categoryIcon}
                            />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="p">
                                    <RawHTML>{not.message}</RawHTML>
                                </Typography>
                            </Grid>
                            <Typography
                                className={classes.commentDatetime}
                                variant="body2"
                            >
                                {formatDate(not.created_on)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    else if (not.category === 'publish')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardActionArea
                    component={Link}
                    to={{
                        pathname: 'p/' + not.payload.short_url,
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FaPen
                                color="#512da8"
                                className={classes.categoryIcon}
                            />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="p">
                                    <RawHTML>{not.message}</RawHTML>
                                </Typography>
                            </Grid>
                            <Typography
                                className={classes.commentDatetime}
                                variant="body2"
                            >
                                {formatDate(not.created_on)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    else if (not.category === 'liked')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardActionArea
                    component={Link}
                    to={{
                        pathname: 'p/' + not.payload.short_url,
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FaHeart
                                color="#bd1e13"
                                className={classes.categoryIcon}
                            />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="p">
                                    <RawHTML>{not.message}</RawHTML>
                                </Typography>
                            </Grid>
                            <Typography
                                className={classes.commentDatetime}
                                variant="body2"
                            >
                                {formatDate(not.created_on)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    else if (not.category === 'follow')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardActionArea
                    component={Link}
                    to={{
                        pathname: 'pr/' + not.payload.sender_profile_id,
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FaUserPlus
                                color="#512da8"
                                className={classes.categoryIcon}
                            />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="p">
                                    <RawHTML>{not.message}</RawHTML>
                                </Typography>
                            </Grid>
                            <Typography
                                className={classes.commentDatetime}
                                variant="body2"
                            >
                                {formatDate(not.created_on)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    else if (not.category === 'community')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardActionArea
                    component={Link}
                    to={{
                        pathname: 'c/' + not.payload.communityId,
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FaUsers
                                color="#512da8"
                                className={classes.categoryIcon}
                            />
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="body2" component="p">
                                    <RawHTML>{not.message}</RawHTML>
                                </Typography>
                            </Grid>
                            <Typography
                                className={classes.commentDatetime}
                                variant="body2"
                            >
                                {formatDate(not.created_on)}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    else if (not.category === 'request')
        return (
            <Card key={index} className={classes.feedCard}>
                <CardContent className={classes.cardcontent}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <FaUsers
                            color="#512da8"
                            className={classes.categoryIcon}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="body2" component="p">
                                <RawHTML>{not.message}</RawHTML>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.requestResponse}
                            spacing={2}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.acceptButton}
                                onClick={() => {
                                    console.log('accepting request')
                                    console.log(not)
                                    dispatch(hideNotification(not.id))
                                    dispatch(
                                        respondRequest(
                                            not.payload.recipientProfileId,
                                            not.payload.requestId,
                                            'community',
                                            true
                                        )
                                    )
                                }}
                            >
                                Accept
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => {
                                    console.log('ignoring request')
                                    dispatch(hideNotification(not.id))
                                }}
                            >
                                Ignore
                            </Button>
                        </Grid>
                        <Typography
                            className={classes.commentDatetime}
                            variant="body2"
                        >
                            {formatDate(not.created_on)}
                        </Typography>
                    </Grid>
                </CardContent>
            </Card>
        )
}

const renderNots = (nots, classes, dispatch) => {
    const rendered = nots
        .sort(compare)
        .slice(0, 10)
        .map((not, index) => renderNot(not, index, classes, dispatch))
    return rendered
}

const filteredNot = (notList, filter) => {
    const filtered = notList.filter(
        (not) => not.category !== 'project' && not.hidden !== true
    )
    if (filter === 'all') {
        return filtered
    } else {
        return filtered
    }
}

function NotificationsFeed(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const notifications = useSelector((state) => state.profile.notifications)
    const notifGetComplete = useSelector(
        (state) => state.profile.notifGetComplete
    )
    const notifGetError = useSelector((state) => state.profile.notifGetError)

    const [notList, setNotList] = useState([])
    const [currentTab, setCurrentTab] = useState('all')
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setNotList(filteredNot(notifications, currentTab))
    }, [currentTab, notifications])

    useEffect(() => {
        dispatch(listNotifications())
    }, [dispatch])

    useEffect(() => {
        if (notifGetComplete) {
            setIsLoading(false)
        }
    }, [notifGetComplete, notifGetError])
    return (
        <Container className={classes.feedBox}>
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={(e, newValue) => {
                        setCurrentTab(newValue)
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="All" value="all" />
                    {/* <Tab label="Requests" value="request" />
                    <Tab label="Invitations" value="invitation" />
                    <Tab label="Publish" value="publish" /> */}
                </Tabs>
                <Box className={classes.notifBox}>
                    {!isLoading ? (
                        notList.length > 0 ? (
                            renderNots(notList, classes, dispatch)
                        ) : (
                            <Typography
                                variant="body2"
                                className={classes.notificationsMessage}
                            >
                                You don't have any notifications.
                            </Typography>
                        )
                    ) : (
                        <CircularProgress
                            className={classes.notificationsProgress}
                        />
                    )}
                </Box>
            </Box>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    feedCard: {
        marginBottom: 5,
    },
    cardcontent: {
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },

    feedBox: {
        marginTop: theme.spacing(1),
        maxWidth: theme.breakpoints.values.sm,
    },
    notificationsProgress: {
        margin: theme.spacing(2),
    },
    notificationsMessage: {
        marginTop: theme.spacing(1),
        color: '#666',
    },
    notifBox: {
        marginTop: theme.spacing(1),
    },
    categoryIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    commentDatetime: {
        marginTop: theme.spacing(1),
        color: '#666',
    },
    requestResponse: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    acceptButton: {
        marginRight: theme.spacing(1),
    },
}))
export default NotificationsFeed
