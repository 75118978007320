import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Hidden,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getPinPublishedStory, listPublishedFeatured } from '../../actions'
import { getSearchResults } from '../../actions/profile'
import { shuffler } from '../../actions/shuffle'
import { debounce } from '../../constants/debounce'
import { slugUrl } from '../../shared/slugUrl'
import PublishedCard from '../publish/PublishedCard'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    configItem: {
        marginBottom: 20,
    },
    profileInput: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    searchInput: {
        backgroundColor: 'white',
        maxWidth: theme.breakpoints.values.sm,
        marginBottom: theme.spacing(2),
    },
    searchInputLarge: {
        width: 500,
        backgroundColor: 'white',
        marginBottom: theme.spacing(2),
    },
    publishedBox: {},
    follow: {
        marginTop: theme.spacing(1),
    },
    profileBio: {
        color: '#666',
    },
    profileLoading: {
        textAlign: 'center',
    },

    searchSpinner: {
        margin: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    editProfile: {
        marginTop: theme.spacing(1),
    },
    istoriaInline: {
        display: 'inline-block',
    },
    exploreMessage: {
        marginBottom: theme.spacing(2),
        fontWeight: 'normal',
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
    dividerTitle: {
        fontSize: '1.25rem',
        color: '#512da8',
        fontWeight: 'bold',
        fontFamily: 'Roboto Slab',
        marginBottom: theme.spacing(2),
    },
    searchResult: {
        display: 'block',
        marginTop: theme.spacing(1),
        color: 'black',
        fontSize: 20,
    },
    results: {
        marginBottom: theme.spacing(2),
    },
}))

function FeedTabExplore(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [pinnedList, setPinnedList] = useState([]) // list to show only pinned story
    const [unReadList, setUnReadList] = useState([]) // list to show only unPinned story

    const publishedList = useSelector((state) => state.write.publishedList)
    const pinnedData = useSelector((state) => state.admin.pinnedList)

    const publishedSearchResultsGetting = useSelector(
        (state) => state.profile.publishedSearchResultsGetting
    )
    const publishedSearchResults = useSelector(
        (state) => state.profile.publishedSearchResults
    )

    const [searchResults, setSearchResults] = useState([])
    const [searchActive, setSearchActive] = useState(false)
    const [searchText, setSearchText] = useState('false')

    useEffect(() => {
        console.log(publishedSearchResults.hits)
        if (publishedSearchResults) {
            console.log('made it')
            setSearchResults(publishedSearchResults.hits)
        }
    }, [publishedSearchResults])

    // filter publishedList with pinned story and unPinned story
    useEffect(() => {
        const filterUnread = publishedList.filter((item) => item.isPin !== true)
        setUnReadList(filterUnread)
    }, [publishedList])

    useEffect(() => {
        setPinnedList(pinnedData)
    }, [pinnedData])

    const publishedListing = useSelector(
        (state) => state.write.publishedListing
    )
    const publishedListingSuccess = useSelector(
        (state) => state.write.publishedListingSuccess
    )
    let d = new Date()
    let currentDay = d.getDate()

    useEffect(() => {
        console.log('loading published')
        dispatch(listPublishedFeatured())
        dispatch(getPinPublishedStory())
    }, [dispatch])

    return (
        <Container className={classes.publishedBox}>
            <Typography variant="h6" className={classes.exploreMessage}>
                Enjoy trending stories from across{' '}
                <Typography
                    style={{
                        fontSize: '1.25rem',
                        color: '#512da8',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.istoriaInline}
                >
                    istoria
                </Typography>
            </Typography>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault()
                    console.log('submit')
                    console.log(e.target.value)
                    dispatch(getSearchResults(searchText, 'published'))
                }}
            >
                <Hidden smDown>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch color="#ccc" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            console.log(e.target.value)
                            setSearchText(e.target.value)
                            if (e.target.value.length > 0) {
                                setSearchActive(true)
                                debounce(
                                    dispatch(
                                        getSearchResults(
                                            e.target.value,
                                            'published'
                                        )
                                    ),
                                    3000
                                )
                            } else {
                                setSearchActive(false)
                            }
                        }}
                        placeholder="Search published writing"
                        className={classes.searchInputLarge}
                        variant="outlined"
                        id="standard-search"
                        type="search"
                    />
                </Hidden>
                <Hidden mdUp>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch color="#ccc" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            console.log(e.target.value)
                            setSearchText(e.target.value)
                            if (e.target.value.length > 0) {
                                setSearchActive(true)
                                debounce(
                                    dispatch(
                                        getSearchResults(
                                            e.target.value,
                                            'published'
                                        )
                                    ),
                                    3000
                                )
                            } else {
                                setSearchActive(false)
                            }
                        }}
                        placeholder="Search published writing"
                        className={classes.searchInput}
                        variant="outlined"
                        id="standard-search"
                        type="search"
                    />
                </Hidden>
            </form>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                y
                className={classes.recommends}
            >
                {publishedSearchResultsGetting && (
                    <CircularProgress className={classes.searchSpinner} />
                )}
                {searchActive && !publishedSearchResultsGetting && (
                    <Container className={classes.results}>
                        {searchResults &&
                            searchResults
                                .filter(function (el) {
                                    return el.profileName
                                })
                                // .filter((profile) => profile.id !== loggedInProfile.id)
                                // Richard pair 1
                                // .slice(0, 5)
                                .map((result, key) => (
                                    <Container>
                                        <a
                                            className={classes.searchResult}
                                            href={
                                                '/p/' +
                                                slugUrl(
                                                    result.title,
                                                    result.shortUrl
                                                )
                                            }
                                        >
                                            <Highlighter
                                                highlightClassName="highlighted"
                                                searchWords={searchText.split(
                                                    ' '
                                                )}
                                                autoEscape={true}
                                                textToHighlight={result.title}
                                            />
                                        </a>
                                        by{' '}
                                        <Highlighter
                                            highlightClassName="highlighted"
                                            searchWords={searchText.split(' ')}
                                            autoEscape={true}
                                            textToHighlight={result.profileName}
                                        />
                                    </Container>
                                ))}
                    </Container>
                )}
            </Grid>
            {!searchActive && publishedListing && (
                <CircularProgress className={classes.searchSpinner} />
            )}
            {publishedListingSuccess && (
                <Box>
                    {publishedList.length === 0 && (
                        <Container className={classes.nothingMessage}>
                            <Typography variant="body2">
                                Theres nothing here yet.
                            </Typography>
                        </Container>
                    )}

                    {/* <Typography
            className={[classes.istoriaInline, classes.dividerTitle]}
          >
            Featured Stories
          </Typography> */}
                    {shuffler(pinnedList, currentDay).map((published) => (
                        <Box>
                            <PublishedCard
                                published={published}
                                pinned
                            ></PublishedCard>
                        </Box>
                    ))}

                    {/* add divider to septate section */}
                    {/* <Divider className={classes.divider} />

          <Typography
            className={[classes.istoriaInline, classes.dividerTitle]}
          >
            More Stories
          </Typography> */}
                    {shuffler(unReadList, currentDay).map((published) => (
                        <Box>
                            <PublishedCard
                                published={published}
                            ></PublishedCard>
                        </Box>
                    ))}
                </Box>
            )}
        </Container>
    )
}

export default FeedTabExplore
