export const debounce = (func, delay) => {
    console.log('debouncing')
    let inDebounce
    return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() => func.apply(context, args), delay)
        console.log('finished debouncing')
    }
}

// export const debounce = (func, wait, immediate) => {
// 	let timeout;
// 	return function() {
// 		let context = this, args = arguments;
// 		let later = function() {
// 			timeout = null;
// 			if (!immediate) func.apply(context, args);
// 		};
// 		let callNow = immediate && !timeout;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, wait);
// 		if (callNow) func.apply(context, args);
// 	};
// };
