import { Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setWriteTab } from '../../actions'
import DetailsTab from './tabs/DetailsTab'
import FeedbackTab from './tabs/FeedbackTab'
import PublishTab from './tabs/PublishTab'
import WriteTab from './tabs/WriteTab'

const useStyles = makeStyles({
    mainBox: {},
    viewTitle: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    viewBody: {
        marginTop: 10,
    },
    viewActionRow: {
        marginTop: 10,
    },
    viewProgress: {
        marginTop: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    progressBox: {
        textAlign: 'center',
    },
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    sidebarHeading: {
        color: '#512da8',
        fontWeight: 'bold',
    },
})

function ProjectContent() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentTab = useSelector((state) => state.ui.writeTab)

    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const current = useSelector((state) => state.ui.sidebarPosition)
    const project = useSelector((state) => state.write.project)

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={(e, newValue) => {
                    dispatch(setWriteTab(newValue))
                }}
                indicatorColor="primary"
                textColor="primary"
                className={classes.feedTabs}
                centered
            >
                <Tab label="Edit" value="edit" />
                <Tab
                    id="project-content-details"
                    disabled={noteSelected}
                    label="Details"
                    value="about"
                />
                {/* <Tab
                    id="project-content-feedback"
                    disabled
                    label="Feedback"
                    value="feedback"
                /> */}
                <Tab
                    id="project-content-publish"
                    disabled={noteSelected}
                    label="Publish"
                    value="publish"
                />
            </Tabs>
            {currentTab === 'edit' && (
                <WriteTab
                    writing={project}
                    current={current}
                    noteSelected={noteSelected}
                />
            )}
            {currentTab === 'about' && <DetailsTab />}
            {currentTab === 'feedback' && <FeedbackTab />}
            {currentTab === 'publish' && <PublishTab />}
        </>
    )
}

export default ProjectContent
