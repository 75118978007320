import {
    PROFILE_GET,
    PROFILE_GET_SUCCESS,
    PROFILE_GET_FAILURE,
    PROFILE_CLEAR,
    PUBLIC_PROFILE_GET_SUCCESS,
    PUBLISHED_GET_SUCCESS,
    PROFILE_ALL_GET_SUCCESS,
    NOTIF_GET_FAILURE,
    NOTIF_GET_SUCCESS,
    NOTIF_GET,
    AVATAR_PUT,
    AVATAR_PUT_FAILURE,
    AVATAR_PUT_SUCCESS,
    AVATAR_PUT_CLEAR,
    AVATAR_GET_SUCCESS,
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_CLEAR,
    DISPLAY_NAME_SET,
    DISPLAY_NAME_SET_SUCCESS,
    DISPLAY_NAME_SET_FAILURE,
    DISPLAY_NAME_SET_CLEAR,
    LIST_RECOMMENDS,
    LIST_RECOMMENDS_SUCCESS,
    LOGIN_COUNTER_SET,
    ANALYTICS_GET_SUCCESS,
    ADMIN_CONFIG_GET_SUCCESS,
    SEARCH_PROFILE_GET_SUCCESS,
    SEARCH_PUBLISHED_GET_SUCCESS,
    SEARCH_PROJECT_GET_SUCCESS,
    SEARCH_PROFILE_GET,
    SEARCH_PUBLISHED_GET,
    SEARCH_PROJECT_GET,
} from '../actions/'

export default (
    state = {
        profileGetting: false,
        profileGetError: false,
        profile: {},
        publicProfile: {},
        published: [],
        allProfile: [],
        notifGetting: false,
        notifGetError: false,
        notifications: [],
        avatarPutting: false,
        avatarPutError: false,
        avatarPutComplete: false,
        avatars: {},
        passwordResetting: false,
        passwordResetError: false,
        passwordResetComplete: false,
        displayNameSetting: false,
        displayNameSetError: false,
        displayNameSetComplete: false,
        recommends: [],
        recommendsGetting: false,
        profileSearchResultsGetting: false,
        projectSearchResultsGetting: false,
        publishedSearchResultsGetting: false,
        profileSearchResults: [],
        projectSearchResults: [],
        publishedSearchResults: [],
        analyticsData: {},
        configData: {},
    },
    action
) => {
    switch (action.type) {
        case PROFILE_CLEAR:
            return {
                ...state,
                profile: {},
            }
        case DISPLAY_NAME_SET:
            return {
                ...state,
                displayNameSetting: true,
                displayNameSetError: false,
                displayNameSetComplete: false,
            }

        case DISPLAY_NAME_SET_CLEAR:
            return {
                ...state,
                displayNameSetting: false,
                displayNameSetError: false,
                displayNameSetComplete: false,
            }

        case DISPLAY_NAME_SET_SUCCESS:
            return {
                ...state,
                displayNameSetting: false,
                displayNameSetError: false,
                displayNameSetComplete: false,
            }

        case DISPLAY_NAME_SET_FAILURE:
            return {
                ...state,
                displayNameSetting: false,
                displayNameSetError: true,
                displayNameSetComplete: false,
            }
        case PASSWORD_RESET:
            return {
                ...state,
                passwordResetting: true,
                passwordResetError: false,
                passwordResetComplete: false,
            }
        case PASSWORD_RESET_CLEAR:
            return {
                ...state,
                passwordResetting: false,
                passwordResetError: false,
                passwordResetComplete: false,
            }
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetting: false,
                passwordResetError: false,
                passwordResetComplete: true,
            }
        case PASSWORD_RESET_FAILURE:
            return {
                ...state,
                passwordResetting: false,
                passwordResetError: true,
                passwordResetComplete: false,
            }
        case PUBLIC_PROFILE_GET_SUCCESS:
            return {
                ...state,
                profileGetting: false,
                publicProfile: action.publicProfile,
            }
        case PUBLISHED_GET_SUCCESS:
            if (!state.published) {
                state.published = []
            }
            if (!state.objPublishedData) {
                state.objPublishedData = {}
            }

            if (action.published) {
                state.published.push(action.published)
                state.objPublishedData[action.published.id] = action.published
            }
            return {
                ...state,
                published: state.published,
            }
        case PROFILE_ALL_GET_SUCCESS:
            if (!state.allProfile) {
                state.allProfile = []
            }
            if (!state.objAllProfile) {
                state.objAllProfile = {}
            }

            if (action.allProfile) {
                state.allProfile.push(action.allProfile)
                state.objAllProfile[action.allProfile.id] = action.allProfile
            }
            return {
                ...state,
                allProfile: state.allProfile,
            }

        case PROFILE_GET:
            return {
                ...state,
                profileGetting: true,
                profileGetError: false,
                profile: {},
            }
        case PROFILE_GET_SUCCESS:
            return {
                ...state,
                profileGetting: false,
                profileGetError: false,
                profile: action.profile,
            }
        case PROFILE_GET_FAILURE:
            return {
                ...state,
                profileGetting: false,
                profileGetError: true,
                profile: {},
            }
        case AVATAR_GET_SUCCESS:
            return { ...state, avatars: action.avatars }
        case AVATAR_PUT:
            return {
                ...state,
                avatarPutComplete: false,
                avatarPutting: true,
                avatarPutError: false,
            }
        case AVATAR_PUT_CLEAR:
            return {
                ...state,
                avatarPutComplete: false,
                avatarPutting: false,
                avatarPutError: false,
            }
        case AVATAR_PUT_SUCCESS:
            return {
                ...state,
                avatarPutComplete: true,
                avatarPutting: false,
                avatarPutError: false,
            }
        case AVATAR_PUT_FAILURE:
            return {
                ...state,
                avatarPutComplete: false,
                avatarPutting: false,
                avatarPutError: false,
            }

        case NOTIF_GET:
            return {
                ...state,
                notifGetting: true,
                notifGetComplete: false,
                notifGetError: false,
                notifications: [],
            }
        case NOTIF_GET_SUCCESS:
            return {
                ...state,
                notifGetting: false,
                notifGetComplete: true,
                notifGetError: false,
                notifications: action.notifications,
            }
        case NOTIF_GET_FAILURE:
            return {
                ...state,
                notifGetting: false,
                notifGetComplete: false,
                notifGetError: true,
                notifications: [],
            }
        case LIST_RECOMMENDS:
            return {
                ...state,
                recommendsGetting: true,
            }
        case LIST_RECOMMENDS_SUCCESS:
            return {
                ...state,
                recommendsGetting: false,
                recommends: action.recommends,
            }
        case LOGIN_COUNTER_SET:
            return {
                ...state,
                loginCounter: action.counter,
            }
        case ANALYTICS_GET_SUCCESS:
            return {
                ...state,
                analyticsData: action.analyticsData,
            }
        case ADMIN_CONFIG_GET_SUCCESS:
            return {
                ...state,
                configData: action.configData,
            }

        case SEARCH_PROFILE_GET:
            return {
                ...state,
                profileSearchResultsGetting: true,
            }

        case SEARCH_PUBLISHED_GET:
            return {
                ...state,
                publishedSearchResultsGetting: true,
            }

        case SEARCH_PROFILE_GET_SUCCESS:
            console.log(action.searchResults)
            return {
                ...state,
                profileSearchResultsGetting: false,
                profileSearchResults: action.searchResults,
            }

        case SEARCH_PUBLISHED_GET_SUCCESS:
            return {
                ...state,
                publishedSearchResults: action.searchResults,
                publishedSearchResultsGetting: false,
            }
        case SEARCH_PROJECT_GET_SUCCESS:
            return {
                ...state,
                configData: action.configData,
            }

        case SEARCH_PROJECT_GET:
            return {
                ...state,
            }
        case 'POPULATE_PUBLISHED_ALL_DATA':
            setDataInProfile(state)
            return {
                ...state,
            }
        default:
            return state
    }
}

const setDataInProfile = (state) => {
    if (state.publicProfile) {
        if (
            Object.keys(state.objPublishedData).length &&
            (!state.publicProfile.likedPublishesDetails ||
                !state.publicProfile.likedPublishesDetails.length)
        ) {
            let profileData = state.publicProfile
            if (profileData.likedPublishes) {
                profileData.likedPublishesDetails = []
                for (let id of profileData.likedPublishes) {
                    if (state.objPublishedData[id]) {
                        profileData.likedPublishesDetails.push(
                            state.objPublishedData[id]
                        )
                    }
                }
            }
        }
        if (
            Object.keys(state.objAllProfile).length &&
            (!state.publicProfile.followingProfileDetails ||
                !state.publicProfile.followingProfileDetails.length)
        ) {
            let profileData = state.publicProfile
            if (profileData.following_profile_ids) {
                profileData.followingProfileDetails = []
                for (let id of profileData.following_profile_ids) {
                    if (state.objAllProfile[id]) {
                        let item = state.objAllProfile[id]
                        if (item.profileData) {
                            item.profileData.id = id
                        }
                        profileData.followingProfileDetails.push(item)
                    }
                }
            }
        }
    }
}
