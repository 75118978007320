import { Box, Button, Chip, Grid, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import {
    FaHeart,
    FaPen,
    FaRegCircle,
    FaUserCheck,
    FaUserPlus,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    followProfile,
    getAvatarUrls,
    unFollowProfile,
} from '../../actions/profile'
import * as routes from '../../constants/routes'
import ShareProfile from './ShareProfile'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 275,
        // height: 320,
        backgroundColor: 'white',
        borderRadius: 12,
        textDecoration: 'none',
        marginBottom: theme.spacing(2),
        display: 'inline-block',
    },
    tags: {},
    follow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    chip: {
        marginLeft: 5,
        marginRight: 5,
        // color: '#512da8',
        // fontWeight: 'bold'
    },
    authorName: {
        fontWeight: 'bold',
        color: '#333',
    },
    likes: {
        marginBottom: theme.spacing(1),
        color: '#a1453f',
        opacity: 0.7,
    },
    pronouns: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        opacity: 0.7,
        color: '#666',
    },
    heart: {
        position: 'relative',
        top: 2,
    },
    user: {
        position: 'relative',
        top: 2,
    },
    star: {
        position: 'relative',
        top: 2,
    },
    map: {
        position: 'relative',
        top: 2,
    },
    writes: {
        color: 'black',
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    avatar: {
        height: 80,
        width: 80,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        border: '1px solid #512da8',
    },
    avatarSkeleton: {
        height: 80,
        width: 80,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    newWriting: {
        color: '#009c12',
        // textTransform: 'uppercase'
    },
    mainBox: {
        width: 300,
    },
    shareProfile: {
        marginTop: theme.spacing(1),
    },
}))

function AuthorCard({ profile, handleClose }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [avatarLoaded, setAvatarLoaded] = useState(false)

    const [following, setFollowing] = useState(false)
    const loggedInProfile = useSelector((state) => state.profile.profile)

    useEffect(() => {
        console.log('################aaaaa##########')
        console.log(profile)
    }, [])

    useEffect(() => {
        if (profile.avatarId) {
            getAvatarUrls(profile.avatarId).then((urls) => {
                if (urls.avatarMedium) {
                    setAvatarUrl(urls.avatarMedium)
                }
                setAvatarLoaded(true)
            })
        } else {
            setAvatarLoaded(true)
        }
    }, [profile.avatarId])
    // let redirectTo = {
    //     pathname: routes.PROFILE_PUBLIC.replace(':id', profile.id),
    // }
    // if (loggedInProfile.id) {
    //     redirectTo = {
    //         pathname: routes.PROFILE_PUBLIC.replace(':id', profile.id),
    //     }
    // }

    let toUrl

    if (loggedInProfile.id) {
        toUrl = routes.PROFILE_PUBLIC.replace(':id', profile.id)
    } else {
        console.log('nani')
    }

    useEffect(() => {
        if (loggedInProfile?.following_profile_ids) {
            if (loggedInProfile.following_profile_ids.includes(profile.id)) {
                setFollowing(true)
            }
        }
    }, [loggedInProfile, profile.id])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox,
            }}
        >
            <Card className={classes.root}>
                <CardActionArea
                    onClick={handleClose}
                    component={Link}
                    to={{ pathname: toUrl }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box className={classes.shareProfile}>
                            <ShareProfile
                                publicProfileId={profile.id}
                                publicProfile={profile}
                            />
                        </Box>
                        {avatarLoaded && (
                            <Avatar
                                className={classes.avatar}
                                src={avatarUrl}
                            />
                        )}
                        {!avatarLoaded && (
                            <Skeleton
                                variant="circle"
                                className={classes.avatarSkeleton}
                                width={80}
                                height={80}
                            />
                        )}

                        {profile.likeCount > 0 && (
                            <Typography
                                variant="h6"
                                className={classes.likes}
                                gutterBottom
                            >
                                <FaHeart
                                    className={classes.heart}
                                    color="#bf281d"
                                />{' '}
                                {profile.likeCount}
                            </Typography>
                        )}

                        <Typography
                            className={classes.authorName}
                            variant="h6"
                            gutterBottom
                        >
                            {profile.displayName}
                        </Typography>

                        {profile.newWriting && (
                            <Typography
                                variant="body2"
                                className={classes.newWriting}
                                gutterBottom
                            >
                                <FaPen
                                    className={classes.star}
                                    color="#009c12"
                                />{' '}
                                New Writing
                            </Typography>
                        )}
                        {/* <Typography variant="body2" className={classes.location} gutterBottom>
              <FaMapMarked className={classes.map} color="#666" /> Boston, MA
            </Typography> */}
                        {profile.pronouns && (
                            <Typography
                                variant="body2"
                                className={classes.pronouns}
                                gutterBottom
                            >
                                <FaRegCircle
                                    className={classes.user}
                                    color="#666"
                                />{' '}
                                {profile.pronouns}
                            </Typography>
                        )}
                        {/* <Typography variant="body2" className={classes.location} gutterBottom>
              <FaMapMarked className={classes.map} color="#666" /> Boston, MA
            </Typography> */}
                        {profile.tags && (
                            <Grid className={classes.tags}>
                                {/* <Typography className={classes.writes} variant="body2">
                writes
              </Typography> */}
                                <Chip
                                    size="small"
                                    className={classes.chip}
                                    variant="outlined"
                                    label={'Sci-Fi'}
                                />
                                <Chip
                                    size="small"
                                    className={classes.chip}
                                    variant="outlined"
                                    label={'Fantasy'}
                                />
                            </Grid>
                        )}
                        {/* Richard pair 1*/}
                        {!loggedInProfile.id && (
                            <Button
                                startIcon={<FaUserPlus />}
                                className={classes.follow}
                                to={{
                                    pathname: routes.SIGNUP,
                                }}
                                variant="outlined"
                            >
                                Join to Follow!
                            </Button>
                        )}

                        {loggedInProfile.id &&
                            profile.id !== loggedInProfile.id && (
                                <>
                                    {!following && (
                                        <Button
                                            startIcon={<FaUserPlus />}
                                            className={classes.follow}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                dispatch(followProfile(profile))
                                                setFollowing(true)
                                            }}
                                            variant="outlined"
                                        >
                                            Follow
                                        </Button>
                                    )}
                                    {following && (
                                        <Button
                                            startIcon={<FaUserCheck />}
                                            className={classes.follow}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                dispatch(
                                                    unFollowProfile(profile)
                                                )
                                                setFollowing(false)
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Following
                                        </Button>
                                    )}
                                </>
                            )}
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default AuthorCard
