import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaEdit, FaFacebook } from 'react-icons/fa'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
    loginFacebook,
    loginGoogle,
    saveProject,
    saveProjectComplete,
} from '../../actions'
import { projectGetComplete } from '../../actions/write'
import * as routes from '../../constants/routes'
import ListProjects from './ListProjects'
import Join from '../layout/Join'

const FeedTabProject = (props) => {
    //richard
    const [disabled, setDisabled] = useState(false)
    const [newlyCreated, setNewlyCreated] = useState(false)
    const classes = useStyles()

    const profile = useSelector((state) => state.profile.profile)
    const dispatch = useDispatch()
    const { projectSubmitted, project } = props
    const FacebookButton = withStyles({
        label: {
            color: '#1877F2',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#1877F2',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#F6fafe',
                borderColor: '#0062cc',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#0062cc',
                borderColor: '#005cbf',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
    })(Button)
    const GoogleButton = withStyles({
        label: {
            color: '#444',
        },
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#fff',
            borderColor: '#444',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#fafafa',
                borderColor: '#444',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#fafafa',
                borderColor: '#444',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.1rem #fafafa',
            },
        },
    })(Button)

    useEffect(() => {
        dispatch(projectGetComplete())
    }, [dispatch])

    if (projectSubmitted) {
        dispatch(saveProjectComplete())
        return <Redirect to={'/write/view/' + project.id} />
    } else {
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                classes={{
                    root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                }}
            >
                <Typography variant="h6" className={classes.projectMessage}>
                    Start your own story
                </Typography>
                {!profile.id && <Join showLogo />}

                {profile.id && (
                    <>
                        <Button
                            disabled={disabled}
                            variant="outlined"
                            color="primary"
                            className={classes.startProject}
                            onClick={(e) => {
                                setDisabled(true)
                                console.log(project.id)
                                dispatch(saveProject('New Project'))
                                // setNewlyCreated(true)
                            }}
                            // component={Link}
                            // to={'/write/view/' + project.ref.id}
                            startIcon={<FaEdit />}
                            id="main-start-project"
                        >
                            Start Project
                        </Button>

                        <ListProjects newlyCreated={newlyCreated} />
                    </>
                )}
            </Grid>
        )
    }
}

const useStyles = makeStyles((theme) => ({
    projectEditor: {
        marginTop: 20,
        minHeight: 200,
        height: 200,
        backgroundColor: 'white',
    },
    editingTitle: {
        marginTop: 20,
        width: '100%',

        backgroundColor: 'white',
    },
    editingAction: {
        marginLeft: 5,
        marginRight: 5,
    },
    loginMessage: {
        textAlign: 'center',
        fontSize: '14pt',
        marginBottom: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    messageLink: {
        color: '#512da8',
    },
    createAccountButton: {
        marginTop: theme.spacing(1),
    },
    loginButton: {
        marginTop: theme.spacing(1),
    },
    projectMessage: {
        fontWeight: 'normal',
    },
    signupMessage: {
        color: '#666',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    startProject: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    googleButton: {
        marginTop: theme.spacing(1),
    },
}))
function mapStateToProps(state) {
    return {
        isWriteSubmitting: state.write.isLoggingIn,
        writeSubmitError: state.write.loginError,
        projectSubmitted: state.write.projectSubmitted,
        project: state.write.project,
    }
}
export default connect(mapStateToProps)(FeedTabProject)
