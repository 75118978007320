import { myFirebase, db } from '../firebase/firebase'
import { clearProfile } from './profile'
import firebase from 'firebase/app'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST,
    }
}

const requestSignUp = () => {
    return {
        type: SIGNUP_REQUEST,
    }
}

const receiveLogin = (user, isAdmin) => {
    return {
        type: LOGIN_SUCCESS,
        user,
        isAdmin,
    }
}

const receiveSignUp = (user) => {
    return {
        type: SIGNUP_SUCCESS,
        user,
    }
}

const loginError = (error) => {
    return {
        type: LOGIN_FAILURE,
        error,
    }
}

const signUpError = (error) => {
    return {
        type: SIGNUP_FAILURE,
        error,
    }
}

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST,
    }
}

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
}

const logoutError = () => {
    return {
        type: LOGOUT_FAILURE,
    }
}

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST,
    }
}

const verifySuccess = () => {
    return {
        type: VERIFY_SUCCESS,
    }
}

export const loginFacebook = () => (dispatch) => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider()

    // console.log('start of log in');
    dispatch(requestLogin())
    myFirebase
        .auth()
        .signInWithPopup(facebookProvider)
        .then((result) => {
            var user = result.user
            console.log(user)
            let profilesRef = db.collection('profiles')
            // console.log('loginUser check');
            profilesRef.where('uid', '==', user.id).onSnapshot(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let isAdmin = profileData.isAdmin
                            ? profileData.isAdmin
                            : false
                        dispatch(receiveLogin(user, isAdmin))
                    })
                },
                () => {
                    // console.log('error checking user admin status');
                }
            ) // ...
        })
        .catch((error) => {
            console.log(error)
            dispatch(loginError(error))
        })
}
export const loginGoogle = () => (dispatch) => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()

    // console.log('start of log in');
    dispatch(requestLogin())
    myFirebase
        .auth()
        .signInWithPopup(googleProvider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // The signed-in user info.
            var user = result.user
            console.log(user)
            let profilesRef = db.collection('profiles')
            // console.log('loginUser check');
            profilesRef.where('uid', '==', user.id).onSnapshot(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let isAdmin = profileData.isAdmin
                            ? profileData.isAdmin
                            : false
                        dispatch(receiveLogin(user, isAdmin))
                    })
                },
                () => {
                    // console.log('error checking user admin status');
                }
            ) // ...
        })
        .catch((error) => {
            console.log(error)
            dispatch(loginError(error))
        })
}

export const loginUser = (email, password) => (dispatch) => {
    // console.log('start of log in');
    dispatch(requestLogin())
    myFirebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
            // console.log('checking user in auth');
            // console.log(user);
            let profilesRef = db.collection('profiles')
            // console.log('loginUser check');
            profilesRef.where('uid', '==', user.id).onSnapshot(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let isAdmin = profileData.isAdmin
                            ? profileData.isAdmin
                            : false
                        dispatch(receiveLogin(user, isAdmin))
                    })
                },
                () => {
                    // console.log('error checking user admin status');
                }
            )
        })
        .catch((error) => {
            // console.log('i errord');
            // console.log(error);
            dispatch(loginError(error))
        })
}

export const signUpEmailPassword = (email, password) => (dispatch) => {
    dispatch(requestSignUp())
    myFirebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
            dispatch(receiveSignUp(user))
        })
        .catch((error) => {
            dispatch(signUpError(error))
        })
}

export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout())
    dispatch(clearProfile())
    myFirebase
        .auth()
        .signOut()
        .then(() => {
            dispatch(receiveLogout())
        })
        .catch((error) => {
            dispatch(logoutError())
        })
}

export const verifyAuth = () => (dispatch) => {
    dispatch(verifyRequest())
    myFirebase.auth().onAuthStateChanged((user) => {
        let profilesRef = db.collection('profiles')
        if (user !== null) {
            // console.log('logged in');

            profilesRef.where('uid', '==', user.uid).onSnapshot(
                (snapshot) => {
                    snapshot.forEach((doc) => {
                        const profileData = doc.data()
                        let isAdmin = profileData.isAdmin
                            ? profileData.isAdmin
                            : false
                        // console.log('yoyoyo');
                        dispatch(receiveLogin(user, isAdmin))
                        dispatch(verifySuccess())
                    })
                },
                () => {
                    // console.log('error checking user admin status');
                }
            )
        } else {
            // console.log('not logged in');
            dispatch(verifySuccess())
        }
    })
}
