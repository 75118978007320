import React, { useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Divider, Typography, Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { unflagPublished } from '../../actions/admin'
import Confirm from '../layout/Confirm'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        padding: theme.spacing(2),
    },
    message: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    unflag: {
        marginTop: theme.spacing(2),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}))
function Removed() {
    const classes = useStyles()
    const loggedInProfile = useSelector((state) => state.profile.profile)
    const published = useSelector((state) => state.write.published)
    const [confirmUnflag, setConfirmUnflag] = useState(false)
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.mainBox}
        >
            {confirmUnflag && (
                <Confirm
                    confirm={confirmUnflag}
                    setConfirm={setConfirmUnflag}
                    message={'Are you sure you want to unflag this post?'}
                    action={'Unflag'}
                    callback={() => {
                        unflagPublished(published.id)
                        setTimeout(() => {
                            window.location.reload()
                        }, 500)
                    }}
                />
            )}

            <FaExclamationTriangle size="4em" color="#666" />
            <Typography className={classes.message} variant="h6">
                This post has been removed for violating the Istoria Community
                Guidelines and/or{' '}
                <a class="alink" href="/terms">
                    Terms of Use
                </a>
                . <br /> <br />
                For more information contact:
                <br />{' '}
                <a className="alink" href="mailto:support@istoria.io">
                    support@istoria.io
                </a>
                .
            </Typography>
            {loggedInProfile.isAdmin && (
                <>
                    <Divider className={classes.divider} />
                    <Typography
                        style={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                    >
                        admin
                    </Typography>

                    <Button
                        variant="outlined"
                        startIcon={<FaExclamationTriangle />}
                        onClick={() => {
                            setConfirmUnflag(true)
                        }}
                        className={classes.unflag}
                    >
                        Unflag
                    </Button>
                </>
            )}
        </Grid>
    )
}

export default Removed
