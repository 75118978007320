import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatRelative } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteComment } from '../../actions/write'
import { deleteDiscussionComment } from '../../actions/community'
import ProfileAvatar from '../profile/ProfileAvatar'
import Confirm from '../layout/Confirm'

function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return formatRelative(d, new Date())
}

const useStyles = makeStyles((theme) => ({
    commentBox: {
        textAlign: 'left',
        marginTop: theme.spacing(1),
        color: '#666',
    },
    icon: {
        position: 'relative',
        top: 2,
    },
    commentInput: {
        width: '100%',
        maxWidth: theme.breakpoints.values.sm,
    },
    commentSubmit: {
        marginTop: theme.spacing(1),
    },
    commentDelete: {},
    commentMessage: {
        marginBottom: theme.spacing(1),
    },
    commentText: {
        fontSize: '14pt',
        color: 'black',
        display: 'block',
        marginLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxWidth: theme.breakpoints.values.sm,
    },
    commentTextBox: {
        marginBottom: theme.spacing(2),
    },
    commentPublished: {
        marginTop: theme.spacing(1),
        color: '#666',
        display: 'inline-block',
        marginLeft: theme.spacing(1),
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
    },
    authorContainer: {
        marginBottom: theme.spacing(1),
    },

    avatarLarge: {
        height: 180,
        width: 180,

        backgroundColor: 'grey',
        border: '4px solid #512da8',
    },
    avatarMedium: {
        height: 64,
        width: 64,
        backgroundColor: '#512da8',
        border: '2px solid #512da8',
    },
    avatarSmall: {
        height: 30,
        width: 30,
        backgroundColor: '#512da8',
        border: '2px solid #512da8',
    },

    authorName: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        display: 'inline-block',
    },
    authorLink: {
        color: '#512da8',
        textDecoration: 'none',
    },
}))

function Comment({ projectId, sectionId, discussionId, communityId, comment }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [confirmDelete, setConfirmDelete] = useState(false)

    const profile = useSelector((state) => state.profile.profile)
    return (
        <Box className={classes.commentBox}>
            <Confirm
                confirm={confirmDelete}
                setConfirm={setConfirmDelete}
                message={
                    "Are you sure you want to delete your comment? This action can't be undone."
                }
                action={'Delete'}
                callback={() => {
                    if (discussionId) {
                        console.log(comment.id)
                        console.log(discussionId)
                        console.log(communityId)
                        dispatch(
                            deleteDiscussionComment(
                                comment.id,
                                discussionId,
                                communityId
                            )
                        )
                    } else {
                        dispatch(
                            deleteComment(projectId, sectionId, comment.id)
                        )
                    }
                }}
            />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.authorContainer}
            >
                <ProfileAvatar
                    size="small"
                    load
                    avatarId={comment.profile.avatarId}
                />
                <Box className={classes.commentTextBox}>
                    <Link
                        className={classes.authorLink}
                        to={'/pr/' + comment.profile.id}
                    >
                        <Typography className={classes.authorName}>
                            {comment.profile.displayName
                                ? comment.profile.displayName.length > 0
                                    ? comment.profile.displayName
                                    : comment.profile.email
                                : comment.profile.email}
                        </Typography>
                    </Link>
                    <Typography
                        variant="body"
                        className={classes.commentPublished}
                    >
                        {formatDate(comment.commented_on)}
                    </Typography>

                    <Typography variant="body" className={classes.commentText}>
                        {comment.text}
                    </Typography>
                    {profile.id === comment.profile.id && (
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.commentDelete}
                            onClick={() => {
                                setConfirmDelete(true)
                            }}
                        >
                            Delete Comment
                        </Button>
                    )}
                </Box>
            </Grid>

            <Box></Box>
        </Box>
    )
}

export default Comment
