import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { FaCheck, FaChevronLeft, FaPenSquare, FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { editProject } from '../../actions/write'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    selected: {
        color: '#512da8',
        fontWeight: 'bold',
    },
    sectionHeading: {
        paddingLeft: 3,
    },
    sidebarItemActions: {},

    viewTitle: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
        display: 'inline-block',
    },
    titleEdit: {
        fontSize: '1.5em',
        [theme.breakpoints.down('sm')]: {
            width: 180,
        },
    },
    titleEditButton: {
        marginTop: -3,
    },
    backButton: {
        marginTop: -3,
    },
    backButtonEditing: {
        marginTop: -5,
    },

    backContainer: {
        paddingLeft: 0,
        marginLeft: 0,
    },
    back: {
        marginTop: theme.spacing(1),
        marginLeft: 0,
        paddingLeft: 0,
        marginBottom: 0,
    },
}))

function ProjectTitle() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const project = useSelector((state) => state.write.project)

    const [editing, setEditing] = useState(false)
    const [title, setTitle] = useState(project.title)

    if (editing) {
        return (
            <Typography
                id="project-title"
                className={classes.viewTitle}
                variant="h5"
            >
                {/* <IconButton
          aria-label="back"
          component={Link}
          to={{
            pathname: routes.HOME,
          }}
        >
          <FaChevronLeft className={classes.backButtonEditing}
            color="#512da8" />
        </IconButton> */}
                <TextField
                    className={classes.titleEdit}
                    variant="outlined"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            dispatch(editProject(project.id, { title: title }))
                            setEditing(false)
                            e.preventDefault()
                        }
                    }}
                />
                <IconButton
                    onClick={() => {
                        dispatch(editProject(project.id, { title: title }))
                        setEditing(false)
                    }}
                >
                    <FaCheck />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setEditing(false)
                    }}
                >
                    <FaTimes />
                </IconButton>
            </Typography>
        )
    } else {
        return (
            <>
                <Box className={classes.backContainer}>
                    <Button
                        component={Link}
                        className={classes.back}
                        to={{
                            pathname: routes.HOME,
                            state: { tab: 'write' },
                        }}
                    >
                        <FaChevronLeft /> &nbsp; Back to Workbench
                    </Button>
                </Box>

                <Typography
                    id="project-title"
                    className={classes.viewTitle}
                    variant="h5"
                    onMouseEnter={() => {}}
                >
                    {/* <IconButton
          aria-label="back"
          className={classes.backButton}
          component={Link}
          to={{
            pathname: routes.HOME,
          }}
        >
          <FaChevronLeft color="#512da8" />
        </IconButton> */}
                    {project.title}
                    <IconButton
                        onClick={() => {
                            setEditing(true)
                        }}
                        aria-label="edit"
                        className={classes.titleEditButton}
                    >
                        <FaPenSquare size="1em" color="#ccc" />
                    </IconButton>
                </Typography>
            </>
        )
    }
}

export default ProjectTitle
