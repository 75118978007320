import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import EditReview from './EditReview'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mainBox: {
        padding: 10,
        maxWidth: 1200,
        margin: 'auto',
    },
    headingBox: {
        textAlign: 'center',
    },
    formBox: {
        display: 'flex',
        width: '100%',
    },
    reviewComment: {
        width: '100%',
    },
    writingBody: {
        textAlign: 'left',
    },
}))

function ViewReview(props) {
    const { id } = useParams()
    const classes = useStyles()

    return <EditReview />
}

export default ViewReview
