import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatRelative } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return formatRelative(d, new Date())
}

const useStyles = makeStyles((theme) => ({
    mainBox: {
        padding: theme.spacing(2),
    },
    message: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
    },
}))

function NotFound({ published }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.mainBox}
        >
            <Typography variant="h4">Not Found</Typography>
            <Typography className={classes.message} variant="h6">
                Please confirm the URL is correct and try again.
            </Typography>
        </Grid>
    )
}

export default NotFound
