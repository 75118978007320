import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { format, formatDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
    FaCalendar,
    FaClock,
    FaEye,
    FaHeart,
    FaPen,
    FaQuoteLeft,
    FaRegHeart,
    FaUserCheck,
    FaUserPlus,
    FaChevronLeft,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import smartquotes from 'smartquotes'
import { getAvatarUrls, likePublished } from '../../actions'
import { followProfile, unFollowProfile } from '../../actions/profile'
import {
    getFeaturedUrls,
    getFromAssociation,
    getProject,
    getPublished,
    listProjectComments,
    incrementViewCount,
} from '../../actions/write'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'
import RawHTML from '../etc/RawHTML'
import ScrollToTop from '../layout/ScrollToTop'
import ProfileAvatar from '../profile/ProfileAvatar'
import SharePublished from '../publish/SharePublished'
import PublishedComments from './PublishedComments'
import NotFound from './NotFound'
import PublishedContent from './PublishedContent'
import Removed from './Removed'

const LikeChip = withStyles({
    root: {
        color: '#bd1e13',
        borderColor: '#bd1e13',
        paddingLeft: 2,
        backgroundColor: 'white',
    },
})(Chip)
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },

    mainBox: {
        maxWidth: theme.breakpoints.values.md,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    content: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    avatar: {
        marginRight: 8,
        height: 30,
        width: 30,
        display: 'inline-block',
        position: 'relative',
        bottom: 2,
    },
    avatarSkeleton: {
        position: 'relative',
        marginRight: 8,
        display: 'inline-block',
        height: 30,
        width: 30,
    },
    title: {
        fontWeight: 'bold',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    authorName: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
        bottom: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#512da8',
    },
    authorNameDefault: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
        bottom: 6,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#512da8',
    },
    authorText: {},
    authorBox: {},

    progressBox: {
        width: '100%',
        textAlign: 'center',
    },
    spinner: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}))

function Published({ joined, version }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    let { vid, pid, cid } = useParams()

    const loggedInProfile = useSelector((state) => state.profile.profile)
    const publishedReady = useSelector(
        (state) => state.write.publishedGettingSuccess
    )
    const published = useSelector((state) => state.write.published)
    const publishedGettingFailure = useSelector(
        (state) => state.write.publishedGettingFailure
    )

    const [ready, setReady] = useState(false)
    const [following, setFollowing] = useState(false)
    const [viaCommunity, setViaCommunity] = useState(false)
    const [featuredUrl, setFeaturedUrl] = useState('')

    useEffect(() => {
        setReady(false)

        if (vid) {
            //console.log('getting published ' + vid)
            dispatch(getPublished(vid))
        } else {
            let cleanPid = pid.substring(pid.length - 7)

            if (cid) {
                dispatch(getFromAssociation(cleanPid, cid))
                //console.log('via community ' + cid)
                setViaCommunity(true)
            } else {
                dispatch(getFromAssociation(cleanPid))
            }

            //console.log('search published')
            //console.log(cleanPid)
        }
    }, [])

    useEffect(() => {
        if (published?.uid) {
            console.log(
                'getting comments for project ' +
                    published.projectId +
                    ' section ' +
                    published.sectionId
            )

            smartquotes().listen()
            if (published.featuredId) {
                setReady(false)
                getFeaturedUrls(published.featuredId).then((urls) => {
                    //console.log(urls)
                    setFeaturedUrl(urls.featuredLarge)
                    setReady(true)
                })
            } else {
                setReady(true)
            }

            dispatch(incrementViewCount(published.id))
        }
        //console.log(published)
    }, [dispatch, published])

    useEffect(() => {
        if (publishedReady) {
            if (published) {
                if (published.projectId) {
                    dispatch(getProject(published.projectId))
                } else if (published.publishedData) {
                    dispatch(getProject(published.publishedData.projectId))
                }
            }
            //console.log(published)
        }
    }, [dispatch, publishedReady])

    return (
        <Grid
            className={classes.mainBox}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            {published?.title && (
                <Helmet>
                    <title>{published.title} - istoria</title>
                </Helmet>
            )}
            <ScrollToTop />

            {publishedGettingFailure && <NotFound />}
            {ready && !published?.removed && (
                <PublishedContent viaCommunity={viaCommunity} />
            )}
            {ready && published?.removed && <Removed />}

            {!ready && !publishedGettingFailure && (
                <Container className={classes.progressBox}>
                    <CircularProgress className={classes.spinner} />
                </Container>
            )}
        </Grid>
    )
}

export default Published
