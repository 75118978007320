import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { shuffler } from '../../actions/shuffle'
import PinnedItem from '../communities/PinnedItem'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    configItem: {
        marginBottom: 20,
    },
    profileInput: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    publishedBox: {},
    follow: {
        marginTop: theme.spacing(1),
    },
    profileBio: {
        color: '#666',
    },
    profileLoading: {
        textAlign: 'center',
    },
    editProfile: {
        marginTop: theme.spacing(1),
    },
    istoriaInline: {
        display: 'inline-block',
    },
    exploreMessage: {
        marginBottom: theme.spacing(2),
        fontWeight: 'normal',
    },
}))

function AdminPinnedStory(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { pinnedList } = props

    let d = new Date()
    let currentDay = d.getDate()

    return (
        <Container className={classes.publishedBox}>
            <Box>
                {pinnedList.length === 0 && (
                    <Container className={classes.nothingMessage}>
                        <Typography variant="body2">
                            Theres nothing here yet.
                        </Typography>
                    </Container>
                )}
                {shuffler(pinnedList, currentDay).map((published) => (
                    <Box>
                        <PinnedItem published={published}></PinnedItem>
                    </Box>
                ))}
            </Box>
        </Container>
    )
}

export default AdminPinnedStory
