import {
    Box,
    Button,
    CircularProgress,
    Container,
    InputAdornment,
    TextField,
    Typography,
    Grid,
    Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaUsers, FaChevronLeft, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { listCommunities } from '../../actions'
import * as routes from '../../constants/routes'
import CommunityCard from '../communities/CommunityCard'
import NewCommunity from './NewCommunity'
import {
    getSearchResults,
    listRecommendedProfiles,
} from '../../actions/profile'
import { debounce } from '../../constants/debounce'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    heading: {
        color: '#666',
        fontSize: '1.25em',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
    },
    text: {
        color: '#666',
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.md,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(1),
    },
    cardGrid: {
        maxWidth: 750,
    },
    emphasis: {
        color: '#512da8',
    },
    searchInput: {
        backgroundColor: 'white',
        maxWidth: theme.breakpoints.values.sm,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    spinner: {
        margin: theme.spacing(2),
    },
    searchInputLarge: {
        width: 500,
        backgroundColor: 'white',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    createButton: {
        marginBottom: theme.spacing(1),
    },
    istoriaInline: {
        display: 'inline-block',
    },
}))

function CommunityAll() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const history = useHistory()

    const cancel = () => {
        setCreating(false)
    }

    const [listReady, setListReady] = useState(false)
    const [publishedItemList, setPublishedItemList] = useState([])
    const communityList = useSelector((state) => state.community.communityList)

    const profileSearchResults = useSelector(
        (state) => state.profile.profileSearchResults
    )
    const recommendsGetting = useSelector(
        (state) => state.profile.recommendsGetting
    )
    const [recommends, setRecommends] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchActive, setSearchActive] = useState(false)
    const [searchText, setSearchText] = useState('false')
    const communityListSuccess = useSelector(
        (state) => state.community.communityListSuccess
    )

    const renderedItems = publishedItemList.map((item) => (
        <CommunityCard
            name={item.name}
            description={item.description}
            comId={item.id}
            featuredId={item.featuredId}
            memberCount={item.member_count}
            community={item}
            backFrom="all"
        />
    ))

    const filteredItems = publishedItemList
        .filter((c) => searchResults.map((s) => s.firestoreId).includes(c.id))
        .map((item) => (
            <CommunityCard
                name={item.name}
                description={item.description}
                comId={item.id}
                featuredId={item.featuredId}
                memberCount={item.member_count}
                community={item}
                backFrom="all"
            />
        ))
    useEffect(() => {
        console.log('listing communities')
        dispatch(listCommunities())
    }, [dispatch])

    useEffect(() => {
        console.log(profileSearchResults)
        if (profileSearchResults.hits) {
            setSearchResults(profileSearchResults.hits)
        }
    }, [profileSearchResults])
    useEffect(() => {
        if (communityListSuccess) {
            setTimeout(() => {
                console.log(communityList)
                setPublishedItemList(communityList)

                setListReady(true)
            }, 400)
        }
    }, [communityListSuccess, communityList])
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className={classes.mainBox}
            >
                <Box className={classes.backActions}>
                    <Button
                        className={classes.back}
                        component={Link}
                        startIcon={<FaChevronLeft />}
                        to={{
                            pathname: routes.HOME,
                            state: { tab: 'connect' },
                        }}
                    >
                        Back
                    </Button>
                </Box>
            </Grid>
            <Typography className={classes.circleMessage} variant="h6">
                Find an{' '}
                <Typography
                    style={{
                        fontSize: '1.25rem',
                        color: '#512da8',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.istoriaInline}
                >
                    istoria circle
                </Typography>
            </Typography>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault()
                    console.log('submit')
                    console.log(e.target.value)
                    dispatch(getSearchResults(searchText, 'profiles'))
                }}
            >
                <Hidden mdUp>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch color="#ccc" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            console.log(e.target.value)
                            setSearchText(e.target.value)
                            if (e.target.value.length > 0) {
                                setSearchActive(true)
                                debounce(
                                    dispatch(
                                        getSearchResults(
                                            e.target.value,
                                            'profiles'
                                        )
                                    ),
                                    3000
                                )
                            } else {
                                setSearchActive(false)
                            }
                        }}
                        placeholder="Search Istoria Circles"
                        className={classes.searchInput}
                        variant="outlined"
                        id="standard-search"
                        type="search"
                    />
                </Hidden>
                <Hidden smDown>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaSearch color="#ccc" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            console.log(e.target.value)
                            setSearchText(e.target.value)
                            if (e.target.value.length > 0) {
                                setSearchActive(true)
                                debounce(
                                    dispatch(
                                        getSearchResults(
                                            e.target.value,
                                            'community'
                                        )
                                    ),
                                    3000
                                )
                            } else {
                                setSearchActive(false)
                            }
                        }}
                        placeholder="Search Istoria Circles"
                        className={classes.searchInputLarge}
                        variant="outlined"
                        id="standard-search"
                        type="search"
                    />
                </Hidden>
            </form>
            <Button
                startIcon={<FaUsers />}
                variant="outlined"
                color="primary"
                className={classes.createButton}
                component={Link}
                to={{
                    pathname: routes.COMMUNITY_NEW,
                }}
            >
                Start a Circle
            </Button>
            {searchActive && (
                <Typography variant="h6">
                    {searchResults.length} Results
                </Typography>
            )}

            {!searchActive && communityListSuccess && (
                <Grid
                    container
                    className={classes.cardGrid}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {renderedItems}
                </Grid>
            )}
            {!searchActive && !communityListSuccess && (
                <CircularProgress className={classes.spinner} />
            )}
            {searchActive && (
                <Grid
                    container
                    className={classes.cardGrid}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {filteredItems}
                </Grid>
            )}
        </Grid>
    )
}

export default CommunityAll
