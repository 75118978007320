import {
    Box,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import {
    FaCheck,
    FaEllipsisV,
    FaICursor,
    FaLayerGroup,
    FaPlusCircle,
    FaRegArrowAltCircleDown,
    FaRegArrowAltCircleUp,
    FaRegCopy,
    FaRegFileAlt,
    FaRegStickyNote,
    FaRegTimesCircle,
    FaTimes,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarNote, setSidebarPosition } from '../../actions/ui'
import {
    addNote,
    addSection,
    copySection,
    deleteNote,
    deleteSection,
    getNotes,
    getSections,
    moveDownNote,
    moveDownSection,
    moveUpNote,
    moveUpSection,
    renameNote,
    renameSection,
} from '../../actions/write'
import NewSection from './NewSection'
import SidebarItem from './SidebarItem'

const useStyles = makeStyles((theme) => ({
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    selected: {
        color: '#512da8',
        fontWeight: 'bold',
    },
    sectionHeading: {
        paddingLeft: 3,
    },
    sectionList: {
        padding: 0,
    },
    divider: {
        color: 'black',
    },
    sidebarItemActions: {
        backgroundColor: 'blue',
    },
    selectNavigator: {},
    formControl: {
        width: '100%',
    },
    addingText: {
        width: '100%',
        backgroundColor: 'white',
    },
    renamingText: {
        backgroundColor: 'white',
        width: '100%',
    },
    addingContainer: {
        marginTop: theme.spacing(2),
    },
    itemIcon: {},
    menuIcon: {
        marginRight: theme.spacing(1),
        color: '#A9A9A9',
        position: 'relative',
        top: 1,
    },
    menuHeaderIcon: {
        marginRight: theme.spacing(1),
        color: '#A9A9A9',
        position: 'relative',
        top: 1,
    },

    sidebarIcon: {
        position: 'relative',
        top: 2,
        marginRight: theme.spacing(2),
        color: '#A9A9A9',
    },
}))

function ProjectSidebar() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const project = useSelector((state) => state.write.project)
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorCon, setAnchorCon] = useState(null)

    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const current = useSelector((state) => state.ui.sidebarPosition)
    const [adding, setAdding] = useState(false)
    const [name, setName] = useState('')

    const sections = useSelector((state) => state.write.sections)
    const notes = useSelector((state) => state.write.notes)

    const [selectedDrop, setSelectedDrop] = useState(
        JSON.stringify({ position: -1, note: false })
    )

    const [renaming, setRenaming] = useState(false)

    const isDefault = false
    const hasNotes = notes.length > 0

    let currentSection
    if (noteSelected) {
        currentSection = project.notes.filter((n) => n.position === current)[0]
    } else {
        currentSection = sections.filter((s) => s.position === current)[0]
    }

    const saveMobile = (sectionName, body, plainBody) => {
        console.log('saving mobile')
        if (adding === 'section') {
            console.log(sectionName)
            console.log(project.notePosition + 1)

            if (isDefault) {
                console.log('renaming default ' + sectionName)
                dispatch(
                    renameSection(
                        project.id,
                        currentSection.id,
                        sectionName,
                        true
                    )
                )
            } else {
                dispatch(
                    addSection(
                        sectionName,
                        project.sectionPosition + 1,
                        body,
                        plainBody,
                        project.id
                    )
                )
            }
        } else {
            console.log(sectionName)
            dispatch(
                addNote(
                    sectionName,
                    project.notePosition + 1,
                    body,
                    plainBody,
                    project.id
                )
            )
        }

        dispatch(setSidebarPosition(-1))
        dispatch(setSidebarNote(false))
    }
    useEffect(() => {
        dispatch(getSections(project.id))
        dispatch(getNotes(project.id))
    }, [dispatch, project.id])

    // useEffect(() => {
    //   console.log(sectionsSnap)
    //   console.log(notesSnap)
    //   if (sectionsSnap)
    //     setSections(sectionsSnap)
    //   if (notesSnap)
    //     setNotes(notesSnap)
    // }, [sectionsSnap, notesSnap]);

    useEffect(() => {
        setSelectedDrop(
            JSON.stringify({ position: current, note: noteSelected })
        )
    }, [noteSelected, current])
    return (
        <Box id="project-navigator">
            <Hidden mdUp>
                {!renaming && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid container item xs={10}>
                            {(!isDefault || (isDefault && hasNotes)) && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel id="nav-label">
                                        {current === -1
                                            ? 'Project'
                                            : noteSelected
                                            ? 'Note'
                                            : 'Writing'}
                                    </InputLabel>

                                    <Select
                                        className={classes.selectNavigator}
                                        labelId="nav-label"
                                        label={
                                            current === -1
                                                ? 'Project'
                                                : noteSelected
                                                ? 'Note'
                                                : 'Writing'
                                        }
                                        value={selectedDrop}
                                        width={1}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            let decoded = JSON.parse(
                                                e.target.value
                                            )
                                            dispatch(
                                                setSidebarPosition(
                                                    decoded.position
                                                )
                                            )
                                            dispatch(
                                                setSidebarNote(decoded.note)
                                            )
                                            setSelectedDrop(e.target.value)
                                            // if (stale)
                                            //   dispatch(getWriting(writing.id))
                                        }}
                                    >
                                        <MenuItem
                                            value={JSON.stringify({
                                                position: -1,
                                                note: false,
                                            })}
                                        >
                                            <FaLayerGroup
                                                className={
                                                    classes.menuHeaderIcon
                                                }
                                            />
                                            <b>{project.title}</b>
                                        </MenuItem>

                                        <MenuItem disabled>Writing</MenuItem>
                                        {sections.map((section) => (
                                            <MenuItem
                                                value={JSON.stringify({
                                                    position: section.position,
                                                    note: false,
                                                })}
                                                key={section.id}
                                            >
                                                <FaRegFileAlt
                                                    className={classes.menuIcon}
                                                />{' '}
                                                {section.title}
                                            </MenuItem>
                                        ))}
                                        <MenuItem disabled>Notes</MenuItem>
                                        {notes.map((note) => (
                                            <MenuItem
                                                value={JSON.stringify({
                                                    position: note.position,
                                                    note: true,
                                                })}
                                                key={note.id}
                                            >
                                                <FaRegStickyNote
                                                    className={classes.menuIcon}
                                                />{' '}
                                                {note.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            {isDefault && !hasNotes && (
                                <>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="nav-label">
                                            {current === -1
                                                ? 'Project'
                                                : noteSelected
                                                ? 'Note'
                                                : 'Writing'}
                                        </InputLabel>

                                        <Select
                                            className={classes.selectNavigator}
                                            labelId="nav-label"
                                            disabled
                                            label={'Project'}
                                            value={-1}
                                            width={1}
                                        >
                                            <MenuItem value={-1}>
                                                <b>{project.title}</b>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}
                            >
                                <FaPlusCircle />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                disabled={
                                    current === -1 || (isDefault && !hasNotes)
                                }
                                onClick={(e) => {
                                    setAnchorCon(e.currentTarget)
                                }}
                            >
                                <FaEllipsisV />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                        setAnchorEl(null)
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            setAdding('section')
                        }}
                    >
                        <FaRegFileAlt /> &nbsp; Add Writing
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            setAdding('note')
                        }}
                    >
                        <FaRegStickyNote /> &nbsp; Add Note
                    </MenuItem>
                </Menu>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorCon}
                    keepMounted
                    open={Boolean(anchorCon)}
                    onClose={() => {
                        setAnchorCon(null)
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setRenaming(true)
                            setName(currentSection.title)
                            setAnchorCon(null)
                        }}
                    >
                        <FaICursor /> &nbsp; Rename
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            dispatch(
                                copySection(
                                    project.id,
                                    currentSection.sectionId
                                )
                            )
                            dispatch(setSidebarPosition(-1))
                            setAnchorCon(null)
                        }}
                    >
                        <FaRegCopy /> &nbsp; Copy
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            if (noteSelected) {
                                dispatch(
                                    deleteNote(
                                        project.id,
                                        currentSection.noteId
                                    )
                                )
                            } else {
                                if (sections.length === 1) {
                                    dispatch(
                                        deleteSection(
                                            project.id,
                                            currentSection.sectionId
                                        )
                                    )
                                    console.log('creating new default section')
                                    dispatch(
                                        addSection(
                                            'Untitled writing',
                                            0,
                                            '',
                                            '',
                                            project.id,
                                            true
                                        )
                                    )
                                } else {
                                    dispatch(
                                        deleteSection(
                                            project.id,
                                            currentSection.sectionId
                                        )
                                    )
                                }
                            }
                            dispatch(setSidebarPosition(-1))
                            dispatch(setSidebarNote(false))

                            setAnchorCon(null)
                        }}
                    >
                        <FaRegTimesCircle /> &nbsp; Delete
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            if (noteSelected) {
                                dispatch(moveUpNote(project.id, currentSection))
                            } else {
                                dispatch(
                                    moveUpSection(project.id, currentSection)
                                )
                            }
                            dispatch(setSidebarPosition(-1))
                            dispatch(setSidebarNote(false))
                            setAnchorCon(null)
                        }}
                    >
                        <FaRegArrowAltCircleUp /> &nbsp; Move Up
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            if (noteSelected) {
                                dispatch(
                                    moveDownNote(project.id, currentSection)
                                )
                            } else {
                                dispatch(
                                    moveDownSection(project.id, currentSection)
                                )
                            }
                            dispatch(setSidebarPosition(-1))
                            dispatch(setSidebarNote(false))
                            setAnchorCon(null)
                        }}
                    >
                        <FaRegArrowAltCircleDown /> &nbsp; Move Down
                    </MenuItem>
                </Menu>

                {adding && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.addingContainer}
                    >
                        <Grid container item xs={10}>
                            <TextField
                                variant="outlined"
                                label="Title"
                                className={classes.addingText}
                                autoFocus
                                inputRef={(input) => input && input.focus()}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        console.log('saving')
                                        if (name !== '') {
                                            saveMobile(name, '', '')
                                            setAdding(false)
                                        }
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                className={classes.saveButton}
                                aria-label="save"
                                onClick={() => {
                                    console.log('saving')
                                    if (name !== '') {
                                        saveMobile(name, '', '')
                                        setAdding(false)
                                    }
                                }}
                            >
                                <FaCheck />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                className={classes.cancelButton}
                                aria-label="cancel"
                                onClick={() => {
                                    console.log('cancel')
                                    setAdding(false)
                                }}
                            >
                                <FaTimes />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                {renaming && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.renamingContainer}
                    >
                        <Grid container item xs={10}>
                            <TextField
                                variant="outlined"
                                label={
                                    noteSelected
                                        ? 'Note Title'
                                        : 'Writing Title'
                                }
                                className={classes.renamingText}
                                value={name}
                                autoFocus
                                inputRef={(input) => input && input.focus()}
                                onChange={(e) => {
                                    setName(e.target.value)
                                    console.log(name)
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        console.log('saving')
                                        if (name !== '') {
                                            if (noteSelected) {
                                                dispatch(
                                                    renameNote(
                                                        project.id,
                                                        currentSection.noteId,
                                                        name
                                                    )
                                                )
                                            } else {
                                                dispatch(
                                                    renameSection(
                                                        project.id,
                                                        currentSection.sectionId,
                                                        name
                                                    )
                                                )
                                            }
                                            setRenaming(false)
                                        }
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                className={classes.saveButton}
                                aria-label="save"
                                onClick={() => {
                                    console.log('saving')
                                    if (name !== '') {
                                        if (noteSelected) {
                                            dispatch(
                                                renameNote(
                                                    project.id,
                                                    currentSection.noteId,
                                                    name
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                renameSection(
                                                    project.id,
                                                    currentSection.sectionId,
                                                    name
                                                )
                                            )
                                        }
                                        setRenaming(false)
                                    }
                                }}
                            >
                                <FaCheck />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                className={classes.cancelButton}
                                aria-label="cancel"
                                onClick={() => {
                                    console.log('cancel')
                                    setRenaming(false)
                                }}
                            >
                                <FaTimes />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </Hidden>

            <Hidden smDown>
                <ListItem
                    button
                    className={classes.sectionHeading}
                    id="project-nav-title"
                    selected={current === -1}
                    onClick={() => {
                        dispatch(setSidebarPosition(-1))
                        dispatch(setSidebarNote(false))
                    }}
                >
                    {/* <ListItemIcon className={classes.itemIcon} ><FaLayerGroup /></ListItemIcon> */}
                    {/* <ListItemText primary={writing.title} /> */}
                    <ListItemText>
                        <b>
                            <FaLayerGroup className={classes.sidebarIcon} />
                            {project.title}
                        </b>
                    </ListItemText>
                </ListItem>

                <List className={classes.sectionList} component="nav">
                    {sections.map((section, index) => (
                        <React.Fragment key={section.sectionId}>
                            <SidebarItem
                                section={section}
                                note={false}
                                first={index === 0}
                            />
                        </React.Fragment>
                    ))}
                </List>

                <NewSection
                    isDefault={false}
                    renameCallback={(newTitle) => {
                        console.log('renaming default ' + newTitle)
                        dispatch(
                            renameSection(
                                project.id,
                                currentSection.id,
                                newTitle,
                                true
                            )
                        )
                    }}
                    saveCallback={(sectionName, body, plainBody) => {
                        console.log(sectionName)
                        console.log(project.notePosition + 1)
                        // setSections(
                        //   sections.concat({ title: sectionName, body: '', bodyText: '' })
                        // );
                        dispatch(
                            addSection(
                                sectionName,
                                project.sectionPosition + 1,
                                body,
                                plainBody,
                                project.id
                            )
                        )
                    }}
                />
                <Divider className={classes.divider} />

                <List className={classes.sectionList} component="nav">
                    {notes.map((note) => (
                        <React.Fragment key={note.noteId}>
                            <SidebarItem section={false} note={note} />
                        </React.Fragment>
                    ))}
                </List>

                <NewSection
                    note
                    saveCallback={(noteName, body, plainBody) => {
                        console.log(noteName)
                        // setNotes(notes.concat({ title: noteName, body: '', bodyText: '' }));
                        dispatch(
                            addNote(
                                noteName,
                                project.notePosition + 1,
                                body,
                                plainBody,
                                project.id
                            )
                        )
                    }}
                />
            </Hidden>
        </Box>
    )
}

export default ProjectSidebar
