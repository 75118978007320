import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ReactDiffViewer from 'react-diff-viewer'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mainBox: {
        padding: 10,
        textAlign: 'center',
    },
    headingBox: {
        textAlign: 'center',
    },
    formBox: {
        display: 'flex',
        width: '100%',
    },
    reviewComment: {
        width: '100%',
    },
    writingBody: {
        textAlign: 'left',
    },
    feedbackHeading: {
        paddingBottom: 10,
    },
}))

function ViewFeedback(props) {
    const classes = useStyles()
    return (
        <Container class={classes.mainBox}>
            <Typography className={classes.feedbackHeading} variant="h5">
                Viewing feedback from <i>walterbship@gmail.com</i>
            </Typography>
            <Container>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.reviewButton}
                >
                    Accept
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.reviewButton}
                >
                    Ignore
                </Button>
            </Container>
            <ReactDiffViewer
                oldValue={'this is \n a robot.'}
                newValue={'This is a robot'}
                splitView={true}
            />
        </Container>
    )
}

export default ViewFeedback
