import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ComingSoon from '../../feed/ComingSoon'

const useStyles = makeStyles((theme) => ({
    getFeedback: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    sendButton: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },

    cancelButton: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
}))

function FeedbackTab() {
    return <ComingSoon label="Feedback" />
}

export default FeedbackTab
