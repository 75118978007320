import { Button, Container, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FaComments, FaInstagram } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    footerBox: {
        borderTop: '1px solid #dbd9d9',
        backgroundColor: '#fafafa',
        position: 'absolute',
        width: '100%',
        margin: 0,
        textAlign: 'center',
        color: 'white',
        padding: 20,
        paddingTop: 0,
        display: 'inline-flex',
        alignItems: 'center',

        justifyContent: 'center',

        flexDirection: 'row',
    },
    footerBoxHome: {
        borderTop: '1px solid #dbd9d9',
        backgroundColor: '#fafafa',
        position: 'absolute',
        width: '100%',
        margin: 0,
        textAlign: 'center',
        color: 'white',
        padding: 20,
        paddingTop: 0,
        display: 'inline-flex',
        alignItems: 'center',

        justifyContent: 'center',

        flexDirection: 'row',
    },
    istoriaLogo: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(1),
        textAlign: 'center',
        paddingLeft: 0,
        color: '#512da8',
        paddingRight: 0,
    },
    istoriaInsta: {
        maxWidth: '300px',
    },
    footerLink: {
        color: '#512da8',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textDecoration: 'none',
        fontWeight: 500,
    },
    feedbackBar: {
        marginTop: theme.spacing(3),
    },
    footerItem: {
        marginTop: 4,
        color: 'black',
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}))

function Footer({ location, isAuthenticated }) {
    const classes = useStyles()
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.feedbackBar}
            >
                <Button
                    color="primary"
                    onClick={() => {
                        window.location.href =
                            'https://us10.list-manage.com/survey?u=06f1159470a750996c9433243&id=12df1aa5c9'
                    }}
                    component={Link}
                    startIcon={<FaComments color="#512da8" />}
                    id="footer-feedback"
                >
                    {' '}
                    Feedback
                </Button>
            </Grid>
            <Container
                maxWidth={false}
                className={
                    location.pathname === '/' && !isAuthenticated
                        ? classes.footerBoxHome
                        : classes.footerBox
                }
            >
                <Container className={classes.istoriaLogo} maxWidth="sm">
                    <Typography
                        style={{
                            fontSize: 28,
                            color: '#512da8',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                    >
                        istoria
                    </Typography>
                    &copy; 2021
                    <Divider className={classes.divider} />
                    {/* Richard */}
                    <Container>
                        <span title="Follow us on Instagram!">
                            <Button
                                color="primary"
                                onClick={() => {
                                    window.location.href =
                                        'https://www.instagram.com/istoria.io/'
                                }}
                                component={Link}
                                // Richard
                                startIcon={<FaInstagram size={30} />}
                                className="istoriaInsta"
                            >
                                {' '}
                                Follow us on Instagram!
                            </Button>
                        </span>
                    </Container>
                    <Divider className={classes.divider} />
                    <Container className={classes.footerItem} maxWidth={false}>
                        <Link className={classes.footerLink} to="/about">
                            About
                        </Link>
                        <Link className={classes.footerLink} to="/privacy">
                            Privacy
                        </Link>
                        <Link className={classes.footerLink} to="/terms">
                            Terms
                        </Link>
                        <Link className={classes.footerLink} to="/newsletter">
                            Newsletter
                        </Link>
                    </Container>
                </Container>
            </Container>
        </>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        profile: state.profile.profile,
    }
}

export default connect(mapStateToProps)(withRouter(Footer))
