import { db } from '../firebase/firebase'
export const GET_PINNED_REQUEST = 'GET_PINNED_REQUEST'

const listPinnedStory = (pinPublishedStory) => {
    return {
        type: GET_PINNED_REQUEST,
        pinnedList: pinPublishedStory,
    }
}

const getDocStoryId = (shortUrl) => {
    return new Promise((resolve) => {
        db.collection('published')
            .where('shortUrl', '==', shortUrl)
            .get()
            .then(function (querySnapshot) {
                const arr = []

                querySnapshot.docs.map((doc) => {
                    arr.push({ id: doc.id, ...doc.data() })
                })

                resolve(arr[0])
            })
    })
}
export const flagPublished = (publishedId) => {
    db.collection('published')
        .doc(publishedId)
        .update({ removed: true, removed_on: new Date().toISOString() })
        .then(() => {
            console.log('removed post')
        })
}
export const unflagPublished = (publishedId) => {
    db.collection('published')
        .doc(publishedId)
        .update({ removed: false })
        .then(() => {
            console.log('removed post')
        })
}
// update flag isPin: true to pin story
export const pinPublishedStory = (shortUrl) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        const docData = await getDocStoryId(shortUrl) // get doc id to update isPin flag
        const { id } = docData
        db.collection('published')
            .doc(id)
            .update({
                isPin: true,
            })
            .then(async (docRef) => {
                resolve(true)
            })
            .catch(function (error) {
                console.error('Error writing Value: ', error)
            })
    })
}

// update flag isPin: false to unpin story
export const unpinPublishedStory = (shortUrl) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        const docData = await getDocStoryId(shortUrl) // get doc id to update isPin flag
        const { id } = docData
        db.collection('published')
            .doc(id)
            .update({
                isPin: false,
            })
            .then(async (docRef) => {
                resolve(true)
            })
            .catch(function (error) {
                console.error('Error writing Value: ', error)
            })
    })
}

// set SnapshotListener that callback when value change on firestore
export const getPinPublishedStory = () => (dispatch) => {
    const query = db.collection('published').where('isPin', '==', true)
    query.onSnapshot(
        async (docSnapshot) => {
            const arr = []
            docSnapshot.docs.map((doc) =>
                arr.push({ id: doc.id, ...doc.data() })
            )
            dispatch(listPinnedStory(arr))
        },
        (err) => {
            console.log(`Encountered error: ${err}`)
        }
    )
}
