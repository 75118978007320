import { Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PublishCommunity from '../../communities/PublishCommunity'
import PublishDirect from '../../publish/PublishDirect'

const useStyles = makeStyles((theme) => ({
    preview: {
        marginTop: 20,
    },
    previewMessage: {
        marginTop: 20,
        textAlign: 'center',
        color: '#666',
    },
    publishBox: {
        maxWidth: theme.breakpoints.values.sm,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(2),
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}))
function PublishTab() {
    const classes = useStyles()
    const project = useSelector((state) => state.write.project)
    const [sections, setSections] = useState([])

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.publishBox}
            >
                <PublishDirect
                    setSections={setSections}
                    projectId={project.id}
                />
                <Divider className={classes.divider} />

                <PublishCommunity sections={sections} />
            </Grid>
        </>
    )
}

export default PublishTab
