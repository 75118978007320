import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ListComments from './ListComments'
import NewComment from './NewComment'
import Join from '../layout/Join'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    commentBox: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}))

function PublishedComments({
    projectId,
    sectionId,
    communityId,
    published,
    discussionId,
}) {
    const profile = useSelector((state) => state.profile.profile)
    const classes = useStyles()

    useEffect(() => {
        console.log(discussionId)
    }, [discussionId])
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.commentBox}
            >
                <NewComment
                    projectId={projectId}
                    sectionId={sectionId}
                    communityId={communityId}
                    discussionId={discussionId}
                />
                <ListComments
                    projectId={projectId}
                    sectionId={sectionId}
                    published={published}
                    communityId={communityId}
                    discussionId={discussionId}
                />
            </Grid>
            {/* {!profile?.id && <Join showDivider />} */}
        </>
    )
}

export default PublishedComments
