import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FaRegFileAlt, FaRegStickyNote } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarNote, setSidebarPosition } from '../../actions'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    corkCard: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
        },

        margin: 5,
    },
    divider: {
        color: 'black',
        marginTop: 8,
    },
    corkHeading: {
        color: '#666',
        marginLeft: 5,
        marginTop: theme.spacing(2),
    },
    cardExcerpt: {
        color: '#666',
        height: 100,
    },
    cardContent: {
        height: 120,
    },
    corkHeadingIcon: {
        position: 'relative',
        top: 2,
    },
}))

function CorkBoard({ isDefault }) {
    const dispatch = useDispatch()
    const project = useSelector((state) => state.write.project)

    const sections = useSelector((state) => state.write.sections)
    const notes = useSelector((state) => state.write.notes)

    const classes = useStyles()

    return (
        <Box id="project-corkboard">
            {project.sections.length > 0 && (
                <>
                    <Typography
                        variant="subtitle2"
                        className={classes.corkHeading}
                    >
                        <FaRegFileAlt className={classes.corkHeadingIcon} />{' '}
                        Writing
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {sections.map((section, index) => (
                            <React.Fragment key={section.sectionId}>
                                <Card
                                    className={classes.corkCard}
                                    id="project-corkboard-first"
                                    key={section.id}
                                >
                                    <CardActionArea
                                        onClick={() => {
                                            dispatch(
                                                setSidebarPosition(
                                                    section.position
                                                )
                                            )
                                            dispatch(setSidebarNote(false))
                                        }}
                                    >
                                        <CardContent
                                            className={classes.cardContent}
                                        >
                                            <Typography
                                                className={classes.title}
                                                gutterBottom
                                            >
                                                {section.title}
                                            </Typography>
                                            {section.bodyText && (
                                                <Typography
                                                    className={
                                                        classes.cardExcerpt
                                                    }
                                                    variant="body2"
                                                >
                                                    {section.bodyText.slice(
                                                        0,
                                                        60
                                                    )}
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </React.Fragment>
                        ))}
                    </Grid>
                </>
            )}
            {notes.length > 0 && (
                <>
                    <Typography
                        variant="subtitle2"
                        className={classes.corkHeading}
                    >
                        <FaRegStickyNote className={classes.corkHeadingIcon} />{' '}
                        Notes
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {notes.map((note) => (
                            <React.Fragment key={note.noteId}>
                                <Card
                                    className={classes.corkCard}
                                    key={note.id}
                                >
                                    <CardActionArea
                                        onClick={() => {
                                            dispatch(
                                                setSidebarPosition(
                                                    note.position
                                                )
                                            )
                                            dispatch(setSidebarNote(true))
                                        }}
                                    >
                                        <CardContent
                                            className={classes.cardContent}
                                        >
                                            <Typography
                                                className={classes.title}
                                                gutterBottom
                                            >
                                                {note.title}
                                            </Typography>
                                            {note.bodyText && (
                                                <Typography
                                                    className={
                                                        classes.cardExcerpt
                                                    }
                                                    variant="body2"
                                                >
                                                    {note.bodyText.slice(0, 60)}
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </React.Fragment>
                        ))}
                    </Grid>
                </>
            )}
        </Box>
    )
}

export default CorkBoard
