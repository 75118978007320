import { Box, Chip, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaExclamation } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    chipSaving: {
        paddingLeft: 2,
        position: 'relative',
        ight: '10px',
        backgroundColor: 'white',
        color: '#512da8',
        avatarColorPrimary: '#512da8',
        avatarColorSecondary: '#512da8',
    },
    chipError: {},
    chipSaved: {
        backgroundColor: 'white',
        color: '#512da8',
        avatarColorPrimary: '#512da8',
        avatarColorSecondary: '#512da8',
    },
    statusBox: {
        position: 'relative',
        bottom: '-50px',
        right: '1px',
    },
    check: {
        fontSize: '10pt',
    },
})

function SaveStatus({ className }) {
    var time = new Date()

    const classes = useStyles()
    const updating = useSelector((state) => state.write.projectUpdating)
    const updateSuccess = useSelector(
        (state) => state.write.projectUpdateSuccess
    )
    const updateFailure = useSelector(
        (state) => state.write.projectUpdateFailure
    )

    const [savedTime, setSavedTime] = useState(
        time.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        })
    )
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)

    const _updateTime = () => {
        setSavedTime(
            time.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
        )
    }
    const _startSpin = () => {
        _updateTime()
        setSaving(true)
    }
    const _stopSpin = () => {
        setTimeout(() => {
            setSaving(false)
        }, 250)
    }

    useEffect(() => {
        if (updating) {
            _startSpin()
        } else {
            _stopSpin()
        }

        setError(updateFailure)
    }, [updating, updateSuccess, updateFailure])

    return (
        <Box className={classes.statusBox}>
            {error ? (
                <Chip
                    size="small"
                    className={classes.chipError}
                    color="secondary"
                    icon={<FaExclamation />}
                    label="Not saved"
                />
            ) : (
                <>
                    {saving ? (
                        <Chip
                            className={classes.chipSaving}
                            size="small"
                            icon={
                                <CircularProgress
                                    className={classes.savingSpinner}
                                    size={'1.2em'}
                                />
                            }
                            label="Saving"
                        />
                    ) : (
                        <Chip
                            className={classes.chipSaved}
                            size="small"
                            label={'Saved ' + savedTime}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

export default SaveStatus
