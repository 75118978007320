import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaArrowCircleRight, FaRegArrowAltCircleLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    getUserProfile,
    //Richard//
    setUserDate,
    setUserDidWelcome,
    setUserDisplayBio,
    setUserDisplayName,
    setUserInsta,
    setUserPronouns,
} from '../../actions'
import * as routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    continue: {
        marginTop: '20px',
    },
    back: {
        marginTop: '20px',
    },
    finish: {
        marginLeft: theme.spacing(1),
        marginTop: '20px',
    },
    logo: {
        maxWidth: 300,
        margin: 20,
        marginTop: 0,
        marginBottom: 60,
    },
    resetButton: {
        marginTop: theme.spacing(1),
    },
    welcomeMessage: {
        marginTop: theme.spacing(1),
    },
    displayNameMessage: {
        color: '#666',
        textAlign: 'center',
        margin: theme.spacing(2),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
    },
    input: {},

    bio: {
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 260,
    },
    pronouns: {
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 260,
    },
    insta: {
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 260,
    },
    display: {
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 260,
    },
    required: {
        marginTop: theme.spacing(1),
        fontSize: 12,
        color: 'red',
        fontWeight: 'bold',
        textAlign: 'center',
        // fontStyle: 'italic',
    },
    optional: {
        marginTop: theme.spacing(1),
        fontSize: 12,
        color: '#666',
        fontWeight: 'bold',
        textAlign: 'center',
        // fontStyle: 'italic',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 260,
    },
    formControlDiv: {
        fontSize: 12,
    },
    formControlColor: {
        color: '#512da8',
    },
}))

function Welcome(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const profile = useSelector((state) => state.profile.profile)

    const [page, setPage] = useState(0)
    const [agreeTos, setAgreeTos] = useState(false)
    const [profileDisplayName, setProfileDisplayName] = useState('')
    const [profileBio, setProfileBio] = useState('')
    const [profilePronouns, setProfilePronouns] = useState('')
    const [profileInsta, setProfileInsta] = useState('')

    useEffect(() => {
        dispatch(getUserProfile())
    }, [dispatch])

    useEffect(() => {
        console.log(profile)
        if (profile.displayName) {
            setProfileDisplayName(profile.displayName)
            setProfileBio(profile.bio)
            setProfilePronouns(profile.pronouns)
            setProfileInsta(profile.insta)
        }
    }, [profile])
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
            }}
        >
            <Typography
                style={{
                    fontSize: 28,
                    color: '#512da8',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto Slab',
                }}
                className={classes.istoriaLogo}
            >
                istoria
            </Typography>
            <Typography className={classes.welcomeMessage} variant="h6">
                Your account has been created!
            </Typography>

            {page === 0 && (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    classes={{
                        root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                    }}
                >
                    <Typography
                        className={classes.displayNameMessage}
                        variant="body2"
                    >
                        Add a display name to get started.
                    </Typography>
                    <TextField
                        className={classes.display}
                        label="Display Name"
                        required
                        value={profileDisplayName}
                        variant="outlined"
                        onChange={(e) => {
                            setProfileDisplayName(e.target.value)
                        }}
                    />

                    {/* <Typography className={classes.required} variant="body2">
          *Required
        </Typography> */}
                </Grid>
            )}
            {page === 1 && (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    classes={{
                        root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
                    }}
                >
                    <TextField
                        className={classes.bio}
                        label="Bio (optional)"
                        InputProps={{
                            className: classes.input,
                        }}
                        multiline
                        rows={6}
                        variant="outlined"
                        value={profileBio}
                        onChange={(e) => {
                            setProfileBio(e.target.value)
                        }}
                    />

                    <TextField
                        className={classes.pronouns}
                        label="Pronouns (optional)"
                        variant="outlined"
                        value={profilePronouns}
                        onChange={(e) => {
                            setProfilePronouns(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.insta}
                        //Richard
                        label="Instagram Username (optional)"
                        variant="outlined"
                        value={profileInsta}
                        onChange={(e) => {
                            setProfileInsta(
                                e.target.value.replace(/[^0-9a-zA-Z._]/g, '')
                            )
                        }}
                    />
                    <FormControl
                        required
                        component="fieldset"
                        className={classes.formControl}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeTos}
                                        color="primary"
                                        onChange={(e) => {
                                            setAgreeTos(e.target.checked)
                                        }}
                                        name="agreeTos"
                                    />
                                }
                                label={
                                    <div className={classes.formControlDiv}>
                                        <span>I agree to the </span>
                                        <Link
                                            className={classes.formControlColor}
                                            to={'/terms'}
                                        >
                                            Terms of Use
                                        </Link>
                                    </div>
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            )}

            {page === 0 && (
                <Button
                    color="primary"
                    required
                    variant="outlined"
                    classes={{ root: classes.continue }}
                    onClick={() => {
                        if (page === 0) {
                            dispatch(setUserDisplayName(profileDisplayName))
                            setPage(1)
                        }
                    }}
                    component={Link}
                    disabled={!profileDisplayName.length > 0}
                    endIcon={<FaArrowCircleRight />}
                >
                    Continue
                </Button>
            )}

            {page === 1 && (
                <Box>
                    <Button
                        color="primary"
                        required
                        variant="outlined"
                        classes={{ root: classes.back }}
                        onClick={() => {
                            if (page === 1) setPage(0)
                        }}
                        startIcon={<FaRegArrowAltCircleLeft />}
                    >
                        Back
                    </Button>

                    <Button
                        color="primary"
                        required
                        disabled={!agreeTos}
                        variant="outlined"
                        classes={{ root: classes.finish }}
                        onClick={() => {
                            if (page === 1) {
                                dispatch(setUserDidWelcome())
                                dispatch(setUserDate(new Date().getTime()))
                                if (profileBio?.length > 0)
                                    dispatch(setUserDisplayBio(profileBio))
                                if (profilePronouns?.length > 0)
                                    dispatch(setUserPronouns(profilePronouns))
                                if (profileInsta?.length > 0)
                                    dispatch(setUserInsta(profileInsta))

                                setPage(0)
                            }
                        }}
                        component={Link}
                        to={{
                            pathname: routes.HOME,
                            state: {
                                noWelcome: true,
                            },
                        }}
                        endIcon={<FaArrowCircleRight />}
                    >
                        Finish
                    </Button>
                </Box>
            )}
        </Grid>
    )
}

export default Welcome
