import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'

var firebaseConfig

firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
}
export const myFirebase = firebase.initializeApp(firebaseConfig)
export const db = myFirebase.firestore()

if (process.env.REACT_APP_ISTORIA_ENV == 'prod') {
    try {
        firebase.analytics()
    } catch (err) {
        console.log(err)
    }
}
let images_url = myFirebase.storage(process.env.REACT_APP_FB_IMAGES_URL)

export const images = images_url
export const storage = myFirebase.storage()
export const functions = myFirebase.functions()
export const increment = firebase.firestore.FieldValue.increment(1)
export const decrement = firebase.firestore.FieldValue.increment(-1)
