import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveComment } from '../../actions/write'
import { saveDiscussionComment } from '../../actions/community'
import AuthorBox from '../project/AuthorBox'
import SignUpModal from '../profile/SignUpModal'
import { FaComments } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    commentBox: {
        marginTop: theme.spacing(2),
        color: '#666',

        minWidth: 380,
        maxWidth: 600,
    },
    icon: {
        position: 'relative',
        top: 2,
    },
    commentInput: {
        width: '100%',
        maxWidth: theme.breakpoints.values.sm,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    commentSubmit: {
        marginTop: theme.spacing(2),
    },
    commentMessage: {
        marginBottom: theme.spacing(1),
    },
    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    privateMessage: {
        color: '#666',
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '30ch',
        },
    },
    authorContainer: {
        marginTop: theme.spacing(2),
    },
}))

function NewComment({ projectId, sectionId, communityId, discussionId }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const [displayStatus, setDisplayStatus] = useState('none')
    const titleText = (
        <Typography>
            <strong>
                Create an account to share your thoughts <br />
                on stories you have read.
            </strong>
        </Typography>
    )

    const published = useSelector((state) => state.write.published)
    const community = useSelector((state) => state.community.community)

    const profile = useSelector((state) => state.profile.profile)
    useEffect(() => {
        if (displayStatus === 'block') {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [displayStatus])
    return (
        <Box className={classes.commentBox} flexGrow={1}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.authorContainer}
            >
                <AuthorBox size="small" profile={profile} />
            </Grid>

            {!profile.id && (
                <Box>
                    <SignUpModal
                        displayStatus={displayStatus}
                        setDisplayStatus={setDisplayStatus}
                        titleText={titleText}
                    />
                    <TextField
                        label="Write a Comment"
                        multiline
                        rows={4}
                        value={text}
                        onClick={() => setDisplayStatus('block')}
                        className={classes.commentInput}
                        InputProps={{
                            className: classes.input,
                        }}
                        variant="outlined"
                    />
                </Box>
            )}
            {profile.id && (
                <TextField
                    label="Write a Comment"
                    multiline
                    rows={4}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className={classes.commentInput}
                    InputProps={{
                        className: classes.input,
                    }}
                    variant="outlined"
                />
            )}
            {communityId && (
                <Typography className={classes.privateMessage} variant="body">
                    Comments are visible only to members of{' '}
                    <b>
                        {discussionId
                            ? community.name
                            : published.community_name}
                    </b>
                </Typography>
            )}
            <Box>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={text.length === 0}
                    value={text}
                    className={classes.commentSubmit}
                    onClick={() => {
                        console.log(text)
                        console.log(published)
                        if (discussionId) {
                            console.log(discussionId)
                            dispatch(
                                saveDiscussionComment(
                                    discussionId,
                                    communityId,
                                    {
                                        profile: profile,
                                        text: text,
                                        authorUid: profile.uid,
                                    }
                                )
                            )
                        } else {
                            dispatch(
                                saveComment(projectId, sectionId, communityId, {
                                    profile: profile,
                                    text: text,
                                    authorUid: published.uid,
                                    published: published,
                                })
                            )
                        }
                        setText('')
                    }}
                >
                    Comment
                </Button>
            </Box>
        </Box>
    )
}

export default NewComment
