import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listCommunities, listUserCommunities } from '../../actions'
import * as routes from '../../constants/routes'
import CommunityCard from '../communities/CommunityCard'
import NewCommunity from './NewCommunity'

function compare(a, b) {
    let comparison = 0
    if (!a.joined) {
        comparison = 1
    } else if (a.joined) {
        comparison = -1
    }
    return comparison
}
function compareTitle(a, b) {
    let comparison = 0
    if (a.title[0] > b.title[0]) {
        comparison = 1
    } else if (a.title[0] < b.title[0]) {
        comparison = -1
    }
    return comparison
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.md - 20,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    formBox: {
        display: 'flex',
        width: '100%',
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    largeGrid: {
        marginTop: theme.spacing(2),
        paddingTop: 0,
    },
    reviewComment: {
        width: '100%',
    },
    writingBody: {
        textAlign: 'left',
    },
    headingLarge: {
        paddingBottom: theme.spacing(0),
    },
    headingSmall: {
        paddingBottom: theme.spacing(1),
    },
    smallList: {
        marginTop: theme.spacing(2),
    },
    createLarge: {
        marginBottom: theme.spacing(2),
    },
    sidebarItem: {
        paddingBottom: theme.spacing(1),
    },
    progressBox: {
        textAlign: 'center',
    },
    spinner: {
        margin: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    cardGrid: {},
    emphasis: {
        marginLeft: 3,
        color: '#512da8',
    },
    createButton: {
        marginBottom: theme.spacing(2),
    },
    viewMore: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function CommunityFeed({ profile }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)

    const cancel = () => {
        setCreating(false)
    }

    const [listReady, setListReady] = useState(false)
    const [publishedItemList, setPublishedItemList] = useState([])
    const communityList = useSelector((state) => state.community.communityList)

    const communityListSuccess = useSelector(
        (state) => state.community.communityListSuccess
    )

    const renderedItems = communityList
        ?.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
        .sort(compare)
        .map((item) => (
            <CommunityCard
                name={item.name}
                description={item.description}
                comId={item.id}
                featuredId={item.featuredId}
                memberCount={item.member_count}
                community={item}
                backFrom="connect"
            />
        ))

    // useEffect(() => {
    //   console.log('listing communities')
    //   dispatch(listCommunities())
    // }, [dispatch])

    useEffect(() => {
        if (profile) {
            console.log('listing user communities')
            dispatch(listUserCommunities(profile.id))
        }
    }, [dispatch, profile])

    useEffect(() => {
        if (communityListSuccess) {
            setTimeout(() => {
                console.log(communityList)
                setPublishedItemList(communityList)
                setListReady(true)
            }, 400)
        }
    }, [communityListSuccess, communityList])

    return (
        <Box>
            {listReady ? (
                <Container className={classes.mainBox}>
                    {!creating && communityList?.length > 0 && (
                        <Grid
                            container
                            className={classes.cardGrid}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {renderedItems}
                        </Grid>
                    )}
                    {creating && <NewCommunity cancel={cancel} />}
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            className={classes.viewMore}
                            component={Link}
                            to={routes.COMMUNITY_ALL}
                            variant="text"
                        >
                            View More
                        </Button>
                        <Button
                            startIcon={<FaUsers />}
                            variant="outlined"
                            color="primary"
                            className={classes.createButton}
                            component={Link}
                            to={{
                                pathname: routes.COMMUNITY_NEW,
                            }}
                        >
                            Start a Circle
                        </Button>
                    </Grid>
                </Container>
            ) : (
                <Container className={classes.progressBox}>
                    <CircularProgress className={classes.spinner} />
                </Container>
            )}
        </Box>
    )
}

export default CommunityFeed
