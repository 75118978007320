import {
  WRITE_TAB,
  WRITE_SIDEBAR_POSITION,
  WRITE_SIDEBAR_NOTE,
  HOME_TOUR_START,
  HOME_TOUR_END,
  PROJECT_TOUR_START,
  PROJECT_TOUR_END,
  TOUR_MENU_OPEN,
  TOUR_MENU_CLOSE,
  NEWSLETTER,
  FEATURED_LOAD,
  FEATURED_LOAD_SUCCESS,
  FEATURED_NEXT,
  FEATURED_PREVIOUS
} from '../actions/'

export default (
  state = {
    writeTab: 'edit',
    sidebarPosition: -1,
    sidebarNote: false,
    homeTouring: false,
    projectTouring: false,
    tourMenuOpen: false,
    newsletterPrompted: false,
    feauturedLoading: false,
    featuredLoadSuccess: false,
    featuredList: [],
    featuredCurrent: 0
  },
  action
) => {
  switch (action.type) {
    case WRITE_TAB:
      return {
        ...state,
        writeTab: action.tab,
      }
    case WRITE_SIDEBAR_POSITION:
      return {
        ...state,
        sidebarPosition: action.position,
      }
    case WRITE_SIDEBAR_NOTE:
      return {
        ...state,
        sidebarNote: action.note,
      }

    case HOME_TOUR_START:
      return {
        ...state,
        homeTouring: true,
      }

    case HOME_TOUR_END:
      return {
        ...state,
        homeTouring: false,
      }

    case PROJECT_TOUR_START:
      return {
        ...state,
        projectTouring: true,
      }

    case PROJECT_TOUR_END:
      return {
        ...state,
        projectTouring: false,
      }

    case TOUR_MENU_OPEN:
      return {
        ...state,
        tourMenuOpen: true,
      }

    case TOUR_MENU_CLOSE:
      return {
        ...state,
        tourMenuOpen: false,
      }
    case NEWSLETTER:
      return {
        ...state,
        newsletterPrompted: false,
      }
    case FEATURED_LOAD:
      console.log("featured loading")
      return {
        ...state,
        newsletterPrompted: false,
      }
    case FEATURED_NEXT:
      if (state.featuredCurrent < (state.featuredList.length - 1)) {
        return {
          ...state,
          featuredCurrent: state.featuredCurrent + 1,
        }
      }
    case FEATURED_PREVIOUS:
      if (state.featuredCurrent > 0) {
        return {
          ...state,
          featuredCurrent: state.featuredCurrent - 1,
        }
      }


    case FEATURED_LOAD_SUCCESS:
      console.log("featured load success")
      console.log(action.featuredList)
      return {
        ...state,
        featuredList: action.featuredList,
      }
    default:
      return state
  }
}
