import {
    PROJECT_REQUEST,
    PROJECT_SUCCESS,
    PROJECT_FAILURE,
    PROJECT_COMPLETE,
    COMMENTS_LIST,
    COMMENTS_LIST_SUCCESS,
    COMMENTS_LIST_FAILURE,
    PROJECT_LIST_REQUEST,
    PROJECT_LIST_SUCCESS,
    PROJECT_LIST_FAILURE,
    PROJECT_GET_REQUEST,
    PROJECT_GET_SUCCESS,
    PROJECT_GET_FAILURE,
    PROJECT_GET_COMPLETE,
    PROJECT_GET_CLEAR,
    PROJECT_UPDATE,
    PROJECT_UPDATE_SUCCESS,
    PROJECT_UPDATE_FAILURE,
    PROJECT_DELETE,
    PROJECT_DELETE_SUCCESS,
    PROJECT_DELETE_FAILURE,
    PROJECT_DELETE_CLEAR,
    PUBLISHED_GET_BEGIN,
    PUBLISHED_GET_SUCCESS,
    PUBLISHED_GET_FAILURE,
    PUBLISHED_GET_CLEAR,
    PUBLISHED_LIST,
    PUBLISHED_LIST_SUCCESS,
    PUBLISHED_LIST_FAILURE,
    SECTIONS_GET_SUCCESS,
    NOTES_GET_SUCCESS,
    UPLOAD_FEATURED,
    UPLOAD_FEATURED_SUCCESS,
    UPLOAD_FEATURED_FAILURE,
} from '../actions/'

export default (
    state = {
        isProjectSubmitting: false,
        projectSubmitError: false,
        projectSubmitted: false,
        isRequestingListProject: false,
        listProjectError: false,
        listProjectAvailable: false,
        isRequestingGetProject: false,
        getProjectError: false,
        getProjectAvailable: false,
        isRevRequestSubmitting: false,
        revReqSubmitted: false,
        revReqError: false,
        isReviewGetting: false,
        reviewAvailable: false,
        reviewError: false,
        project: {},
        projectsList: [],
        sections: [],
        notes: [],
        review: {},
        revRequest: {},
        projectUpdating: false,
        projectUpdateSuccess: false,
        projectUpdateFailure: false,
        projectDeleting: false,
        projectDeletingSuccess: false,
        projectDeletingFailure: false,
        published: {},
        publishedGetting: false,
        publishedGettingSuccess: false,
        publishedGettingFailure: false,
        publishedList: [],
        publishedListing: false,
        publishedListingSuccess: false,
        publishedListingFailure: false,
        comments: [],
        commentsListing: false,
        commentsListingSuccess: false,
        commentsListingFailure: false,
        featuredUploading: false,
        featuredUploadSuccess: false,
        featuredUploadFailure: false,
    },
    action
) => {
    // console.log(action.type)
    switch (action.type) {
        case COMMENTS_LIST:
            return {
                ...state,
                comments: [],
                commentsListing: true,
                commentsListingSuccess: false,
                commentsListingFailure: false,
            }

        case COMMENTS_LIST_SUCCESS:
            return {
                ...state,
                comments: action.comments,
                commentsListing: false,
                commentsListingSuccess: true,
                commentsListingFailure: false,
            }
        case COMMENTS_LIST_FAILURE:
            return {
                ...state,
                comments: [],
                commentsListing: false,
                commentsListingSuccess: false,
                commentsListingFailure: true,
            }
        case PROJECT_UPDATE:
            return {
                ...state,
                projectUpdating: true,
                projectUpdateSuccess: false,
                projectUpdateFailure: false,
            }

        case PROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                projectUpdating: false,
                projectUpdateSuccess: true,
                projectUpdateFailure: false,
            }
        case PROJECT_UPDATE_FAILURE:
            return {
                ...state,
                projectUpdating: false,
                projectUpdateSuccess: false,
                projectUpdateFailure: true,
            }
        case PUBLISHED_LIST:
            return {
                ...state,
                publishedListing: true,
                publishedListingSuccess: false,
                publishedListingFailure: false,
            }

        case PUBLISHED_LIST_SUCCESS:
            // console.log(action)
            return {
                ...state,
                publishedList: action.publishedList,
                publishedListing: false,
                publishedListingSuccess: true,
                publishedListingFailure: false,
            }
        case PUBLISHED_LIST_FAILURE:
            return {
                ...state,
                publishedListing: false,
                publishedListingSuccess: false,
                publishedListingFailure: true,
            }
        case PUBLISHED_GET_CLEAR:
            return {
                ...state,
                published: null,
                comments: [],
                publishedGetting: false,
                publishedGettingSuccess: false,
                publishedGettingFailure: false,
            }
        case PUBLISHED_GET_BEGIN:
            return {
                ...state,
                comments: [],
                publishedGetting: true,
                publishedGettingSuccess: false,
                publishedGettingFailure: false,
            }

        case PUBLISHED_GET_SUCCESS:
            return {
                ...state,
                published: action.published,
                publishedGetting: false,
                publishedGettingSuccess: true,
                publishedGettingFailure: false,
            }
        case PUBLISHED_GET_FAILURE:
            return {
                ...state,
                published: action.published,
                publishedGetting: false,
                publishedGettingSuccess: false,
                publishedGettingFailure: true,
            }

        case PROJECT_DELETE:
            return {
                ...state,
                projectDeleting: true,
                projectDeletingSuccess: false,
                projectDeletingFailure: false,
            }

        case PROJECT_DELETE_SUCCESS:
            return {
                ...state,
                projectDeleting: false,
                projectDeletingSuccess: true,
                projectDeletingFailure: false,
            }
        case PROJECT_DELETE_FAILURE:
            return {
                ...state,
                projectDeleting: false,
                projectDeletingSuccess: false,
                projectDeletingFailure: true,
            }

        case PROJECT_DELETE_CLEAR:
            return {
                ...state,
                projectDeleting: false,
                projectDeletingSuccess: false,
                projectDeletingFailure: false,
            }

        case PROJECT_REQUEST:
            return {
                ...state,
                isProjectSubmitting: true,
                projectSubmitted: false,
                projectSubmitError: false,
            }
        case PROJECT_SUCCESS:
            return {
                ...state,
                isProjectSubmitting: false,
                projectSubmitError: false,
                projectSubmitted: true,
                project: action.project,
            }
        case PROJECT_FAILURE:
            return {
                ...state,
                isProjectSubmitting: false,
                projectSubmitted: false,
                projectSubmitError: true,
            }

        case PROJECT_COMPLETE:
            return {
                ...state,
                isProjectSubmitting: false,
                projectSubmitError: false,
                projectSubmitted: false,
                project: {},
            }
        case PROJECT_LIST_REQUEST:
            return {
                ...state,
                isRequestingListProject: true,
                listProjectAvailable: false,
                listProjectError: false,
            }
        case PROJECT_LIST_SUCCESS:
            return {
                ...state,
                isRequestingListProject: false,
                listProjectAvailable: true,
                listProjectError: false,
                projectsList: action.projectsList,
            }
        case PROJECT_LIST_FAILURE:
            return {
                ...state,
                isRequestingListProject: false,
                listProjectAvailable: false,
                listProjectError: true,
            }
        case PROJECT_GET_REQUEST:
            return {
                ...state,
                isRequestingGetProject: true,
                getProjectAvailable: false,
                getProjectError: false,
            }
        case PROJECT_GET_SUCCESS:
            return {
                ...state,
                isRequestingGetProject: false,
                getProjectAvailable: true,
                getProjectError: false,
                project: action.project,
            }
        case PROJECT_GET_COMPLETE:
            return {
                ...state,
                isRequestingGetProject: false,
                getProjectAvailable: false,
                getProjectError: false,
            }
        case PROJECT_GET_CLEAR:
            return {
                ...state,
                isRequestingGetProject: false,
                getProjectAvailable: false,
                getProjectError: false,
                project: null,
            }
        case PROJECT_GET_FAILURE:
            return {
                ...state,
                isRequestingGetProject: false,
                getProjectAvailable: false,
                getProjectError: true,
            }
        case UPLOAD_FEATURED:
            return {
                ...state,
                featuredUploading: true,
                featuredUploadSuccess: false,
                featuredUploadFailure: false,
            }
        case UPLOAD_FEATURED_SUCCESS:
            return {
                ...state,
                featuredUploading: false,
                featuredUploadSuccess: true,
                featuredUploadFailure: false,
            }
        case UPLOAD_FEATURED_FAILURE:
            return {
                ...state,
                featuredUploading: false,
                featuredUploadSuccess: false,
                featuredUploadFailure: true,
            }
        case SECTIONS_GET_SUCCESS:
            return {
                ...state,
                sections: action.sections,
            }
        case NOTES_GET_SUCCESS:
            return {
                ...state,
                notes: action.notes,
            }

        default:
            return state
    }
}
