import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSearchResults,
  listRecommendedProfiles,
} from '../../actions/profile'
import { debounce } from '../../constants/debounce'
import CommunityFeed from '../communities/CommunityFeed'
import AuthorCard from '../profile/AuthorCard'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  mainBox: {
    maxWidth: theme.breakpoints.values.md,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  searchSpinner: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marignRight: 'auto',
  },
  searchInput: {
    backgroundColor: 'white',
    maxWidth: theme.breakpoints.values.sm,
    marginBottom: theme.spacing(2),
  },

  searchInputLarge: {
    width: 500,
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
  },
  formBox: {
    display: 'flex',
    width: '100%',
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  largeGrid: {
    marginTop: theme.spacing(2),
    paddingTop: 0,
  },
  reviewComment: {
    width: '100%',
  },
  writingBody: {
    textAlign: 'left',
  },
  headingLarge: {
    paddingBottom: theme.spacing(0),
  },
  headingSmall: {
    paddingBottom: theme.spacing(1),
  },
  smallList: {
    marginTop: theme.spacing(2),
  },
  createLarge: {
    marginBottom: theme.spacing(2),
  },
  sidebarItem: {
    paddingBottom: theme.spacing(1),
  },
  loginMessage: {
    textAlign: 'center',
    fontSize: '14pt',
    marginBottom: theme.spacing(1),
  },
  messageLink: {
    color: '#512da8',
  },

  divider: {
    borderTop: '1px solid #ccc',
    marginBottom: theme.spacing(2),
    width: '60px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  createAccountButton: {
    marginTop: theme.spacing(1),
  },
  loginButton: {
    marginTop: 10,
  },
  signupMessage: {
    color: '#666',
    marginTop: theme.spacing(1),
  },
  recommends: {
    maxWidth: theme.breakpoints.values.md,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  emphasis: {
    color: '#512da8',
  },
  istoriaInline: {
    display: 'inline-block',
  },
  connectMessage: {
    marginBottom: theme.spacing(2),
    fontWeight: 'normal',
  },
  circleMessage: {
    marginBottom: theme.spacing(2),
    fontWeight: 'normal',
  },
  searchResult: {
    display: 'block',
    marginTop: theme.spacing(1),
    color: 'black',
    fontSize: 20,
  },
  results: {
    marginBottom: theme.spacing(2),
  },
}))

function FeedTabConnect(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const profileSearchResults = useSelector(
    (state) => state.profile.profileSearchResults
  )

  const profileSearchResultsGetting = useSelector(
    (state) => state.profile.profileSearchResultsGetting
  )
  const profileRecommends = useSelector((state) => state.profile.recommends)
  const recommendsGetting = useSelector(
    (state) => state.profile.recommendsGetting
  )

  const [recommends, setRecommends] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchActive, setSearchActive] = useState(false)
  const [searchText, setSearchText] = useState('false')
  const profile = useSelector((state) => {
    if (state.profile.profile) {
      console.log(state.profile.profile)
    }
    return state.profile.profile
  })

  useEffect(() => {
    dispatch(listRecommendedProfiles())
  }, [dispatch])

  useEffect(() => {
    if (profileSearchResults.hits) {
      let filtered = profileSearchResults.hits.filter(
        (result) => result?.hideSearch != true
      )
      setSearchResults(filtered)
    }
  }, [profileSearchResults])

  useEffect(() => {
    if (profileRecommends.length > 0) {
      setRecommends(profileRecommends)
    }
  }, [profileRecommends])

  return (
    <>
      {profile?.id && (
        <>
          <Typography className={classes.circleMessage} variant="h6">
            Join an{' '}
            <Typography
              style={{
                fontSize: '1.25rem',
                color: '#512da8',
                fontWeight: 'bold',
                fontFamily: 'Roboto Slab',
              }}
              className={classes.istoriaInline}
            >
              istoria circle
            </Typography>{' '}
            for peer feedback and support
          </Typography>
          <CommunityFeed profile={profile} />
          <Divider className={classes.divider} />
        </>
      )}
      <Typography variant="h6" className={classes.connectMessage}>
        Discover the writers of{' '}
        <Typography
          style={{
            fontSize: '1.25rem',
            color: '#512da8',
            fontWeight: 'bold',
            fontFamily: 'Roboto Slab',
          }}
          className={classes.istoriaInline}
        >
          istoria
        </Typography>
        .
      </Typography>

      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(getSearchResults(searchText, 'profiles'))
        }}
      >
        <Hidden mdUp>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch color="#ccc" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchText(e.target.value)
              if (e.target.value.length > 0) {
                setSearchActive(true)
                debounce(
                  dispatch(
                    getSearchResults(
                      e.target.value,
                      'profiles'
                    )
                  ),
                  3000
                )
              } else {
                setSearchActive(false)
              }
            }}
            placeholder="Search authors"
            className={classes.searchInput}
            variant="outlined"
            id="standard-search"
            type="search"
          />
        </Hidden>
        <Hidden smDown>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch color="#ccc" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              console.log(e.target.value)
              setSearchText(e.target.value)
              if (e.target.value.length > 0) {
                setSearchActive(true)
                debounce(
                  dispatch(
                    getSearchResults(
                      e.target.value,
                      'profiles'
                    )
                  ),
                  3000
                )
              } else {
                setSearchActive(false)
              }
            }}
            placeholder="Search authors"
            className={classes.searchInputLarge}
            variant="outlined"
            id="standard-search"
            type="search"
          />
        </Hidden>
      </form>

      {searchActive && profileSearchResultsGetting && (
        <CircularProgress className={classes.searchSpinner} />
      )}
      {searchActive && !profileSearchResultsGetting && searchResults && (
        <Container className={classes.results}>
          {searchResults
            // .filter((profile) => profile.id !== loggedInProfile.id)
            // Richard pair 1
            // .slice(0, 5)
            .map((result) => (
              <Container key={result.id}>
                <a
                  className={classes.searchResult}
                  href={'/pr/' + result.firestoreId}
                >
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={result.displayName}
                  />
                </a>
              </Container>
            ))}
        </Container>
      )}

      {!searchActive && recommendsGetting && (
        <CircularProgress className={classes.searchSpinner} />
      )}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        y
        className={classes.recommends}
      >
        {recommends
          // .filter((profile) => profile.id !== loggedInProfile.id)
          // Richard pair 1
          // .slice(0, 5)
          .map((profile) => (
            <AuthorCard profile={profile} key={profile.id} />
          ))}
      </Grid>
    </>
  )
  // return <CommunityFeed />;
}

export default FeedTabConnect
