import slugify from 'slugify'

export const slugUrl = (title, shortUrl) => {
    return (
        slugify(title, {
            replacement: '-', // replace spaces with replacement character, defaults to `-`
            lower: true, // convert to lower case, defaults to `false`
            strict: true, // strip special characters except replacement, defaults to `false`
            locale: 'vi', // language code of the locale to use
            trim: true, // trim leading and trailing replacement chars, defaults to `true`
        }) +
        '-' +
        shortUrl
    )
}
