import {
  Badge,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { FaBookOpen, FaPencilAlt, FaUserFriends } from 'react-icons/fa'
import Joyride from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { didHomeTour, getAdminConfig } from '../../actions/profile'
import { endHomeTour, startHomeTour } from '../../actions/ui'
import { deleteProjectClear } from '../../actions/write'
import RawHTML from '../etc/RawHTML'
import FeedTabConnect from './FeedTabConnect'
import FeedTabExplore from './FeedTabExplore'
import FeedTabWriting from './FeedTabWriting'
import Banner from '../layout/Banner'
export const TextOnHoverTab = ({ text, icon, setCurrentTab }) => {
  const [hover, setHover] = useState(false)
  return (
    <Tab
      icon={hover ? text : icon}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      value={text}
      onClick={() => setCurrentTab()}
    />
  )
}

export const TransitionAlerts = ({ text }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          severity="info"
          variant="outlined"
          className={classes.alertContainer}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <div>
            <AlertTitle className={classes.alertText}>
              <RawHTML>{text}</RawHTML>
            </AlertTitle>
          </div>
        </Alert>
      </Collapse>
    </div>
  )
}

function MainFeed({ dotour }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const noWelcome = location.state?.noWelcome
  const pathName = location.pathname

  const homeTouring = useSelector((state) => state.ui.homeTouring)
  const configData = useSelector((state) => state.profile.configData)

  const profile = useSelector((state) => state.profile.profile)
  const defaultTab = 'read'
  const defaultTabIndex = 2
  const [doWelcome, setDoWelcome] = useState(false)
  const [tabValue, setTabValue] = useState(2)
  const [currentTab, setCurrentTab] = useState(defaultTab)
  const [showHeader, setShowHeader] = useState(false)
  const [headerText, setHeaderText] = useState('')

  useEffect(() => {
    if (configData?.show_header) {
      setShowHeader(configData.show_header)
    }
    if (configData?.header_text) {
      setHeaderText(configData.header_text)
    }
    console.log(configData)
  }, [configData])

  const steps = [
    {
      content: (
        <>
          <p className={classes.joyRideText}>
            Welcome to Istoria, a space for{' '}
            <strong>inspiring storytelling</strong>
          </p>
          <Typography className={classes.joyRideText}>
            <FaPencilAlt></FaPencilAlt> <strong>Write, </strong>
            organize, and publish your projects <br /> <br />
            <FaUserFriends></FaUserFriends>{' '}
            <strong>Connect </strong>
            with a writing community <br /> <br />
            <FaBookOpen></FaBookOpen> <strong>Read </strong>
            and celebrate stories you love <br /> <br />
          </Typography>
        </>
      ),
      placement: 'center',
      target: 'body',
    },
  ]

  useEffect(() => {
    dispatch(deleteProjectClear())
    dispatch(getAdminConfig())
  }, [])

  useEffect(() => {
    // console.log('logging location.state');
    // console.log(location.state);
    // console.log(tabValue);
    if (location.state) {
      if (location.state.tab === 'read') {
        setTabValue(2)
        setCurrentTab('read')
      }
      if (location.state.tab === 'connect') {
        setTabValue(1)
        setCurrentTab('connect')
      }
      if (location.state.tab === 'write') {
        setTabValue(0)
        setCurrentTab('write')
      }
    } else {
      // console.log('logging default tab');
      // console.log(defaultTab);
      setTabValue(defaultTabIndex)
      setCurrentTab(defaultTab)
    }
  }, [location])

  useEffect(() => {
    if (tabValue == 2) {
    }
    console.log(tabValue)
  }, [tabValue])
  useEffect(() => {
    // console.log(profile);
    if (Object.keys(profile).length > 0) {
      if (profile.didWelcome !== true) {
        if (!noWelcome) {
          // console.log('doing welcome');
          setDoWelcome(true)
        }
      }
      if (profile.didHomeTour !== true) {
        // console.log('first tour');
        dispatch(startHomeTour())
      }
    }
  }, [profile])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box className={classes.mainBox}>
      {/* <TransitionAlerts /> */}

      {showHeader && <Banner text={headerText} />}
      {/* <Alert>hello</Alert> */}

      {doWelcome && <Redirect to="/welcome" />}
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        className={classes.feedTabs}
        centered
      >
        <TextOnHoverTab
          id="main-write-tab"
          text="write"
          icon={<FaPencilAlt />}
          setCurrentTab={() => {
            history.replace(pathName, { tab: 'write' })
          }}
        />
        <TextOnHoverTab
          text="connect"
          icon={<FaUserFriends />}
          setCurrentTab={() => {
            history.replace(pathName, { tab: 'connect' })
          }}
        />
        <TextOnHoverTab
          text="read"
          icon={<FaBookOpen />}
          setCurrentTab={() => {
            history.replace(pathName, { tab: 'read' })
          }}
        />
      </Tabs>

      {homeTouring && (
        <Joyride
          steps={steps}
          continuous={true}
          run={homeTouring}
          showProgress={true}
          locale={{
            back: 'Back',
            close: 'Close',
            last: 'End',
            next: 'Next',
            skip: 'Skip',
          }}
          showSkipButton
          callback={(data) => {
            if (
              data.action === 'close' ||
              data.action === 'reset'
            ) {
              dispatch(endHomeTour())
              dispatch(didHomeTour())
              // console.log('exiting tour');
            }
          }}
          styles={{
            options: {
              backgroundColor: 'white',
              primaryColor: '#512da8',
              textColor: '#000',
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, .8)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              width: 700,
            },
          }}
        />
      )}
      <SwipeableViews
        index={tabValue}
        onChangeIndex={(newValue) => {
          setTabValue(newValue)
        }}
      >
        <FeedTabWriting index={0} />
        <FeedTabConnect index={1} />
        <FeedTabExplore index={2} />
      </SwipeableViews>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  feedContent: {
    paddingTop: theme.spacing,
  },
  mainBox: {
    textAlign: 'center'
  },
  loginButton: {
    marginTop: '20px',
  },
  signupButton: {},
  feedTabs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  root: {
    width: '76%',
    marginBottom: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginLeft: '12%',
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    zIndex: '5',
  },
  alertText: {
    display: 'flex',
    flexDirection: 'row',
    alignText: 'center',
  },
  joyRideText: {
    fontSize: '22px',
  },
}))
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -15,
    top: 10,
    border: `2px solid #512da8`,
    backgroundColor: '#512da8',
    padding: '0 4px',
  },
}))(Badge)

export default MainFeed
