import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FaImage } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunity } from '../../actions'
import {
    getPublicCommunityFeaturedUrls,
    saveCommunityFeaturedImage,
} from '../../actions/community'

const useStyles = makeStyles((theme) => ({
    dropbox: {
        border: '1px solid #ccc',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: 'white',
        textAlign: 'center',
        borderRadius: '4px',
        maxWidth: 600,
    },
    message: {
        color: '#666',
        marginTop: theme.spacing(2),
    },
    featuredPreview: {
        width: '100%',
        maxHeight: 200,
        objectFit: 'contain',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    previewContainer: {
        maxHeight: 200,
        marginBottom: theme.spacing(2),
    },
    featuredIcon: {
        position: 'relative',
        top: 10,
    },
    featuredBox: {},
}))

function CommunityFeaturedPicker({
    communityId,
    featuredImageList,
    setSubmitted,
}) {
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                console.log(acceptedFiles)
                if (setSubmitted) {
                    setSubmitted(true)
                }
                dispatch(
                    saveCommunityFeaturedImage(communityId, acceptedFiles[0])
                )
            }
        },
        [communityId]
    )
    const classes = useStyles()
    const dispatch = useDispatch()

    const community = useSelector((state) => state.community.community)
    useEffect(() => {
        console.log('get featured writing picker')
        dispatch(getCommunity(communityId))
    }, [dispatch, communityId])

    useEffect(() => {
        console.log('ks comm')

        if (community) {
            console.log('got comm')

            if (community.featuredId) {
                console.log('got featured')
                getPublicCommunityFeaturedUrls(community.featuredId).then(
                    (urls) => {
                        console.log(urls.featuredLarge)
                        console.log('got featured large')

                        setMediaUrl(urls.featuredLarge)
                    }
                )
            }
            console.log(community)
        }
    }, [community, communityId])

    const [mediaUrl, setMediaUrl] = useState('')
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    return (
        <Container className={classes.dropbox} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <>
                    {mediaUrl && (
                        <Grid
                            container
                            className={classes.previewContainer}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img
                                alt="Featured preview"
                                className={classes.featuredPreview}
                                src={mediaUrl}
                            />
                        </Grid>
                    )}

                    <Container className={classes.featuredBox}>
                        <FaImage
                            className={classes.featuredIcon}
                            size="2em"
                            color="#666"
                        />{' '}
                        Select featured image
                    </Container>
                    <Typography
                        className={classes.message}
                        variant="body2"
                    ></Typography>
                </>
            )}
        </Container>
    )
}

export default CommunityFeaturedPicker
