import { Box, Button, Chip, Grid, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import {
    FaHeart,
    FaPen,
    FaRegCircle,
    FaUserCheck,
    FaUserPlus,
    FaUserShield,
    FaMedal,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    followProfile,
    getAvatarUrls,
    unFollowProfile,
} from '../../actions/profile'
import {
    addCommunityAdmin,
    removeCommunityAdmin,
} from '../../actions/community'

import * as routes from '../../constants/routes'
import ShareProfile from '../profile/ShareProfile'
import Confirm from '../layout/Confirm'
const useStyles = makeStyles((theme) => ({
    root: {
        width: 275,
        // height: 320,
        backgroundColor: 'white',
        borderRadius: 12,
        textDecoration: 'none',
        marginBottom: theme.spacing(2),
        display: 'inline-block',
        paddingBottom: theme.spacing(1),
    },
    tags: {},
    follow: {
        marginBottom: theme.spacing(1),
    },
    makeAdmin: {
        marginBottom: theme.spacing(1),
    },
    chip: {
        marginLeft: 5,
        marginRight: 5,
        // color: '#512da8',
        // fontWeight: 'bold'
    },
    authorName: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: theme.spacing(1),
    },
    likes: {
        marginBottom: theme.spacing(1),
        color: '#a1453f',
        opacity: 0.7,
    },
    pronouns: {
        marginBottom: theme.spacing(1),
        opacity: 0.7,
        color: '#666',
    },
    heart: {
        position: 'relative',
        top: 2,
    },
    user: {
        position: 'relative',
        top: 2,
    },
    star: {
        position: 'relative',
        top: 2,
    },
    map: {
        position: 'relative',
        top: 2,
    },
    writes: {
        color: 'black',
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    avatar: {
        height: 80,
        width: 80,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        border: '1px solid #512da8',
    },
    avatarSkeleton: {
        height: 80,
        width: 80,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    circleAdmin: {
        color: '#512da8',
        // textTransform: 'uppercase'
    },
    creator: {
        color: '#e68a00',
    },
    mainBox: {
        width: 300,
    },
    shareProfile: {
        marginTop: theme.spacing(1),
    },
}))

function MemberCard({ profile }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [avatarLoaded, setAvatarLoaded] = useState(false)

    const [following, setFollowing] = useState(false)
    const community = useSelector((state) => state.community.community)

    const loggedInProfile = useSelector((state) => state.profile.profile)
    const [loggedInProfileIsCircleAdmin, setLoggedInProfileIsCircleAdmin] =
        useState(false)
    const [isCircleAdmin, setIsCircleAdmin] = useState(false)
    const [isCreator, setIsCreator] = useState(false)
    const [isSelf, setIsSelf] = useState(false)
    const [confirmAddAdmin, setConfirmAddAdmin] = useState(false)
    const [confirmRemoveAdmin, setConfirmRemoveAdmin] = useState(false)

    useEffect(() => {
        if (profile.avatarId) {
            getAvatarUrls(profile.avatarId).then((urls) => {
                if (urls.avatarMedium) {
                    setAvatarUrl(urls.avatarMedium)
                }
                setAvatarLoaded(true)
            })
        } else {
            setAvatarLoaded(true)
        }
    }, [profile.avatarId])
    let redirectTo = {
        pathname: routes.SIGNUP,
    }
    if (loggedInProfile.id) {
        redirectTo = {
            pathname: routes.PROFILE_PUBLIC.replace(':id', profile.id),
        }
    }

    useEffect(() => {
        if (community?.admin_profile_ids?.includes(profile.id)) {
            setIsCircleAdmin(true)
        }
        if (community?.admin_profile_ids?.includes(loggedInProfile.id)) {
            setLoggedInProfileIsCircleAdmin(true)
        }
        if (community?.creator_profile_id == profile.id) {
            setIsCreator(true)
        }
    }, [community, profile, loggedInProfile])

    useEffect(() => {
        if (loggedInProfile?.following_profile_ids) {
            if (loggedInProfile.following_profile_ids.includes(profile.id)) {
                setFollowing(true)
            }
        }
        if (loggedInProfile.id == profile.id) {
            setIsSelf(true)
        }
    }, [loggedInProfile, profile.id])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox,
            }}
        >
            <Confirm
                confirm={confirmAddAdmin}
                setConfirm={setConfirmAddAdmin}
                message={
                    'Are you sure you want to add a Circle admin? The member will have the ability to perform restricted actions such as removing members, adding Circle admins, and deleting the Circle.'
                }
                action={'Add'}
                callback={() => {
                    dispatch(addCommunityAdmin(profile.id, community.id))
                }}
            />
            <Confirm
                confirm={confirmRemoveAdmin}
                setConfirm={setConfirmRemoveAdmin}
                message={'Are you sure you want to remove a Circle admin?'}
                action={'Remove'}
                callback={() => {
                    dispatch(removeCommunityAdmin(profile.id, community.id))
                }}
            />
            <Card className={classes.root}>
                <CardActionArea component={Link} to={redirectTo}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box className={classes.shareProfile}>
                            <ShareProfile
                                publicProfileId={profile.id}
                                publicProfile={profile}
                            />
                        </Box>
                        {avatarLoaded && (
                            <Avatar
                                className={classes.avatar}
                                src={avatarUrl}
                            />
                        )}
                        {!avatarLoaded && (
                            <Skeleton
                                variant="circle"
                                className={classes.avatarSkeleton}
                                width={80}
                                height={80}
                            />
                        )}

                        {profile.likeCount > 0 && (
                            <Typography
                                variant="h6"
                                className={classes.likes}
                                gutterBottom
                            >
                                <FaHeart
                                    className={classes.heart}
                                    color="#bf281d"
                                />{' '}
                                {profile.likeCount}
                            </Typography>
                        )}

                        <Typography
                            className={classes.authorName}
                            variant="h6"
                            gutterBottom
                        >
                            {profile.displayName}
                        </Typography>

                        {isCreator && (
                            <Typography
                                variant="body2"
                                className={classes.creator}
                                gutterBottom
                            >
                                <FaMedal
                                    className={classes.star}
                                    color="#e68a00"
                                />{' '}
                                Creator
                            </Typography>
                        )}
                        {isCircleAdmin && (
                            <Typography
                                variant="body2"
                                className={classes.circleAdmin}
                                gutterBottom
                            >
                                <FaUserShield
                                    className={classes.star}
                                    color="#512da8"
                                />{' '}
                                Circle Admin
                            </Typography>
                        )}
                        {/* <Typography variant="body2" className={classes.location} gutterBottom>
              <FaMapMarked className={classes.map} color="#666" /> Boston, MA
            </Typography> */}
                        {profile.pronouns && (
                            <Typography
                                variant="body2"
                                className={classes.pronouns}
                                gutterBottom
                            >
                                <FaRegCircle
                                    className={classes.user}
                                    color="#666"
                                />{' '}
                                {profile.pronouns}
                            </Typography>
                        )}
                        {/* <Typography variant="body2" className={classes.location} gutterBottom>
              <FaMapMarked className={classes.map} color="#666" /> Boston, MA
            </Typography> */}
                        {profile.tags && (
                            <Grid className={classes.tags}>
                                {/* <Typography className={classes.writes} variant="body2">
                writes
              </Typography> */}
                                <Chip
                                    size="small"
                                    className={classes.chip}
                                    variant="outlined"
                                    label={'Sci-Fi'}
                                />
                                <Chip
                                    size="small"
                                    className={classes.chip}
                                    variant="outlined"
                                    label={'Fantasy'}
                                />
                            </Grid>
                        )}
                        {!loggedInProfile.id && (
                            <Button
                                startIcon={<FaUserPlus />}
                                className={classes.follow}
                                to={{
                                    pathname: routes.SIGNUP,
                                }}
                                variant="outlined"
                            >
                                Join to Follow!
                            </Button>
                        )}

                        {loggedInProfile.id && !isSelf && (
                            <>
                                {!following && (
                                    <Button
                                        startIcon={<FaUserPlus />}
                                        className={classes.follow}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            dispatch(followProfile(profile))
                                            setFollowing(true)
                                        }}
                                        variant="outlined"
                                    >
                                        Follow
                                    </Button>
                                )}
                                {following && (
                                    <Button
                                        startIcon={<FaUserCheck />}
                                        className={classes.follow}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            dispatch(unFollowProfile(profile))
                                            setFollowing(false)
                                        }}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Following
                                    </Button>
                                )}
                            </>
                        )}
                        {loggedInProfile.id &&
                            isCircleAdmin &&
                            loggedInProfileIsCircleAdmin &&
                            !isSelf && (
                                <Button
                                    startIcon={<FaUserShield />}
                                    className={classes.makeAdmin}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setConfirmRemoveAdmin(true)
                                    }}
                                    variant="outlined"
                                >
                                    Remove Circle Admin
                                </Button>
                            )}
                        {loggedInProfile.id &&
                            !isCircleAdmin &&
                            loggedInProfileIsCircleAdmin &&
                            !isSelf && (
                                <Button
                                    startIcon={<FaUserShield />}
                                    className={classes.makeAdmin}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setConfirmAddAdmin(true)
                                    }}
                                    variant="outlined"
                                >
                                    Add Circle Admin
                                </Button>
                            )}
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default MemberCard
