import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    Box,
    TextField,
    Typography,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPinPublishedStory, pinPublishedStory } from '../../actions'
import {
    getAdminAnalytics,
    getAdminConfig,
    setAdminConfig,
    updateSearchIndex,
    getAllProfile,
} from '../../actions/profile'
import AdminPinnedStory from '../feed/AdminPinnedStory'
import AuthorCard from './AuthorCard'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#fff',
    },
    mainLanding: {
        textAlign: 'center',
    },
    mainNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    mainBox: {},
    configItem: {
        marginBottom: 20,
    },
    profileInput: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
    istoriaLogo: {
        marginRight: theme.spacing(1),
    },
    analyticsCard: {
        maxWidth: 320,
        minWidth: 280,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    analyticsGrid: {
        marginTop: theme.spacing(1),
    },
    storiesCard: {
        minWidth: 400,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    storiesGrid: {
        marginTop: theme.spacing(1),
    },
    headerLine: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    headerText: {
        backgroundColor: 'white',
        minWidth: 320,
        maxWidth: theme.breakpoints.values.md,
    },
    generalTab: {
        marginTop: theme.spacing(2),
    },
    analyticsTab: {
        marginTop: theme.spacing(2),
    },
    storiesTab: {
        marginTop: theme.spacing(2),
    },
    pinnedStoryContainer: {
        flex: 1,
        width: '100%',
        marginTop: theme.spacing(2),
    },
    istoriaInline: {
        display: 'inline-block',
    },
    dividerTitle: {
        fontSize: '1.25rem',
        color: '#512da8',
        fontWeight: 'bold',
        fontFamily: 'Roboto Slab',
        marginBottom: theme.spacing(2),
    },
}))
function Admin() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [loadingState, setLoadingState] = useState('initial')

    const [currentTab, setCurrentTab] = useState('general')
    const [totalCount, setTotalCount] = useState(-1)
    const [storyCount, setStoryCount] = useState(-1)
    const [showHeader, setShowHeader] = useState(false)
    const [headerText, setHeaderText] = useState('')
    const [pinnedURL, setPinnedURL] = useState('') // handle pinnedId of textInput
    const [pinnedList, setPinnedList] = useState([])

    const analyticsData = useSelector((state) => state.profile.analyticsData)
    const configData = useSelector((state) => state.profile.configData)
    const pinnedData = useSelector((state) => state.admin.pinnedList)
    const allProfile = useSelector((state) => state.profile.allProfile)

    useEffect(() => {
        console.log('getting admin config')
        dispatch(getAdminConfig())
        dispatch(getPinPublishedStory())
        dispatch(getAllProfile())
    }, [dispatch])

    useEffect(() => {
        console.log(allProfile)
    }, [allProfile])
    // render list of pinned story
    useEffect(() => {
        setPinnedList(pinnedData)
    }, [pinnedData])

    useEffect(() => {
        console.log(configData)
        if (configData?.show_header) {
            setShowHeader(configData.show_header)
        }
        if (configData?.header_text) {
            setHeaderText(configData.header_text)
        }
    }, [configData])

    useEffect(() => {
        console.log(analyticsData)
        if (analyticsData?.analytics) {
            setLoadingState('loaded')
            if (analyticsData.total_count) {
                setTotalCount(analyticsData.total_count)
            }
            if (analyticsData.story_count) {
                setStoryCount(analyticsData.story_count)
            }
        }
    }, [analyticsData])

    const handlePublishId = (event) => {
        setPinnedURL(event.target.value)
    }

    const onPinStory = async (event) => {
        event.preventDefault()
        dispatch(pinPublishedStory(pinnedURL))
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
            }}
        >
            <Grid
                container
                className={classes.headerLine}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                        color: '#512da8',
                    }}
                    className={classes.istoriaLogo}
                >
                    istoria
                </Typography>

                <Typography
                    style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                >
                    admin
                </Typography>
            </Grid>
            <Tabs
                value={currentTab}
                onChange={(e, newValue) => {
                    setCurrentTab(newValue)
                }}
            >
                <Tab value="general" label="General" />
                <Tab value="users" label="Users" />
                <Tab disabled value="writing" label="Writing" />
                <Tab value="analytics" label="Analytics" />
                <Tab value="stories" label="Stories" />
            </Tabs>
            <Grid
                container
                className={classes.analyticsGrid}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {currentTab === 'general' && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.generalTab}
                    >
                        <TextField
                            id="headerText"
                            className={classes.headerText}
                            label="Header text"
                            variant="outlined"
                            multiline
                            value={headerText}
                            onChange={(e) => {
                                setHeaderText(e.target.value)
                                dispatch(
                                    setAdminConfig({
                                        header_text: e.target.value,
                                    })
                                )
                            }}
                        />

                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showHeader}
                                        onChange={() => {
                                            let newHeaderState = !showHeader
                                            setShowHeader(newHeaderState)
                                            dispatch(
                                                setAdminConfig({
                                                    show_header: newHeaderState,
                                                })
                                            )
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Show header"
                            />
                        </FormGroup>

                        <Button
                            onClick={() => {
                                dispatch(updateSearchIndex())
                            }}
                            variant="contained"
                        >
                            Update Search Index
                        </Button>
                    </Grid>
                )}

                {currentTab === 'users' && (
                    <>
                        displayName, email, profileId, uid
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.usersTab}
                        >
                            {allProfile.map((profile) => (
                                <Box>
                                    {profile.profileData && (
                                        <>
                                            {profile.profileData.displayName},
                                            {profile.profileData.email},
                                            {profile.id},
                                            {profile.profileData.uid}
                                        </>
                                    )}
                                </Box>
                            ))}
                        </Grid>
                    </>
                )}
                {currentTab === 'analytics' && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.analyticsTab}
                    >
                        {loadingState === 'loading' && <CircularProgress />}
                        {loadingState === 'initial' && (
                            <Button
                                onClick={() => {
                                    setLoadingState('loading')
                                    dispatch(getAdminAnalytics())
                                }}
                                variant="contained"
                            >
                                Load Analytics
                            </Button>
                        )}

                        {loadingState === 'loaded' && totalCount > -1 && (
                            <Card className={classes.analyticsCard}>
                                <CardContent>
                                    <Typography variant="h6">
                                        Total # of projects
                                    </Typography>
                                    <Typography variant="h2">
                                        {totalCount}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                        {loadingState === 'loaded' && storyCount > -1 && (
                            <Card className={classes.analyticsCard}>
                                <CardContent>
                                    <Typography variant="h6">
                                        # of published stories
                                    </Typography>
                                    <Typography variant="h2">
                                        {storyCount}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                )}
                {currentTab === 'stories' && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.storiesTab}
                    >
                        <Card className={classes.storiesCard}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    className={[
                                        classes.istoriaInline,
                                        classes.dividerTitle,
                                    ]}
                                >
                                    Pin Story
                                </Typography>
                                <br></br>
                                <form
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={onPinStory}
                                >
                                    <TextField
                                        onChange={handlePublishId}
                                        value={pinnedURL}
                                        id="published-writing"
                                        label="Enter published.shortUrl"
                                        className={classes.headerText}
                                        variant="outlined"
                                        type="text"
                                        margin="normal"
                                    />
                                </form>
                                <br></br>
                            </CardContent>
                        </Card>

                        <Card className={classes.storiesCard}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    className={[
                                        classes.istoriaInline,
                                        classes.dividerTitle,
                                    ]}
                                >
                                    Currently Pinned Stories
                                </Typography>
                                {/* <Card className={classes.pinnedStoryContainer}> */}
                                {/* <div className={classes.pinnedStoryContainer}> */}
                                <AdminPinnedStory
                                    pinnedList={pinnedList}
                                    className={classes.pinnedStoryContainer}
                                />
                                {/* </div> */}
                                {/* </Card> */}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default Admin
