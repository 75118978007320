import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { logoutUser } from '../../actions'

function Logout(props) {
    const { dispatch } = props

    useEffect(() => {
        dispatch(logoutUser())
    }, [dispatch])
    return <Redirect to="/" />
}
function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
    }
}

export default connect(mapStateToProps)(Logout)
