import {
    TextField,
    Container,
    Typography,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import {
    listCommunities,
    listProfileCommunities,
    communityBackFrom,
} from '../../actions/community'
import * as routes from '../../constants/routes'
import {
    FaLayerGroup,
    FaLink,
    FaRegFileAlt,
    FaSync,
    FaTimesCircle,
} from 'react-icons/fa'
import {
    publishToCommunity,
    unPublishFromCommunity,
    getFromAssociation,
} from '../../actions/write'
import { Link, useHistory } from 'react-router-dom'
import { slugUrl } from '../../shared/slugUrl'
import Confirm from '../layout/Confirm'

const useStyles = makeStyles((theme) => ({
    communityInput: {
        backgroundColor: 'white',
        marginTop: theme.spacing(2),
    },
    pickerBox: {
        padding: 0,
    },
    publishButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    publishedMessage: {},
    publishDescription: {
        marginBottom: 15,
        textAlign: 'center',
        color: '#666',
    },
    progress: {
        marginTop: theme.spacing(1),
    },
    spinner: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function PublishCommunity({ sections }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [ready, setReady] = useState(false)
    const [redirect, doRedirect] = useState(false)
    const project = useSelector((state) => state.write.project)
    const [title, setTitle] = useState('')
    const current = useSelector((state) => state.ui.sidebarPosition)
    const location = useLocation()
    const featuredUploading = useSelector(
        (state) => state.write.featuredUploading
    )
    const projectUpdating = useSelector((state) => state.write.projectUpdating)

    const [publishing, setPublishing] = useState(false)
    const isOverview = current === -1

    const currentSection = project.sections.filter(
        (s) => s.position === current
    )[0]
    const currentObj = current === -1 ? project : currentSection
    const [selected, setSelected] = useState('')

    const published = useSelector((state) => state.write.published)
    const profile = useSelector((state) => state.profile.profile)
    const [confirmUnpublish, setConfirmUnpublish] = useState(false)

    const profileCommunities = useSelector(
        (state) => state.community.profileCommunities
    )

    useEffect(() => {
        if (profile) dispatch(listProfileCommunities(profile.id))
    }, [dispatch, profile])

    useEffect(() => {
        console.log(profileCommunities)
    }, [profileCommunities])

    useEffect(() => {
        setReady(false)

        if (currentObj && project?.community_id) {
            let cleanPid = currentObj.id.substring(0, 7)
            console.log(cleanPid)
            console.log(currentObj.id)
            dispatch(getFromAssociation(cleanPid, project.community_id))

            console.log('search published')
        }
    }, [project, currentObj])

    useEffect(() => {
        if (isOverview) {
            setTitle(project.title)
        } else {
            if (currentSection) setTitle(currentSection.title)
        }
    }, [project, currentSection, isOverview])

    useEffect(() => {
        console.log(currentSection)
    }, [currentSection])
    return (
        <>
            {/* <Confirm
                    confirm={confirmUnpublish}
                    setConfirm={setConfirmUnpublish}
                    message={
                        'Are you sure you want to leave this Circle? If you change your mind you will have to request to join the Circle again.'
                    }
                    action={'Leave'}
                    callback={() => {
                        console.log('leaving')
                        dispatch(leaveCommunity(community.id))
                    }}
                /> */}
            <Typography variant="h6">Publish to Istoria Circle</Typography>
            {isOverview && (
                <Typography
                    className={classes.publishDescription}
                    variant="body2"
                >
                    Publishing <b>{title}</b> to an Istoria Circle will only
                    share your writing with other members of the circle.
                </Typography>
            )}
            {!isOverview && (
                <Typography
                    className={classes.publishDescription}
                    variant="body2"
                >
                    Publishing <b>{title}</b> from project{' '}
                    <b>{project.title}</b> to an Istoria Circle will only share
                    your writing with other members of the circle.
                </Typography>
            )}
            {currentObj.published_to_community && (
                <Container>
                    <Typography
                        variant="body2"
                        className={classes.publishedMessage}
                    >
                        Published to <b>{currentObj.community_name}</b>
                    </Typography>
                    {!published?.shortUrl && (
                        <CircularProgress className={classes.spinner} />
                    )}
                    {published?.shortUrl && (
                        <Button
                            variant="outlined"
                            color="primary"
                            classes={{ root: classes.publishButton }}
                            startIcon={<FaLink />}
                            component={Link}
                            onClick={() => {
                                dispatch(
                                    communityBackFrom(location.pathname, {})
                                )
                            }}
                            to={{
                                pathname:
                                    routes.COMMUNITY_PUBLISHED_VIEW.replace(
                                        ':cid',
                                        project.community_id
                                    ).replace(
                                        ':pid',
                                        slugUrl(
                                            published.title,
                                            published.shortUrl
                                        )
                                    ),
                            }}
                        >
                            View in Circle
                        </Button>
                    )}
                    <br />
                    <Button
                        variant="outlined"
                        startIcon={<FaTimesCircle />}
                        onClick={() => {
                            if (!isOverview) {
                                dispatch(
                                    unPublishFromCommunity(
                                        project.id,
                                        currentSection.sectionId,
                                        currentObj.community_published_id,
                                        currentObj.community_id
                                    )
                                )
                            } else {
                                dispatch(
                                    unPublishFromCommunity(
                                        project.id,
                                        null,
                                        currentObj.community_published_id,
                                        currentObj.community_id
                                    )
                                )
                            }
                        }}
                    >
                        Unpublish from Circle
                    </Button>
                </Container>
            )}

            {!currentObj.published_to_community && (
                <Container className={classes.pickerBox}>
                    <Autocomplete
                        options={profileCommunities}
                        className={classes.communityInput}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                            console.log(value)
                            if (value) setSelected(value)
                        }}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select an Istoria circle'}
                                variant="outlined"
                            />
                        )}
                    />

                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={
                            publishing ||
                            featuredUploading ||
                            projectUpdating ||
                            !selected
                        }
                        onClick={() => {
                            console.log(selected)
                            setPublishing(true)
                            if (isOverview) {
                                dispatch(
                                    publishToCommunity(
                                        project.title,
                                        null,
                                        project.description,
                                        project.id,
                                        null,
                                        {
                                            profile: profile,
                                            genres: project.genres,
                                            tags: project.tags,
                                        },
                                        sections,
                                        selected.id,
                                        selected.name
                                    )
                                )
                            } else {
                                console.log(sections)
                                console.log(currentSection)
                                dispatch(
                                    publishToCommunity(
                                        currentSection.title,
                                        project.title,
                                        currentSection.description,
                                        project.id,
                                        currentSection.id,
                                        {
                                            profile: profile,
                                            genres: project.genres,
                                            tags: project.tags,
                                        },
                                        sections,
                                        selected.id,
                                        selected.name
                                    )
                                )
                            }
                        }}
                        classes={{ root: classes.publishButton }}
                        startIcon={
                            isOverview ? <FaLayerGroup /> : <FaRegFileAlt />
                        }
                    >
                        Publish to Circle
                    </Button>
                </Container>
            )}
        </>
    )
}

export default PublishCommunity
