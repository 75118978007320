import {
    Box,
    Card,
    CardActionArea,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { formatDuration, formatRelative } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FaCheckCircle, FaHistory } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { getPublicFeaturedUrls } from '../../actions/write'
import * as routes from '../../constants/routes'

// function shortenString(str) {
//   if (str.length <= 180) return str
//   else return str.slice(0, 180) + '...'
// }

const StyleChip = withStyles({
    root: {
        color: '#009c12',
        borderColor: '#009c12',
    },
})(Chip)
const CommunityChip = withStyles({
    root: {
        color: '#512da8',
        borderColor: '#512da8',
    },
})(Chip)
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 120,
        width: '100%',
    },
    root: {
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.md,
        textAlign: 'center',
        width: 360,
        minHeight: 310,
        maxHeight: 360,
        borderRadius: 12,
    },
    banner: {
        position: 'relative',
    },
    header: {
        marginTop: theme.spacing(1),
        color: '#666',
        textAlign: 'center',
    },
    title: {
        color: '#333',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
    },
    titleGutter: {
        color: '#333',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
    },
    detailBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    date: {
        fontSize: 12,
        fontStyle: 'italic',
    },
    tagsBox: {
        marginLeft: theme.spacing(2),
    },
    actionsBox: {
        marginLeft: 4,
    },
    cardActions: {
        marginTop: 0,
        marginRight: theme.spacing(1),
    },
    author: {
        textAlign: 'center',
    },
    avatarLine: {
        textAlign: 'center',
    },
    avatar: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 22,
        width: 22,
        top: 5,
        left: 5,
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
        opacity: 0.7,
    },
    excerptBody: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
    },
    authorName: {
        color: 'black',
        display: 'block',
        textDecoration: 'none',
        paddingBottom: theme.spacing(1),
        fontStyle: 'italic',
    },
    by: {
        fontWeight: 'normal',
        color: 'black',
    },
    noexcerpt: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        color: '#666',
    },
    edited: {
        color: '#666',
        fontSize: '0.8125rem',
    },
    created: {
        color: '#666',
    },
    history: {
        position: 'relative',
        top: 2,
    },
    bookmark: {
        position: 'relative',
        top: 2,
    },
    tags: {
        marginBottom: theme.spacing(1),
        height: theme.spacing(3),
    },
    cardGrid: {},
    fullGrid: {},

    likes: {
        color: '#a1453f',
        opacity: 0.5,
        marginRight: theme.spacing(1),
    },
    readTime: {
        color: '#666',
        opacity: 0.7,
        marginRight: theme.spacing(1),
    },
    wordCount: {
        color: '#666',
        opacity: 0.7,
    },

    heart: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    quotes: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    info: {
        marginBottom: theme.spacing(1) - 2,
    },
    published: {
        opacity: 0.6,
        marginTop: theme.spacing(1),
        color: '#009c12',
    },
    publishedCommunity: {
        opacity: 0.6,
        marginTop: theme.spacing(1),
        color: '#512da8',
    },
    unpublished: {
        border: 'solid goldenrod 2px',
        padding: '4px',
        color: 'goldenrod',
        borderRadius: '50px',
        backgroundColor: 'gray',
    },
}))

function ProjectCard({
    title,
    description,
    projectId,
    project,
    likeCount,
    author,
    featuredId,
    hideAuthor,
    url,
    published,
}) {
    const classes = useStyles()
    const toUrl = url ? url : routes.PUBLISHED.replace(':id', projectId)

    const [mediaUrl, setMediaUrl] = useState(
        require('../../static/grey-background.jpg')
    )
    const [lastEdited, setLastEdited] = useState(null)
    const [created, setCreated] = useState(null)

    const findTrue = (obj) => {
        let count = 0
        for (let k in obj) {
            if (obj[k] === true) {
                count++
            }
            console.log(obj[k])
        }
        return count
    }

    let numerator = 0
    let denominator = 0

    if (project.published_section_ids) {
        numerator = findTrue(Object.keys(project.published_section_ids))
        console.log(project)
        denominator = Object.keys(project.published_section_ids).length
    }

    // let publishCount = numerator + '/' + denominator + ' Published'
    let publishCount = 'Published Publicly'

    useEffect(() => {
        console.log('this writing')
        if (project) {
            console.log(project)
            let wordCount = 5001
            let rt = Math.ceil(wordCount / 275)

            if (project.updated_on) {
                let editedStr = formatRelative(
                    Date.parse(project.updated_on),
                    new Date()
                )
                setLastEdited(editedStr)
            }

            let createdStr = formatRelative(
                Date.parse(project.created_on),
                new Date()
            )

            setCreated(createdStr)
        }
    }, [project])

    useEffect(() => {
        console.log('dispatch')
        // getAuthorProfile(projectId).then((profile) => {
        //   console.log(profile);
        //   console.log('got author profile');

        //   setAuthorProfile(profile.data);
        // });

        getPublicFeaturedUrls(featuredId).then((urls) => {
            console.log(urls)
            if (urls) setMediaUrl(urls.featuredLarge)
        })
    }, [featuredId])

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={Link}
                className={classes.action}
                to={{
                    pathname: toUrl,
                }}
            >
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    className={classes.fullGrid}
                >
                    <img
                        alt="Featured"
                        className={classes.media}
                        src={mediaUrl}
                    />
                    <Grid
                        container
                        direction="column"
                        justify="space-around"
                        alignItems="center"
                        className={classes.cardGrid}
                    >
                        {!(
                            !project.excerpt?.length > 0 &&
                            !project.description?.length > 0
                        ) && (
                            <Typography
                                className={classes.title}
                                variant="h5"
                                component="h2"
                            >
                                {title}
                            </Typography>
                        )}
                        {!project.excerpt?.length > 0 &&
                            !project.description?.length > 0 && (
                                <Typography
                                    className={classes.titleGutter}
                                    variant="h5"
                                    component="h2"
                                >
                                    {title}
                                </Typography>
                            )}

                        {lastEdited && (
                            <Typography
                                variant="body2"
                                className={classes.edited}
                            >
                                <FaHistory className={classes.history} /> Last
                                edited {lastEdited}
                            </Typography>
                        )}
                        {!lastEdited && created && (
                            <Typography
                                variant="body2"
                                className={classes.edited}
                            >
                                <FaHistory className={classes.bookmark} /> Last
                                edited {created}
                            </Typography>
                        )}
                        {project.published && (
                            <StyleChip
                                variant="outlined"
                                size="small"
                                icon={<FaCheckCircle color="#009c12" />}
                                label={publishCount}
                                color="#009c12"
                                className={classes.published}
                            />
                        )}
                        {project.published_to_community && (
                            <CommunityChip
                                variant="outlined"
                                size="small"
                                icon={<FaCheckCircle color="#512da8" />}
                                label="Published to Circle"
                                color="#512da8"
                                className={classes.publishedCommunity}
                            />
                        )}

                        {project.description?.length > 0 && (
                            <Typography
                                variant="body2"
                                component="p"
                                className={classes.excerptBody}
                            >
                                {project.description}
                            </Typography>
                        )}
                        {project.excerpt?.length > 0 &&
                            !project.description?.length > 0 && (
                                <Typography
                                    variant="body2"
                                    component="p"
                                    className={classes.excerptBody}
                                >
                                    {project.excerpt}
                                </Typography>
                            )}

                        <Box className={classes.tags}>
                            {project.genres &&
                                project.genres
                                    .slice(0, 1)
                                    .map((genre) => (
                                        <Chip
                                            size="small"
                                            className={classes.chipItem}
                                            variant="outlined"
                                            label={genre}
                                        />
                                    ))}
                            {project.tags &&
                                project.tags
                                    .slice(0, 1)
                                    .map((tag) => (
                                        <Chip
                                            size="small"
                                            className={classes.chipItem}
                                            label={tag}
                                        />
                                    ))}
                        </Box>

                        {/* <Grid
              container
              className={classes.info}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {likedCount && <Typography variant="body2" className={classes.likes} gutterBottom>
                <FaHeart className={classes.heart} color="#bf281d" />{likedCount}
              </Typography>}
              {readTime && <Typography variant="body2" className={classes.readTime} gutterBottom>
                <FaClock className={classes.clock} color="#666" />{readTime} read
          </Typography>}
              {wordCount && <Typography variant="body2" className={classes.wordCount} gutterBottom>
                <FaQuoteLeft className={classes.quotes} color="#666" />{wordCount} word{wordCount !== '1' && <>s</>}
              </Typography>}
            </Grid> */}
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

export default ProjectCard
