import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ScrollToTop from './ScrollToTop'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    createAccountButton: {
        marginTop: '20px',
    },
    istoriaLogo: {
        marginBottom: theme.spacing(1),
    },

    logo: {
        maxWidth: 300,
        margin: 20,
        marginTop: 30,
        marginBottom: 30,
    },
    termsBox: {
        maxWidth: 1024,
        textAlign: 'center',
    },
    writeHeading: {},
}))

function Privacy(props) {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox, // class name, e.g. `classes-nesting-root-x`
            }}
        >
            <ScrollToTop />
            <Container className={classes.termsBox}>
                <Typography
                    style={{
                        fontSize: 28,
                        color: '#512da8',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.istoriaLogo}
                >
                    istoria
                </Typography>
                <Typography
                    style={{
                        fontSize: 28,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                >
                    privacy policy
                </Typography>
                <Typography className={classes.writeHeading} variant="body2">
                    <h3> Last updated January 05, 2020</h3> <br />
                    Thank you for choosing to be part of our community at
                    istoria.io (“Company”, “we”, “us”, or “our”). We are
                    committed to protecting your personal information and your
                    right to privacy. If you have any questions or concerns
                    about our notice , or our practices with regards to your
                    personal information, please contact us at
                    contact@istoria.io.
                    <br />
                    <br />
                    When you visit our website istoria.io, mobile application,
                    and use our services, you trust us with your personal
                    information. We take your privacy very seriously. In this
                    privacy notice, we seek to explain to you in the clearest
                    way possible what information we collect, how we use it and
                    what rights you have in relation to it. We hope you take
                    some time to read through it carefully, as it is important.
                    If there are any terms in this privacy notice that you do
                    import ScrollToTop from './ScrollToTop'; not agree with,
                    please discontinue use of our Sites or Apps and our
                    services. This privacy notice applies to all information
                    collected through our website (such as istoria.io), mobile
                    application, ("Apps"), and/or any related services, sales,
                    marketing or events (we refer to them collectively in this
                    privacy notice as the "Services"). Please read this privacy
                    notice carefully as it will help you make informed decisions
                    about sharing your personal information with us.
                    <br />
                    <h3>WHAT INFORMATION DO WE COLLECT? </h3>
                    <em>
                        Some information – such as IP address and/or browser and
                        device characteristics – is collected automatically when
                        you visit our Services or Apps.
                    </em>{' '}
                    <br />
                    <br /> We automatically collect certain information when you
                    visit, use or navigate the Services or Apps. This
                    information does not reveal your specific identity (like
                    your name or contact information) but may include device and
                    usage information, such as your IP address, browser and
                    device characteristics, operating system, language
                    preferences, referring URLs, device name, country, location,
                    information about how and when you use our Services or Apps
                    and other technical information. This information is
                    primarily needed to maintain the security and operation of
                    our Services or Apps, and for our internal analytics and
                    reporting purposes. Like many businesses, we also collect
                    information through cookies and similar technologies.
                    Information collected through our Apps <br />
                    <br />
                    <em>
                        We may collect information regarding your geo-location,
                        mobile device, push notifications, when you use our
                        apps.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    If you use our Apps, we may also collect the following
                    information: Geo-Location Information. We may request access
                    or permission to and track location-based information from
                    your mobile device, either continuously or while you are
                    using our mobile application, to provide location-based
                    services. If you wish to change our access or permissions,
                    you may do so in your device’s settings. Mobile Device
                    Access. We may request access or permission to certain
                    features from your mobile device, including your mobile
                    device’s sensors, storage, contacts, reminders, and other
                    features. If you wish to change our access or permissions,
                    you may do so in your device’s settings. Mobile Device Data.
                    We may automatically collect device information (such as
                    your mobile device ID, model and manufacturer), operating
                    system, version information and IP address. Push
                    Notifications. We may request to send you push notifications
                    regarding your account or the mobile application. If you
                    wish to opt-out from receiving these types of
                    communications, you may turn them off in your device’s
                    settings. <h3>HOW DO WE USE YOUR INFORMATION?</h3>
                    <em>
                        We process your information for purposes based on
                        legitimate business interests, the fulfillment of our
                        contract with you, compliance with our legal
                        obligations, and/or your consent.{' '}
                    </em>{' '}
                    <br />
                    <br /> We use personal information collected via our
                    Services or Apps for a variety of business purposes
                    described below. We process your personal information for
                    these purposes in reliance on our legitimate business
                    interests, in order to enter into or perform a contract with
                    you, with your consent, and/or for compliance with our legal
                    obligations. We indicate the specific processing grounds we
                    rely on next to each purpose listed below. We use the
                    information we collect or receive: To facilitate account
                    creation and logon process. If you choose to link your
                    account with us to a third party account (such as your
                    Google or Facebook account), we use the information you
                    allowed us to collect from those third parties to facilitate
                    account creation and logon process for the performance of
                    the contract. See the section below headed "HOW DO WE HANDLE
                    YOUR SOCIAL LOGINS" for further information. To send you
                    marketing and promotional communications. We and/or our
                    third party marketing partners may use the personal
                    information you send to us for our marketing purposes, if
                    this is in accordance with your marketing preferences. You
                    can opt-out of our marketing emails at any time (see the
                    "WHAT ARE YOUR PRIVACY RIGHTS" below). To send
                    administrative information to you. We may use your personal
                    information to send you product, service and new feature
                    information and/or information about changes to our terms,
                    conditions, and policies. Request Feedback. We may use your
                    information to request feedback and to contact you about
                    your use of our Services or Apps. To protect our Services.
                    We may use your information as part of our efforts to keep
                    our Services or Apps safe and secure (for example, for fraud
                    monitoring and prevention). To enable user-to-user
                    communications. We may use your information in order to
                    enable user-to-user communications with each user's consent.
                    To enforce our terms, conditions and policies for Business
                    Purposes, Legal Reasons and Contractual. To respond to legal
                    requests and prevent harm. If we receive a subpoena or other
                    legal request, we may need to inspect the data we hold to
                    determine how to respond. To manage user accounts. We may
                    use your information for the purposes of managing our
                    account and keeping it in working order. To deliver services
                    to the user. We may use your information to provide you with
                    the requested service. To respond to user inquiries/offer
                    support to users. We may use your information to respond to
                    your inquiries and solve any potential issues you might have
                    with the use of our Services. For other Business Purposes.
                    We may use your information for other Business Purposes,
                    such as data analysis, identifying usage trends, determining
                    the effectiveness of our promotional campaigns and to
                    evaluate and improve our Services or Apps, products,
                    marketing and your experience. We may use and store this
                    information in aggregated and anonymized form so that it is
                    not associated with individual end users and does not
                    include personal information. We will not use identifiable
                    personal information without your consent.{' '}
                    <h3>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
                    <em>
                        We only share information with your consent, to comply
                        with laws, to provide you with services, to protect your
                        rights, or to fulfill business obligations.
                    </em>{' '}
                    <br />
                    <br /> We may process or share data based on the following
                    legal basis: Consent: We may process your data if you have
                    given us specific consent to use your personal information
                    in a specific purpose. Legitimate Interests: We may process
                    your data when it is reasonably necessary to achieve our
                    legitimate business interests. Performance of a Contract:
                    Where we have entered into a contract with you, we may
                    process your personal information to fulfill the terms of
                    our contract. Legal Obligations: We may disclose your
                    information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a
                    judicial proceeding, court order, or legal process, such as
                    in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements). Vital Interests: We may
                    disclose your information where we believe it is necessary
                    to investigate, prevent, or take action regarding potential
                    violations of our policies, suspected fraud, situations
                    involving potential threats to the safety of any person and
                    illegal activities, or as evidence in litigation in which we
                    are involved. More specifically, we may need to process your
                    data or share your personal information in the following
                    situations: Vendors, Consultants and Other Third-Party
                    Service Providers. We may share your data with third party
                    vendors, service providers, contractors or agents who
                    perform services for us or on our behalf and require access
                    to such information to do that work. Examples include:
                    payment processing, data analysis, email delivery, hosting
                    services, customer service and marketing efforts. We may
                    allow selected third parties to use tracking technology on
                    the Services or Apps, which will enable them to collect data
                    about how you interact with the Services or Apps over time.
                    This information may be used to, among other things, analyze
                    and track data, determine the popularity of certain content
                    and better understand online activity. Unless described in
                    this Policy, we do not share, sell, rent or trade any of
                    your information with third parties for their promotional
                    purposes. Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company. Third-Party Advertisers. We may use third-party
                    advertising companies to serve ads when you visit the
                    Services or Apps. These companies may use information about
                    your visits to our Website(s) and other websites that are
                    contained in web cookies and other tracking technologies in
                    order to provide advertisements about goods and services of
                    interest to you. Affiliates. We may share your information
                    with our affiliates, in which case we will require those
                    affiliates to honor this privacy notice. Affiliates include
                    our parent company and any subsidiaries, joint venture
                    partners or other companies that we control or that are
                    under common control with us. Business Partners. We may
                    share your information with our business partners to offer
                    you certain products, services or promotions. Other Users.
                    When you share personal information (for example, by posting
                    comments, contributions or other content to the Services or
                    Apps) or otherwise interact with public areas of the
                    Services or Apps, such personal information may be viewed by
                    all users and may be publicly distributed outside the
                    Services or Apps in perpetuity. If you interact with other
                    users of our Services or Apps and register through a social
                    network (such as Facebook), your contacts on the social
                    network will see your name, profile photo, and descriptions
                    of your activity. Similarly, other users will be able to
                    view descriptions of your activity, communicate with you
                    within our Services or Apps, and view your profile.{' '}
                    <h3>
                        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </h3>{' '}
                    <em>
                        We may use cookies and other tracking technologies to
                        collect and store your information.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Policy.
                    <h3>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>{' '}
                    <em>
                        If you choose to register or log in to our services
                        using a social media account, we may have access to
                        certain information about you.{' '}
                    </em>{' '}
                    <br />
                    <br /> Our Services or Apps offer you the ability to
                    register and login using your third party social media
                    account details (like your Facebook or Twitter logins).
                    Where you choose to do this, we will receive certain profile
                    information about you from your social media provider. The
                    profile Information we receive may vary depending on the
                    social media provider concerned, but will often include your
                    name, e-mail address, friends list, profile picture as well
                    as other information you choose to make public. We will use
                    the information we receive only for the purposes that are
                    described in this privacy notice or that are otherwise made
                    clear to you on the Services or Apps. Please note that we do
                    not control, and are not responsible for, other uses of your
                    personal information by your third party social media
                    provider. We recommend that you review their privacy policy
                    to understand how they collect, use and share your personal
                    information, and how you can set your privacy preferences on
                    their sites and apps.
                    <h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3>{' '}
                    <em>
                        We keep your information for as long as necessary to
                        fulfill the purposes outlined in this privacy notice
                        unless otherwise required by law.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting or other legal
                    requirements). No purpose in this policy will require us
                    keeping your personal information for longer than 6 months
                    past the termination of the user's account. When we have no
                    ongoing legitimate business need to process your personal
                    information, we will either delete or anonymize it, or, if
                    this is not possible (for example, because your personal
                    information has been stored in backup archives), then we
                    will securely store your personal information and isolate it
                    from any further processing until deletion is possible.
                    <h3>HOW DO WE KEEP YOUR INFORMATION SAFE? </h3>{' '}
                    <em>
                        We aim to protect your personal information through a
                        system of organizational and technical security
                        measures.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    We have implemented appropriate technical and organizational
                    security measures designed to protect the security of any
                    personal information we process. However, please also
                    remember that we cannot guarantee that the internet itself
                    is 100% secure. Although we will do our best to protect your
                    personal information, transmission of personal information
                    to and from our Services or Apps is at your own risk. You
                    should only access the services within a secure environment.{' '}
                    <h3>DO WE COLLECT INFORMATION FROM MINORS?</h3>{' '}
                    <em>
                        We do not knowingly collect data from or market to
                        children under 18 years of age.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Services or Apps, you
                    represent that you are at least 18 or that you are the
                    parent or guardian of such a minor and consent to such minor
                    dependent’s use of the Services or Apps. If we learn that
                    personal information from users less than 18 years of age
                    has been collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we have collected
                    from children under age 18, please contact us at
                    dpo@istoria.io. <h3> WHAT ARE YOUR PRIVACY RIGHTS?</h3>{' '}
                    <em>
                        You may review, change, or terminate your account at any
                        time.{' '}
                    </em>{' '}
                    <br />
                    <br /> If you are resident in the European Economic Area and
                    you believe we are unlawfully processing your personal
                    information, you also have the right to complain to your
                    local data protection supervisory authority. You can find
                    their contact details here:
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                    If you have questions or comments about your privacy rights,
                    you may email us at privacy@istoria.io. Account Information
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can: ■ Log into your account settings and update your user
                    account. Upon your request to terminate your account, we
                    will deactivate or delete your account and information from
                    our active databases. However, some information may be
                    retained in our files to prevent fraud, troubleshoot
                    problems, assist with any investigations, enforce our Terms
                    of Use and/or comply with legal requirements. Cookies and
                    similar technologies: Most Web browsers are set to accept
                    cookies by default. If you prefer, you can usually choose to
                    set your browser to remove cookies and to reject cookies. If
                    you choose to remove cookies or reject cookies, this could
                    affect certain features or services of our Services or Apps.
                    <h3> CONTROLS FOR DO-NOT-TRACK FEATURES</h3> Most web
                    browsers and some mobile operating systems and mobile
                    applications include a Do-Not-Track (“DNT”) feature or
                    setting you can activate to signal your privacy preference
                    not to have data about your online browsing activities
                    monitored and collected. No uniform technology standard for
                    recognizing and implementing DNT signals has been finalized.
                    As such, we do not currently respond to DNT browser signals
                    or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online
                    tracking is adopted that we must follow in the future, we
                    will inform you about that practice in a revised version of
                    this privacy notice.{' '}
                    <h3>
                        DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </h3>{' '}
                    <em>
                        Yes, if you are a resident of California, you are
                        granted specific rights regarding access to your
                        personal information.{' '}
                    </em>{' '}
                    <br />
                    <br />
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below. If you are under 18
                    years of age, reside in California, and have a registered
                    account with the Services or Apps, you have the right to
                    request removal of unwanted data that you publicly post on
                    the Services or Apps. To request removal of such data,
                    please contact us using the contact information provided
                    below, and include the email address associated with your
                    account and a statement that you reside in California. We
                    will make sure the data is not publicly displayed on the
                    Services or Apps, but please be aware that the data may not
                    be completely or comprehensively removed from our systems.{' '}
                    <h3> DO WE MAKE UPDATES TO THIS POLICY?</h3>{' '}
                    <em>
                        Yes, we will update this policy as necessary to stay
                        compliant with relevant laws.
                    </em>
                    <br />
                    <br />
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated “Revised”
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                    <h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3> If you
                    have questions or comments about this policy, you may email
                    us at dpo@istoria.io.
                    <h3>
                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                        COLLECT FROM YOU?
                    </h3>
                    Based on the laws of some countries, you may have the right
                    to request access to the personal information we collect
                    from you, change that information, or delete it in some
                    circumstances. To request to review, update, or delete your
                    personal information, please submit a request to
                    privacy@istoria.io. We will respond to your request within
                    30 days.
                </Typography>
            </Container>
        </Grid>
    )
}

export default Privacy
