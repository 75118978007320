import { Button, IconButton } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { FaCheck, FaPlusCircle, FaTimes } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    sidebarHeading: {
        color: '#512da8',
        fontWeight: 'bold',
    },
    saveButton: {
        margin: 0,
        padding: 0,
    },
    cancelButton: {
        padding: 5,
    },
    listItem: {},
    divider: {
        color: 'black',
    },
    plus: {
        marginRight: theme.spacing(1) + 2,
    },
}))

function NewSection({ saveCallback, renameCallback, note, isDefault }) {
    const classes = useStyles()
    const [adding, setAdding] = useState(false)
    const [name, setName] = useState('')

    return (
        <>
            {!adding && (
                <ListItem
                    button
                    variant="outlined"
                    className={classes.listItem}
                    onClick={() => {
                        console.log('cancel')
                        setAdding(true)
                    }}
                >
                    <FaPlusCircle className={classes.plus} color="#512da8" />
                    {!note && (
                        // <ListItemText className={classes.newButton} primary="Add writing" />
                        <Button color="primary">Add Writing</Button>
                    )}
                    {note && (
                        // <ListItemText className={classes.newButton} primary="Add note" />
                        <Button color="primary">Add Note</Button>
                    )}
                </ListItem>
            )}
            {adding && (
                <ListItem>
                    <TextField
                        inputRef={(input) => input && input.focus()}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                console.log('saving')
                                if (name !== '') {
                                    if (isDefault) {
                                        renameCallback(name)
                                    } else {
                                        saveCallback(name, '', '')
                                    }
                                }
                                setAdding(false)
                                e.preventDefault()
                            }
                        }}
                    />
                    <IconButton
                        className={classes.saveButton}
                        aria-label="save"
                        onClick={() => {
                            console.log('saving')

                            if (name !== '') {
                                if (isDefault) {
                                    renameCallback(name)
                                } else {
                                    saveCallback(name, '', '')
                                }
                            }
                            setAdding(false)
                        }}
                    >
                        <FaCheck size=".75em" />
                    </IconButton>
                    <IconButton
                        className={classes.cancelButton}
                        aria-label="cancel"
                        onClick={() => {
                            console.log('cancel')
                            setAdding(false)
                        }}
                    >
                        <FaTimes size=".75em" />
                    </IconButton>
                </ListItem>
            )}
        </>
    )
}

export default NewSection
