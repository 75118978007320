import { Box, Button, makeStyles, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useRef, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { updateNote, updateSection } from '../../actions/write'

function useInterval(callback, delay) {
    const intervalId = React.useRef(null)
    const savedCallback = React.useRef(callback)
    React.useEffect(() => {
        savedCallback.current = callback
    })
    React.useEffect(() => {
        const tick = () => savedCallback.current()
        if (typeof delay === 'number') {
            intervalId.current = window.setInterval(tick, delay)
            return () => window.clearInterval(intervalId.current)
        }
    }, [delay])
    return intervalId.current
}

export const defaultTitle = 'Untitled Writing Segment'

function WriteEditor() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const project = useSelector((state) => state.write.project)
    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const current = useSelector((state) => state.ui.sidebarPosition)

    const [reloading, setReloading] = useState(false)
    const [body, setBody] = useState('')
    const [bodyText, setBodyText] = useState('')
    const [savedBody, setSavedBody] = useState('')
    const [oldBodyText, setOldBodyText] = useState('')

    const screenLarge = useMediaQuery(theme.breakpoints.up('md'))

    let d = new Date()
    const [lastSaved, setLastSaved] = useState(d.getTime())

    const quillRef = useRef(null)

    const sections = useSelector((state) => state.write.sections)
    const notes = useSelector((state) => state.write.notes)

    const isDefault = sections.length === 1 && sections[0].default

    let currentSection
    if (noteSelected) {
        currentSection = notes.filter((n) => n.position === current)[0]
    } else {
        currentSection = sections.filter((s) => s.position === current)[0]
    }
    console.log(noteSelected, 'notes')

    const doSave = () => {
        if (!reloading) {
            if (currentSection) {
                setReloading(true)
                if (noteSelected) {
                    dispatch(
                        updateNote(project.id, {
                            noteId: currentSection.noteId,
                            body: body,
                            bodyText: bodyText,
                        })
                    )
                } else {
                    // console.log('saving section not not')
                    dispatch(
                        updateSection(project.id, {
                            sectionId: currentSection.sectionId,
                            body: body,
                            bodyText: bodyText,
                        })
                    )
                }
                setSavedBody(body)
                setLastSaved(d.getTime())
                setReloading(false)
            }
        }
    }

    useInterval(() => {
        // console.log('checking writing changed')
        if (oldBodyText !== bodyText) {
            // console.log('writing changed, saving')
            doSave()
            setOldBodyText(bodyText)
        }
    }, 3000)

    useEffect(() => {
        console.log(sections, 'this is sections')
        setReloading(true)
        if (currentSection) {
            if (noteSelected) {
                // console.log('reloading note')
                currentSection = notes.filter((n) => n.position === current)[0]
                // console.log(currentSection)
                if (currentSection.body !== undefined) {
                    setBody(currentSection.body)
                    setSavedBody(currentSection.body)
                }
                console.log(noteSelected, 'notes')
            } else {
                // console.log('sections')
                // console.log(current)
                // console.log(sections)
                currentSection = sections.filter(
                    (s) => s.position === current
                )[0]
                // console.log(currentSection)
                setBody(currentSection.body)
                setSavedBody(currentSection.body)
                console.log(currentSection, 'current section')
            }
        }

        setReloading(false)
    }, [current, noteSelected, isDefault])

    useEffect(() => {
        if (screenLarge) {
            quillRef.current.focus()
            // console.log('focusing')
        }
    }, [screenLarge])

    return (
        <>
            <ReactQuill
                id="project-editor"
                modules={{
                    clipboard: {
                        matchVisual: false,
                    },
                }}
                selection={{ start: 0, end: 0 }}
                className={classes.writeQuill}
                value={body}
                ref={quillRef}
                onBlur={() => {
                    if (body !== savedBody) {
                        doSave()
                    }
                }}
                onChange={(content, _delta, _source, editor) => {
                    setBody(content)
                    setBodyText(editor.getText())
                }}
            />
            <br />
            <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                flexDirection="row"
            >
                <Button variant="outlined" onClick={() => doSave()}>
                    <FaSave /> &nbsp;Save
                </Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(() => ({
    writeQuill: {
        backgroundColor: 'white',
    },
}))
export default WriteEditor
