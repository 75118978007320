import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FaHammer } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    commentBox: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        border: '1px solid #ccc',
        color: '#666',
        maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    icon: {
        position: 'relative',
        top: 2,
    },
}))

function ComingSoon({ label }) {
    const classes = useStyles()
    return (
        <Box>
            <Typography className={classes.commentBox} variant="h6">
                <FaHammer className={classes.icon} /> {label} coming soon.
            </Typography>
        </Box>
    )
}

export default ComingSoon
