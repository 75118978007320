import {
    CircularProgress,
    Container,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Joyride from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { didProjectTour } from '../../actions'
import {
    endProjectTour,
    setSidebarNote,
    setSidebarPosition,
    setWriteTab,
    startProjectTour,
} from '../../actions/ui'
import { getProject, projectGetComplete } from '../../actions/write'
import * as routes from '../../constants/routes'
import ProjectContent from './ProjectContent'
import ProjectSidebar from './ProjectSidebar'
import ProjectTitle from './ProjectTitle'

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />
}

const useStyles = makeStyles({
    mainBox: {},
    viewTitle: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    viewBody: {
        marginTop: 10,
    },
    viewActionRow: {
        marginTop: 10,
    },
    viewProgress: {
        marginTop: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    progressBox: {
        textAlign: 'center',
    },
    newButton: {
        fontWeight: 'bold',
    },
    sidebar: {
        paddingTop: 80,
    },
    sidebarHeading: {
        color: '#512da8',
        fontWeight: 'bold',
    },
    joyRideText: {
        fontSize: '22px',
    },
})

function ViewProject() {
    let { id } = useParams()

    const dispatch = useDispatch()

    const classes = useStyles()
    const [ready, setReady] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const noteSelected = useSelector((state) => state.ui.sidebarNote)
    const position = useSelector((state) => state.ui.sidebarPosition)
    const project = useSelector((state) => state.write.project)

    const profile = useSelector((state) => state.profile.profile)

    const touring = useSelector((state) => state.ui.projectTouring)

    const getProjectAvailable = useSelector(
        (state) => state.write.getProjectAvailable
    )
    const writingDeletingSuccess = useSelector(
        (state) => state.write.projectDeletingSuccess
    )

    const setPosition = (pos) => {
        dispatch(setSidebarPosition(pos))
    }
    const setNoteSelected = (note) => {
        dispatch(setSidebarNote(note))
    }

    const setCurrent = (position, note) => {
        setPosition(position)
        setNoteSelected(note)
        console.log('is note')
        console.log(noteSelected)
    }

    useEffect(() => {
        console.log(profile)
        console.log('ununun')
        if (Object.keys(profile).length > 0) {
            if (profile.didProjectTour !== true) {
                console.log('first project tour')
                dispatch(startProjectTour())
            }
        }
    }, [profile])

    useEffect(() => {
        dispatch(setWriteTab('edit'))
        setReady(false)
        if (!loaded) dispatch(getProject(id))
        console.log('getting writing')
    }, [])

    useEffect(() => {
        if (getProjectAvailable && project.sections && !loaded) {
            console.log('write available')
            setTimeout(() => {
                setReady(true)
                setLoaded(true)
                dispatch(projectGetComplete())
            }, 100)
        }
    }, [getProjectAvailable, project.sections])

    const steps = [
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        Great job, you created your frist writing{' '}
                        <strong>Project!</strong> <br></br>
                        <br></br>
                        This tutorial will help you use the Project Workspace.
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        // {
        //   content:
        //     <>
        //       <h3>This is the Project overview</h3>
        //       <Typography className={classes.joyRideText}>
        //         You can easily see all the Writing and Notes in this Project.
        //       </Typography>
        //     </>,
        //   target: '#project-corkboard',
        // },
        // {
        //   content:
        //     <>
        //       <h3>Click any Writing or Note to edit</h3>
        //       <Typography className={classes.joyRideText}>
        //         You can also use the navigation panel on the left.
        //       </Typography>
        //     </>,
        //   target: '#project-corkboard-first',
        // },
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        The <strong>Navigation Panel</strong> allows you to add,
                        rename, and delete Writing sections and Notes.
                    </Typography>
                </>
            ),
            target: '#project-navigator',
        },
        // {
        //   content:
        //     <>
        //       <h3>This is the editor</h3>
        //       <Typography className={classes.joyRideText}>
        //         Anything you write here will be automatically saved to the Writing or Note selected on the navigation panel.
        //       </Typography>
        //     </>,
        //   target: '#project-editor',
        // },
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        Click the pencil to rename the Project
                    </Typography>
                </>
            ),
            target: '#project-title',
        },
        // {
        //   content:
        //     <>
        //       <h3>
        //         Additional options are available by clicking the three dots next to Writing and Notes
        //         </h3>
        //     </>,
        //   target: '#project-sidebar-first',
        // },
        // {
        //   content:
        //     <>
        //       <h3>
        //         Click the Project title in the navigation pane to return to the Project overview
        //         </h3>
        //     </>,
        //   target: '#project-nav-title',
        // },
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        Key details about the Project such as the featured image
                        are managed here
                    </Typography>
                </>
            ),
            target: '#project-content-details',
        },
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        Our feedback tool is coming soon.
                    </Typography>
                </>
            ),
            target: '#project-content-feedback',
        },
        {
            content: (
                <>
                    <Typography className={classes.joyRideText}>
                        You can publish your entire Project, or just a single
                        Writing section.
                    </Typography>
                </>
            ),
            target: '#project-content-publish',
        },
        // {
        //   content:
        //     <>
        //       <h3>
        //         When your writing receives 5 likes, it will be promoted to the main landing page under <b>Read</b>
        //       </h3>
        //     </>,
        //   target: '#project-content-publish',
        // },
        // {
        //   content:
        //     <>
        //       <h3>
        //         Click here to notify your followers about your new published writing
        //        </h3>
        //     </>,
        //   target: '#project-content-publish',
        // },
        // {
        //   content:
        //     <>
        //       <h3>
        //         Click here from any Project workspace to watch this tutorial again
        //         </h3>
        //     </>,
        //   target: '#mainbar-help',
        // }
    ]

    return (
        <>
            {project.title && (
                <Helmet>
                    <title>{project.title} - istoria</title>
                </Helmet>
            )}

            <Container className={classes.mainBox}>
                {writingDeletingSuccess && <Redirect to={routes.HOME} />}
                {ready ? (
                    <>
                        <ProjectTitle />
                        {touring && (
                            <Joyride
                                steps={steps}
                                continuous={true}
                                run={touring}
                                showProgress={true}
                                locale={{
                                    back: 'Back',
                                    close: 'Close',
                                    last: 'End',
                                    next: 'Next',
                                    skip: 'Skip',
                                }}
                                showSkipButton
                                callback={(data) => {
                                    if (data.index === 0) {
                                        dispatch(setSidebarPosition(-1))
                                        dispatch(setSidebarNote(false))
                                        dispatch(setWriteTab('edit'))
                                    }

                                    if (data.index === 3) {
                                        dispatch(setSidebarPosition(0))
                                        dispatch(setSidebarNote(false))
                                    }
                                    if (data.index === 7) {
                                        dispatch(setSidebarPosition(-1))
                                        dispatch(setSidebarNote(false))
                                        dispatch(setWriteTab('about'))
                                    }
                                    if (data.index === 10) {
                                        dispatch(setSidebarPosition(-1))
                                        dispatch(setSidebarNote(false))
                                        dispatch(setWriteTab('publish'))
                                    }

                                    if (
                                        data.action === 'close' ||
                                        data.action === 'reset'
                                    ) {
                                        dispatch(setSidebarPosition(-1))
                                        dispatch(setSidebarNote(false))
                                        dispatch(endProjectTour())
                                        dispatch(didProjectTour())
                                        console.log('exiting')
                                    }
                                }}
                                styles={{
                                    options: {
                                        backgroundColor: 'white',
                                        primaryColor: '#512da8',
                                        textColor: '#000',
                                        beaconSize: 36,
                                        overlayColor: 'rgba(0, 0, 0, .8)',
                                        spotlightShadow:
                                            '0 0 15px rgba(0, 0, 0, 0.5)',
                                        width: 450,
                                    },
                                }}
                            />
                        )}
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="flex-start"
                        >
                            <Hidden smDown>
                                <Grid className={classes.sidebar} item xs={3}>
                                    <ProjectSidebar />
                                </Grid>
                                <Grid item xs={9}>
                                    <ProjectContent />
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid item xs={12}>
                                    <ProjectSidebar />
                                    <ProjectContent />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </>
                ) : (
                    <Container className={classes.progressBox}>
                        <CircularProgress className={classes.viewProgress} />
                    </Container>
                )}
            </Container>
        </>
    )
}

export default ViewProject
