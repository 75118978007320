import { Box, Container, Grid, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ScrollToTop from './ScrollToTop'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    createAccountButton: {
        marginTop: '20px',
    },

    logo: {
        maxWidth: 300,
        margin: 20,
        marginTop: 30,
        marginBottom: 30,
    },
    termsBox: {
        maxWidth: theme.breakpoints.values.md,
        textAlign: 'center',
    },
    aboutAvatar: {
        width: 160,
        height: 160,
        marginTop: theme.spacing(2),
        border: '1px solid #512da8',
    },
    aboutText: {
        textAlign: 'left',
        maxWidth: theme.breakpoints.values.md - 400,
        marginTop: theme.spacing(2),
    },
    aboutQA: {
        fontStyle: 'italic',
        color: '#666',
        maxWidth: 500,
        minWidth: 300,
        marginTop: theme.spacing(1),
    },
    aboutBox: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    aboutMessage: {
        maxWidth: theme.breakpoints.values.md - 240,
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#666',
    },
    aboutTeam: {
        marginTop: theme.spacing(3),
    },
    istoriaLogo: {
        marginLeft: 8,
        marginRight: 7,
    },
    istoriaInline: {
        display: 'inline-block',
    },
    aLink: {
        color: '#512da8',
    },
}))

function Terms(props) {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
                root: classes.mainBox,
            }}
        >
            <ScrollToTop />
            <Container className={classes.termsBox}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Typography
                        style={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                    >
                        the
                    </Typography>
                    <Typography
                        style={{
                            fontSize: 24,
                            color: '#512da8',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                        className={classes.istoriaLogo}
                    >
                        istoria
                    </Typography>

                    <Typography
                        style={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                    >
                        story
                    </Typography>
                </Grid>
                <Typography
                    component={Box}
                    className={classes.aboutMessage}
                    variant="body"
                >
                    We started{' '}
                    <Typography
                        style={{
                            fontSize: 16,
                            color: '#512da8',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                        className={classes.istoriaInline}
                    >
                        istoria
                    </Typography>{' '}
                    because...well, we love stories! Reading, writing, and
                    connecting through stories has played a major role in each
                    of our lives but, as we got older, we found it increasingly
                    difficult to find spaces created specifically for stories.{' '}
                    <br /> <br /> Our hope is that{' '}
                    <Typography
                        style={{
                            fontSize: 16,
                            color: '#512da8',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Slab',
                        }}
                        className={classes.istoriaInline}
                    >
                        istoria
                    </Typography>{' '}
                    becomes that space - an environment where emerging
                    storytellers and story-lovers can connect with each other
                    and foster the development of honest, insightful, and
                    inspiring stories.
                </Typography>

                <Typography
                    style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto Slab',
                    }}
                    className={classes.aboutTeam}
                >
                    the team
                </Typography>

{ false && <Grid
                    container
                    className={classes.aboutBox}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Avatar
                        className={classes.aboutAvatar}
                        alt="Elijah Maletz"
                        src="https://storage.googleapis.com/istoria-images/assets/headshot-elijah.jpg"
                    />
                    <Grid
                        className={classes.aboutText}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography component={Container} variant="body">
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                            >
                                <a
                                    className={classes.aLink}
                                    href="https://istoria.io/pr/9j4j7COOulL5b18OIr26"
                                >
                                    {' '}
                                    Elijah Maletz{' '}
                                </a>
                            </Typography>{' '}
                            is responsible for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ’s business planning and product design.
                        </Typography>
                        <Typography
                            className={classes.aboutQA}
                            component={Container}
                            variant="body"
                        >
                            Q. With what story character do you most closely
                            identify? <br />
                            A. I deeply connected with Lauren Olamina from “The
                            Parable of the Sower.” Her journey of coming to
                            terms with the world around her felt like a
                            biography of my inner life. <br />
                            <br />
                            Q. What author do you find particularly inspiring?{' '}
                            <br />
                            A. To that point, Octavia Butler is currently my
                            favorite author. She broke through so many barriers
                            in the literary world and her work is just
                            stunningly insightful and fresh. <br />
                            <br />
                            Q. What is your dream for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ? <br />
                            A. I really believe in the power of stories - I hope
                            that people find stories on Istoria that challenge
                            them to rethink the world around them, reconnect
                            with the world inside of them, and reach out to
                            people who might be closer to them than they
                            realized. <br />
                        </Typography>
                    </Grid>
                </Grid>}
                <Grid
                    container
                    className={classes.aboutBox}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Avatar
                        className={classes.aboutAvatar}
                        alt="Pranav Daryanani"
                        src="https://storage.googleapis.com/istoria-images/assets/headshot-pranav.jpg"
                    />
                    <Grid
                        className={classes.aboutText}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography component={Container} variant="body">
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                            >
                                <a
                                    className={classes.aLink}
                                    href="https://istoria.io/pr/w8MihBBvyf54iQAtUYZb"
                                >
                                    {' '}
                                    Pranav Daryanani
                                </a>
                            </Typography>{' '}
                            is a student collaborator leading{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ’s writing community at Yale.
                        </Typography>
                        <Typography
                            className={classes.aboutQA}
                            component={Container}
                            variant="body"
                        >
                            Q. With what story character do you most closely
                            identify?
                            <br />
                            A. The title character, Santiago, from Hemingway’s
                            “The Old Man and the Sea”. His determination to
                            succeed while appreciating the power of nature
                            translated as a lesson in accepting the things I
                            cannot change while having the courage to change the
                            things I can.
                            <br />
                            <br />
                            Q. What author do you find particularly inspiring?
                            <br />
                            A. Someone very special to me once introduced me to
                            Jack Kerouac’s work. The freedom by which he led his
                            life and his vivid observations of life taught me
                            appreciate my own worldview and to learn how others
                            see the world.
                            <br />
                            <br />
                            Q. What is your dream for&nbsp;
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ? <br />
                            A. I don’t believe it’s my place or our team’s place
                            to define what Istoria should be. I love the ideas
                            of communities, especially how they can shape
                            beautiful connections, and I believe the writers and
                            readers should define it. Ideally, every time you
                            login you should walk away having learned something
                            new and log-off having been inspired to write and
                            share your own journey.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={classes.aboutBox}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Avatar
                        className={classes.aboutAvatar}
                        alt="Eliot Woodrich"
                        src="https://storage.googleapis.com/istoria-images/assets/headshot-eliot.jpg"
                    />
                    <Grid
                        className={classes.aboutText}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography component={Container} variant="body">
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                            >
                                <a
                                    className={classes.aLink}
                                    href="https://istoria.io/pr/F4lLdGQZ3U86LZLfUNYq"
                                >
                                    {' '}
                                    Eliot Woodrich
                                </a>
                            </Typography>{' '}
                            is responsible for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ’s technical platform design and development.
                        </Typography>
                        <Typography
                            className={classes.aboutQA}
                            component={Container}
                            variant="body"
                        >
                            Q. With what story character do you most closely
                            identify? <br />
                            A. I love Hari Seldon from “Foundation” because he
                            only appears briefly before dying in the first book
                            but dominates the series through his ideas.
                            <br />
                            <br />
                            Q. What is your dream for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ?<br />
                            A. I hope{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>{' '}
                            makes writing easy and accessible so writers of any
                            experience can create something meaningful.
                            <br />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={classes.aboutBox}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Avatar
                        className={classes.aboutAvatar}
                        alt="Jess Ballerstein"
                        src="https://storage.googleapis.com/istoria-images/assets/headshot-jess.png"
                    />
                    <Grid
                        className={classes.aboutText}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography component={Container} variant="body">
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                            >
                                Jess Ballerstein
                            </Typography>{' '}
                            is responsible for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ’s marketing and brand presence.
                        </Typography>
                        <Typography
                            className={classes.aboutQA}
                            component={Container}
                            variant="body"
                        >
                            Q. With what story character do you most closely
                            identify?
                            <br />
                            A. As of now I closely identify with Santiago from
                            “The Alchemist.” Always in search of something
                            better, following passions while being true to self.
                            Yet, trusting and savoring the process is always the
                            hard part. <br />
                            <br />
                            Q. What author do you find particularly inspiring?
                            <br />
                            A. Nikki Giovanni has been in a special place in my
                            heart. She speaks raw truth and emotion. Even in
                            terms of her love life she is always to perfectly
                            express words that are hard to put into words.{' '}
                            <br />
                            <br />
                            Q. What is your dream for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ? <br />
                            A. For it to inspire more creatives that hasn’t
                            tapped into that realm of creativity yet. I want our
                            writers’ stories to be seen and heard on a global
                            scale; for each writer to have their own platform
                            through ours. <br />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={classes.aboutBox}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Avatar
                        className={classes.aboutAvatar}
                        alt="Ebraheem AlAthari"
                        src="https://storage.googleapis.com/istoria-images/assets/headshot-ebraheem.JPG"
                    />
                    <Grid
                        className={classes.aboutText}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography component={Container} variant="body">
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                            >
                                Ebraheem AlAthari
                            </Typography>{' '}
                            is responsible for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ’s cloud and administration infrastructure.
                        </Typography>
                        <Typography
                            className={classes.aboutQA}
                            component={Container}
                            variant="body"
                        >
                            Q. With what story character do you most closely
                            identify? <br />
                            A. I relate to Fry from Futurama; we have the same
                            laid-back style :) <br />
                            <br />
                            Q. What author do you find particularly inspiring?{' '}
                            <br />
                            A. I’ve always loved David Sedaris; his books still
                            make me laugh. <br />
                            <br />
                            Q. What is your dream for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>
                            ? <br />
                            A. My dream is for{' '}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    color: '#512da8',
                                    fontWeight: 'bold',
                                    fontStyle: 'normal',
                                    fontFamily: 'Roboto Slab',
                                }}
                                className={classes.istoriaInline}
                            >
                                istoria
                            </Typography>{' '}
                            to encourage people to find their inner writer.{' '}
                            <br />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Terms
