import { Box, Container } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import NewCommunity from './components/communities/NewCommunity'
import ViewCommunity from './components/communities/ViewCommunity'
import ProtectedRoute from './components/etc/ProtectedRoute'
import Home from './components/feed/Home'
import MainFeed from './components/feed/MainFeed'
import WelcomeLanding from './components/layout/WelcomeLanding'
import About from './components/layout/About'
import Footer from './components/layout/Footer'
import MainBar from './components/layout/MainBar'
import Privacy from './components/layout/Privacy'
import Terms from './components/layout/Terms'
import Admin from './components/profile/Admin'
import Login from './components/profile/Login'
import Logout from './components/profile/Logout'
import Notifications from './components/profile/Notifications'
import Profile from './components/profile/Profile'
import PublicProfile from './components/profile/PublicProfile'
import SignUp from './components/profile/SignUp'
import Welcome from './components/profile/Welcome'
import ViewProject from './components/project/ViewProject'
import Published from './components/publish/Published'
import ViewFeedback from './components/review/ViewFeedback'
import ViewReview from './components/review/ViewReview'
import * as routes from './constants/routes'
import CommunityAll from './components/communities/CommunityAll'
import Discussion from './components/communities/Discussion'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    mainContainer: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    childContainer: {
        textAlign: 'center',
    },
}))

function App(props) {
    const classes = useStyles()
    const { isAuthenticated, isVerifying, isAdmin } = props
    const theme = createTheme({
        palette: {
            primary: {
                light: '#512da8',
                main: '#512da8',
                dark: '#512da8',
                contrastText: '#fff',
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <CssBaseline />

                <Router>
                    <Container
                        maxWidth={false}
                        className={classes.mainContainer}
                    >
                        <Box>
                            <MainBar />
                        </Box>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={routes.VIEW}
                                component={ViewProject}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />

                            <ProtectedRoute
                                exact
                                path={routes.COMMUNITY_DISCUSSION}
                                component={Discussion}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.COMMUNITY_VIEW}
                                component={ViewCommunity}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.COMMUNITY_ALL}
                                component={CommunityAll}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.COMMUNITY_PUBLISHED_VIEW}
                                component={Published}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.COMMUNITY_NEW}
                                component={NewCommunity}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.FEEDBACK_VIEW}
                                component={ViewFeedback}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <Route path={routes.SIGNUP}>
                                <SignUp />
                            </Route>
                            <Route path={routes.FEED}>
                                <MainFeed dotour={false} />
                            </Route>
                            <Route path={routes.WELCOME_LANDING}>
                                <WelcomeLanding />
                            </Route>
                            <Route path={routes.PROFILE_PUBLIC}>
                                <PublicProfile />
                            </Route>
                            <ProtectedRoute
                                exact
                                path={routes.WELCOME}
                                component={Welcome}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.PROFILE}
                                component={Profile}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />

                            <ProtectedRoute
                                exact
                                path={routes.REVIEW}
                                component={ViewReview}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.NOTIFICATIONS}
                                component={Notifications}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.ADMIN}
                                component={Admin}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                                isAdminRoute={true}
                                isAdmin={isAdmin}
                            />
                            <ProtectedRoute
                                exact
                                path={routes.HOME_TOUR}
                                isAuthenticated={isAuthenticated}
                                isVerifying={isVerifying}
                            >
                                <Home dotour />
                            </ProtectedRoute>
                            <Route path={routes.PUBLISHED_VERSION}>
                                <Published />
                            </Route>
                            <Route
                                path={routes.NEWSLETTER}
                                component={() => {
                                    window.location.href =
                                        'https://mailchi.mp/6fadb3a9aeef/9up2qfw1xx'
                                    return null
                                }}
                            />

                            <Route path={routes.PUBLISHED}>
                                <Published />
                            </Route>
                            <Route path={routes.SIGNUP}>
                                <SignUp />
                            </Route>
                            <Route path={routes.LOGIN}>
                                <Login />
                            </Route>
                            <Route path={routes.LOGOUT}>
                                <Logout />
                            </Route>
                            <Route path={routes.TERMS}>
                                <Terms />
                            </Route>
                            <Route path={routes.ABOUT}>
                                <About />
                            </Route>
                            <Route path={routes.PRIVACY}>
                                <Privacy />
                            </Route>
                            <Route path={routes.HOME}>
                                <Home />
                            </Route>
                        </Switch>
                    </Container>
                    <Footer />
                </Router>
            </React.Fragment>
        </ThemeProvider>
    )
}
function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
        isAdmin: state.auth.isAdmin,
    }
}

export default connect(mapStateToProps)(App)
