import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
    FaClock,
    FaComments,
    FaEye,
    FaHeart,
    FaQuoteLeft,
    FaStar,
    FaThumbtack,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { getAvatarUrls } from '../../actions/profile'
import { getFeaturedUrls } from '../../actions/write'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'

function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

const DiscussionChip = withStyles({
    root: {
        color: '#666',
        borderColor: 'white',
        backgroundColor: 'white',
    },
})(Chip)

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 360,
        width: '100%',
    },
    root: {
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.sm,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(1),
        borderRadius: 12,
        position: 'relative',
    },
    banner: {
        position: 'relative',
    },
    header: {
        color: '#666',
        textAlign: 'center',
    },
    headerGutter: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#666',
        textAlign: 'center',
    },
    title: {
        color: '#333',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    detailBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
    },
    date: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#512da8',
    },
    tagsBox: {
        marginLeft: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    actionsBox: {
        marginLeft: 4,
    },
    cardActions: {
        marginTop: 0,
        marginRight: theme.spacing(1),
    },
    author: {
        textAlign: 'center',
    },
    avatarLine: {
        textAlign: 'center',
    },
    avatar: {
        display: 'inline-block',
        position: 'relative',
        border: '1px solid #512da8',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    avatarSkeleton: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    excerptBody: {
        padding: theme.spacing(2),
        textAlign: 'left',
        fontSize: '14pt',
    },
    authorName: {
        display: 'block',
        textDecoration: 'none',
        color: '#512da8',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    likes: {
        color: '#a1453f',
        marginRight: theme.spacing(1),
    },
    readTime: {
        color: '#666',
        marginRight: theme.spacing(1),
    },
    wordCount: {
        color: '#666',
    },

    heart: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    quotes: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    by: {
        fontWeight: 'normal',
        color: 'black',
    },
    info: {
        marginBottom: theme.spacing(1) - 2,
    },
    featuredMedia: {
        width: '100%',
        maxHeight: 300,
        objectFit: 'cover',
    },
    shareLine: {
        textAlign: 'center',
        height: 45,
    },
    shareLineGutter: {
        paddingTop: theme.spacing(1),
        textAlign: 'center',
        height: 52,
    },
    featuredChipLine: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: 0,
        right: 0,
    },
    featuredChipLineGutter: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: -2,
        right: 0,
    },
    featuredLine: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    shareBox: {
        paddingTop: 5,
    },
    chip: {},
    cardBody: {
        fontSize: '14pt',
        lineBreak: 'anywhere',
    },
    cardTitle: {
        fontWeight: 'bold',
    },
}))

function shortenString(str) {
    if (str.length <= 180) return str
    else return str.slice(0, 180) + '...'
}

const FeaturedChip = withStyles({
    root: {
        color: '#e68a00',
        borderColor: 'white',
        backgroundColor: 'white',
    },
})(Chip)
function DiscussionCard({ discussion, community, pinned }) {
    const classes = useStyles()
    const [featuredUrl, setFeaturedUrl] = useState('')

    let toUrl = routes.COMMUNITY_DISCUSSION.replace(
        ':cid',
        community.id
    ).replace(':did', discussion.id)

    return (
        <Card className={classes.root}>
            <CardActionArea
                component={Link}
                to={{
                    pathname: toUrl,
                }}
            >
                <CardContent>
                    <Box>
                        {!pinned && (
                            <DiscussionChip
                                className={classes.chip}
                                label="Discussion"
                                icon={<FaComments color="#666" />}
                            />
                        )}
                        {pinned && (
                            <DiscussionChip
                                className={classes.chip}
                                label="Pinned"
                                icon={<FaThumbtack color="#666" />}
                            />
                        )}
                    </Box>
                    <Typography className={classes.cardTitle} variant="h5">
                        {discussion.title}
                    </Typography>
                    <Typography className={classes.cardBody} variant="body1">
                        {discussion.bodyText}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default DiscussionCard
