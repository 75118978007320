import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getUserAvatar, getAvatarUrls } from '../../actions/profile'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    avatarLarge: {
        height: 180,
        width: 180,

        backgroundColor: 'grey',
        // border: '4px solid #512da8',
    },
    avatarMedium: {
        height: 42,
        width: 42,
        backgroundColor: '#ccc',
        border: '1px solid #512da8',
        // border: '2px solid #512da8',
    },
    avatarSmall: {
        height: 30,
        width: 30,
        backgroundColor: '#ccc',
        border: '1px solid #512da8',
        // border: '2px solid #512da8',
    },
})

function ProfileAvatar({ size, load, avatarId }) {
    const dispatch = useDispatch()
    const classes = useStyles()

    let sizeClass = classes.avatarSmall
    if (size === 'large') sizeClass = classes.avatarLarge
    if (size === 'medium') sizeClass = classes.avatarMedium

    const [avatarUrls, setAvatarUrls] = useState({})

    useEffect(() => {
        if (load) {
            getAvatarUrls(avatarId).then((urls) => {
                // console.log('got one');
                // console.log(urls)
                setAvatarUrls(urls)
            })
        } else {
            dispatch(getUserAvatar())
        }
    }, [avatarId, dispatch, load])

    useEffect(() => {
        if (!avatarId) {
            setAvatarUrls({})
        }
    }, [avatarId])
    return (
        <>
            {size === 'small' && (
                <Avatar
                    alt="avatar"
                    className={sizeClass}
                    color="darkgrey"
                    id="mainbar-profile-avatar"
                    src={avatarUrls.avatarSmall}
                />
            )}

            {size === 'medium' && (
                <Avatar
                    alt="avatar"
                    className={sizeClass}
                    color="darkgrey"
                    id="mainbar-profile-avatar"
                    src={avatarUrls.avatarMedium}
                />
            )}
            {size === 'large' && (
                <Avatar
                    alt="avatar"
                    className={sizeClass}
                    color="darkgrey"
                    id="mainbar-profile-avatar"
                    src={avatarUrls.avatarLarge}
                />
            )}
        </>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(ProfileAvatar)
