import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Button,
    Divider,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
    mainBox: {
        marginTop: '20px',
        minHeight: '30vh',
    },
    createAccountButton: {
        marginTop: '20px',
    },
}))
function Confirm({ confirm, setConfirm, message, callback, action }) {
    const classes = useStyles()

    return (
        <Dialog
            open={confirm}
            onClose={() => {
                setConfirm(confirm)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setConfirm(false)
                    }}
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        callback()
                        setConfirm(false)
                    }}
                >
                    {action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm
