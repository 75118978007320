import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    SIGNUP_REQUEST,
    SIGNUP_FAILURE,
    SIGNUP_SUCCESS,
} from '../actions/'

export default (
    state = {
        isLoggingIn: false,
        isLoggingOut: false,
        isVerifying: false,
        isSigningUp: false,
        loginError: false,
        logoutError: false,
        signUpError: false,
        error: null,
        isAuthenticated: false,
        isAdmin: false,

        user: {},
    },
    action
) => {
    switch (action.type) {
        case SIGNUP_REQUEST:
            return {
                ...state,
                isSigningUp: true,
                isAdmin: false,
                signUpError: false,
            }
        case SIGNUP_FAILURE:
            return {
                ...state,
                isSigningUp: false,
                isAdmin: false,
                isAuthenticated: false,
                signUpError: true,
                error: action.error,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isSigningUp: false,
                isAdmin: false,
                isAuthenticated: true,
                user: action.user,
            }

        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                loginError: false,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                isAdmin: action.isAdmin,
                user: action.user,
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                isAdmin: false,
                loginError: true,
                error: action.error,
            }
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true,
                logoutError: false,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggingOut: false,
                isAdmin: false,
                isAuthenticated: false,
                user: {},
            }
        case LOGOUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false,
                logoutError: true,
            }
        case VERIFY_REQUEST:
            return {
                ...state,
                isVerifying: true,
                verifyingError: false,
            }
        case VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false,
            }

        default:
            return state
    }
}
