import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Button,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import {
    FaClock,
    FaComments,
    FaEye,
    FaHeart,
    FaCalendar,
    FaQuoteLeft,
    FaStar,
    FaChevronLeft,
} from 'react-icons/fa'
import { format, formatDuration } from 'date-fns'
import { Link, useParams, Redirect } from 'react-router-dom'
import { getAvatarUrls } from '../../actions/profile'
import {
    getDiscussion,
    getCommunity,
    pinDiscussion,
    unpinDiscussion,
    deleteDiscussion,
} from '../../actions/community'
import * as routes from '../../constants/routes'
import { slugUrl } from '../../shared/slugUrl'
import CommunityBanner from './CommunityBanner'
import { useDispatch, useSelector } from 'react-redux'
import PublishedComments from '../publish/PublishedComments'
import ProfileAvatar from '../profile/ProfileAvatar'
import Confirm from '../layout/Confirm'
function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

function formatDate(dateStr) {
    let d = Date.parse(dateStr)

    return format(d, 'LLLL do, yyyy')
}
const DiscussionChip = withStyles({
    root: {
        color: '#666',
        borderColor: 'white',
        backgroundColor: 'white',
    },
})(Chip)

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    media: {
        objectFit: 'cover',
        height: 360,
        width: '100%',
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.md,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    publishedOn: {
        color: '#666',

        marginBottom: theme.spacing(2),
    },
    root: {
        marginBottom: theme.spacing(2),
        maxWidth: theme.breakpoints.values.sm,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(1),
        borderRadius: 12,
        position: 'relative',
    },
    banner: {
        position: 'relative',
    },
    header: {
        color: '#666',
        textAlign: 'center',
    },
    content: {
        paddingTop: theme.spacing(1),
    },
    headerGutter: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#666',
        textAlign: 'center',
    },
    title: {
        color: 'black',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    discussionInfo: {
        color: '#666',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    detailBar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    chipItem: {
        margin: 5,
        marginTop: 0,
    },
    date: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#512da8',
    },
    tagsBox: {
        marginLeft: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    actionsBox: {
        marginLeft: 4,
    },
    cardActions: {
        marginTop: 0,
        marginRight: theme.spacing(1),
    },
    author: {
        textAlign: 'center',
    },
    avatarLine: {
        textAlign: 'center',
    },
    avatar: {
        display: 'inline-block',
        position: 'relative',
        border: '1px solid #512da8',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    avatarSkeleton: {
        display: 'inline-block',
        position: 'relative',
        marginRight: 8,
        height: 30,
        width: 30,
        top: 10,
    },
    excerptBody: {
        padding: theme.spacing(2),
        textAlign: 'left',
        fontSize: '14pt',
    },
    likes: {
        color: '#a1453f',
        marginRight: theme.spacing(1),
    },
    readTime: {
        color: '#666',
        marginRight: theme.spacing(1),
    },
    wordCount: {
        color: '#666',
    },

    heart: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    clock: {
        position: 'relative',
        top: 2,
        marginRight: 2,
    },
    quotes: {
        position: 'relative',
        top: 2,
        marginRight: 3,
    },
    by: {
        fontWeight: 'normal',
        color: 'black',
    },
    info: {
        marginBottom: theme.spacing(1) - 2,
    },
    featuredMedia: {
        width: '100%',
        maxHeight: 300,
        objectFit: 'cover',
    },
    shareLine: {
        textAlign: 'center',
        height: 45,
    },
    shareLineGutter: {
        paddingTop: theme.spacing(1),
        textAlign: 'center',
        height: 52,
    },
    featuredChipLine: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: 0,
        right: 0,
    },
    featuredChipLineGutter: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(1),
        position: 'absolute',
        top: -2,
        right: 0,
    },
    featuredLine: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    shareBox: {
        paddingTop: 5,
    },
    chip: {},
    cardBody: {
        padding: theme.spacing(2),
        fontSize: '14pt',
        lineBreak: 'anywhere',
    },
    cardTitle: {
        fontWeight: 'bold',
    },
    backActions: {
        marginTop: theme.spacing(2),
    },
    authorName: {
        textDecoration: 'none',
        display: 'inline-block',
        position: 'relative',
        bottom: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: '#512da8',
    },
    avatar: {
        marginRight: 8,
        height: 30,
        width: 30,
        display: 'inline-block',
        position: 'relative',
        bottom: 2,
    },
    displayName: {
        marginBottom: theme.spacing(2),
    },
}))

function shortenString(str) {
    if (str.length <= 180) return str
    else return str.slice(0, 180) + '...'
}

const FeaturedChip = withStyles({
    root: {
        color: '#e68a00',
        borderColor: 'white',
        backgroundColor: 'white',
    },
})(Chip)
function Discussion({}) {
    const classes = useStyles()
    const [featuredUrl, setFeaturedUrl] = useState('')
    const dispatch = useDispatch()
    const community = useSelector((state) => state.community.community)
    const discussion = useSelector((state) => state.community.discussion)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmPin, setConfirmPin] = useState(false)
    const [confirmUnpin, setConfirmUnpin] = useState(false)
    const [doRedirect, setDoRedirect] = useState(false)
    let toUrl = 'test'

    let { cid, did } = useParams()

    useEffect(() => {
        dispatch(getCommunity(cid))
        dispatch(getDiscussion(did, cid))
    }, [dispatch])

    return (
        <Grid
            className={classes.mainBox}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            {doRedirect && (
                <Redirect
                    to={routes.COMMUNITY_VIEW.replace(':id', community.id)}
                />
            )}

            <Confirm
                confirm={confirmDelete}
                setConfirm={setConfirmDelete}
                message={
                    "Are you sure you want to delete this discussion? Deletion is permanent and can't be undone."
                }
                action={'Delete'}
                callback={() => {
                    console.log('deleted')
                    deleteDiscussion(discussion.id, community.id)
                    setDoRedirect(true)
                }}
            />
            <Confirm
                confirm={confirmPin}
                setConfirm={setConfirmPin}
                message={
                    'Are you sure you want to pin this discussion? The discussion will appear at top of the Circle feed and will replace any currently pinned discussion.'
                }
                action={'Pin'}
                callback={() => {
                    console.log('pinned')
                    pinDiscussion(discussion.id, community.id)
                }}
            />
            <Confirm
                confirm={confirmUnpin}
                setConfirm={setConfirmUnpin}
                message={
                    'Are you sure you want to unpin this discussion? It will no longer appear at the top of the Circle feed.'
                }
                action={'Unpin'}
                callback={() => {
                    console.log('unpinned')
                    unpinDiscussion(discussion.id, community.id)
                }}
            />

            <Grid
                container
                className={classes.backActions}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Button
                    component={Link}
                    startIcon={<FaChevronLeft />}
                    to={{
                        pathname: routes.COMMUNITY_VIEW.replace(
                            ':id',
                            community.id
                        ),
                    }}
                    className={classes.backContainer}
                >
                    Back to {community.name}
                </Button>
            </Grid>

            {discussion && (
                <>
                    <Typography variant="h6" className={classes.discussionInfo}>
                        <FaComments color="#666" /> Discussion
                    </Typography>

                    {discussion.title && (
                        <Typography variant="h6" className={classes.title}>
                            {discussion.title}
                        </Typography>
                    )}
                    <Typography className={classes.cardBody} variant="body">
                        {discussion.bodyText}
                    </Typography>

                    <Divider className={classes.divider} />
                    <Typography variant="body" className={classes.publishedOn}>
                        <FaCalendar className={classes.calendar} />{' '}
                        {formatDate(discussion.created_on)}
                    </Typography>
                    {discussion?.profile && (
                        <Box className={classes.authorBox}>
                            <Box className={classes.avatar}>
                                <ProfileAvatar
                                    size="small"
                                    load
                                    avatarId={discussion.profile.avatarId}
                                />
                            </Box>

                            <Typography
                                variant="h6"
                                className={classes.authorName}
                                component={Link}
                                to={{
                                    pathname: routes.PROFILE_PUBLIC.replace(
                                        ':id',
                                        discussion.profile.id
                                    ),
                                }}
                            >
                                {discussion.profile.displayName}
                            </Typography>
                        </Box>
                    )}
                    {!discussion.pinned && (
                        <Button
                            onClick={() => {
                                setConfirmPin(true)
                            }}
                        >
                            Pin
                        </Button>
                    )}
                    {discussion.pinned && (
                        <Button
                            onClick={() => {
                                setConfirmUnpin(true)
                            }}
                        >
                            Unpin
                        </Button>
                    )}

                    <Button
                        // component={Link}
                        // to={{
                        //   pathname: routes.COMMUNITY_VIEW.replace(
                        //     ':id',
                        //     community.id
                        //   ),
                        // }}
                        onClick={() => {
                            setConfirmDelete(true)
                        }}
                    >
                        Delete
                    </Button>

                    <PublishedComments
                        discussionId={discussion.id}
                        communityId={community.id}
                    />
                </>
            )}
        </Grid>
    )
}

export default Discussion
