import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { FaCheck, FaTimesCircle, FaUsers } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
    leaveCommunity,
    requestJoin,
    unrequestJoinCommunity,
} from '../../actions'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: '25px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    mediaLarge: {
        objectFit: 'cover',
        width: '100%',
        height: 320,
    },
    banner: {
        position: 'relative',
        maxWidth: theme.breakpoints.values.lg,
        marginTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
    },
    headingLargeBox: {
        color: 'white',
        position: 'absolute',
        top: '35%',
        left: 40,
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    headingLarge: {
        color: 'white',
        fontSize: '2.5em',
    },
    headingSmall: {
        color: 'white',
        position: 'absolute',
        top: '20%',
        left: 30,
        fontSize: '2.5em',
        // backgroundColor: 'rgba(77, 77, 77, 0.8)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    back: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        marginLeft: theme.spacing(0),
    },
    backContainer: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
    },
    blurb: {
        position: 'absolute',
        bottom: 20,
        left: 30,
        fontSize: '1.25em',
        color: 'white',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: 'rgba(77, 77, 77, 0.8)',
    },
    smallList: {
        marginTop: theme.spacing(1),
        maxWidth: theme.breakpoints.values.md,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    mainBox: {
        maxWidth: theme.breakpoints.values.lg,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    communityActions: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    communityDescription: {
        paddingLeft: theme.spacing(1),
        color: '#666',
    },
    nothingMessage: {
        textAlign: 'center',
        color: '#666',
    },
    admin: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    progressBox: {
        textAlign: 'center',
        margin: theme.spacing(2),
    },
    messageBox: {
        padding: theme.spacing(2),
    },
    textBox: {
        padding: theme.spacing(1),
    },
    memberCount: {
        color: '#666',
        textAlign: 'center',
        width: 180,
    },

    divider: {
        borderTop: '1px solid #ccc',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '60px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    messageText: {
        color: '#666',
        textAlign: 'center',
    },
    joinButton: {
        marginTop: theme.spacing(2),
    },
    tags: {
        marginBottom: theme.spacing(1),
    },
    chipItem: {
        marginRight: theme.spacing(1),
    },
    startDiscussion: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    discussionTitle: {
        backgroundColor: 'white',
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    discussionBody: {
        backgroundColor: 'white',
        width: '100%',
    },
    discussionAdd: {
        maxWidth: 600,
        padding: theme.spacing(2),
    },
    discussionHeading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    discussionMessage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },

    emphasis: {
        color: '#512da8',
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    postToCircle: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    postCancel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}))
function NotMember() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [following, setFollowing] = useState('no')
    const [hovering, setHovering] = useState(false)

    const profile = useSelector((state) => state.profile.profile)
    const community = useSelector((state) => state.community.community)
    const comId = community.id

    useEffect(() => {
        console.log(community)
        console.log(profile.requested_community_ids)
        if (profile?.requested_community_ids?.includes(comId))
            setFollowing('requested')

        if (community?.member_profile_ids?.includes(profile.id))
            setFollowing('joined')
    }, [profile, community.member_profile_ids, comId])
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.messageBox}
        >
            <FaUsers size="2em" />
            <Typography variant="h4">{community.name}</Typography>
            {community.member_profile_ids && (
                <Typography variant="body" className={classes.memberCount}>
                    {community.member_profile_ids.length} member
                    {community.member_profile_ids.length !== 1 && 's'}{' '}
                </Typography>
            )}

            <Divider className={classes.divider} />
            <Typography className={classes.messageText} variant="body">
                You are not currently a member of this Istoria Circle.
                <br />
                To view and participate in <b>{community.name}</b>, you can
                request to join.
            </Typography>

            {following === 'no' && (
                <Button
                    className={classes.button}
                    onClick={() => {
                        dispatch(requestJoin(comId))
                        setFollowing('requested')
                    }}
                    variant="outlined"
                >
                    Request to Join
                </Button>
            )}

            {following === 'requested' && (
                <Button
                    className={classes.button}
                    onClick={() => {
                        dispatch(unrequestJoinCommunity(comId))
                        setFollowing('no')
                    }}
                    variant="outlined"
                >
                    Cancel Request
                </Button>
            )}
            {following === 'joined' && (
                <Button
                    className={classes.button}
                    onClick={() => {
                        if (hovering) {
                            dispatch(leaveCommunity(comId))
                        }
                    }}
                    onMouseEnter={() => {
                        setHovering(true)
                    }}
                    onMouseLeave={() => {
                        setHovering(false)
                    }}
                    variant="outlined"
                    startIcon={hovering ? <FaTimesCircle /> : <FaCheck />}
                    color={hovering ? '' : 'primary'}
                >
                    {hovering ? 'Leave' : 'Joined'}
                </Button>
            )}
        </Grid>
    )
}

export default NotMember
